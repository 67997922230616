import { Link } from 'react-router-dom';
import { Button } from '../../../ui';
import { Approutes, LOCAL_STORAGE_KEYS } from '../../../constants';
import { WelcomeCheckMark, WelcomeHourGlass, WelcomeRejected } from '../../../assets/svgs';
import { useDecodedUser, useNotify, useResubmitProfile } from '../../../hooks';
import { handleApiError } from '../../../utilities';
import { useState } from 'react';

const InstitutionFlow = () => {
	const notify = useNotify();
	const user = useDecodedUser();
	const [hasResubmittedProfile, setHasResubmittedProfile] = useState(false);

	const { mutate: resubmitProfile, isLoading: isResubmitting } = useResubmitProfile();

	const handleResubmitProfile = () => {
		resubmitProfile(user?.user_id, {
			onSuccess: () => {
				setHasResubmittedProfile(true);
				localStorage.setItem(LOCAL_STORAGE_KEYS.has_resubmitted_profile, 'true');
			},
			onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'resubmit_profile_error' } }),
		});
	};

	return user?.institution_admin_profile_status === 'DECLINED' &&
		!hasResubmittedProfile &&
		!localStorage.getItem(LOCAL_STORAGE_KEYS.has_resubmitted_profile) ? (
		<div className="verification-con">
			<WelcomeRejected />
			<h1>Profile Declined</h1>
			<p>
				You profile has been declined by an admin. The reason has been sent to your email. Make the needed changes in{' '}
				<Link to={Approutes.profile}>your profile</Link> and reapply for verification.
			</p>
			<Button onClick={handleResubmitProfile} loading={isResubmitting}>
				Reapply for verification
			</Button>
		</div>
	) : user?.institution_admin_profile_status === 'PENDING' ||
	  hasResubmittedProfile ||
	  localStorage.getItem(LOCAL_STORAGE_KEYS.has_resubmitted_profile) === 'true' ? (
		<div className="verification-con">
			<WelcomeHourGlass className="hourglass" />
			<h1>Awaiting Verification</h1>
			<p>
				You are profile is currently going through the verification stage by <br />
				Descinder's admin. Check back within 72 Hours.
			</p>
		</div>
	) : user?.institution_admin_profile_status === 'APPROVED' ? (
		<div className="verification-con">
			<WelcomeCheckMark />
			<h1>Profile Verified</h1>
			<p>
				You profile has been verified. Welcome to Descinder. <br /> You can now start inviting interns
			</p>

			<Link to={Approutes.institution_admin.index}>
				<Button>Go to Dashboard</Button>
			</Link>
		</div>
	) : (
		<div>
			<h1>Welcome to Descinder</h1>
			<p>We're glad to have you onboard</p>

			<Link to={Approutes.institution_admin.index}>
				<Button>Go to Dashboard</Button>
			</Link>
		</div>
	);
};
export default InstitutionFlow;
