import styled from 'styled-components';
import { CheckMark } from '../../../../assets/svgs';
import { StyledButton } from '../../../../ui/Button/styles';
import { maxQuery, minQuery } from '../../../../utilities';

export const Container = styled.div`
	min-height: calc(100vh - 18rem);

	> * {
		background: #fff;
	}

	center {
		height: calc(100vh - 24rem);
		display: grid;
		place-items: center;
	}
	.empty-state {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		border-radius: 0.25rem;
		padding: 1rem;
		height: calc(100vh - 24rem);

		&.sub {
			margin-bottom: 1rem;
		}

		&.smaller {
			height: 12rem;
		}

		p {
			color: #1e1e1e;
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 135%;
			margin-top: 1rem;
		}
		span {
			text-transform: capitalize;
		}

		button {
			text-decoration: underline;
			background-color: transparent;
		}
	}

	.package-payment-con {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem 2rem;
		border-radius: 0.25rem 0.25rem 0 0;

		padding: 2.5rem 3.5rem 0;
		padding: clamp(1.5rem, 0.9444rem + 1.7778vw, 2.5rem) clamp(1.5rem, 0.3889rem + 3.5556vw, 3.5rem) 0;

		${maxQuery('lg')} {
			grid-template-columns: 1fr;
		}

		> div {
			border-radius: 0.25rem;
			border: 1px solid rgba(18, 18, 18, 0.05);
			padding: 2rem 2.5rem;

			:nth-child(1) {
				.header {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-bottom: 0.75rem;

					span {
						color: #bbbaba;
						font-weight: 600;
						font-size: 0.875rem;
					}
				}
				h4 {
					color: #4f5362;
					font-family: 'Urbanist', sans-serif;
					font-weight: 700;
					line-height: 1.37;
					letter-spacing: 0.05894rem;
					font-size: 1rem;
				}
				ul {
					color: #4f5362;
					font-size: 0.88425rem;
					line-height: 150%;
					letter-spacing: 0.02456rem;
					text-align: left;
					margin-bottom: 1rem;
					padding-left: 1.5rem;

					li {
						margin-bottom: 0.5rem;
						position: relative;

						::before {
							content: '';
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							left: -1.3rem;
							background-image: url(${CheckMark});
							background-size: cover;
							background-repeat: no-repeat;
							background-position: center;
							width: 0.8rem;
							height: 0.8rem;
						}
					}
				}
				${StyledButton} {
					padding: 0.875rem 2rem;
					width: 100%;
				}
			}
			:nth-child(2) {
				h4 {
					color: #11071a;
					font-family: 'Urbanist', sans-serif;
					line-height: 1.37;
					font-size: 1rem;
					font-weight: 600;
					margin-bottom: 0.91rem;
				}
				> div {
					display: flex;
					justify-content: space-between;
					align-items: center;

					:not(:last-child) {
						margin-bottom: 1rem;
					}
				}
				b {
					color: #494848;
					font-size: 0.875rem;
					font-weight: 600;
				}
				span {
					color: #bbbaba;
					font-size: 0.875rem;
					font-weight: 500;
				}
			}
			hr {
				margin-bottom: 1.25rem;
				border-color: #1212120d;
			}
		}
	}

	h2 {
		color: #11071a;
		font-family: 'Urbanist', sans-serif;
		font-size: 1.25rem;
		font-weight: 700;
		padding: 3.25rem 3.5rem 0.375rem;
		padding: 3.25rem clamp(1.5rem, 0.3889rem + 3.5556vw, 3.5rem) 0.375rem;
		border-radius: 0.25rem 0.25rem 0 0;
	}

	.table-container {
		overflow: auto;

		border-radius: 0 0 0.25rem 0.25rem;
		padding: 0 3.5rem 2.5rem;
		padding: 0 clamp(1.5rem, 0.3889rem + 3.5556vw, 3.5rem) clamp(1.5rem, 0.9444rem + 1.7778vw, 2.5rem);
	}
	table {
		width: 100%;
		min-width: max-content;
		border-collapse: collapse;

		${minQuery('300px')} {
			> * > tr > *:first-child {
				left: 0;
				position: sticky;
			}
		}

		thead {
			tr {
				border-bottom: 1px solid #1212120d;
			}
			th {
				padding: 0.875rem 1rem;
				text-align: inherit;
				color: #bbbaba;
				font-weight: 500;
				background-color: #fff;

				:first-child {
					padding-left: 0;
				}
			}
		}

		tbody {
			tr {
				:not(:last-child) {
					border-bottom: 1px solid #1212120d;
				}
			}

			td {
				font-weight: 500;
				padding: 1.25rem 1rem;
				color: #757575;
				background-color: #fff;

				:first-child {
					padding-left: 0;
				}
				:last-child {
					display: flex;
					align-items: center;
					gap: 1rem;

					a {
						display: flex;
						align-items: center;
					}

					svg {
						cursor: pointer;

						path {
							fill: #4060ea;
						}
					}
				}
			}
		}
	}
	.invoice {
		display: flex;
		align-items: center;
		gap: 0.72rem;
	}
	.status {
		color: #007f61;
		font-weight: 500;
		border-radius: 3.6rem;
		background: rgba(0, 127, 97, 0.1);
		display: flex;
		padding: 0.43rem 0.72rem;
		align-items: center;
		gap: 0.36rem;
		width: fit-content;
		font-size: 0.875rem;
		text-transform: capitalize;

		&.declined {
			color: #ea2d2e;
		}
		&.approved {
			color: #017d34;
		}
		&.refunded {
			background-color: #fff6e9;
			color: #c08030;

			path {
				fill: #c08030;
			}
		}
	}
`;
