import styled from 'styled-components';

export const Container = styled.header`
	position: sticky;
	top: 0;
	z-index: 50;
	background: #fff;
	padding: 0.5rem 6.25rem 0.25rem;
	padding: 0.5rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);
	max-height: 108px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

	> div {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.logout-icon {
			cursor: pointer;
		}
	}
`;
