import { APPLICANT_SKILLS } from '../../../constants';
import { Button, InputGroup, SelectGroup, Tag } from '../../../ui';
import { Tags } from '../../../ui/Tag/styles';
import { getDateTime } from '../../../utilities';

const ProfileInfo = ({ isEditing, userDetails, handleChange, formData, isSubmitting, handleSubmit }) => {
	return isEditing ? (
		<div className="inputs-group">
			<InputGroup label="Name" name="name" onChange={handleChange} value={formData.name} placeholder="Enter Name" required />

			<InputGroup
				label="Contact Number"
				name="contact_number"
				onChange={handleChange}
				value={formData.contact_number}
				placeholder="Enter Contact Number"
				required
			/>

			<InputGroup
				label="About"
				variant="textarea"
				name="about"
				onChange={handleChange}
				value={formData.about}
				placeholder="Enter About"
				required
			/>

			<SelectGroup
				label="My Skills"
				name="skills"
				onChange={handleChange}
				placeholder="Select your skills"
				value={formData.skills}
				options={APPLICANT_SKILLS}
				required
				variant="multiple"
				dropdownPosBottom={false}
				shouldFilterByDefault={false}
			/>

			<Button className="submit-btn" loading={isSubmitting} onClick={handleSubmit}>
				Save Changes
			</Button>
		</div>
	) : (
		<div className="info-con">
			<div>
				<div>
					<span>Name</span>
					<b>{userDetails?.name}</b>
				</div>
			</div>
			<div>
				<div>
					<span>About</span>
					<b>{userDetails?.about}</b>
				</div>
			</div>
			<div>
				<div>
					<span>Phone</span>
					<b>{userDetails?.contact_number}</b>
				</div>
				<div>
					<span>Registration Date</span>
					<b>{getDateTime(userDetails?.created_at)}</b>
				</div>
			</div>
			<div>
				<div>
					<span>Skills</span>
					<Tags>
						{userDetails?.skills?.map((skill) => (
							<Tag tag={skill} key={skill} hideClose />
						))}
					</Tags>
				</div>
			</div>
		</div>
	);
};
export default ProfileInfo;
