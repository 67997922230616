import styled from 'styled-components';
import { StyledButton } from '../../../ui/Button/styles';
import { StyledPagination } from '../../../ui/Pagination/styles';
import { maxQuery } from '../../../utilities';
import { StyledSearchBar } from '../../../ui/SearchBar/styles';

export const Container = styled.section`
	border-radius: 0.25rem;
	background: #fff;
	box-shadow: -9px 19px 40px 0px rgba(0, 0, 0, 0.02);
	padding: 1.5rem;
	display: grid;
	gap: 3rem;
	grid-template-columns: 31fr 19fr;
	min-height: calc(100vh - (5.3rem + 134px));

	${maxQuery('xl')} {
		grid-template-columns: 1fr;
		gap: 1rem;
		background-color: transparent;
		padding: 0;
	}

	> section {
		background: #fff;
		overflow: hidden;

		${maxQuery('xl')} {
			padding: 1.5rem;
			border-radius: 0.25rem;
		}

		.status {
			color: #017d34;
			font-size: 0.875rem;
			font-weight: 500;
			position: relative;
			padding-left: 0.9rem;
			text-transform: capitalize;

			&.pending {
				color: #c08030;

				::before {
					background-color: #c08030;
				}
			}

			::before {
				content: '';
				width: 0.4375rem;
				height: 0.4375rem;
				background-color: #017d34;
				border-radius: 50%;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		.title {
			display: flex;
			gap: 0.5rem;
			align-items: center;
			flex-wrap: wrap;
			margin: 1rem 0 0.5rem;

			b {
				color: #11071a;
				font-size: 1.5rem;
				font-size: clamp(1.25rem, 1.1111rem + 0.4444vw, 1.5rem);
				font-weight: 700;
				line-height: 132%;
			}

			span {
				background: #fcf8f3;
				color: #c08030;
				font-size: 0.75rem;
				font-weight: 700;
				line-height: 120%;
				text-transform: uppercase;
				border-radius: 6.25rem;
				padding: 0.3125rem 0.625rem;
			}
		}
		.title + p {
			font-weight: 500;
			line-height: 140%;
			border-bottom: 1px solid #ebebeb;
			padding-bottom: 1.5rem;
		}

		.attachments {
			padding-bottom: 1.5rem;
			border-bottom: 1px solid #ebebeb;
			overflow: hidden;

			h6 {
				color: #000;
				font-family: 'Urbanist', sans-serif;
				font-size: 1rem;
				margin: 1.25rem 0 1.5rem;
				display: block;
			}
		}

		.tasks {
			center,
			.empty-state {
				height: 300px;
			}
			> div {
				:first-child {
					display: flex;
					align-items: center;
					margin: 1.5rem 0;

					h4 {
						color: #11071a;
						font-family: 'Urbanist', sans-serif;
						font-size: 1.25rem;
						font-weight: 600;
						margin-right: auto;
					}

					${StyledSearchBar} {
						${maxQuery('sm')} {
							width: 12rem;
						}
						${maxQuery('xs')} {
							display: none;
						}
					}
					${StyledButton} {
						padding: 0.5rem 1.5rem;
						min-height: 3.2375rem;
						margin-left: 1rem;

						svg {
							display: none;
						}

						${maxQuery('sm')} {
							padding: 0.5rem 1rem;

							.text-con {
								display: none;
							}
							svg {
								display: block;
							}
						}
					}
				}
				:nth-child(2) {
					div {
						border-radius: 0.25rem;
						border: 1px solid #dadada;
						padding: 0.7rem;
						display: flex;
						align-items: center;

						:not(:last-child) {
							margin-bottom: 0.7rem;
						}

						span,
						a {
							:nth-child(1) {
								display: grid;
								place-items: center;
								cursor: pointer;

								&.active path {
									fill: #4060ea;
								}
							}
							:nth-child(2) {
								margin: 0 auto 0 0.7rem;
								display: -webkit-box;
								overflow: hidden;
								-webkit-line-clamp: 1;
								-webkit-box-orient: vertical;
							}
							:nth-child(3),
							:nth-child(4),
							:nth-child(5) {
								padding: 0.3125rem;
								display: grid;
								place-items: center;
								cursor: pointer;
							}
						}
						.edit-icon path {
							stroke: #4060ea;
						}
					}
				}
			}
			${StyledPagination} {
				margin-top: 1.5rem;
			}
		}
	}

	aside {
		border-radius: 0.25rem;
		border: 1px solid rgba(18, 18, 18, 0.05);
		background: #fff;
		padding: 2rem 1.5rem 1.5rem;
		display: grid;

		h4 {
			color: #11071a;
			font-family: 'Urbanist', sans-serif;
			font-size: 1.25rem;
			font-weight: 600;
			border-bottom: 1px solid #ebebeb;
			padding-bottom: 0.62rem;
			margin-bottom: 1.5rem;
		}
		h6 {
			color: #a1a0a0;
			font-family: 'Urbanist', sans-serif;
			font-size: 0.875rem;
			font-weight: 600;
		}
		.timeline {
			display: flex;
			justify-content: space-between;
			gap: 1rem;
			flex-wrap: wrap;

			div {
				font-size: 1rem;
				font-weight: 600;

				h6 {
					margin-bottom: 0.5rem;
				}
			}
		}
		.resources {
			margin: 1.88rem 0;

			h6 {
				margin-bottom: 0.88rem;
			}

			div {
				display: flex;
				align-items: center;
				gap: 0.62rem;
				color: #494848;
				font-size: 0.875rem;
				font-weight: 500;
				line-height: 135%;
				border-radius: 0.25rem;
				border: 1px solid #dadada;
				padding: 0.62rem 0.69rem;

				:not(:last-child) {
					margin-bottom: 0.5rem;
				}

				span {
					display: -webkit-box;
					overflow: hidden;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
				.name {
					margin-right: auto;
				}
				.duration {
					margin-right: 2rem;
					font-weight: 600;
					color: #4060ea;
				}
				svg {
					cursor: pointer;
				}
			}
		}
		.resource-tags h6 {
			margin-bottom: 0.62rem;
		}
		.supervisor {
			margin-top: 1.87rem;

			h6 {
				margin-bottom: 0.87rem;
			}

			div {
				display: flex;
				align-items: center;
				gap: 0.68rem;
				font-size: 1rem;
				font-weight: 600;

				img {
					width: 1.375rem;
					height: 1.375rem;
				}
			}
		}
		.completion-status {
			margin: 1.87rem 0 3rem;

			h6 {
				margin-bottom: 0.87rem;
			}

			div {
				color: #a1a0a0;
				font-size: 0.875rem;
				font-weight: 600;
				display: flex;
				align-items: center;
				gap: 1rem;
			}
		}
		${StyledButton} {
			width: 100%;
			justify-content: center;
			margin-top: auto;
		}
	}
`;

export const Range = styled.div`
	flex: 1;
	border-radius: 0.625rem;
	background: #f6f7fe;
	height: 0.625rem;
	position: relative;

	::before {
		content: '';
		position: absolute;
		inset: 0 ${({ $percent }) => `${100 - $percent}%`} 0 0;
		border-radius: 0.625rem;
		background: #4060ea;
	}
`;
