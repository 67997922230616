import { useRef, useState } from 'react';
import { Button, InputGroup } from '../../../../ui';
import { Container } from './styles';
import { handleApiError, hasMin1Num, hasMin1Special, hasMinOf8Chars } from '../../../../utilities';
import { useMutation } from '@tanstack/react-query';
import { useAxiosPrivate, useNotify } from '../../../../hooks';
import { Appurls } from '../../../../constants';

const Password = () => {
	const errorRef = useRef();
	const notify = useNotify();
	const axiosPrivate = useAxiosPrivate();
	const [formData, setFormData] = useState({
		password: '',
		new_password: '',
		confirm_password: '',
	});

	const { mutate: changePassword, isLoading } = useMutation({
		mutationFn: (data) => axiosPrivate.post(Appurls.auth.password.change_password, data),
	});

	const handleChange = (event) => {
		let name = event.target.name || '';
		let value = event.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
		errorRef.current.innerText = '';
	};
	const handleSubmit = (event) => {
		event.preventDefault();

		if (formData.new_password !== formData.confirm_password) {
			errorRef.current.innerText = 'Passwords do not match.';
			return;
		}
		if (!hasMinOf8Chars(formData.new_password)) {
			errorRef.current.innerText = 'Minimum of 8 characters required';
			return;
		}
		if (!hasMin1Special(formData.new_password)) {
			errorRef.current.innerText = 'Minimum of 1 special character required';
			return;
		}
		if (!hasMin1Num(formData.new_password)) {
			errorRef.current.innerText = 'Minimum of 1 number required';
			return;
		}
		if (formData.password === formData.new_password) {
			errorRef.current.innerText = "New password can't be the same as old password";
			return;
		}

		changePassword(
			{
				old_password: formData.password,
				new_password: formData.new_password,
			},
			{
				onSuccess: () => {
					setFormData({
						password: '',
						new_password: '',
						confirm_password: '',
					});
					notify({ message: 'Your password has been changed successfully', status: 'success', toastOptions: { toastId: 'password_success' } });
				},
				onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'password_error' } }),
			}
		);
	};
	return (
		<Container>
			<form className="password-con" onSubmit={handleSubmit}>
				<InputGroup
					label="Current Password"
					type="password"
					name="password"
					onChange={handleChange}
					placeholder="Enter Current Password"
					value={formData.password}
					required
				/>
				<InputGroup
					label="New password"
					type="password"
					name="new_password"
					onChange={handleChange}
					placeholder="Enter New Password"
					value={formData.new_password}
					required
					minLength={4}
				/>
				<InputGroup
					label="Confirm password"
					type="password"
					name="confirm_password"
					onChange={handleChange}
					placeholder="Confirm Password"
					value={formData.confirm_password}
					required
				/>
				<p className="error" ref={errorRef}></p>

				<Button type="submit" loading={isLoading}>
					Change Password
				</Button>
			</form>

			<div className="rules">
				<h4>Password rules</h4>
				<p>To create a new password, you have to meet all the following requirements.</p>
				<ul>
					<li className={hasMinOf8Chars(formData.new_password) ? 'active' : ''}>Minimum of 8 characters</li>
					<li className={hasMin1Special(formData.new_password) ? 'active' : ''}>At least one special character</li>
					<li className={hasMin1Num(formData.new_password) ? 'active' : ''}>At least one number</li>
					<li
						className={formData.password.length && formData.new_password.length && formData.password !== formData.new_password ? 'active' : ''}
					>
						Can’t be the same as old password
					</li>
				</ul>
			</div>
		</Container>
	);
};
export default Password;
