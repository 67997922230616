import { COMPANY_SECTORS, COMPANY_SIZE } from '../../../constants';
import { Button, InputGroup, SelectGroup } from '../../../ui';

const Information = ({ userDetails, isEditing, handleChange, formData, isSubmitting, handleSubmit }) => {
	return isEditing ? (
		<div className="inputs-group">
			<SelectGroup
				label="Company Sector"
				name="sector"
				onChange={handleChange}
				value={formData.sector}
				options={COMPANY_SECTORS}
				placeholder="Select Company Sector"
				required
				shouldFilterByDefault={false}
				dropdownPosBottom={false}
			/>

			<SelectGroup
				label="Company size"
				name="size"
				onChange={handleChange}
				value={formData.size}
				options={COMPANY_SIZE}
				placeholder="Enter Company size"
				required
				shouldFilterByDefault={false}
				dropdownPosBottom={false}
			/>

			<InputGroup
				label="Website Url"
				name="website_url"
				onChange={handleChange}
				value={formData.website_url}
				placeholder="Enter Website Url"
				required
			/>

			<Button className="submit-btn" loading={isSubmitting} onClick={handleSubmit}>
				Save Changes
			</Button>
		</div>
	) : (
		<div className="info-con">
			<div>
				<div>
					<span>Company Sector</span>
					<b>{userDetails?.sector}</b>
				</div>
			</div>
			<div>
				<div>
					<span>Company size</span>
					<b>{userDetails?.size}</b>
				</div>
			</div>
			<div>
				<div>
					<span>Website Url</span>
					<b>{userDetails?.website_url || 'N/A'}</b>
				</div>
			</div>
		</div>
	);
};
export default Information;
