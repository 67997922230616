import styled from 'styled-components';
import { StyledInputGroup } from '../../../../ui/InputGroup/styles';
import { StyledButton } from '../../../../ui/Button/styles';
import { maxQuery } from '../../../../utilities';

export const Container = styled.div`
	border-radius: 0.25rem;
	background: #fff;
	box-shadow: -9px 19px 40px 0px rgba(0, 0, 0, 0.02);
	padding: 3.5rem;
	padding: clamp(1.5rem, 0.3889rem + 3.5556vw, 3.5rem);
	min-height: calc(100vh - 18rem);
	display: grid;
	gap: 1.5rem;
	grid-template-columns: 1fr 1fr;

	${maxQuery('md')} {
		display: flex;
		flex-direction: column-reverse;
	}

	.password-con {
		max-width: 32rem;

		${StyledInputGroup} {
			margin-bottom: 1.25rem;
		}
		.error {
			color: #ff7542;
			font-weight: 600;
			font-size: 0.875rem;
			margin: 0;
		}
		${StyledButton} {
			margin-top: 2rem;
			width: 100%;
		}
	}

	.rules {
		border-radius: 0.25rem;
		background: rgba(204, 213, 249, 0.2);
		padding: 3rem;
		padding: clamp(1rem, -0.1111rem + 3.5556vw, 3rem);

		h4 {
			color: #12091b;
			font-family: 'Urbanist', sans-serif;
			font-size: 1.25rem;
			font-size: clamp(0.875rem, 0.6667rem + 0.6667vw, 1.25rem);
			font-weight: 600;
			margin-bottom: 0.5rem;
		}
		p {
			font-size: 1.125rem;
			font-size: clamp(0.75rem, 0.5417rem + 0.6667vw, 1.125rem);
			margin-bottom: 1rem;
		}
		ul {
			color: #757575;
			font-size: 1.125rem;
			font-size: clamp(0.75rem, 0.5417rem + 0.6667vw, 1.125rem);
			padding-left: 1rem;

			li {
				list-style-type: disc;
				margin-bottom: 0.5rem;

				&.active {
					color: #21b787;
					font-weight: 500;
				}
			}
		}
	}
`;
