import { useLogout } from '../../../hooks';
import { Container } from './styles';

const SomethingWentWrong = () => {
	const logout = useLogout();

	return (
		<Container>
			<h1>
				You shouldn't be seeing <br /> this page
			</h1>
			<br />
			<p>
				Please <span onClick={() => logout()}>logout</span> and login again
			</p>
		</Container>
	);
};
export default SomethingWentWrong;
