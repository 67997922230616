import styled from 'styled-components';

export const StyledInputGroup = styled.div`
	position: relative;

	:focus-within {
		label {
			color: #4060ea;
		}
		input,
		textarea {
			outline: 1.5px solid #4060ea;
		}
	}
	label {
		color: #494848;
		font-weight: 500;
		font-size: 0.875rem;
		line-height: 120%;
		margin-bottom: 0.5rem;
		display: flex;
		align-items: center;

		.asterisk {
			color: #ea2d2e;
			font-size: 1.1rem;
			transform: translateY(0.16em);
			margin-left: 0.4rem;
		}
		.input-info-con {
			position: relative;
			display: flex;
			align-items: center;
			margin-left: 1rem;

			&:focus-within .outer {
				opacity: 1;
			}

			svg {
				cursor: pointer;
				outline: none;
			}

			.outer {
				position: absolute;
				bottom: calc(100% + 0.75rem);
				right: -0.4rem;
				width: max-content;
				max-width: 12.5rem;
				display: flex;
				opacity: 0;
				transition: opacity 0.5s ease-in;
				pointer-events: none;
				z-index: 2;

				::before {
					content: '';
					position: absolute;
					background-color: #ffffff;
					z-index: 1;
					border-radius: 0.25rem;
					border: 1px solid #dadada;
					width: 1rem;
					height: 1rem;
					right: 1.25rem;
					top: 100%;
					transform: rotate(45deg) translateY(calc(-50% - 0.5rem));
				}

				span {
					position: relative;
					z-index: 2;
					background: #ffffff;
					border: 1px solid #dadada;
					font-weight: 500;
					font-size: 0.6875rem;
					line-height: 1.73;
					color: #494848;
					padding: 0.8125rem 1rem;
					border-radius: 0.25rem;

					::before {
						content: '';
						position: absolute;
						background-color: #ffffff;
						z-index: 1;
						border-radius: 0.25rem;
						border: 1px solid transparent;
						width: calc(1rem - 1px);
						height: calc(1rem - 1px);
						right: calc(1.25rem - 1px);
						top: 100%;
						transform: rotate(45deg) translateY(calc(-50% - 0.5rem));
					}
				}
			}
		}
	}

	input,
	textarea {
		display: block;
		width: 100%;
		padding: 1rem 1.5rem;
		font-family: inherit;
		font-size: 0.875rem;
		line-height: 130%;
		border-radius: 0.125rem;
		border: 1px solid rgba(229, 229, 229, 0.7);
		background: rgba(229, 229, 229, 0.1);

		:disabled {
			cursor: not-allowed;
			opacity: 0.8;
		}
		:focus {
			background-color: #fff;
		}
	}
	input[type='password']:valid {
		letter-spacing: 0.25rem;
	}
	textarea {
		resize: vertical;
		min-height: 5rem;
	}
	.action-con {
		position: absolute;
		bottom: 1rem;
		right: 0.75rem;
		display: grid;
		cursor: pointer;

		svg:not(.active) {
			display: none;
		}
	}
	.selected-options {
		display: flex;
		flex-wrap: wrap;
		gap: 0.5rem;
		margin-top: 0.625rem;

		span {
			display: flex;
			padding: 0.625rem 1rem;
			align-items: center;
			gap: 0.25rem;
			border-radius: 6.25rem;
			background: #f9f9f9;
			font-size: 0.75rem;
			font-weight: 500;
			line-height: 132%;
		}
		svg {
			cursor: pointer;
		}
	}
	.input-multiple-group {
		position: relative;

		button {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			color: #4060ea;
			font-size: 0.6185rem;
			font-weight: 800;
			letter-spacing: -0.0025rem;
			padding: 0 1.25rem;
			border-radius: 0.25rem;
			background-color: transparent;
		}
	}
`;
