import { useEffect, useRef, useState } from 'react';
import { Container } from './styles';
import { useDecodedUser, useGetMessages, useMarkMessageAsRead, useMessage } from '../../../../hooks';
import { Loader } from '../../../../ui';
import UserMessage from '../UserMessage';
import RichTextArea from '../RichTextArea';
import { ChevronDown, MessageNotificationClose } from '../../../../assets/svgs';
import { isIntern, isStartUp } from '../../../../utilities';

const RoomMessage = ({ roomId, newMessages, handleBack, closedNotificationCount, increaseCount }) => {
	const messageListTop = useRef(null);
	const messageListEnd = useRef(null);
	const user = useDecodedUser();
	const [hasClosedNotification, setHasClosedNotification] = useState(false);

	const [topElementIdBeforeRefetch, setTopElementIdBeforeRefetch] = useState('');

	const {
		data: messagesResponse,
		// refetch: refetchAllMessages,
		isLoading: isMessagesLoading,
		isError: isMessagesError,
		fetchNextPage: fetchMoreMessages,
		isFetchingNextPage: isFetchingMoreMessages,
		hasNextPage: messagesHasNextPage,
	} = useGetMessages({ search: '', room_id: roomId || '' });

	const { mutate: markAsRead } = useMarkMessageAsRead();
	const { handleHasMarkedMessageAsRead } = useMessage();

	const [messageHistory, setMessageHistory] = useState([]);

	const [newMessagesToAdd, setNewMessagesToAdd] = useState([]);

	useEffect(() => setHasClosedNotification(false), [roomId]);

	// Sort messages to show latest messages first and group messages by their date
	useEffect(() => {
		setMessageHistory(
			messagesResponse?.pages
				?.reduce((previous, current) => {
					const groupedByDate = {};

					previous?.forEach((group) => {
						groupedByDate[group?.date] = group?.messages || [];
					});
					current?.results?.forEach((group) => {
						groupedByDate[group?.date] = [...(group?.messages?.slice()?.reverse() || []), ...(groupedByDate[group?.date] || [])];
					});

					const messagesGroupedByDate = Object.keys(groupedByDate).map((key) => ({ date: key, messages: groupedByDate[key] }));

					return messagesGroupedByDate;
				}, [])
				?.reverse()
		);
	}, [messagesResponse?.pages, roomId]);

	// If there are new messages, merge them with the previous ones
	useEffect(() => {
		newMessages?.forEach((newMessage) => {
			const messages = [];

			messageHistory?.forEach((groups) => messages.push(...groups?.messages));

			!messages?.find((message) => newMessage?.id === message?.id) &&
				setNewMessagesToAdd((prev) => (!prev?.find((message) => message?.id === newMessage?.id) ? [...prev, newMessage] : prev));
		});
	}, [messageHistory, newMessages]);

	// Get new messages when the user gets to the top of the chat
	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					if (!messageHistory) return;

					setTopElementIdBeforeRefetch(messageListTop.current?.nextElementSibling.children?.[1]?.id);

					messagesHasNextPage ? fetchMoreMessages() : observer.unobserve(entry.target);
				}
			});
		}, {});

		messageListTop.current && observer.observe(messageListTop.current);

		return () => observer.disconnect();
	}, [messageHistory, messagesHasNextPage, fetchMoreMessages]);

	// Scroll to bottom of chat on initial load
	useEffect(() => {
		if (messagesResponse?.pageParams?.length !== 1) return;

		messageListEnd.current?.scrollIntoView();
	}, [messagesResponse?.pageParams, messageHistory]);

	// Scroll to the last message that was at the top before rerender and new messages enters at the top
	useEffect(() => {
		if (!messagesResponse?.pageParams || messagesResponse?.pageParams?.length === 1 || !topElementIdBeforeRefetch) return;

		document.querySelector(`#${topElementIdBeforeRefetch}`)?.scrollIntoView();

		// Scroll down a bit to prevent jumping
		// ONGOING
		// let hasSeen = false;
		// const element = document.querySelector(`#${topElementIdBeforeRefetch}`);

		// const lastTopElelemtScrollHeight = [...document.querySelectorAll('.message-body div:nth-child(2) > *')].reduce(
		// 	(previous, currentElement) => {
		// 		if (currentElement.id === element?.id) {
		// 			hasSeen = true;
		// 		}
		// 		return hasSeen ? previous : previous + element?.clientHeight;
		// 	},
		// 	0
		// );
		// console.log(lastTopElelemtScrollHeight);
	}, [messagesResponse, topElementIdBeforeRefetch]);

	// Mark last message as read
	useEffect(() => {
		const allMessages = [...(messageHistory?.[messageHistory.length - 1]?.messages || []), ...newMessagesToAdd];
		const lastMessage = allMessages?.[allMessages.length - 1];

		if (lastMessage?.id && lastMessage?.user_message === false) {
			markAsRead(lastMessage?.id, {
				onSuccess: () => {
					handleHasMarkedMessageAsRead(roomId);
				},
			});
		}
	}, [messageHistory, newMessagesToAdd, roomId, markAsRead, handleHasMarkedMessageAsRead]);

	useEffect(() => {
		setNewMessagesToAdd([]);
	}, [roomId]);

	if (isMessagesError) {
		return (
			<Container className={roomId ? 'active' : ''}>
				<div>
					<div className="heading">
						<div>
							<button className="back-btn" onClick={handleBack}>
								<ChevronDown />
							</button>
							<div></div>
						</div>
					</div>

					<div className="message-body">
						<br />
						<br />
						<br />
						<center>
							<p>Something went wrong while trying to load your messages</p>
						</center>
					</div>
				</div>
			</Container>
		);
	}

	return (
		<Container className={roomId ? 'active' : ''}>
			{isMessagesLoading ? (
				<div className="messages-main-loader">
					<Loader />
				</div>
			) : (
				<div>
					<div className="heading">
						<div>
							<button className="back-btn" onClick={handleBack}>
								<ChevronDown />
							</button>
							<div>
								<h4>
									{messagesResponse?.pages?.[0]?.extra?.room_type === 'PERSONAL'
										? messagesResponse?.pages?.[0]?.extra?.participants?.name?.[0] === '#'
											? `Resource ${messagesResponse?.pages?.[0]?.extra?.participants?.name}`
											: messagesResponse?.pages?.[0]?.extra?.participants?.name
										: messagesResponse?.pages?.[0]?.extra?.room_type === 'PROJECT'
										? messagesResponse?.pages?.[0]?.extra?.project_name
										: null}
								</h4>
								{messagesResponse?.pages?.[0]?.extra?.room_type === 'PROJECT' ? (
									<span>{messagesResponse?.pages?.[0]?.extra?.participants?.length} participants</span>
								) : null}
							</div>
							{/* <i>...</i> */}
						</div>
					</div>

					{(isIntern(user) || isStartUp(user)) &&
					!hasClosedNotification &&
					closedNotificationCount < 5 &&
					messagesResponse?.pages?.[0]?.extra?.participants?.are_in_project === false ? (
						<p className="notification">
							<button
								onClick={() => {
									setHasClosedNotification(true);
									increaseCount();
								}}
							>
								<MessageNotificationClose />
							</button>

							{isIntern(user) ? (
								<>
									Please refrain from sharing personal information that could reveal your race or other sensitive details. This ensures that our
									platform, Descinder, remains diverse and inclusive in line with our community guidelines and terms of use.
								</>
							) : (
								<>
									Please refrain from asking for any personal information that could reveal their race or other sensitive details. This ensures that
									our platform, Descinder, remains diverse and inclusive in line with our community guidelines and terms of use.
								</>
							)}
						</p>
					) : null}

					<div className="message-body">
						{isFetchingMoreMessages ? (
							<div className="messages-mini-loader">
								<Loader />
							</div>
						) : null}
						<div ref={messageListTop}></div>

						{messageHistory?.length || newMessages?.length ? (
							<>
								{messageHistory?.map((messageGroup, groupIndex) => (
									<div key={messageGroup?.date}>
										{groupIndex !== 0 ? <br /> : null}
										<span className="day">{messageGroup?.date}</span>
										<br />

										{messageGroup?.messages?.map((message) => (
											<UserMessage
												key={message?.id}
												id={`id-${message?.id}`}
												profilePicture={message?.avatar}
												isMine={message?.user_message}
												name={message?.user_message ? 'Me' : message?.sender?.name}
												dateTime={message?.created_at}
												message={message?.text}
											/>
										))}
									</div>
								))}
								{messageHistory?.length && newMessagesToAdd?.length ? <br /> : null}
								{newMessagesToAdd?.map((message) => (
									<UserMessage
										key={message?.id}
										id={`id-${message?.id}`}
										profilePicture={message?.avatar}
										isMine={message?.user_message}
										name={message?.user_message ? 'Me' : message?.name}
										dateTime={message?.created_at}
										message={message?.text}
									/>
								))}
							</>
						) : (
							<center>
								<p>Start a conversation</p>
							</center>
						)}

						<div ref={messageListEnd}></div>
					</div>

					<RichTextArea
						receiverId={
							messagesResponse?.pages?.[0]?.extra?.room_type === 'PERSONAL'
								? messagesResponse?.pages?.[0]?.extra?.participants?.id
								: messagesResponse?.pages?.[0]?.extra?.room_type === 'PROJECT'
								? messagesResponse?.pages?.[0]?.extra?.project_id
								: null
						}
						scrollToMessageEnd={() => {
							setTimeout(() => messageListEnd.current?.scrollIntoView(), 100);
						}}
					/>
				</div>
			)}
			<aside></aside>
		</Container>
	);
};
export default RoomMessage;
