import { Button, FileDownloadGroup, FileGroup, InputGroup } from '../../../ui';

const Documentation = ({
	formData,
	isEditing,
	userDetails,
	handleChange,
	documentType,
	setDocumentType,
	handleEdit,
	isSubmitting,
	handleSubmit,
}) => {
	return !isEditing ? (
		<div className="info-con">
			<div>
				<div>
					<span>Certification Document</span>
					<div>
						{userDetails?.certification_docs?.[0]?.file_name ? (
							<FileDownloadGroup
								fileName={userDetails?.certification_docs?.[0]?.file_name}
								url={userDetails?.certification_docs?.[0]?.url}
								fileSizeInKb={userDetails?.certification_docs?.[0]?.file_size_in_kb}
							/>
						) : (
							<b>No file submitted</b>
						)}
					</div>
				</div>
				<div>
					<span>Enrollment Document</span>
					<div>
						{userDetails?.enrollment_doc?.[0]?.file_name ? (
							<FileDownloadGroup
								fileName={userDetails?.enrollment_doc?.[0]?.file_name}
								url={userDetails?.enrollment_doc?.[0]?.url}
								fileSizeInKb={userDetails?.enrollment_doc?.[0]?.file_size_in_kb}
							/>
						) : (
							<b>No file submitted</b>
						)}
					</div>
				</div>
			</div>
			<div>
				<div>
					<span>Profile Link</span>
					<b>{userDetails?.profile_link || 'N/A'}</b>
				</div>
			</div>
			<div>
				<div>
					<span>Supplimentary Document</span>
					{userDetails?.supplimentary_docs?.[0]?.file_name ? (
						<FileDownloadGroup
							fileName={userDetails?.supplimentary_docs?.[0]?.file_name}
							url={userDetails?.supplimentary_docs?.[0]?.url}
							fileSizeInKb={userDetails?.supplimentary_docs?.[0]?.file_size_in_kb}
						/>
					) : (
						<b>No file submitted</b>
					)}
				</div>
			</div>
		</div>
	) : (
		<div className="inputs-group">
			<div className="document-group">
				{documentType === 'enrollment' ? (
					<FileGroup
						label="Enrolment Document"
						name="enrolment_document"
						onChange={handleChange}
						helpTextTwo="PNG, JPEG, Doc, PDF. Max size of 1MB"
						accept="image/png, image/jpeg, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
						maxSize={1}
						value={formData.enrolment_document}
						required
					/>
				) : (
					<FileGroup
						label="Certification Document"
						name="certification_document"
						onChange={handleChange}
						helpTextTwo="PNG, JPEG, Doc, PDF. Max size of 1MB"
						accept="image/png, image/jpeg, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
						maxSize={1}
						value={formData.certification_document}
						required
					/>
				)}
				<button type="button" onClick={() => setDocumentType((prev) => (prev === 'enrollment' ? 'certification' : 'enrollment'))}>
					{documentType === 'enrollment'
						? "Don't have an enrollment document? Upload certification"
						: "Don't have an certification document? Upload enrollment"}
				</button>
			</div>
			<InputGroup
				label="Profile Link (Online Portfolio, Linkedin etc)"
				name="profile_link"
				onChange={handleChange}
				value={formData.profile_link}
				placeholder="Enter Link to Profile here"
			/>
			<FileGroup
				label="Supplementary Documents (Optional)"
				name="supplementary_document"
				onChange={handleChange}
				helpTextTwo="PNG, JPEG, Doc, PDF. Max size of 1MB"
				accept="image/png, image/jpeg, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
				maxSize={1}
				value={formData.supplementary_document}
			/>

			<Button className="submit-btn" loading={isSubmitting} onClick={handleSubmit}>
				Save Changes
			</Button>
		</div>
	);
};
export default Documentation;
