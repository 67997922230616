import styled, { keyframes } from 'styled-components';

export const Container = styled.section`
	display: flex;
	gap: 1.75rem;
	overflow: auto;
	padding-bottom: 1rem;

	&.loading {
		overflow: hidden;
	}
`;
const loading = keyframes`
		100% {
			background-position: -100% 0%;
		}
	`;

export const Pallet = styled.div`
	border-radius: 0.125rem;
	background: #fff;
	padding: 2rem;
	display: flex;
	align-items: center;
	gap: 1.5rem;
	width: max-content;
	flex: 1;

	&.loading {
		background-color: rgba(255, 255, 255, 0.8);

		h6,
		p,
		.img {
			background: linear-gradient(90deg, #e7e5e5 -24.18%, #f9f8f8 50.26%, #e7e5e5 114.84%);
			display: block;
			animation: ${loading} 1s infinite linear;
			background-size: 200% 100%;
			background-position: 100% 0%;
		}
		h6,
		p {
			height: 1rem;
			border-radius: 0.375rem;
		}
		h6 {
			width: 8rem;
		}
		p {
			width: 6rem;
		}
	}

	> div {
		width: max-content;
		flex-shrink: 0;
	}

	.img {
		background-color: #f6f7fe;
		width: 3.125rem;
		height: 3.125rem;
		border-radius: 50%;
		display: grid;
		place-items: center;
	}
	h6 {
		color: #11071a;
		font-family: 'Urbanist', sans-serif;
		font-size: 1.25rem;
		margin-bottom: 0.5rem;
	}
	p {
		color: #828282;
		font-size: 0.75rem;
		letter-spacing: 0.06rem;
		text-transform: uppercase;
	}
`;
