import { useEffect, useRef, useState } from 'react';
import { Button } from '../../ui';
import { Container } from './styles';

const OTP = ({ title, value = ['', '', '', ''], setValue, handleSubmit, isSubmitting, resendOTP, isSendingOTP }) => {
	const [timer, setTimer] = useState(30);
	const interval = useRef();

	const handleChange = (event) => {
		let inputValue = event.target.value || '';
		let index = event.target.dataset.index || '';
		let next = event.target.dataset?.next || 'null';
		let newValue = [...value];
		newValue[index] = inputValue;

		if (inputValue.length > 0) {
			document.querySelector(`#${next}`)?.focus();
		}
		if (inputValue.length > 1) {
			return;
		}

		setValue(newValue);
	};
	const handleKeyDown = (event) => {
		if (event.key === 'Backspace') {
			let inputValue = event.target.value || '';
			let index = event.target.dataset.index || '';
			let prev = event.target.dataset?.prev || 'null';

			if (inputValue.length === 0) {
				let newValue = [...value];
				newValue[parseInt(index) - 1] = '';

				setValue(newValue);

				document.querySelector(`#${prev}`)?.focus();
			}
		}
	};
	const handlePaste = () => {
		navigator.clipboard.readText().then((pastedText) => {
			const shavedText = pastedText.replace(/[^0-9]/g, '');

			let newValue = [];
			for (let i = 0; i < shavedText.length && i < 4; i++) {
				newValue[i] = shavedText[i];
			}

			document.querySelector(`#four`)?.focus();

			setValue(newValue);
		});
	};

	useEffect(() => {
		interval.current = setInterval(() => {
			setTimer((prev) => {
				if (prev === 0) {
					clearInterval(interval.current);
					return prev;
				} else {
					return prev - 1;
				}
			});
		}, 1000);

		return () => clearInterval(interval.current);
	}, [timer]);

	return (
		<Container>
			<div>
				{title ? <h6>{title}</h6> : null}
				<h2>Enter OTP</h2>
				<p>Provide 4 Digit OTP to verify your email address</p>

				<form onSubmit={handleSubmit}>
					<div className="input-group">
						<label htmlFor="OTP">Enter 4 Digit OTP</label>

						<div>
							<input
								type="number"
								value={value[0]}
								onKeyDown={handleKeyDown}
								onChange={handleChange}
								id="one"
								data-next="two"
								data-index={0}
								maxLength={1}
								required
								onPaste={handlePaste}
							/>
							<input
								type="number"
								value={value[1]}
								onKeyDown={handleKeyDown}
								onChange={handleChange}
								id="two"
								data-prev="one"
								data-next="three"
								data-index={1}
								maxLength={1}
								required
								onPaste={handlePaste}
							/>
							<input
								type="number"
								value={value[2]}
								onKeyDown={handleKeyDown}
								onChange={handleChange}
								id="three"
								data-prev="two"
								data-next="four"
								data-index={2}
								maxLength={1}
								required
								onPaste={handlePaste}
							/>
							<input
								type="number"
								value={value[3]}
								onKeyDown={handleKeyDown}
								onChange={handleChange}
								id="four"
								data-prev="three"
								data-index={3}
								maxLength={1}
								required
								onPaste={handlePaste}
							/>
						</div>
					</div>

					<Button type="submit" disabled={isSubmitting || isSendingOTP} loading={isSubmitting}>
						Verify Email
					</Button>
					<Button
						variant="secondary"
						type="button"
						disabled={isSubmitting || isSendingOTP || timer > 0}
						loading={isSendingOTP}
						onClick={() => {
							setTimer(30);
							resendOTP();
						}}
					>
						RESEND OTP {timer > 0 ? `(${timer})` : null}
					</Button>
				</form>
			</div>
		</Container>
	);
};

export default OTP;
