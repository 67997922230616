import { useMutation, useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';
import {
	ActiveInternsType,
	ActiveSupervisorType,
	InternLog,
	PendingSupervisorType,
	ServerGetResponse,
	ServerGetResponseInData,
	SupervisorStatType,
} from '../types';

export const useGetPendingSupervisors = ({ page = 1, page_size = 5, search = '', enabled = true }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponse<PendingSupervisorType>> =>
			axiosPrivate
				.get(`${Appurls.institution_admin.supervisor.pending}?page=${page}&page_size=${page_size}&search=${search}`)
				.then((response) => response.data),
		queryKey: ['institution-pending-supervisor-head-list', { page, page_size, search }],
		enabled,
	});
};

export const useGetSignedUpSupervisors = ({ page = 1, page_size = 5, search = '', enabled = true }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponse<ActiveSupervisorType>> =>
			axiosPrivate
				.get(`${Appurls.institution_admin.supervisor.active}?page=${page}&page_size=${page_size}&search=${search}`)
				.then((response) => response.data),
		queryKey: ['institution-signedup-supervisor-head-list', { page, page_size, search }],
		enabled,
	});
};

export const useGetSupervisorDetail = ({ id }: { id: string }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ActiveSupervisorType> =>
			axiosPrivate.get(Appurls.institution_admin.supervisor.get_detail(id)).then((response) => response?.data),
		queryKey: ['institution-supervisor', { id: id }],
	});
};

export const useGetSignedUpSupervisorsAsHead = ({ page = 1, page_size = 5, search = '', dept_id = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponse<ActiveSupervisorType>> =>
			axiosPrivate
				.get(`${Appurls.institution_admin.department.head.get_supervisors(dept_id)}?page=${page}&page_size=${page_size}&search=${search}`)
				.then((response) => response.data),
		queryKey: ['institution-head-signedup-supervisor-head-list', { page, page_size, search, dept_id }],
	});
};

export const useInviteInstitutionSupervisor = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation((data: { department: string; supervisors: { email: string; phone: string; name: string }[] }) =>
		axiosPrivate.post(Appurls.institution_admin.supervisor.onboard(data.department), { data: data.supervisors })
	);
};

export const useReinviteInstitutionSupervisor = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation((id: string) => axiosPrivate.get(Appurls.institution_admin.supervisor.reinvite(id)));
};

export const useGetInstitutionSupervisorStats = ({ id }: { id: string }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponseInData<SupervisorStatType>> =>
			axiosPrivate.get(Appurls.institution_admin.supervisor.use_stat(id)).then((response) => response.data),
		queryKey: ['institution-superrvisor-stats'],
	});
};

export const useGetInstitutionSupervisorStudents = ({ id }: { id: string }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponse<ActiveInternsType>> =>
			axiosPrivate.get(Appurls.institution_admin.supervisor.get_students(id)).then((response) => response.data),
		queryKey: ['institution-superrvisor-students', { id }],
	});
};

export const useGetMyInstitutionSupervisorStats = () => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponseInData<SupervisorStatType>> =>
			axiosPrivate.get(Appurls.institution_admin.supervisor.my_stat).then((response) => response.data),
		queryKey: ['my-institution-superrvisor-stats'],
	});
};

export const useGetApplicantLogsForInstitutionSupervisor = ({
	applicantId,
	page = 1,
	page_size = 5,
}: {
	applicantId: string;
	page: number;
	page_size: number;
}) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponse<InternLog>> =>
			axiosPrivate
				.get(`${Appurls.institution_admin.supervisor.get_logs(applicantId)}?page=${page}&page_size=${page_size}`)
				.then((response) => response?.data),
		queryKey: ['applicant_logs_for_institution_supervisor', { page, page_size, applicantId }],
	});
};
