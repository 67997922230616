import styled from 'styled-components';
import { DescinderIconLarge } from '../../assets/svgs';
import { minQuery } from '../../utilities';

export const Container = styled.div`
	background: #f9fafa;
	background-size: contain;
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	> section {
		display: grid;
		grid-template-rows: 1fr;
		padding: 2.5rem 0 0;
		background: url(${DescinderIconLarge}) no-repeat 0 center;
		flex-grow: 1;

		${minQuery('lg')} {
			flex-grow: unset;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 3rem;
			padding: 2rem 6.25rem 0;
			padding: 2rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem) 0;
		}

		article {
			max-width: 43%;
			padding-bottom: 4.2rem;
			display: none;

			${minQuery('lg')} {
				display: block;
			}

			h1 {
				color: #0c1c24;
				font-size: 4rem;
				font-size: clamp(3rem, 0.7273rem + 3.6364vw, 4rem);
				line-height: 100%;
				position: relative;
				margin-bottom: 1rem;

				::before {
					content: '';
					position: absolute;
					background-color: #f2c94c;
					height: 2px;
					left: 0;
					top: -2.2rem;
					width: 9.375rem;
				}
			}
			p {
				font-size: 1.125rem;
				line-height: 160%;
				max-width: 90%;
			}
		}

		.outlet-con {
			border-radius: 4px;
			display: flex;
			background: #fff;
			padding: 3.2rem 6.5rem;
			padding: clamp(2rem, 1.3333rem + 2.1333vw, 3.2rem) clamp(2rem, -0.5rem + 8vw, 6.5rem);

			${minQuery('lg')} {
				flex: 1;
				min-height: 37rem;
				max-width: 52%;
				position: relative;
				z-index: 3;
			}
		}
	}

	> div {
		width: 100%;
		background: #4060ea;
		max-height: 6.4rem;
		min-height: 3rem;
		flex: 1;

		${minQuery('lg')} {
			max-height: unset;
			min-height: 2.7rem;
			position: relative;
			z-index: 1;
			flex-grow: 1;

			::before {
				content: '';
				position: absolute;
				bottom: calc(100% - 1px);
				left: 0;
				width: 100%;
				height: 7.3rem;
				background: #4060ea;
				z-index: 1;
			}
		}
	}
`;
