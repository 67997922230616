import { useEffect } from 'react';
import { CelebratingMan, WelcomeEffectOne, WelcomeEffectTwo } from '../../assets/svgs';
import { Approutes } from '../../constants';
import { useDecodedUser } from '../../hooks';
import { Button } from '../../ui';
import { Container } from './styles';
import { Link, useNavigate } from 'react-router-dom';
import { isAdmin, isInstitutionAdmin, isIntern, isInternSupervisor, isStartUp } from '../../utilities';
import StartupFlow from './StartupFlow';
import InternFlow from './InternFlow';
import InstitutionFlow from './InstitutionFlow';

const Welcome = () => {
	const user = useDecodedUser();
	const navigate = useNavigate();

	useEffect(() => {
		if (isInternSupervisor(user)) {
			navigate(Approutes.supervisor.resources, { replace: true });
		} else if (isAdmin(user)) {
			navigate(Approutes.home, { replace: true });
		}
	}, [navigate, user]);

	return (
		<Container>
			<section>
				<div className="image-con">
					<img src={WelcomeEffectOne} alt="" />
					<img src={CelebratingMan} alt="" />
					<img src={WelcomeEffectTwo} alt="" />
				</div>

				{isStartUp(user) ? (
					<StartupFlow />
				) : isIntern(user) ? (
					<InternFlow />
				) : isInternSupervisor(user) ? (
					<div>
						<h1>Welcome To Descinder</h1>
						<p>We're glad to have you onboard</p>

						<b></b>
						<p className="wider">You will be notified when you have been invited by a new resource to their project.</p>

						<Link to={Approutes.supervisor.resources}>
							<Button>View Resources</Button>
						</Link>
					</div>
				) : isInstitutionAdmin(user) ? (
					<InstitutionFlow />
				) : (
					<div>
						<h1>Welcome To Descinder</h1>
						<p>We're glad to have you onboard</p>

						<b></b>
						{/* <p className="wider">You will be notified when you have been invited by a new resource to their project.</p> */}

						{/* <Link to={Approutes.supervisor.resources}>
							<Button>View Resources</Button>
						</Link> */}
					</div>
				)}
			</section>
		</Container>
	);
};

export default Welcome;
