import styled from 'styled-components';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	inset: 0;
	z-index: 99;

	div {
		border-radius: 0.25rem;
		background: #fff;
		padding: 2rem 5rem;
		width: 40rem;
		max-width: calc(100vw - 2rem);
		height: 27rem;
		max-height: calc(100vh - 4rem);

		> svg {
			margin-left: auto;
			display: block;
			cursor: pointer;
			width: 1.25rem;
			width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);

			:active {
				outline: none;
			}
		}
		h2 {
			text-align: center;
			font-size: 2rem;
			font-size: clamp(1.75rem, 1.6111rem + 0.4444vw, 2rem);
			margin: 1rem 0 0.5rem;
			color: #070d19;
		}
		h2 + p {
			color: #a8a8a8;
			font-size: 1rem;
			font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
			line-height: 120%;
			margin: 0.5rem 0 2rem;
			text-align: center;
		}

		button {
			display: block;
			width: 100%;
			background: rgba(229, 229, 229, 0.1);
			border: 1px solid #f1f1f1;
			border-radius: 2px;
			line-height: 1.25;
			text-align: left;
			padding: 1.5rem 3rem;

			:hover,
			:focus {
				border: 1px solid #4060ea;
				background: #f1f4fdbf;
			}

			b {
				font-weight: 700;
				font-size: 1.0625rem;
				letter-spacing: -0.02em;
				color: #222222;
				display: block;
			}
			span {
				font-size: 0.825rem;
				text-transform: capitalize;
				color: #868e96;
			}
		}
	}
`;
