import { useMutation } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';

export const useUpdateInstitutionProfile = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({
		mutationFn: (data: FormData) =>
			axiosPrivate.patch(Appurls.institution_admin.update_profile, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
	});
};

export const useApproveInstitutionProfile = ({ user_id = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({
		mutationFn: (data: { is_institution_profile_approved: boolean }) =>
			axiosPrivate.post(Appurls.dashboard.approve_institution(user_id), data),
	});
};

export const useRejectInstitutionProfile = ({ user_id = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({ mutationFn: (data) => axiosPrivate.post(Appurls.dashboard.reject_institution(user_id), data) });
};
