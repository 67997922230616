import { InternIcon, StartupIcon } from '../../../assets/svgs';
import { Container } from './styles';
import { Approutes } from '../../../constants';
import { useNavigate } from 'react-router-dom';

const ChooseAccount = () => {
	const navigate = useNavigate();

	return (
		<Container>
			<div>
				<h2>Choose Account</h2>
				<p>Choose an account type that suits you</p>

				<div>
					<button onClick={() => navigate(Approutes.signup.startup)}>
						<StartupIcon />

						<span>
							<b>Start-Up & SMEs</b>
							<span>Choose this if you are a start-up/SMEs</span>
						</span>
					</button>

					<button onClick={() => navigate(Approutes.signup.intern)}>
						<InternIcon />

						<span>
							<b>Fresh Graduate | Intern</b>
							<span>Choose this for graduate or intern</span>
						</span>
					</button>

					{/* // TOBEREMOVED */}
					{/* <button onClick={() => navigate(Approutes.signup.institution)}>
						<InternIcon />

						<span>
							<b>Institution</b>
							<span>Choose this as an Institution</span>
						</span>
					</button> */}
				</div>
			</div>
		</Container>
	);
};

export default ChooseAccount;
