import styled from 'styled-components';
import { StyledLoader } from '../../../../ui/Loader/styles';
import { maxQuery } from '../../../../utilities';

export const Container = styled.main`
	height: 100%;
	overflow: auto;

	${maxQuery('md')} {
		display: none;

		&.active {
			display: block;
		}
	}

	> div {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.messages-main-loader {
		display: grid;
		place-items: center;
		min-height: 10rem;
		height: 100%;

		${StyledLoader} {
			width: 3rem;
			height: 3rem;
			border-width: 0.3rem;
		}
	}
	.messages-mini-loader {
		display: flex;
		justify-content: center;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 0.125rem;

		${StyledLoader} {
			width: 1rem;
			height: 1rem;
			border-width: 0.1rem;
		}
	}

	.heading {
		padding: 1.25rem 1.5rem 0;

		> div {
			min-height: 60px;
			display: flex;
			align-items: center;
			gap: 0.75rem;
			border-bottom: 1px solid #ebebeb;
			padding-bottom: 1.25rem;
		}
		.back-btn {
			width: 2.5rem;
			height: 2.5rem;
			background-color: #fff;
			border: 1px solid #1212120d;
			display: none;

			${maxQuery('md')} {
				display: block;
			}

			svg {
				transform: rotate(90deg);
			}
		}
		h4 {
			font-family: 'Urbanist', sans-serif;
			font-weight: 700;
			font-size: 1.25rem;
			line-height: 1.25;
			text-transform: capitalize;
			color: #070d19;
		}
		span {
			margin-top: 0.5rem;
			font-weight: 500;
			font-size: 0.75rem;
			line-height: 1.36;
			color: #a8a8a8;
		}
		i {
			margin-left: auto;
		}
	}
	.notification {
		position: relative;
		background: rgba(64, 96, 234, 0.05);
		border: 0.75px solid #4060ea;
		border-radius: 0.25rem;
		padding: 0.5rem 1.5rem 0.5rem 1rem;
		font-size: 0.825rem;
		line-height: 1.5;
		color: #4060ea;
		margin: 1rem 1.5rem 0;
		text-align: justify;

		button {
			position: absolute;
			right: 0.5rem;
			top: 0.5rem;
			background-color: transparent;
		}
	}
	.message-body {
		padding: 1.25rem 1.5rem;
		overflow: auto;
		flex: 1;
		position: relative;
		scrollbar-width: thin;

		.day {
			font-weight: 500;
			font-size: 0.875rem;
			line-height: 1.36;
			color: #a8a8a8;
			text-align: center;
			display: block;
		}
	}
`;
