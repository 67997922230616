import { useAuth } from '.';
import { Appurls, LOCAL_STORAGE_KEYS } from '../constants';
import { axios } from '../library';

export const useRefreshToken = () => {
	const { auth } = useAuth();

	const refresh = () =>
		axios.post(Appurls.auth.refresh_token, {
			refresh: auth?.refresh || localStorage.getItem(LOCAL_STORAGE_KEYS.refresh_key),
		});

	return refresh;
};
