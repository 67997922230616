import { useState } from 'react';
import { Button, InputGroup } from '../../../../ui';
import { CloseModal } from '../../../../assets/svgs';
import { Container } from './styles';
import { useNotify } from '../../../../hooks';
import { handleApiError } from '../../../../utilities';
import { useQueryClient } from '@tanstack/react-query';

const RejectUser = ({ isRejecting, refetchData, handleRejection, handleClose }) => {
	const queryClient = useQueryClient();
	const notify = useNotify();
	const [formData, setFormData] = useState({
		reason: '',
	});

	const handleChange = (event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleRejecting = (event) => {
		event.preventDefault();

		handleRejection(
			{
				reason: formData.reason,
			},
			{
				onSuccess: () => {
					notify({ message: 'Rejection successful', status: 'success', toastOptions: { toastId: 'user_profile_approved_success' } });
					refetchData();
					queryClient.invalidateQueries(['dashboard-stats']);
					queryClient.invalidateQueries(['dashboard-intern-stats']);
					queryClient.invalidateQueries(['dashboard-business-stats']);
					queryClient.invalidateQueries({ queryKey: ['dashboard-users'] });
					handleClose();
				},
				onError: (error) =>
					notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'user_profile_approved_failure' } }),
			}
		);
	};
	return (
		<Container>
			<form onSubmit={handleRejecting}>
				<div className="header">
					<CloseModal tabIndex={0} onClick={handleClose} />
					<h2>Decline Profile</h2>
				</div>

				<InputGroup
					label="Reason for declining"
					name="reason"
					onChange={handleChange}
					placeholder="Enter your reason for declining"
					value={formData.reason}
					variant="textarea"
					required
				/>

				<Button loading={isRejecting}>Decline Profile</Button>
			</form>
		</Container>
	);
};
export default RejectUser;
