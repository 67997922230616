import { useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { useDecodedUser } from './useDecodedUser';
import { Appurls } from '../constants';
import { isIntern, isStartUp } from '../utilities';

export const useGetCurrentSubscription = () => {
	const user = useDecodedUser();
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (data) =>
			axiosPrivate
				.get(
					isIntern(user)
						? Appurls.subscription.intern_current_subscription
						: isStartUp(user)
						? Appurls.subscription.startup_current_subscription
						: null,
					data
				)
				.then((response) => response?.data),
		queryKey: ['current-subscription'],
	});
};
