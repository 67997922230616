import { useEffect } from 'react';
import { useDecodedUser, useGetUserById } from '../../hooks';
import AsIntern from './AsIntern';
import AsStartup from './AsStartup';
import AsInstitution from './AsInstitution';
import { Container } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { Approutes } from '../../constants';
import {
	hasInstitutionCompletedProfile,
	hasInternCompletedProfile,
	hasStartupCompletedProfile,
	isAdmin,
	isInstitutionAdmin,
	isIntern,
	isInternSupervisor,
	isStartUp,
} from '../../utilities';
import { CompleteProfileIllustration } from '../../assets/svgs';
import { SomethingWentWrong } from '../ErrorPages';

const CompleteRegistration = () => {
	const navigate = useNavigate();
	const user = useDecodedUser();
	const { state } = useLocation();
	const { data: configDetails } = useGetUserById({ id: user?.user_id });

	useEffect(() => {
		const navigationUrl = state?.from || Approutes.home;

		if (
			isInternSupervisor(user) ||
			isAdmin(user) ||
			(isIntern(user) && configDetails && hasInternCompletedProfile(configDetails)) ||
			(isStartUp(user) && configDetails && hasStartupCompletedProfile(configDetails)) ||
			(isInstitutionAdmin(user) && configDetails && hasInstitutionCompletedProfile(configDetails))
		) {
			navigate(navigationUrl, { replace: true });
		}
	}, [navigate, user, configDetails, state]);

	return (
		<Container>
			<header>
				<div className="text-con">
					<h4>Welcome to Descinder</h4>
					<p>Let's setup your profile</p>
				</div>

				<CompleteProfileIllustration />
			</header>

			<section>
				{isStartUp(user) ? (
					<AsStartup />
				) : isIntern(user) ? (
					<AsIntern />
				) : isInstitutionAdmin(user) ? (
					<AsInstitution />
				) : (
					<SomethingWentWrong />
				)}
			</section>
		</Container>
	);
};

export default CompleteRegistration;
