import { useEffect, useState } from 'react';
import { CloseModal, ProjectEmpty } from '../../../../assets/svgs';
import { PROFILE_STATUS, queryStrings } from '../../../../constants';
import { Button, Loader } from '../../../../ui';
import { Container } from './styles';
import { useSearchParams } from 'react-router-dom';
import { getPreviousSearchParams, handleApiError } from '../../../../utilities';
import { useApproveInstitutionProfile, useGetDashboardUserApprovalLogs, useNotify, useRejectInstitutionProfile } from '../../../../hooks';
import { useQueryClient } from '@tanstack/react-query';
import RejectUser from '../RejectUser';
import { AxiosError } from 'axios';
import { ApprovalLogs } from '../../components';

const InstitutionDetails = () => {
	const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
	const [control, setControl] = useState<'INSTITUTION_DETAILS' | 'APPROVAL_LOGS'>('INSTITUTION_DETAILS');

	const queryClient = useQueryClient();
	const notify = useNotify();
	const [searchParams, setSearchParams] = useSearchParams();

	const institutionId = searchParams.get(queryStrings.institutionId) || '';

	// const { data: user, isLoading, isError, refetch } = useGetDashboardUser({ user_id: institutionId, user: 'institution' });
	const { data: approvalLogs, isLoading: isLogsLoading, refetch: refetchLogs } = useGetDashboardUserApprovalLogs({ user_id: institutionId });

	const { mutate: approveInstitution, isLoading: isApproving } = useApproveInstitutionProfile({ user_id: institutionId });
	const { mutate: rejectInstitution, isLoading: isRejecting } = useRejectInstitutionProfile({ user_id: institutionId });

	const handleClose = () => {
		let previousParams = getPreviousSearchParams(searchParams);
		delete previousParams[queryStrings.institutionId];

		setSearchParams(previousParams, { replace: true });
	};
	const handleApproving = () => {
		if (user?.institution_admin_profile_status === PROFILE_STATUS.awaiting_approval) {
			notify({
				message: 'Institution is yet to complete their profile',
				status: 'error',
				toastOptions: { toastId: 'institutiom_profile_pre_rejection_failure' },
			});

			return;
		}

		approveInstitution(
			{ is_institution_profile_approved: true },
			{
				onSuccess: () => {
					notify({
						message: 'Institution has been approved',
						status: 'success',
						toastOptions: { toastId: 'institution_profile_approved_success' },
					});
					refetchLogs();
					// refetch();

					queryClient.invalidateQueries(['dashboard-stats']);
					queryClient.invalidateQueries(['dashboard-institution-stats']);
					queryClient.invalidateQueries({ queryKey: ['dashboard-users'] });
				},
				onError: (error) =>
					notify({
						message: handleApiError(error as AxiosError),
						status: 'error',
						toastOptions: { toastId: 'institution_profile_approved_failure' },
					}),
			}
		);
	};

	const handleDecline = () => {
		if (user?.institution_admin_profile_status === PROFILE_STATUS.awaiting_approval) {
			notify({
				message: 'Institution is yet to complete their profile',
				status: 'error',
				toastOptions: { toastId: 'institutiom_profile_pre_rejection_failure' },
			});

			return;
		}

		setIsReasonModalOpen(true);
	};

	useEffect(() => {
		if (institutionId) return;

		let previousParams = getPreviousSearchParams(searchParams);
		delete previousParams[queryStrings.institutionId];

		setSearchParams(previousParams, { replace: true });
	}, [institutionId, searchParams, setSearchParams]);

	return (
		<>
			<Container>
				{('isLoading' && false) || isLogsLoading ? (
					<center>
						<CloseModal tabIndex={0} onClick={handleClose} />

						<div>
							<Loader />
						</div>
					</center>
				) : 'isError' && false ? (
					<center>
						<CloseModal tabIndex={0} onClick={handleClose} />

						<div>
							<div>
								<ProjectEmpty />
								<p>Unable to fetch institution's details at the moment</p>
							</div>
						</div>
					</center>
				) : (
					<div>
						<CloseModal tabIndex={0} onClick={handleClose} />

						<section className="header">
							<div>
								<div className="img"></div>
								<div>
									<b>Institution Name</b>
									<p>{user?.['Institution Name']}</p>
								</div>
							</div>
						</section>

						<div className="nav">
							<button className={control === 'INSTITUTION_DETAILS' ? 'active' : ''} onClick={() => setControl('INSTITUTION_DETAILS')}>
								Institution Details
							</button>
							<button className={control === 'APPROVAL_LOGS' ? 'active' : ''} onClick={() => setControl('APPROVAL_LOGS')}>
								Approval Logs
							</button>
						</div>

						{control === 'INSTITUTION_DETAILS' ? (
							<>
								<article className="flexed">
									<div>
										<b>No of Department</b>
										<p>{user?.['No of Department']}</p>
									</div>
									<div>
										<b>Mobile Contact</b>
										<p>{user?.['Mobile Contact']}</p>
									</div>
								</article>

								<article className="flexed">
									<div>
										<b>Institution Email</b>
										<p>{user?.['Institution Email']}</p>
									</div>
									<div>
										<b>Country</b>
										<p>{user?.['Country']}</p>
									</div>
								</article>

								<article>
									<b>Residing Address</b>
									<p>{user?.['Residing Address']}</p>
								</article>
							</>
						) : control === 'APPROVAL_LOGS' ? (
							<ApprovalLogs user={user} approvalLogs={approvalLogs} />
						) : null}

						<div className="btn-group">
							<Button
								variant="secondary"
								loading={isRejecting}
								disabled={user?.institution_admin_profile_status === 'DECLINED'}
								onClick={handleDecline}
							>
								Decline
							</Button>
							<Button loading={isApproving} disabled={user?.institution_admin_profile_status === 'APPROVED'} onClick={handleApproving}>
								Approve
							</Button>
						</div>
					</div>
				)}
			</Container>

			{isReasonModalOpen ? (
				<RejectUser
					isRejecting={isRejecting}
					handleRejection={rejectInstitution}
					refetchData={() => {
						// refetch();
						refetchLogs();
					}}
					handleClose={() => setIsReasonModalOpen(false)}
				/>
			) : null}
		</>
	);
};
export default InstitutionDetails;

const user = {
	id: 1,
	'Institution Name': 'Teesside University',
	'No of Department': '13',
	'Mobile Contact': '07722874092',
	'Institution Email': 'Teesside@uk.com',
	'Residing Address': '57, Cadogan Street',
	Country: 'United Kingdom',
	institution_admin_profile_status: 'PENDING',
};
