import styled from 'styled-components';
import { StyledSelectGroup } from '../../../ui/SelectGroup/styles';
import { maxQuery } from '../../../utilities';
import { StyledButton } from '../../../ui/Button/styles';
import { StyledInputGroup } from '../../../ui/InputGroup/styles';

export const PlanContainer = styled.div`
	form.institution-plan {
		max-width: 1400px;

		.select-con,
		.btn-group {
			grid-template-columns: 1fr 1fr 1fr;

			> ${StyledSelectGroup} {
				grid-column: 1/3;

				${maxQuery('md')} {
					grid-column: 1/4;
				}
			}
		}
		.btn-group {
			grid-template-columns: 1fr 1fr 1fr;

			> ${StyledButton} {
				grid-column: 1/3;
			}
		}
	}
	.promo-con {
		margin-top: 1rem;
		text-align: center;
		grid-template-columns: 1fr 1fr 1fr;

		> div {
			grid-column: 1/3;
		}

		${StyledInputGroup} {
			max-width: 12rem;
			margin: auto;
		}

		button {
			font-family: 'Urbanist', sans-serif;
			font-weight: 500;
			font-size: 0.875rem;
			line-height: 1.36;
			color: #4060ea;
			padding: 0.5rem 1rem;
			background-color: transparent;
		}
		p {
			margin-top: 0.5rem;
			font-size: 0.75rem;
			line-height: 1.58;

			&.error {
				color: #ea2d2e;
			}
		}
	}
`;
