import { useState } from 'react';
import CreateAccount from './CreateAccount';
import { OTP } from '../../../components';
import { useNavigate } from 'react-router-dom';
import { useNotify, useSignup } from '../../../hooks';
import { useMutation } from '@tanstack/react-query';
import { axios } from '../../../library';
import { Approutes, Appurls } from '../../../constants';
import { handleApiError } from '../../../utilities';

const Startup = () => {
	const navigate = useNavigate();
	const notify = useNotify();
	const { mutate: verifyAccount, isLoading } = useMutation({
		mutationFn: (otp) => axios.post(Appurls.auth.verify_account, { otp, email: formData.company_email }),
	});

	const { mutate: signup, isLoading: isSigningUp } = useSignup();
	const [step, setStep] = useState(1);
	const [formData, setFormData] = useState({
		company_email: '',
		password: '',
		phone_number: '',
		otp: ['', '', '', ''],
	});

	const handleSubmit = (event) => {
		event.preventDefault();

		verifyAccount(formData.otp.join(''), {
			onSuccess: () => {
				navigate(Approutes.login);
				notify({
					message: 'Account created successfully. Log in with your details',
					status: 'success',
					toastOptions: { toastId: 'verify_account_success' },
				});
			},
			onError: (error) => {
				let errorMessage = error?.response.status === 400 ? 'Invalid OTP supplied' : handleApiError(error);

				notify({ message: errorMessage, status: 'error', toastOptions: { toastId: 'verify_account_failed' } });
			},
		});
	};

	return step === 1 ? (
		<CreateAccount formData={formData} setFormData={setFormData} setStep={setStep} />
	) : step === 2 ? (
		<OTP
			title="START-UP"
			value={formData.otp}
			setValue={(value) => setFormData((prev) => ({ ...prev, otp: value }))}
			isSubmitting={isLoading}
			handleSubmit={handleSubmit}
			isSendingOTP={isSigningUp}
			resendOTP={() =>
				signup(
					{ email: formData.company_email, password: formData.password, phone_number: formData.phone_number, role: 'STARTUP' },
					{
						onSuccess: () =>
							notify({
								message: 'A new OTP has been resent',
								variant: 'minor',
								toastOptions: { toastId: 'otp_success', position: 'bottom-center' },
							}),
						onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'signup' } }),
					}
				)
			}
		/>
	) : null;
};

export default Startup;
