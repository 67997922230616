import { useQueryClient } from '@tanstack/react-query';
import { COUNTRY } from '../../../constants';
import { Button, FileGroup, InputGroup, SelectGroup } from '../../../ui';
import { useNotify, useUpdateInstitutionProfile } from '../../../hooks';
import { handleApiError } from '../../../utilities';
import { FileImage } from '../../../assets/svgs';
import { FormEvent } from 'react';
import { FormDataType } from '.';
import { GeneralChangeEventType } from '../../../types';
import { AxiosError } from 'axios';

type InstitutionProfileProp = {
	setStep: React.Dispatch<React.SetStateAction<number>>;
	formData: FormDataType;
	handleChange: GeneralChangeEventType;
};

const InstitutionProfile = ({ setStep, formData, handleChange }: InstitutionProfileProp) => {
	const queryClient = useQueryClient();
	const notify = useNotify();
	const { mutate: updateProfile, isLoading } = useUpdateInstitutionProfile();

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();

		const axiosFormData = new FormData();

		formData.institution_name && axiosFormData.append('name', formData.institution_name);
		formData.phone_number && axiosFormData.append('mobile_number', formData.phone_number);
		formData.institution_email && axiosFormData.append('institution_email', formData.institution_email);
		formData.avatar.file && axiosFormData.append('avatar', formData.avatar.file);
		formData.address && axiosFormData.append('address', formData.address);
		formData.country && axiosFormData.append('country', formData.country);

		updateProfile(axiosFormData, {
			onSuccess: () => {
				setStep(2);
				queryClient.invalidateQueries(['user']);
				queryClient.invalidateQueries(['user-id']);
			},
			onError: (error) =>
				notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'profile_update' } }),
		});
	};

	return (
		<form onSubmit={handleSubmit}>
			<h2>Institution Profile</h2>
			<p>Provide information to complete your institution profile</p>

			<div>
				<InputGroup
					label="Institution's Name"
					name="institution_name"
					onChange={handleChange}
					placeholder="Enter Institution's Name"
					value={formData.institution_name}
					required
				/>
				<InputGroup
					label="Institution's Mobile Number"
					name="phone_number"
					onChange={handleChange}
					placeholder="Enter Mobile Number"
					value={formData.phone_number}
					required
				/>
				<InputGroup
					label="Institution's Email Address"
					name="institution_email"
					onChange={handleChange}
					placeholder="Enter Email Address"
					value={formData.institution_email}
					required
				/>
				<FileGroup
					label="Upload avatar (Optional)"
					name="avatar"
					onChange={handleChange}
					helpTextTwo="JPG, GIF or PNG. Max size of 800Kb"
					accept="image/png, image/gif, image/jpeg"
					maxSize={0.8}
					value={formData.avatar}
					icon={FileImage}
				/>
				<InputGroup
					label="Institution's Address"
					name="address"
					onChange={handleChange}
					placeholder="Enter Institution's Address"
					value={formData.address}
					required
				/>
				<SelectGroup
					label="Institution's Country"
					name="country"
					onChange={handleChange}
					placeholder="Select Country"
					value={formData.country}
					options={COUNTRY}
					required
					dropdownPosBottom={false}
				/>
			</div>

			<div className="btn-group">
				<Button loading={isLoading} disabled={isLoading} style={{ gridColumn: '1/3' }}>
					Next
				</Button>
			</div>
		</form>
	);
};

export default InstitutionProfile;
