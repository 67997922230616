import { StyledInputGroup } from './styles';
import { EyeOpen, EyeSlashed, SelectClose, InputInfo } from '../../assets/svgs';
import { useState } from 'react';
import { InputGroupPropType } from './types';

const InputGroup = ({
	label,
	value = '',
	onChange,
	placeholder,
	variant = 'input',
	type = 'text',
	name,
	moreInfo,
	max = Infinity,
	required = false,
	...rest
}: InputGroupPropType) => {
	const [inputMultipleVal, setMultipleInputVal] = useState('');
	const [passwordVisible, setPasswordVisible] = useState(false);

	const handleRemove = (selectedOption: string) => {
		let newValue = (value as string[]).filter((option) => option !== selectedOption);

		onChange(undefined, name, newValue);
	};

	return (
		<StyledInputGroup>
			{label ? (
				<label htmlFor={name}>
					{label}
					{required ? <span className="asterisk">*</span> : null}
					{moreInfo ? (
						<span className="input-info-con">
							<InputInfo className="info" tabIndex={0} />

							<span className="outer">
								<span>{moreInfo}</span>
							</span>
						</span>
					) : null}
				</label>
			) : null}

			{variant === 'input' ? (
				<input
					type={type === 'password' ? (passwordVisible ? 'text' : 'password') : type}
					placeholder={placeholder}
					name={name}
					value={value}
					onChange={onChange}
					required={required}
					{...(rest as React.ComponentProps<'input'>)}
				/>
			) : variant === 'input-multiple' ? (
				<div className="input-multiple-group">
					<input
						type={type === 'password' ? (passwordVisible ? 'text' : 'password') : type}
						placeholder={placeholder}
						name={name}
						value={inputMultipleVal}
						onChange={(event) => setMultipleInputVal(event.target.value || '')}
						required={required ? ((value as string[]).length > 0 ? false : true) : required}
						{...(rest as React.ComponentProps<'input'>)}
					/>
					<button
						type="button"
						onClick={() => {
							if (!(inputMultipleVal.trim().length > 0)) return;

							let newValue =
								(value as string[]).length > 0
									? (value as string[]).indexOf(inputMultipleVal.trim()) >= 0
										? value
										: [...(value as string[]), inputMultipleVal.trim()]
									: [inputMultipleVal.trim()];

							setMultipleInputVal('');
							(value as string[]).length < max && onChange(undefined, name, newValue);
						}}
					>
						Enter
					</button>
				</div>
			) : variant === 'textarea' ? (
				<textarea
					rows={3}
					placeholder={placeholder}
					name={name}
					value={value}
					required={required}
					onChange={onChange}
					{...(rest as React.ComponentProps<'textarea'>)}
				/>
			) : null}

			{type === 'password' ? (
				<div className="action-con" tabIndex={0} onClick={() => setPasswordVisible((prev) => !prev)}>
					<EyeOpen className={passwordVisible ? 'active' : ''} />
					<EyeSlashed className={passwordVisible ? '' : 'active'} />
				</div>
			) : null}

			{variant === 'input-multiple' && (value as string[]).length > 0 ? (
				<div className="selected-options">
					{(value as string[])?.map((value) => (
						<span key={value}>
							{value} <SelectClose onClick={() => handleRemove(value)} />
						</span>
					))}
				</div>
			) : null}
		</StyledInputGroup>
	);
};

export default InputGroup;
