import { useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';

export const useGetBusinessStats = ({ extraQuery = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () => axiosPrivate.get(`${Appurls.dashboard.dashboard_business_stats}?${extraQuery}`).then((response) => response.data),
		queryKey: ['dashboard-business-stats', extraQuery],
	});
};

export const useGetInternStats = ({ extraQuery = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () => axiosPrivate.get(`${Appurls.dashboard.dashboard_intern_stats}?${extraQuery}`).then((response) => response.data),
		queryKey: ['dashboard-intern-stats', extraQuery],
	});
};

export const useGetDashboardStats = () => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () => axiosPrivate.get(Appurls.dashboard.dashboard_stats).then((response) => response.data),
		queryKey: ['dashboard-stats'],
	});
};

export const useGetDashboardInstitution = ({ page = 1, page_size = 5, search, enabled = false, extraQuery = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(`${Appurls.user.index}?page=${page}&page_size=${page_size}&search=${search}&role=INSTITUTION_ADMIN${extraQuery}`)
				.then((response) => response.data),
		queryKey: ['dashboard-users-institution', { page, page_size, search, extraQuery }],
		enabled,
	});
};
export const useGetDashboardUsers = ({ page = 1, page_size = 5, search, user = 'businesses', extraQuery = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(
					`${
						user === 'businesses' ? Appurls.dashboard.businesses : Appurls.dashboard.interns
					}?page=${page}&page_size=${page_size}&search=${search}${extraQuery}`
				)
				.then((response) => response.data),
		queryKey: ['dashboard-users', { page, page_size, search, user, extraQuery }],
	});
};

export const useGetDashboardUser = ({ user_id, user = 'businesses' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(user === 'businesses' ? Appurls.dashboard.get_businesses(user_id) : Appurls.dashboard.get_interns(user_id))
				.then((response) => response.data),
		queryKey: ['dashboard-user', { user_id, user }],
	});
};

export const useGetDashboardUserApprovalLogs = ({ user_id }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () => axiosPrivate.get(Appurls.dashboard.get_approval_logs(user_id)).then((response) => response.data),
		queryKey: ['dashboard-approval-logs', { user_id }],
	});
};

export const useGetFinances = ({ page = 1, page_size = 5, user = 'businesses' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(
					`${user === 'businesses' ? Appurls.dashboard.startup_packages : Appurls.dashboard.intern_packages}?page=${page}&page_size=${page_size}`
				)
				.then((response) => response.data),
		queryKey: ['dashboard-finances', { page, page_size, user }],
	});
};

export const useGetFinance = ({ user_id, user = 'businesses' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(user === 'businesses' ? Appurls.dashboard.get_startup_package(user_id) : Appurls.dashboard.get_intern_package(user_id))
				.then((response) => response.data),
		queryKey: ['dashboard-finance', { user_id, user }],
	});
};
