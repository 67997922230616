import { getDateTime } from '../../../../utilities';

const PersonalDetails = ({ user }) => {
	return (
		<>
			<article>
				<b>Brief Introduction About Yourself</b>
				<p>{user?.about}</p>
			</article>

			<article className="flexed">
				<div>
					<b>Email</b>
					<p>{user?.email}</p>
				</div>
				<div>
					<b>Contact Number</b>
					<p>{user?.contact_number}</p>
				</div>
			</article>

			<article className="flexed">
				<div>
					<b>Applicant Type</b>
					<p>{user?.applicant_type}</p>
				</div>

				<div>
					<b>English proficiency level</b>
					<p>{user?.english_proficiency_level}</p>
				</div>
			</article>

			<article className="flexed">
				<div>
					<b>Preferred Internship Start Date</b>
					<p>{getDateTime(user?.start_date, 'mm dd yyyy') || 'N/A'}</p>
				</div>

				<div>
					<b>Internship Duration</b>
					<p>{user?.program_duration || 'N/A'}</p>
				</div>
			</article>

			<article className="flexed">
				<div>
					<b>Interested Roles</b>
					<p>{user?.interested_role?.join(', ') || 'N/A'}</p>
				</div>
				<div>
					<b>Interested Sectors</b>
					<p>{user?.interested_sector?.join(', ') || 'N/A'}</p>
				</div>
			</article>

			<article>
				<b>Skills</b>
				<p>{user?.skills?.join(', ')}</p>
			</article>
		</>
	);
};
export default PersonalDetails;

// --------------
// applicant_avatar
// certification_docs
// enrollment_doc
// supplimentary_docs
// id_card
// Languages
