import { APPLICANT_TYPE, INTERESTED_ROLES, INTERESTED_SECTOR, LEVEL_OF_EDUCATION, INTERNSHIP_DURATIONS } from '../../../constants';
import { Button, InputGroup, SelectGroup, Tag } from '../../../ui';
import { getDateTime } from '../../../utilities';
import { Tags } from '../../../ui/Tag/styles';

const EducationInterest = ({ userDetails, isEditing, handleChange, formData, isSubmitting, handleSubmit }) => {
	return isEditing ? (
		<div className="inputs-group">
			<SelectGroup
				label="Highest Level of Education"
				name="highest_edu_level"
				onChange={handleChange}
				value={formData.highest_edu_level}
				options={LEVEL_OF_EDUCATION}
				placeholder="Select Highest Level of Education"
				required
				shouldFilterByDefault={false}
			/>

			<SelectGroup
				label="Internship Type"
				name="applicant_type"
				onChange={handleChange}
				value={formData.applicant_type}
				placeholder="Enter About"
				required
				shouldFilterByDefault={false}
				options={APPLICANT_TYPE}
			/>

			<InputGroup
				label="Area of Study"
				name="area_of_study"
				onChange={handleChange}
				value={formData.area_of_study}
				placeholder="Enter About"
				required
			/>

			<InputGroup
				label="Recent Institution"
				name="recent_institution"
				onChange={handleChange}
				value={formData.recent_institution}
				placeholder="Enter About"
				required
			/>

			<SelectGroup
				label="Interested Sector"
				name="interested_sector"
				onChange={handleChange}
				placeholder="Select your skills"
				value={formData.interested_sector}
				options={INTERESTED_SECTOR}
				required
				variant="multiple"
				dropdownPosBottom={false}
			/>

			<SelectGroup
				label="Interested Role"
				name="interested_role"
				onChange={handleChange}
				placeholder="Select your skills"
				value={formData.interested_role}
				options={INTERESTED_ROLES}
				required
				variant="multiple"
				dropdownPosBottom={false}
			/>
			<InputGroup
				label="Preferred Internship Start Date"
				name="start_date"
				type="date"
				value={formData.start_date}
				onChange={handleChange}
				min={new Date().toISOString().substring(0, 10)}
				moreInfo="This is used to determine your visibilty to startups."
				required
			/>
			<SelectGroup
				label="Internship Duration"
				name="duration"
				onChange={handleChange}
				placeholder="Select how long you'd like to intern for"
				value={formData.duration}
				options={INTERNSHIP_DURATIONS}
				required
				shouldFilterByDefault={false}
			/>

			<Button className="submit-btn" loading={isSubmitting} onClick={handleSubmit}>
				Save Changes
			</Button>
		</div>
	) : (
		<div className="info-con">
			<div>
				<div>
					<span>Highest Level of Education</span>
					<b>{userDetails?.highest_edu_level}</b>
				</div>
				<div>
					<span>Internship Type</span>
					<b>{userDetails?.applicant_type}</b>
				</div>
			</div>
			<div>
				<div>
					<span>Area of Study</span>
					<b>{userDetails?.area_of_study}</b>
				</div>
				<div>
					<span>Recent Institution</span>
					<b>{userDetails?.recent_institution}</b>
				</div>
			</div>
			<div>
				<div>
					<span>Interested Sector</span>
					<Tags>
						{userDetails?.interested_sector?.map((sector) => (
							<Tag key={sector} tag={sector} hideClose />
						))}
					</Tags>
				</div>
				<div>
					<span>Interested Role</span>
					<Tags>
						{userDetails?.interested_role?.map((role) => (
							<Tag key={role} tag={role} hideClose />
						))}
					</Tags>
				</div>
			</div>

			<div>
				<div>
					<span>Internship Start Date</span>
					<b>{getDateTime(userDetails?.start_date, 'mm dd yyyy')}</b>
				</div>
				<div>
					<span>Internship Duration</span>
					<b>{userDetails?.program_duration}</b>
				</div>
			</div>
		</div>
	);
};
export default EducationInterest;
