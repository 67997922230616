import { ChevronDown, CloseModal } from '../../../assets/svgs';
import { Container } from './styles';
import { Button, Loader } from '../../../ui';
import { useDecodedUser, useGeneralStore, useNotify, useStripeClientSecret } from '../../../hooks';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { isInstitutionAdmin, isIntern, isStartUp } from '../../../utilities';
import { LOCAL_STORAGE_KEYS } from '../../../constants';
import { stripePromise } from '../../../config';

const CardInformation = ({ formData, handleClose, handleBack = null, handleSubmit }) => {
	const notify = useNotify();
	const user = useDecodedUser();
	const { setStore } = useGeneralStore();

	const payload = {};
	if (formData.promo_code) payload.coupon = formData.promo_code;

	if (isIntern(user)) {
		payload.intern_start_date = formData.start_date;
		payload.package_type = [formData.commitment?.key, ...formData.add_ons.map(({ key }) => key)];
	} else if (isStartUp(user)) {
		payload.package_type = formData.commitment?.key;
	} else if (isInstitutionAdmin(user)) {
		payload.package_type = formData.plan.split(' Interns')[0].replaceAll(' ', '');
	}

	const { data, isLoading, isFetching, isError, refetch } = useStripeClientSecret({ payload });

	const handlePaymentCompletion = () => {
		notify({ message: 'Payment was successful', status: 'success', toastOptions: { toastId: 'subscription_payment_success' } });
		setStore((prev) => ({ ...prev, has_paid: true }));
		localStorage.setItem(LOCAL_STORAGE_KEYS.has_paid, 'true');

		handleSubmit();
	};

	return (
		<Container>
			<div>
				<div className="header">
					<CloseModal tabIndex={0} className="close-icon" onClick={handleClose} />
					<h2>Payment</h2>
					<p>Provide Payment details to make card payment for your packages</p>
					<hr />
				</div>
				{handleBack ? (
					<button className="back-btn" onClick={handleBack}>
						<ChevronDown /> Go Back
					</button>
				) : null}

				{isLoading ? (
					<div className="loading-con">
						<Loader />
					</div>
				) : isError ? (
					<div>
						<p className="error-msg">Unable to set up connection with payment platform</p>

						<div className="btn-group">
							<Button variant="secondary" disabled={isFetching} onClick={handleBack}>
								Go Back
							</Button>
							<Button loading={isFetching} onClick={refetch}>
								Try again
							</Button>
						</div>
					</div>
				) : (
					<EmbeddedCheckoutProvider
						stripe={stripePromise}
						options={{ clientSecret: data?.client_secret, onComplete: () => handlePaymentCompletion() }}
					>
						<EmbeddedCheckout className="stripe-con" />
					</EmbeddedCheckoutProvider>
				)}
			</div>
		</Container>
	);
};
export default CardInformation;
