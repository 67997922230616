import { AuthProvider, GeneralStoreProvider } from '../../context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const AppWrapper = ({ children }) => {
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<GeneralStoreProvider>{children}</GeneralStoreProvider>
			</AuthProvider>
		</QueryClientProvider>
	);
};
export default AppWrapper;
