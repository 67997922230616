import { getDateDifference, getDateTime, getInitials } from '../../../../utilities';
import { Container } from './styles';

const UserMessage = ({ id, profilePicture, name, dateTime, message, isMine }) => {
	const dateDifference = getDateDifference(new Date(), dateTime) || 0;
	const dateTimeOfMessage = dateDifference > 0 ? getDateTime(dateTime, null, false) : getDateTime(dateTime, null, false);

	return (
		<Container id={id} className={isMine ? 'mine' : ''}>
			{profilePicture ? <img src={profilePicture} alt="" /> : <div className="initials">{getInitials(name)}</div>}

			<div className={`${isMine ? 'mine' : ''} text-con`}>
				{/* <div className="name-date-group">
					<b className="name">{name}</b>
					<span className="date">{dateTimeOfMessage}</span>
				</div> */}

				<div className="message-con" dangerouslySetInnerHTML={{ __html: message }} />
				<span className="date">{dateTimeOfMessage}</span>
			</div>
		</Container>
	);
};
export default UserMessage;
