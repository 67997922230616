import { EyeOpen } from '../../../../../assets/svgs';
import { STARTUP_PAYMENT_PACKAGE } from '../../../../../constants';
import { getDateTime } from '../../../../../utilities';

const BusinessTable = ({ users, handleItemClick }) => {
	return (
		<>
			<div className="table-container">
				<table>
					<thead>
						<tr>
							<th>S/N</th>
							<th>Company's Name</th>
							<th>Business Plan</th>
							<th>Amount Paid</th>
							<th>Subscription Start</th>
							<th>Subscription End</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{users?.results?.map((user, index) => (
							<tr key={user?.id}>
								<td>{index + 1}</td>
								<td>{user?.company_name}</td>
								<td>{user?.packages?.map((item) => STARTUP_PAYMENT_PACKAGE.find((packageItem) => packageItem.key === item)?.title).join(', ')}</td>
								<td>{user?.amount}</td>
								<td>{getDateTime(user?.subscribed_at, 'mm dd yyyy')}</td>
								<td>{getDateTime(user?.end_date, 'mm dd yyyy')}</td>
								<td>
									<EyeOpen onClick={() => handleItemClick(user?.user)} />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{users?.results?.map((user, index) => (
				<div key={user?.id} className="mobile-card">
					<div className="flexed">
						<div>
							<b>Company's Name</b>
							<p>{user?.company_name}</p>
						</div>
						<div className="action-con" onClick={() => handleItemClick(user?.user)}>
							<EyeOpen />
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>Business Plan</b>
							<p>{user?.packages?.map((item) => STARTUP_PAYMENT_PACKAGE.find((packageItem) => packageItem.key === item)?.title).join(', ')}</p>
						</div>
						<div>
							<b>Amount Paid</b>
							<p>{user?.amount}</p>
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>Subscription Start </b>
							<p>{getDateTime(user?.subscribed_at, 'mm dd yyyy')}</p>
						</div>
						<div>
							<b>Subscription End</b>
							<p>{getDateTime(user?.end_date, 'mm dd yyyy')}</p>
						</div>
					</div>
				</div>
			))}
		</>
	);
};
export default BusinessTable;
