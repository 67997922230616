import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { Approutes, LOCAL_STORAGE_KEYS, queryStrings } from '../../constants';
import { SubscriptionPlan } from '../Modals';
import { MessageProvider } from '../../context';

const RequireAuth = () => {
	const { auth } = useAuth();
	const { pathname } = useLocation();
	const [searchParams] = useSearchParams();

	if (!(auth?.access || localStorage.getItem(LOCAL_STORAGE_KEYS.access_key))) {
		return <Navigate to={`${Approutes.login}?next=${pathname}`} />;
	}

	return (
		<>
			<MessageProvider>
				<Outlet />
			</MessageProvider>

			{searchParams.get(queryStrings.subscription_plan) === 'open' ? <SubscriptionPlan /> : null}
		</>
	);
};

export default RequireAuth;
