import { useRef, useState } from 'react';
import { Button, InputGroup } from '../../../ui';
import { CloseModal } from '../../../assets/svgs';
import { Container } from './styles';
import { useAddTask, useNotify } from '../../../hooks';
import { handleApiError } from '../../../utilities';

const AddTask = ({ projectId, refetchTasks, handleClose }) => {
	const errorRef = useRef();
	const notify = useNotify();
	const [formData, setFormData] = useState({
		task_name: '',
		task_description: '',
	});

	const { mutate: addTasks, isLoading } = useAddTask();

	const handleChange = (event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const onSubmit = (event) => {
		event.preventDefault();

		if (formData.task_name.length === 0) {
			errorRef.current.innerText = 'Task name cannot be empty';
			return;
		}

		addTasks(
			{ id: projectId, data: { tasks: [{ name: formData.task_name, description: formData.task_description }] } },
			{
				onSuccess: () => {
					notify({ message: 'Task has been added to your project', status: 'success', toastOptions: { toastId: 'add_task_success' } });
					refetchTasks && refetchTasks();
					handleClose();
				},
				onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'add_task_error' } }),
			}
		);
	};
	return (
		<Container>
			<form onSubmit={onSubmit}>
				<CloseModal tabIndex={0} onClick={handleClose} />
				<h2>Add Task</h2>

				<InputGroup
					label="Task Name"
					name="task_name"
					onChange={(event) => {
						errorRef.current.innerText = '';
						handleChange(event);
					}}
					placeholder="Task Name"
					value={formData.task_name}
					required
				/>
				<InputGroup
					label="Task Description"
					variant="textarea"
					name="task_description"
					onChange={(event) => {
						errorRef.current.innerText = '';
						handleChange(event);
					}}
					placeholder="Enter description of the task"
					value={formData.task_description}
					required
				/>
				<div className="error" ref={errorRef}></div>

				<Button type="submit" loading={isLoading}>
					Add Task
				</Button>
			</form>
		</Container>
	);
};
export default AddTask;
