import { isIntern, isStartUp } from '../../utilities';
import StartupProfile from './StartupProfile';
import InternProfile from './InternProfile';
import { useDecodedUser, useUser } from '../../hooks';
import PageNotFound from '../ErrorPages/404';

const Profile = () => {
	const { data: userDetails, refetch: refetchUserDetails } = useUser();

	const user = useDecodedUser();

	if (isStartUp(user)) {
		return <StartupProfile decodedUser={user} userDetails={userDetails} refetchUserDetails={refetchUserDetails} />;
	} else if (isIntern(user)) {
		return <InternProfile decodedUser={user} userDetails={userDetails} refetchUserDetails={refetchUserDetails} />;
	}

	return <PageNotFound />;
};

export default Profile;
