import { Link } from 'react-router-dom';
import { EyeOpen, ProjectEmpty } from '../../assets/svgs';
import { Loader } from '../../ui';
import { Approutes } from '../../constants';
import { useGetApplicantLogs } from '../../hooks';
import { getDateTime } from '../../utilities';
import { useState } from 'react';
import { LogSummary } from '../../components';

const Logs = ({ projectId }) => {
	const [logState, setLogState] = useState({
		viewLogDetails: false,
		log: null,
	});

	const { data: timesheets, isLoading, isError } = useGetApplicantLogs({ projectId, page: 1, page_size: 5 });
	const handleViewLog = (log) => {
		setLogState({
			viewLogDetails: true,
			log,
		});
	};

	return isLoading ? (
		<center>
			<Loader />
		</center>
	) : isError ? (
		<div className="empty-state">
			<ProjectEmpty />
			<p>Unable to fetch available logs at the moment</p>
		</div>
	) : !timesheets?.results || timesheets?.results?.length === 0 ? (
		<div className="empty-state">
			<ProjectEmpty />
			<p>You haven't submitted any log</p>
		</div>
	) : (
		<>
			<div className="table-container">
				<table>
					<thead>
						<tr>
							<th>S/N</th>
							<th>Task Name</th>
							<th>Key Achievement</th>
							<th>Log report</th>
							<th>Report Date </th>
							<th>Status</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{timesheets?.results?.map((log, index) => (
							<tr key={log?.id}>
								<td>{index + 1}</td>
								<td>{log?.related_task?.name}</td>
								<td>{log?.achievement}</td>
								<td>{log?.is_viewed ? 'Viewed' : 'Not Viewed'}</td>
								<td>{getDateTime(log?.created_at, 'mm dd yyyy')}</td>
								<td className={log?.related_task?.is_completed ? 'open' : 'closed'}>{log?.related_task?.is_completed ? 'Open' : 'Closed'}</td>
								<td>
									<EyeOpen onClick={() => handleViewLog(log)} />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			<Link to={Approutes.intern.tasks.logs}>See All Logs</Link>

			{logState.viewLogDetails ? (
				<LogSummary log={logState.log} handleClose={() => setLogState({ viewLogDetails: false, log: null })} />
			) : null}
		</>
	);
};
export default Logs;
