import { useLocation } from 'react-router-dom';
import { LogoDark } from '../../../assets/svgs';
import { Approutes } from '../../../constants';
import { Button } from '../../../ui';
import { Container } from './styles';

const Header = () => {
	const { pathname } = useLocation();

	return (
		<Container>
			<div>
				<div className="logo-con">
					<LogoDark />
				</div>

				{pathname.startsWith(Approutes.signup.index) ? (
					<a href={Approutes.login}>
						<Button>Login</Button>
					</a>
				) : (
					<a href={Approutes.signup.index}>
						<Button>Signup</Button>
					</a>
				)}
			</div>
		</Container>
	);
};

export default Header;
