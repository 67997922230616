import styled from 'styled-components';

export const Container = styled.div`
	.dropdown-con {
		position: relative;

		:focus-within ul {
			display: block;
		}
		:focus-within button {
			background: #4060ea;

			path {
				fill: #fff;
			}
		}

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 2px 6px;
			background: #f1f4fd;
			border-radius: 2px;
		}

		ul {
			background: #fff;
			box-shadow: 0 0 4px #e4e7ec;
			overflow: auto;
			border-radius: 0.25rem;
			position: absolute;
			right: 0;
			width: max-content;
			z-index: 3;
			display: none;
			max-height: 270px;
			top: calc(100% + 0.5rem);

			&.top {
				top: unset;
				bottom: calc(100% + 0.5rem);
			}

			li {
				text-align: center;
				font-size: 0.875rem;
				cursor: pointer;
				outline: none;
				padding: 0.5rem 1rem;

				:hover,
				:focus {
					background: #f9fafb;
				}
				&.active {
					background: #ecf1fe;
				}
				&.empty-option {
					color: transparent;
				}

				a {
					justify-content: center;
				}
			}
		}
	}
`;
