import { useMutation } from '@tanstack/react-query';
import { Appurls } from '../constants';
import { useAxiosPrivate } from './useAxiosPrivate';

export const useUpdateStartupProfile = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({
		mutationFn: (data) => axiosPrivate.patch(Appurls.user.update_startup, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
	});
};

export const useApproveStartupProfile = ({ user_id }) => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({ mutationFn: (data) => axiosPrivate.post(Appurls.dashboard.approve_startup(user_id), data) });
};

export const useRejectStartupProfile = ({ user_id }) => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({ mutationFn: (data) => axiosPrivate.post(Appurls.dashboard.reject_startup(user_id), data) });
};
