import styled from 'styled-components';
import { StyledLoader } from '../../../../ui/Loader/styles';
import { StyledSearchBar } from '../../../../ui/SearchBar/styles';
import { maxQuery } from '../../../../utilities';

export const Container = styled.aside`
	height: 100%;
	overflow: auto;
	border-right: 1px solid #ebebeb;
	scrollbar-width: thin;

	${maxQuery('md')} {
		&.inactive {
			display: none;
		}
	}

	.search-con {
		padding: 1.25rem 1.5rem 1rem;
		background-color: #fff;
		position: sticky;
		top: 0;
		z-index: 10;

		${StyledSearchBar} {
			width: 100%;
		}
	}
	.empty-state {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: calc(100vh - 24rem);
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;
		text-align: center;

		p {
			color: #1e1e1e;
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 135%;
			margin-top: 1rem;
		}
		span {
			text-transform: capitalize;
		}
	}
	.conversation-main-loader {
		display: grid;
		place-items: center;
		min-height: 10rem;
		height: calc(100% - 12rem);

		${StyledLoader} {
			width: 3rem;
			height: 3rem;
			border-width: 0.3rem;
		}
	}
	.conversation-mini-loader {
		display: flex;
		justify-content: center;
		margin-top: 0.5rem;

		${StyledLoader} {
			width: 1.5rem;
			height: 1.5rem;
			border-width: 0.15rem;
		}
	}
	.users-con {
		padding: 0 1.5rem 0.5rem;
	}
`;

// 40px
