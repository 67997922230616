import styled, { keyframes } from 'styled-components';
import { StyledButton } from '../../ui/Button/styles';
import { maxQuery } from '../../utilities';

const rotate = keyframes`
0% {
	transform: rotate(0deg);
}
50% {
	transform: rotate(180deg);
}
100% {
	transform: rotate(360deg);
}
`;

export const Container = styled.div`
	padding: 2rem 6.25rem;
	padding: 2rem clamp(0rem, -3.4722rem + 11.1111vw, 6.25rem);

	> section {
		border-radius: 0.25rem;
		background: #fff;
		box-shadow: -9px 19px 40px 0px rgba(0, 0, 0, 0.02);
		min-height: calc(100vh - (4rem + 76px));
		text-align: center;
		overflow: hidden;

		> div {
			padding: 0 6.25rem 3rem;
			padding: 0 clamp(1rem, -1.7926rem + 8.9362vw, 6.25rem) 3rem;
		}
	}
	/* 46 28% 74% */
	.image-con {
		margin-bottom: 2rem;
		height: 12.8125rem;
		height: clamp(7.1875rem, 4.1955rem + 9.5745vw, 12.8125rem);
		max-height: 28vh;
		background-color: #4060ea;
		overflow: hidden;
		position: relative;

		img {
			position: absolute;
			bottom: 0;
			height: 100%;

			:nth-child(1) {
				left: 0;
				${maxQuery('sm')} {
					transform: translateX(-2rem);
				}
				${maxQuery('xs')} {
					transform: translateX(-4rem);
				}
			}
			:nth-child(2) {
				left: 50%;
				transform: translateX(-50%);
				z-index: 1;
			}
			:nth-child(3) {
				right: 0;

				${maxQuery('sm')} {
					transform: translateX(3rem);
				}
				${maxQuery('xs')} {
					transform: translateX(7rem);
				}
			}
		}
	}
	h1 {
		font-size: 2.25rem;
		font-size: clamp(1.25rem, 0.7181rem + 1.7021vw, 2.25rem);
		color: #070d19;
	}
	h1 + p {
		color: #a8a8a8;
		font-size: 1rem;
		font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
		line-height: 120%;
		border-bottom: 1px solid #ebebeb;
		padding-bottom: 1.5rem;
		margin: 0.5rem auto 2.5rem;
		width: 40.875rem;
		max-width: 62ch;

		a {
			display: inline-block;
			margin: 0;
			width: max-content;
			text-decoration: underline;
		}
	}
	.verification-con {
		text-align: center;

		.hourglass {
			animation: ${rotate} 1.5s cubic-bezier(0.72, -0.29, 0.28, 1.25) 1s infinite forwards;
		}
		h1 {
			font-family: 'Urbanist', sans-serif;
			font-weight: 700;
			margin-top: 0.75rem;
		}
		p {
			border-bottom: none;
			line-height: 1.5;
			margin: 0.5rem auto;

			${maxQuery('xs')} {
				br {
					display: none;
				}
			}
		}
		${StyledButton} {
			margin: auto;
		}
	}
	b {
		color: #11071a;
		font-size: 1.25rem;
	}
	b + p {
		margin: 0.5rem auto 2rem;
		color: rgba(82, 80, 80, 0.4);
		font-size: 1rem;
		font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
		font-weight: 500;
		max-width: 36ch;

		&.wider {
			max-width: 64ch;
		}
	}
	a {
		display: block;
		margin: auto;
		width: max-content;
		max-width: 100%;
	}
	${StyledButton} {
		width: 19.1875rem;
		justify-content: center;
		max-width: 100%;
	}
`;
