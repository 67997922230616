import dayjs from 'dayjs';

const getInternshipTimeLeft = (startDate, endDate, getValueOnly = false, getValueIn) => {
	const today = new Date();
	const internStartDate = dayjs(today > new Date(startDate) ? today : startDate);

	const differenceInMonths =
		parseInt(internStartDate.diff(endDate, 'month', true).toString().split('.')?.[1]?.[0] || '0') >= 7
			? Math.ceil(Math.abs(internStartDate.diff(endDate, 'month', true)))
			: Math.abs(internStartDate.diff(endDate, 'month'));

	const differenceInWeeks = Math.abs(internStartDate.diff(endDate, 'week'));
	const differenceInDays = Math.abs(internStartDate.diff(endDate, 'day'));
	const differenceInHours = Math.abs(internStartDate.diff(endDate, 'hour'));
	const differenceInMinutes = Math.abs(internStartDate.diff(endDate, 'minute'));
	const differenceInSeconds = Math.abs(internStartDate.diff(endDate, 'second'));

	if (getValueIn === 'months') {
		return getValueOnly ? differenceInMonths : `${differenceInMonths} ${differenceInMonths > 1 ? 'months' : 'month'}`;
	} else if (getValueIn === 'weeks') {
		return getValueOnly ? differenceInWeeks : `${differenceInWeeks} ${differenceInWeeks > 1 ? 'weeks' : 'week'}`;
	} else if (getValueIn === 'days') {
		return getValueOnly ? differenceInDays : `${differenceInDays} ${differenceInDays > 1 ? 'days' : 'day'}`;
	} else if (getValueIn === 'hours') {
		return getValueOnly ? differenceInHours : `${differenceInHours} ${differenceInHours > 1 ? 'hours' : 'hour'}`;
	} else if (getValueIn === 'minutes') {
		return getValueOnly ? differenceInMinutes : `${differenceInMinutes} ${differenceInMinutes > 1 ? 'minutes' : 'minute'}`;
	} else if (getValueIn === 'seconds') {
		return getValueOnly ? differenceInSeconds : `${differenceInSeconds} ${differenceInSeconds > 1 ? 'seconds' : 'second'}`;
	}

	return differenceInMonths > 0
		? getValueOnly
			? differenceInMonths
			: `${differenceInMonths} ${differenceInMonths > 1 ? 'months' : 'month'}`
		: differenceInWeeks > 0
		? getValueOnly
			? differenceInWeeks
			: `${differenceInWeeks} ${differenceInWeeks > 1 ? 'weeks' : 'week'}`
		: differenceInDays > 0
		? getValueOnly
			? differenceInDays
			: `${differenceInDays} ${differenceInDays > 1 ? 'days' : 'day'}`
		: differenceInHours > 0
		? getValueOnly
			? differenceInHours
			: `${differenceInHours} ${differenceInHours > 1 ? 'hours' : 'hour'}`
		: differenceInMinutes > 0
		? getValueOnly
			? differenceInMinutes
			: `${differenceInMinutes} ${differenceInMinutes > 1 ? 'minutes' : 'minute'}`
		: getValueOnly
		? differenceInSeconds
		: `${differenceInSeconds} ${differenceInSeconds > 1 ? 'seconds' : 'second'}`;
};

export default getInternshipTimeLeft;
