import { FormEvent, useRef, useState } from 'react';
import { Button, InputGroup, SelectGroup } from '../../../../ui';
import { CloseModal } from '../../../../assets/svgs';
import { Container } from './styles';
import { GeneralChangeEventType } from '../../../../types';
import { useQueryClient } from '@tanstack/react-query';
import { handleApiError } from '../../../../utilities';
import { AxiosError } from 'axios';
import { useInviteInstitutionIntern, useGetDepartments, useNotify, useGetSignedUpSupervisors } from '../../../../hooks';

type InviteInternModalProp = {
	handleClose: () => void;
	handleSendBulkInvite: () => void;
};

const InviteInternModal = ({ handleClose, handleSendBulkInvite }: InviteInternModalProp) => {
	const errorRef = useRef<HTMLDivElement>(null!);
	const queryClient = useQueryClient();
	const notify = useNotify();

	const [formData, setFormData] = useState({
		email: '',
		department: '',
		supervisor: '',
	});
	const { data: departments, isLoading: isLoadingDepartments } = useGetDepartments({ page: 1, page_size: 1000, search: '', enabled: true });
	const { data: activeSupervisors, isLoading: isLoadingActiveSupervisors } = useGetSignedUpSupervisors({
		page: 1,
		page_size: 1000,
		search: '',
		enabled: true,
	});

	const { mutate: inviteIntern, isLoading } = useInviteInstitutionIntern();
	const selectedDeptId = departments?.results?.find((dept) => dept.name === formData.department)?.id || '';

	const handleChange: GeneralChangeEventType = (event, name, value) => {
		errorRef.current.innerText = '';

		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name as string]: value }));

		if (name === 'department') {
			setFormData((prev) => ({ ...prev, supervisor: '' }));
		}
	};
	const onSubmit = (event: FormEvent) => {
		event.preventDefault();

		if (formData.email.length === 0 || formData.department.length === 0 || formData.supervisor.length === 0) {
			errorRef.current.innerText = 'One or more required field needs to be filled';
			return;
		}
		const deptId = departments?.results?.find((dept) => dept.name === formData.department)?.id || '';
		const supervisorId = activeSupervisors?.results?.find((supervisor) => supervisor.dept_supervisor_name === formData.supervisor)?.id || '';

		if (!deptId || !supervisorId) {
			errorRef.current.innerText = 'Unable to invite supervisor at this moment';
			return;
		}

		inviteIntern(
			{
				departmentId: deptId,
				email: formData.email,
				supervisorId: supervisorId,
			},
			{
				onSuccess: () => {
					notify({
						message: 'The student has been invited',
						status: 'success',
						toastOptions: { toastId: 'institution_intern_create_success' },
					});

					queryClient.invalidateQueries(['institution-pending-intern-list']);
					handleClose();
				},
				onError: (error) =>
					notify({
						message: handleApiError(error as AxiosError),
						status: 'error',
						toastOptions: { toastId: 'institution_intern_create_error' },
					}),
			}
		);
	};

	return (
		<Container>
			<form onSubmit={onSubmit}>
				<div className="form-header">
					<CloseModal tabIndex={0} onClick={handleClose} />
					<h2>Invite Intern</h2>
					<p>Specify Intern's Email, Department and Supervisor </p>
					<br />
				</div>

				<InputGroup
					label="Student's Email"
					type="email"
					name="email"
					onChange={handleChange}
					placeholder="Enter Student's Email"
					value={formData.email}
					required
				/>
				<SelectGroup
					label="Select Department"
					name="department"
					onChange={handleChange}
					options={departments?.results?.map((dept) => dept?.name) || []}
					loading={isLoadingDepartments}
					emptyOptionsMessage="You currently have no department created"
					placeholder="Select Department"
					value={formData.department}
					required
				/>
				<SelectGroup
					label="Assign Supervisor"
					name="supervisor"
					onChange={handleChange}
					options={
						activeSupervisors?.results
							?.filter((supervisor) => supervisor.department === selectedDeptId)
							.map((supervisor) => supervisor?.dept_supervisor_name) || []
					}
					loading={isLoadingActiveSupervisors}
					emptyOptionsMessage="You currently have no supervisor's in this department"
					placeholder="Select Supervisor"
					value={formData.supervisor}
					disabled={!formData.department}
					dropdownPosBottom={false}
					required
				/>
				<div className="error" ref={errorRef}></div>

				<Button type="submit" loading={isLoading}>
					Send Invite
				</Button>

				<button type="button" className="bulk-invite" onClick={handleSendBulkInvite}>
					Send Bulk Invite instead
				</button>
			</form>
		</Container>
	);
};
export default InviteInternModal;
