import { useEffect, useState } from 'react';
import { InputSearch } from '../../assets/svgs';
import InputGroup from '../InputGroup';
import { StyledSearchBar } from './styles';

// handleChange must be wrapped with useCallback
const SearchBar = ({ name = 'search', placeholder = 'Search', handleChange, value = '', ...rest }) => {
	const [search, setSearch] = useState('');

	useEffect(() => {
		setSearch(value);
	}, [value]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			handleChange(null, name, search);
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [name, search, handleChange]);

	return (
		<StyledSearchBar>
			<InputSearch />
			<InputGroup name={name} placeholder={placeholder} value={search} onChange={(event) => setSearch(event.target.value)} {...rest} />
		</StyledSearchBar>
	);
};
export default SearchBar;
