import { EyeOpen } from '../../../assets/svgs';
import { STARTUP_PAYMENT_PACKAGE } from '../../../constants';
import { getDateTime } from '../../../utilities';

const BusinessTable = ({ page, users, handleItemClick }) => {
	return (
		<>
			<div className="table-container">
				<table>
					<thead>
						<tr>
							<th>S/N</th>
							<th>Company's Name</th>
							<th>Business Plan</th>
							<th>Amount Paid</th>
							<th>Subscription Start</th>
							<th>Subscription End</th>
							<th>Registration Time</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{users?.results?.map((user, index) => (
							<tr key={user?.id}>
								<td>{(page - 1) * 10 + index + 1}</td>
								<td>{user?.company_name || 'N/A'}</td>
								<td>
									{(typeof user?.business_plan !== 'string' &&
										user?.business_plan?.map((item) => STARTUP_PAYMENT_PACKAGE.find((packageItem) => packageItem.key === item)?.title).join(', ')) ||
										'N/A'}
								</td>
								<td>£{user?.amount_paid || 0}</td>
								<td>{getDateTime(user?.sub_start, 'mm dd yyyy') || 'N/A'}</td>
								<td>{getDateTime(user?.sub_end, 'mm dd yyyy') || 'N/A'}</td>
								<td>
									{getDateTime(user?.created_at, 'mm dd yyyy')}, {getDateTime(user?.created_at, '', false)}
								</td>
								<td>
									<EyeOpen onClick={() => handleItemClick(user?.id)} />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{users?.results?.map((user) => (
				<div key={user?.id} className="mobile-card">
					<div className="flexed">
						<div>
							<b>Company's Name</b>
							<p>{user?.company_name || 'N/A'}</p>
						</div>
						<div className="action-con" onClick={() => handleItemClick(user?.id)}>
							<EyeOpen />
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>Business Plan</b>
							<p>
								{(typeof user?.business_plan !== 'string' &&
									user?.business_plan?.map((item) => STARTUP_PAYMENT_PACKAGE.find((packageItem) => packageItem.key === item)?.title).join(', ')) ||
									'N/A'}
							</p>
						</div>
						<div>
							<b>Total Amount Paid</b>
							<p>£{user?.amount_paid || 0}</p>
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>Subscription Start </b>
							<p>{getDateTime(user?.sub_start, 'mm dd yyyy') || 'N/A'}</p>
						</div>
						<div>
							<b>Subscription End</b>
							<p>{getDateTime(user?.sub_end, 'mm dd yyyy') || 'N/A'}</p>
						</div>
					</div>
					<div>
						<b>Registration Time</b>
						<p>
							{getDateTime(user?.created_at, 'mm dd yyyy')}, {getDateTime(user?.created_at, '', false)}
						</p>
					</div>
				</div>
			))}
		</>
	);
};
export default BusinessTable;
