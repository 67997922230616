import { useQueryClient } from '@tanstack/react-query';
import { useNotify, useReinviteInstitutionSupervisor, useRevokeInvite } from '../../../../hooks';
import { PendingSupervisorType } from '../../../../types';
import { Button } from '../../../../ui';
import { handleApiError } from '../../../../utilities';
import { AxiosError } from 'axios';
import { useState } from 'react';

const PendingSupervisors = ({ page, supervisors }: { page: number; supervisors: PendingSupervisorType[] | undefined }) => {
	const notify = useNotify();
	const queryClient = useQueryClient();
	const [reInvitingFor, setReInvitingFor] = useState('');
	const [revokingFor, setRevokingFor] = useState('');

	const { mutate: reinviteSupervisor, isLoading: isReinviting } = useReinviteInstitutionSupervisor();
	const { mutate: revokeInvite, isLoading: isRevoking } = useRevokeInvite();

	const handleReinvite = (id: string) => {
		setReInvitingFor(id);

		reinviteSupervisor(id, {
			onSuccess: () => {
				notify({
					message: 'A new invite has been resent',
					status: 'success',
					toastOptions: { toastId: 'supervisor_head_reinvite_success' },
				});

				queryClient.invalidateQueries(['institution-pending-supervisor-head-list']);
			},
			onError: (error) =>
				notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'supervisor_head_reinvite_error' } }),
			onSettled: () => {
				setReInvitingFor('');
			},
		});
	};
	const handleRevoke = (id: string) => {
		setRevokingFor(id);

		revokeInvite(id, {
			onSuccess: () => {
				notify({
					message: 'The invite has been revoked',
					status: 'success',
					toastOptions: { toastId: 'supervisor_head_revoke_success' },
				});

				queryClient.invalidateQueries(['institution-pending-supervisor-head-list']);
			},
			onError: (error) =>
				notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'supervisor_head_revoke_error' } }),
			onSettled: () => {
				setRevokingFor('');
			},
		});
	};

	return (
		<>
			<div className="table-container">
				<table>
					<thead>
						<tr>
							<th>S/N</th>
							<th>Supervisor's Name</th>
							<th>Supervisor's Email</th>
							<th>Supervisor's Number</th>
							<th>Department</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{supervisors?.map((supervisor, index) => (
							<tr key={supervisor?.id}>
								<td>{(page - 1) * 10 + index + 1}</td>
								<td>
									{supervisor?.dept_supervisor_name} {supervisor?.has_invitation_expired ? ' - (Invite expired)' : ''}
								</td>
								<td>{supervisor?.email || 'N/A'}</td>
								<td>{supervisor?.dept_supervisor_phone || 'N/A'}</td>
								<td>{supervisor?.department_name || 'N/A'}</td>
								<td>
									<Button
										style={{ padding: '0.2rem 1rem' }}
										variant="secondary"
										disabled={!supervisor?.has_invitation_expired}
										onClick={() => handleReinvite(supervisor?.id)}
										loading={isReinviting && reInvitingFor === supervisor?.id}
									>
										Resend Invite
									</Button>
									<Button
										style={{ padding: '0.2rem 1rem', color: '#c70c0d', borderColor: '#c70c0d' }}
										variant="secondary"
										disabled={isReinviting && reInvitingFor === supervisor?.id}
										onClick={() => handleRevoke(supervisor?.id)}
										loading={isRevoking && revokingFor === supervisor?.id}
									>
										Revoke Invite
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{supervisors?.map((supervisor) => (
				<div key={supervisor?.id} className="mobile-card">
					<div className="flexed">
						<div>
							<b>Supervisor's Name</b>
							<p>
								{supervisor?.dept_supervisor_name} {supervisor?.dept_supervisor_name}{' '}
								{supervisor?.has_invitation_expired ? ' - (Invite expired)' : ''}
							</p>
						</div>
						<div className="action-con">
							<Button
								style={{ padding: '0.2rem 1rem' }}
								variant="secondary"
								disabled={!supervisor?.has_invitation_expired}
								onClick={() => handleReinvite(supervisor?.id)}
								loading={isReinviting && reInvitingFor === supervisor?.id}
							>
								Resend Invite
							</Button>
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>Supervisor's Email</b>
							<p>{supervisor?.email || 'N/A'}</p>
						</div>
						<div>
							<b>Supervisor's Number</b>
							<p>{supervisor?.dept_supervisor_phone || 'N/A'}</p>
						</div>
					</div>
					<div>
						<b>Department</b>
						<p>{supervisor?.department_name}</p>
					</div>
				</div>
			))}
		</>
	);
};
export default PendingSupervisors;
