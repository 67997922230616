import { Button, InputGroup } from '../../../../ui';
import { Container } from './styles';
import { Approutes } from '../../../../constants';
import { useNotify, useSignupAsInstitution } from '../../../../hooks';
import { handleApiError } from '../../../../utilities';
import { GeneralChangeEventType } from '../../../../types';
import { FormData, SetFormData, SetStep } from '..';
import { AxiosError } from 'axios';
import { useRef } from 'react';

type CreateAccountProp = {
	formData: FormData;
	setFormData: SetFormData;
	setStep: SetStep;
};

const CreateAccount = ({ formData, setFormData, setStep }: CreateAccountProp) => {
	const notify = useNotify();
	const errorRef = useRef<HTMLParagraphElement>(null!);
	const { mutate: signup, isLoading } = useSignupAsInstitution();

	const handleChange: GeneralChangeEventType = (event) => {
		let name = event?.target.name || '';
		let value = event?.target.value || '';

		errorRef.current.innerText = '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (formData.password !== formData.confirm_password) {
			errorRef.current.innerText = 'Passwords do not match.';

			return;
		}

		signup(
			{ email: formData.institution_email, password: formData.password },
			{
				onSuccess: () => setStep(2),
				onError: (error) => notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'signup' } }),
			}
		);
	};

	return (
		<Container>
			<h6>INSTITUTIONAL PORTAL</h6>
			<h2>Create an Account</h2>
			<p>Provide Credentials below to create an Account</p>

			<form onSubmit={handleSubmit}>
				<InputGroup
					label="Email Address"
					type="email"
					name="institution_email"
					onChange={handleChange}
					placeholder="Enter Email Address"
					value={formData.institution_email}
					required
				/>
				<InputGroup
					label="Password"
					type="password"
					name="password"
					onChange={handleChange}
					placeholder="Password"
					value={formData.password}
					required
					minLength={4}
				/>
				<InputGroup
					label="Confirm Password"
					type="password"
					name="confirm_password"
					onChange={handleChange}
					placeholder="Confirm Password"
					value={formData.confirm_password}
					required
				/>

				<p className="error" ref={errorRef}></p>

				<div className="terms-and-condition">
					<InputGroup type="checkbox" name="agree" onChange={handleChange} required />
					<span>
						I agree to the{' '}
						<a href={Approutes.docs.terms_of_use} target="_blank" rel="noreferrer">
							Terms and Condition
						</a>
					</span>
				</div>

				<Button type="submit" loading={isLoading}>
					Create an Account
				</Button>

				<span className="login">
					Already have an account? <a href={Approutes.login}>Login!</a>
				</span>
			</form>
		</Container>
	);
};

export default CreateAccount;
