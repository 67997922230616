import { useMutation } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';

export const usePatchLogs = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({
		mutationFn: ({ id, data }: { id: string; data: { is_viewed: boolean } }) => axiosPrivate.patch(Appurls.logs.use_logs(id), data),
	});
};
