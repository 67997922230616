import styled from 'styled-components';
import { StyledInputGroup } from '../../ui/InputGroup/styles';
import { StyledButton } from '../../ui/Button/styles';

export const Container = styled.div`
	display: flex;
	align-items: center;

	&,
	> div {
		width: 100%;
	}

	h2 {
		color: #292d2d;
		font-size: 2.5rem;
		font-size: clamp(2rem, 1.7222rem + 0.8889vw, 2.5rem);
		line-height: 100%;
	}
	p {
		color: #3a3a3a;
		font-size: 0.9375rem;
		font-size: clamp(0.75rem, 0.6458rem + 0.3333vw, 0.9375rem);
		line-height: 170%;
		margin: 0.5rem 0 2.3rem;
		margin: 0.5rem 0 clamp(1.75rem, 1.4444rem + 0.9778vw, 2.3rem);
	}
	.password-group {
		position: relative;

		a {
			position: absolute;
			right: 0;
			top: 0;
			font-weight: 600;
			font-size: 0.875rem;
			line-height: 120%;
			color: #4060ea;
			z-index: 10;
		}
	}
	${StyledInputGroup} {
		margin-bottom: 1.25rem;
	}
	${StyledButton} {
		margin: 2rem 0 1rem;
		justify-content: center;
		width: 100%;
	}

	.login {
		color: #504f4f;
		font-size: 0.875rem;
		text-align: center;
		display: block;

		a {
			color: #4060ea;
			font-weight: 600;
		}
	}

	form {
		width: 100%;
	}
`;
