import { LogoLoaderOne, LogoLoaderThree, LogoLoaderTwo } from '../../assets/svgs';
import { StyledLoader, StyledLogoLoader } from './styles';

const Loader = ({ variant = 'spinner', size = '4rem' }) => {
	return variant === 'logo' ? (
		<StyledLogoLoader $size={size}>
			<LogoLoaderOne />
			<LogoLoaderTwo />
			<LogoLoaderThree />
		</StyledLogoLoader>
	) : (
		<StyledLoader />
	);
};
export default Loader;
