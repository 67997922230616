import { FileDownloadGroup } from '../../../../ui';

const ApplicationDocs = ({ user }) => {
	return (
		<>
			<article className="attachments">
				<b>Certification Document</b>

				<div>
					{user?.certification_docs?.[0]?.url ? (
						<FileDownloadGroup
							fileName={user?.certification_docs?.[0]?.file_name}
							url={user?.certification_docs?.[0]?.url}
							fileSizeInKb={user?.certification_docs?.[0]?.file_size_in_kb}
						/>
					) : (
						'N/A'
					)}
				</div>
			</article>

			<article className="attachments">
				<b>Enrollment Document</b>

				<div>
					{user?.enrollment_doc?.[0]?.url ? (
						<FileDownloadGroup
							fileName={user?.enrollment_doc?.[0]?.file_name}
							url={user?.enrollment_doc?.[0]?.url}
							fileSizeInKb={user?.enrollment_doc?.[0]?.file_size_in_kb}
						/>
					) : (
						'N/A'
					)}
				</div>
			</article>

			<article className="attachments">
				<b>Supplimentary Document</b>

				<div>
					{user?.supplimentary_docs?.[0]?.url ? (
						<FileDownloadGroup
							fileName={user?.supplimentary_docs?.[0]?.file_name}
							url={user?.supplimentary_docs?.[0]?.url}
							fileSizeInKb={user?.supplimentary_docs?.[0]?.file_size_in_kb}
						/>
					) : (
						'N/A'
					)}
				</div>
			</article>
		</>
	);
};
export default ApplicationDocs;
