import styled from 'styled-components';
import { StyledButton } from '../../../../ui/Button/styles';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	inset: 0;
	z-index: 99;

	> div {
		border-radius: 0.25rem;
		background: #fff;
		padding: 2rem 5rem;
		width: 38rem;
		max-width: calc(100vw - 2rem);
		min-height: 27rem;
		max-height: calc(100vh - 4rem);
		overflow: auto;

		> svg {
			margin-left: auto;
			display: block;
			cursor: pointer;
			width: 1.25rem;
			width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);

			:active {
				outline: none;
			}
		}
		h2 {
			text-align: center;
			font-size: 2rem;
			font-size: clamp(1.75rem, 1.6111rem + 0.4444vw, 2rem);
			margin: 1rem 0 0.5rem;
			color: #070d19;
		}
		h2 + p {
			color: #a8a8a8;
			font-size: 1rem;
			font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
			line-height: 120%;
			margin: 0.5rem 0 2rem;
			text-align: center;
		}

		center {
			height: 12rem;
			display: grid;
			place-items: center;
			background-color: #fff;
			border-radius: 0.125rem;
			padding: 1rem;
		}

		.resource-con {
			display: flex;
			align-items: center;
			gap: 0.75rem;
			color: #494848;
			font-size: 1rem;
			font-weight: 500;
			line-height: 135%;
			border-radius: 0.25rem;
			border: 1px solid #dadada;
			padding: 1.25rem 1.5rem;

			:hover {
				border: 1px solid #4060ea;
				background: #f1f4fdbf;
			}

			:not(:last-child) {
				margin-bottom: 0.5rem;
			}

			img {
				width: 2rem;
				height: 2rem;
			}

			.name {
				margin-right: auto;
				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
			}
			.duration {
				margin-right: 1.25rem;
				font-weight: 600;
				color: #4060ea;
				min-width: max-content;
				font-size: 0.825rem;
				padding: 0.625rem;
				background: #f6f7fe;
				border: 0.5px solid rgba(64, 96, 234, 0.1);
				border-radius: 50px;
			}
			${StyledButton} {
				padding: 0.75rem 0.75rem;

				&.in-project-btn path {
					fill: #fff;
				}

				span {
					display: flex;
					align-items: center;
				}
			}
		}
	}
`;
