import { useRef, useState } from 'react';
import { Container } from './styles';
import { Button, Loader, Pagination, PalletList, SearchBar } from '../../../../ui';
import { useGetInstitutionAdminDepartmentsStats, useGetDepartments } from '../../../../hooks';
import { statisticsFilters } from '../../../../constants';
import {
	ButtonPlus,
	DashboardAmountPaid,
	DashboardAmountRefunded,
	DashboardBusiness,
	DashboardInterns,
	ProjectEmpty,
} from '../../../../assets/svgs';
import { Filter } from '../../../../components';
import { useCallback } from 'react';
import { GeneralChangeEventType, FilterValue } from '../../../../types';
import { DepartmentTable } from '../../components';
import { AddDepartmentModal, ChangeDepartmentHeadModal } from '../../modals';

const InstitutionDepartment = () => {
	const pageSize = useRef(6);
	const [page, setPage] = useState(1);
	const [isAddDepartmentModalOpen, setIsAddDepartmentModalOpen] = useState(false);
	const [isChangeHeadModalOpen, setIsChangeHeadModalOpen] = useState(false);
	const [departmentIdToChangeHead, setDepartmentIdToChangeHead] = useState('');
	const [formData, setFormData] = useState({
		search: '',
		filter_stats: (() => {
			let state: FilterValue = {};
			statisticsFilters.forEach((option) => (state[option?.name] = { value: '', queryKey: option?.queryKey }));

			return state;
		})(),
		filter: (() => {
			let state: FilterValue = {};
			[].forEach((option: { name: string | number; queryKey: any }) => (state[option?.name] = { value: '', queryKey: option?.queryKey }));

			return state;
		})(),
	});

	const { data: stats, isLoading: statsLoading } = useGetInstitutionAdminDepartmentsStats();
	const {
		data: departments,
		isLoading: isLoadingDepartments,
		isError: isDepartmentError,
	} = useGetDepartments({ page, page_size: pageSize.current, search: formData.search, enabled: true });

	const isFetchingData = isLoadingDepartments;
	const isDataError = isDepartmentError;

	const result = departments;

	const statsData = [
		{
			title: 'No. of Department',
			body: stats?.data?.dept || 0,
			icon: <DashboardBusiness />,
		},
		{
			title: 'Invite sent',
			body: stats?.data?.total_invite || 0,
			icon: <DashboardInterns />,
		},
		{
			title: 'Completed Profile',
			body: stats?.data?.completed_profile || 0,
			icon: <DashboardAmountPaid />,
		},
		{
			title: 'Completed Program',
			body: stats?.data?.completed_program || 0,
			icon: <DashboardAmountRefunded />,
		},
	];

	const handleChange: GeneralChangeEventType = useCallback((event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name as string]: value }));
	}, []);

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Departments</h4>
					{/* <p>Update your photo and personal details</p> */}
				</div>

				<Button onClick={() => setIsAddDepartmentModalOpen(true)}>
					<ButtonPlus /> Add Department
				</Button>
				<Filter name="filter_stats" onChange={handleChange} value={formData.filter_stats} options={[]} />
			</div>

			<PalletList loading={statsLoading} data={statsData} />

			<div className="table-header">
				<div className="controls">
					<h3>Department Listings</h3>
				</div>

				<div className="filter-con">
					<SearchBar name="search" placeholder="Search through departments" handleChange={handleChange} value={formData.search} />
					<Filter name="filter" onChange={handleChange} value={formData.filter} options={[]} />
				</div>
			</div>

			{isFetchingData ? (
				<center>
					<Loader />
				</center>
			) : isDataError ? (
				<center>
					<div>
						<ProjectEmpty />
						<p className="error-message">Unable to fetch departments at the moment</p>
					</div>
				</center>
			) : !result?.results || result?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p className="error-message">No departments to display</p>
				</div>
			) : (
				<DepartmentTable
					page={page}
					departments={departments?.results}
					handleChangeHead={(id: string) => {
						setDepartmentIdToChangeHead(id);
						setIsChangeHeadModalOpen(true);
					}}
				/>
			)}

			<Pagination page={page} setPage={setPage} maxPages={Math.ceil((result?.total || 0) / pageSize.current)} disabled={isFetchingData} />

			{isAddDepartmentModalOpen ? <AddDepartmentModal handleClose={() => setIsAddDepartmentModalOpen(false)} /> : null}
			{isChangeHeadModalOpen && departmentIdToChangeHead ? (
				<ChangeDepartmentHeadModal departmentId={departmentIdToChangeHead} handleClose={() => setIsChangeHeadModalOpen(false)} />
			) : null}
		</Container>
	);
};
export default InstitutionDepartment;
