import { toast, ToastOptions } from 'react-toastify';
import { Notification } from '../ui';

type NotifyType = {
	message: string;
	variant?: 'major' | 'minor';
	status?: 'info' | 'success' | 'error';
	toastOptions: ToastOptions;
};

export const useNotify = () => {
	const notify = ({ message = '', status = 'info', variant = 'major', toastOptions = {} }: NotifyType) => {
		toast(<Notification message={message} variant={variant} status={status} />, toastOptions);
	};

	return notify;
};
