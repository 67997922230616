import styled from 'styled-components';

export const StyledPagination = styled.div`
	display: flex;

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 3rem;
		height: 3rem;
		padding: 0.3125rem 1.25rem;
		padding: clamp(0.24088rem, 0.2011rem + 0.1273vw, 0.3125rem) clamp(0.96rem, 0.7989rem + 0.5156vw, 1.25rem);
		border-radius: 0.25rem;
		color: #4060ea;
		background: transparent;
		font-size: 0.875rem;
		font-size: clamp(0.82588rem, 0.7986rem + 0.0873vw, 0.875rem);

		:disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}

		&.active {
			background: #4060ea;
			color: #fff;
		}
	}
`;
