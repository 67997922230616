import { useState } from 'react';
import EnterEmail from './EnterEmail';
import CreatePassword from './CreatePassword';
import { useMutation } from '@tanstack/react-query';
import { axios } from '../../library';
import { Appurls } from '../../constants';
import { useNotify } from '../../hooks';
import { handleApiError } from '../../utilities';
import { OTP } from '../../components';

const ForgotPassword = () => {
	const notify = useNotify();
	const { mutate: verifyOTP, isLoading } = useMutation({
		mutationFn: (otp) => axios.post(Appurls.auth.password.verify_otp, { otp, email: formData.email }),
	});
	const { mutate: resetPassword, isLoading: isSigningUp } = useMutation({
		mutationFn: (data) => axios.post(Appurls.auth.password.reset_password, data),
	});
	const [step, setStep] = useState(1);
	const [formData, setFormData] = useState({ email: '', password: '', confirm_password: '', otp: ['', '', '', ''] });

	const handleSubmit = (event) => {
		event.preventDefault();

		verifyOTP(formData.otp.join(''), {
			onSuccess: () => setStep(3),
			onError: (error) => {
				let errorMessage = error?.response.status === 400 ? 'Invalid OTP supplied' : handleApiError(error);

				notify({ message: errorMessage, status: 'error', toastOptions: { toastId: 'create_password' } });
			},
		});
	};

	return step === 1 ? (
		<EnterEmail formData={formData} setFormData={setFormData} setStep={setStep} />
	) : step === 2 ? (
		<OTP
			value={formData.otp}
			setValue={(value) => setFormData((prev) => ({ ...prev, otp: value }))}
			isSubmitting={isLoading}
			handleSubmit={handleSubmit}
			isSendingOTP={isSigningUp}
			resendOTP={() =>
				resetPassword(
					{ email: formData.email },
					{
						onSuccess: () =>
							notify({
								message: 'A new OTP has been resent',
								variant: 'minor',
								toastOptions: { toastId: 'otp_success', position: 'bottom-center' },
							}),
						onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'signup' } }),
					}
				)
			}
		/>
	) : step === 3 ? (
		<CreatePassword formData={formData} setFormData={setFormData} />
	) : null;
};

export default ForgotPassword;
