import styled from 'styled-components';
import { StyledButton } from '../../ui/Button/styles';
import { maxQuery } from '../../utilities';

export const Container = styled.div`
	padding: 2rem 6.25rem;
	padding: 2rem clamp(1rem, -1.9167rem + 9.3333vw, 6.25rem);

	.action-con {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1.3rem;

		.back-btn {
			color: #252b33;
			font-size: 0.9375rem;
			font-weight: 600;
			border-radius: 0.25rem;
			border: 1px solid #ebebeb;
			background: #fff;
			padding: 1rem 1.5rem;
			display: inline-flex;
			align-items: center;
			gap: 1rem;

			${maxQuery('md')} {
				padding: 1.2rem 1rem;

				span {
					display: none;
				}
			}

			svg {
				width: 0.8125rem;
				transform: rotate(90deg);
			}
		}
		> div {
			display: flex;
			gap: 1rem;

			${StyledButton} {
				font-size: 0.875rem;
				padding: 0.825rem 1.25rem;

				${maxQuery('md')} {
					padding: 0.625rem 1rem;
				}

				&.cancel-btn {
					border-width: 2px;
				}
				&.edit-btn span,
				&.invite-btn span {
					display: flex;
					align-items: center;
					gap: 0.5rem;

					${maxQuery('md')} {
						span {
							display: none;
						}
					}
				}

				&.edit-btn {
					border-width: 2px;

					svg {
						fill: #4060ea;
					}
					path {
						stroke: #4060ea;
					}
				}
			}
		}
	}
	.loading-con {
		height: calc(100vh - 15rem);
		display: grid;
		place-items: center;
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;
	}
	.empty-state {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: calc(100vh - 15rem);
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;

		p {
			color: #1e1e1e;
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 135%;
			margin-top: 1rem;
		}
		span {
			text-transform: capitalize;
		}
	}
`;
