import { useEffect, useRef } from 'react';
import { useDecodedUser, useMessage } from '../../../../hooks';
import { Loader, SearchBar } from '../../../../ui';
import UserMessageListItem from '../UserMessageListItem';
import { Container } from './styles';
import { ProjectEmpty } from '../../../../assets/svgs';

const Conversations = ({ isMessageActive, handleConversationClick }) => {
	const user = useDecodedUser();

	const {
		conversations,
		conversationSearchTerm,
		isConversationsLoading,
		isConversationsError,
		fetchMoreConversations,
		isFetchingMoreConversations,
		conversationsHasNextPage,
		handleConversationSearchChange,
	} = useMessage();

	const conversationListEnd = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						conversationsHasNextPage ? fetchMoreConversations() : observer.unobserve(entry.target);
					}
				});
			},
			{ rootMargin: '100px' }
		);

		conversationListEnd.current && observer.observe(conversationListEnd.current);

		return () => observer.disconnect();
	}, [conversationsHasNextPage, fetchMoreConversations]);

	return (
		<Container className={isMessageActive ? 'inactive' : ''}>
			<div className="search-con">
				<SearchBar
					name="search"
					placeholder="Search"
					handleChange={handleConversationSearchChange}
					value={conversationSearchTerm}
					disabled={conversations?.length === 0 || isConversationsError}
				/>
			</div>

			{isConversationsLoading ? (
				<div className="conversation-main-loader">
					<Loader />
				</div>
			) : isConversationsError ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>Something went wrong while fetching your conversations</p>
				</div>
			) : (
				<div className="users-con">
					{conversations?.length ? (
						conversations?.map((convo) => (
							<UserMessageListItem
								key={convo?.id}
								profilePicture={convo?.room_type === 'PERSONAL' ? convo?.participants?.avatar : null}
								name={convo?.room_type === 'PERSONAL' ? convo?.participants?.name : convo?.room_type === 'PROJECT' ? convo?.project_name : null}
								dateTime={convo?.last_message?.created_at}
								message={
									convo?.last_message
										? `${convo?.last_message?.sender?.id === user?.user_id ? 'Me: ' : ''} ${convo?.last_message?.text}`
										: 'No message yet'
								}
								handleClick={() => handleConversationClick(convo?.id)}
								isUnread={convo?.unread_count > 0}
							/>
						))
					) : (
						<div className="empty-state">
							<ProjectEmpty />
							<p>You haven't started any conversation</p>
						</div>
					)}
				</div>
			)}
			{isFetchingMoreConversations ? (
				<div className="conversation-mini-loader">
					<Loader />
				</div>
			) : null}

			<div className="list-end" ref={conversationListEnd}></div>
		</Container>
	);
};

export default Conversations;
