import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;

	&,
	> div {
		width: 100%;
	}
	h2 {
		color: #292d2d;
		font-size: 2.5rem;
		font-size: clamp(2rem, 1.7222rem + 0.8889vw, 2.5rem);
		line-height: 100%;
	}
	p {
		color: #3a3a3a;
		font-size: 0.9375rem;
		font-size: clamp(0.75rem, 0.6458rem + 0.3333vw, 0.9375rem);
		line-height: 170%;
		margin: 0.5rem 0 2.3rem;
		margin: 0.5rem 0 clamp(1.75rem, 1.4444rem + 0.9778vw, 2.3rem);
	}

	button {
		border-radius: 0.125rem;
		border: 1px solid #f1f1f1;
		background: rgba(229, 229, 229, 0.1);
		display: flex;
		padding: 1rem 2rem;
		padding: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem) clamp(1rem, 0.4444rem + 1.7778vw, 2rem);
		gap: 0.63rem;
		margin-bottom: 1.5rem;
		margin-bottom: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
		width: 100%;
		text-align: left;

		:hover,
		:focus {
			border: 1px solid #4060ea;
			background: rgba(241, 244, 253, 0.75);
		}

		svg {
			width: clamp(2.57725rem, 2.1341rem + 1.4182vw, 3.375rem);
		}
		b {
			color: #222;
			font-size: 1.0625rem;
			font-size: clamp(0.875rem, 0.7708rem + 0.3333vw, 1.0625rem);
			font-weight: 700;
			letter-spacing: -0.02125rem;
			display: block;
			margin: 0.5rem 0 0.25rem;
		}
		b + span {
			color: #868e96;
			font-size: 0.875rem;
			font-size: clamp(0.75rem, 0.6806rem + 0.2222vw, 0.875rem);
			line-height: 1.4;
			text-transform: capitalize;
		}
	}
`;
