import { Button, InputGroup } from '../../../ui';
import { getDateTime } from '../../../utilities';

const Overview = ({ isEditing, userDetails, handleChange, formData, isSubmitting, handleSubmit }) => {
	return isEditing ? (
		<div className="inputs-group">
			<InputGroup
				label="Company Name"
				name="company_name"
				onChange={handleChange}
				value={formData.company_name}
				placeholder="Enter Company Name"
				required
			/>

			<InputGroup
				label="Contact Number"
				name="company_phone"
				onChange={handleChange}
				value={formData.company_phone}
				placeholder="Enter Contact Number"
				required
			/>

			<InputGroup
				label="Introduction/About"
				variant="textarea"
				name="about"
				onChange={handleChange}
				value={formData.about}
				placeholder="Enter About"
				required
			/>

			<Button className="submit-btn" loading={isSubmitting} onClick={handleSubmit}>
				Save Changes
			</Button>
		</div>
	) : (
		<div className="info-con">
			<div>
				<div>
					<span>Company Name</span>
					<b>{userDetails?.company_name}</b>
				</div>
			</div>
			<div>
				<div>
					<span>Contact Number</span>
					<b>{userDetails?.company_phone}</b>
				</div>
				<div>
					<span>Registration Date</span>
					<b>{getDateTime(userDetails?.created_at)}</b>
				</div>
			</div>
			<div>
				<div>
					<span>About</span>
					<b>{userDetails?.about}</b>
				</div>
			</div>
		</div>
	);
};
export default Overview;
