import { Link } from 'react-router-dom';
import { EyeOpen, ProjectEmpty, TaskLog } from '../../assets/svgs';
import { useGetApplicantTask } from '../../hooks';
import { Loader } from '../../ui';
import { getDateTime } from '../../utilities';
import { Approutes } from '../../constants';
import { useState } from 'react';
import { LogTimesheet } from '../../components';

const Tasks = ({ projectId }) => {
	const [logState, setLogState] = useState({
		submitLog: false,
		taskId: '',
	});
	const { data: tasks, isLoading, isError } = useGetApplicantTask({ projectId, page: 1, page_size: 3, status: 'in-progress' });

	const handleNewLog = (taskId) => {
		setLogState({
			submitLog: true,
			taskId,
		});
	};

	return isLoading ? (
		<center>
			<Loader />
		</center>
	) : isError ? (
		<div className="empty-state">
			<ProjectEmpty />
			<p>Unable to fetch available tasks at the moment</p>
		</div>
	) : !tasks?.results || tasks?.results?.length === 0 ? (
		<div className="empty-state">
			<ProjectEmpty />
			<p>You haven't been assigned any task on this project</p>
		</div>
	) : (
		<>
			<div className="table-container">
				<table>
					<thead>
						<tr>
							<th>S/N</th>
							<th>Task</th>
							<th>Start Date</th>
							<th>End Date</th>
							<th>Status</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{tasks?.results?.map((task, index) => (
							<tr key={task?.id}>
								<td>{index + 1}</td>
								<td>{task?.name}</td>
								<td>{task?.start_date ? getDateTime(task?.start_date, 'mm dd yyyy') : 'Not set'}</td>
								<td>{task?.end_date ? getDateTime(task?.end_date, 'mm dd yyyy') : 'Not set'}</td>
								<td>{task?.is_completed ? 'Completed' : 'Active'}</td>
								<td>
									<Link to={Approutes.intern.tasks.use_task_details(projectId, task?.id)}>
										<EyeOpen />
									</Link>
									<TaskLog onClick={() => handleNewLog(task?.id)} />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<Link to={Approutes.intern.tasks.use_my_tasks(projectId)}>See all your tasks under this project</Link>

			{logState.submitLog ? <LogTimesheet taskId={logState.taskId} handleClose={() => setLogState({ submitLog: false, taskId: '' })} /> : null}
		</>
	);
};
export default Tasks;
