import styled from 'styled-components';
import { maxQuery } from '../../utilities';

export const Container = styled.div`
	padding: 2.5rem 6.25rem;
	padding: 1.5rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);

	> section {
		height: calc(100vh - 7.7rem);
		min-height: 30rem;
		background-color: #fff;
		border-radius: 0.125rem;
		display: grid;
		grid-template-columns: 0px auto;
		grid-template-columns: 305px auto;
		grid-template-columns: clamp(250px, 33%, 305px) auto;

		${maxQuery('md')} {
			grid-template-columns: 1fr;
		}
	}
`;
