import { StyledSelectGroup } from './styles';
import { ChevronDown, InputInfo, SelectClose } from '../../assets/svgs';
import { FocusEvent, useState } from 'react';
import { useEffect } from 'react';
import { SelectGroupPropType } from './types';

const SelectGroup = ({
	label,
	value = '',
	onChange,
	placeholder,
	variant = 'single',
	name,
	moreInfo,
	dropdownPosBottom = true,
	options = [],
	emptyOptionsMessage = 'No option to display',
	required = false,
	max = Infinity,
	loading = false,
	shouldFilterByDefault = true,
	shouldHaveEmptyOption = false,
	...rest
}: SelectGroupPropType) => {
	const [inputVal, setInputVal] = useState(variant === 'single' ? value || '' : '');
	const [shouldFilter, setShouldFilter] = useState(shouldFilterByDefault);

	const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
		if (event.relatedTarget?.tagName === 'LI') return;

		let eventValue = (event?.target as HTMLInputElement)?.value;
		let selectedOption = options.find((option) => option?.toLowerCase() === eventValue.toLowerCase()) || '';

		setShouldFilter(false);

		if (variant === 'single') {
			setInputVal(selectedOption);
			onChange(null, name, selectedOption);
		} else {
			let newValue =
				(value as string[]).length > 0
					? (value as string[]).indexOf(selectedOption) >= 0
						? value
						: [...(value as string[]), selectedOption]
					: [selectedOption];

			setInputVal('');
			selectedOption && (value as string[]).length < max && onChange(null, name, newValue);
		}
	};
	const handleRemove = (selectedOption: string | number) => {
		let newValue = (value as string[])?.filter((option) => option !== selectedOption);

		onChange(null, name, newValue);
	};

	useEffect(() => {
		if (variant === 'single') setInputVal(value || '');
	}, [variant, value]);

	return (
		<StyledSelectGroup $dropdownPosBottom={dropdownPosBottom}>
			{label ? (
				<label htmlFor={name}>
					{label} {required ? <span className="asterisk">*</span> : null}
					{moreInfo ? (
						<span className="input-info-con">
							<InputInfo className="info" tabIndex={0} />
							<span className="outer">
								<span>{moreInfo}</span>
							</span>
						</span>
					) : null}
				</label>
			) : null}

			<div className="input-icon-group">
				<input
					type="text"
					placeholder={placeholder}
					name={name}
					value={inputVal}
					required={variant === 'multiple' ? (required ? ((value as string[]).length > 0 ? false : true) : required) : required}
					onBlur={handleBlur}
					onChange={(event) => {
						setShouldFilter(event.target.value ? true : false);
						setInputVal(event.target.value);
					}}
					autoComplete="off"
					{...rest}
				/>
				<div className="chevron-con">
					<ChevronDown />
				</div>
			</div>

			{variant === 'multiple' && (value as string[]).length > 0 ? (
				<div className="selected-options">
					{(value as string[])?.map((option, index) => (
						<span key={index}>
							{option} <SelectClose onClick={() => handleRemove(option)} />
						</span>
					))}
				</div>
			) : null}

			<ul>
				{loading ? (
					<li>Loading...</li>
				) : options?.length === 0 ? (
					<li>{emptyOptionsMessage}</li>
				) : (
					<>
						{shouldHaveEmptyOption && !shouldFilter ? (
							<li
								className="empty-option"
								onClick={(event) => {
									event.currentTarget.blur();

									onChange(null, name, '');
								}}
								tabIndex={0}
							>
								Empty
							</li>
						) : null}
						{options
							.filter((option) => (shouldFilter ? option?.toLowerCase().indexOf(inputVal.toString().toLowerCase()) >= 0 : option))
							.map((option, index) => (
								<li
									key={index}
									onClick={(event) => {
										event.currentTarget.blur();

										setShouldFilter(false);

										if (variant === 'multiple') {
											let newValue =
												(value as string[]).length > 0
													? (value as string[]).indexOf(option) >= 0
														? value
														: [...(value as string[]), option]
													: [option];

											setInputVal('');
											(value as string[]).length < max && onChange(null, name, newValue);
										} else {
											setInputVal(option);
											onChange(null, name, option);
										}
									}}
									tabIndex={0}
								>
									{option}
								</li>
							))}
					</>
				)}
			</ul>
		</StyledSelectGroup>
	);
};

export default SelectGroup;
