import { Loader } from '../../ui';
import { ChevronDown, ProjectEmpty } from '../../assets/svgs';
import { useState } from 'react';
import { Container, StyledSupervisor } from './styles';
import Logs from './Logs';
import { useGetInstitutionInternSupervisor } from '../../hooks';

const InstitutionInternSupervisor = () => {
	const [viewSupervisorLog, setViewSupervisorLog] = useState('');
	const { data: supervisor, isLoading, isError } = useGetInstitutionInternSupervisor();

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Supervisor</h4>
					{/* <p>Update your photo and personal details</p> */}
				</div>
			</div>

			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>Unable to fetch supervisors at the moment</p>
				</div>
			) : (
				<>
					<div className="supervisors">
						<StyledSupervisor key={supervisor?.id} className={supervisor?.related_project?.id === viewSupervisorLog ? 'active' : ''}>
							<div className="supervisor-con">
								<div>
									<b>{supervisor?.dept_supervisor_name}</b> <span>{supervisor?.department_name}</span>
								</div>
								<div>
									<b>{supervisor?.institution_name}</b>
									<span>Institution Name</span>
								</div>
								<div>
									<b>{supervisor?.email}</b>
									<span>Email Address</span>
								</div>
								{/* <div>
									<b>{supervisor?.logs_rate?.completed} Tasks</b>
									<span>Logs Approved </span>
								</div>
								<div>
									<b>{supervisor?.logs_rate?.pending} Tasks</b>
									<span>Logs Pending </span>
								</div> */}
								<div>
									<button
										onClick={() => setViewSupervisorLog((prev) => (prev !== supervisor?.related_project?.id ? supervisor?.related_project?.id : ''))}
									>
										<ChevronDown />
									</button>
								</div>
							</div>

							{supervisor?.related_project?.id === viewSupervisorLog ? <Logs projectId={supervisor?.related_project?.id} /> : null}
						</StyledSupervisor>
					</div>
				</>
			)}
		</Container>
	);
};

export default InstitutionInternSupervisor;
