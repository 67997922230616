import { useEffect } from 'react';
import { useAuth, useLogout, useNotify, useRefreshToken } from '.';
import { axiosPrivate } from '../library';
import { LOCAL_STORAGE_KEYS } from '../constants';

export const useAxiosPrivate = () => {
	const { auth, setAuth } = useAuth();
	const notify = useNotify();
	const refresh = useRefreshToken();
	const logout = useLogout();

	useEffect(() => {
		const requestInterceptor = axiosPrivate.interceptors.request.use(
			(config) => {
				config.headers = config.headers ?? {};

				if (!config?.headers['Authorization']) {
					config.headers['Authorization'] = `Bearer ${auth?.access || localStorage.getItem(LOCAL_STORAGE_KEYS.access_key)}`;
				}
				return config;
			},
			(error) => Promise.reject(error)
		);

		const responseInterceptor = axiosPrivate.interceptors.response.use(
			(response) => response,

			async (error) => {
				let prevRequest = error?.config;

				if (error?.response?.status === 401 && !prevRequest.hasSent) {
					prevRequest.hasSent = true;

					try {
						const {
							data: { access: newAccessToken },
						} = await refresh();

						setAuth((prev) => ({ ...prev, access: newAccessToken }));
						localStorage.setItem(LOCAL_STORAGE_KEYS.access_key, newAccessToken);

						prevRequest = {
							...prevRequest,
							headers: {
								...prevRequest.headers,
								Authorization: `Bearer ${newAccessToken}`,
							},
						};

						return axiosPrivate(prevRequest);
					} catch (error) {
						if (error?.response?.status === 401) {
							notify({ message: 'Your session has expired. Please log in again', toastOptions: { toastId: 'session_expired' } });

							logout(true);
						}
					}
				} else if (error?.response?.status === 401 && prevRequest.hasSent) {
					notify({ message: 'Something went wrong while connecting to the server', toastOptions: { toastId: 'session_expired' } });

					logout(true);
				}

				return Promise.reject(error);
			}
		);

		return () => {
			axiosPrivate.interceptors.response.eject(responseInterceptor);
			axiosPrivate.interceptors.request.eject(requestInterceptor);
		};
	}, [auth, setAuth, refresh, notify, logout]);

	return axiosPrivate;
};
