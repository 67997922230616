import { useRef, useState } from 'react';
import { Button, InputGroup } from '../../../ui';
import { CloseModal } from '../../../assets/svgs';
import { Container } from './styles';

const EditTask = ({ taskName, taskDesc, handleClose, handleSubmit }) => {
	const errorRef = useRef();
	const [formData, setFormData] = useState({
		task_name: taskName || '',
		task_description: taskDesc || '',
	});

	const handleChange = (event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const onSubmit = (event) => {
		event.preventDefault();

		if (formData.task_name.length === 0) {
			errorRef.current.innerText = 'Task name cannot be empty';
			return;
		}

		handleSubmit(formData);
	};
	return (
		<Container>
			<form onSubmit={onSubmit}>
				<CloseModal tabIndex={0} onClick={handleClose} />
				<h2>Edit Task</h2>

				<InputGroup
					label="Task Name"
					name="task_name"
					onChange={(event) => {
						errorRef.current.innerText = '';
						handleChange(event);
					}}
					placeholder="Task Name"
					value={formData.task_name}
					required
				/>
				<InputGroup
					label="Task Description"
					variant="textarea"
					name="task_description"
					onChange={(event) => {
						errorRef.current.innerText = '';
						handleChange(event);
					}}
					placeholder="Enter description of the task"
					value={formData.task_description}
					required
				/>
				<div className="error" ref={errorRef}></div>

				<Button type="submit">Edit</Button>
			</form>
		</Container>
	);
};
export default EditTask;
