import jwtDecode from 'jwt-decode';
import { useAuth } from './useAuth';
import { useMemo } from 'react';
import { LOCAL_STORAGE_KEYS } from '../constants';
import { UserType } from '../types';

export const useDecodedUser: () => UserType | undefined = () => {
	const { auth } = useAuth();

	return useMemo(() => {
		let token = auth?.access || localStorage.getItem(LOCAL_STORAGE_KEYS.access_key) || '';
		return token ? jwtDecode(token) : undefined;
	}, [auth?.access]);
};
