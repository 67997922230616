import { useMutation, useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';
import { ActiveHeadType, PendingHeadType, ServerGetResponse } from '../types';

export const useGetPendingDepartmentHeads = ({ page = 1, page_size = 5, search = '', enabled = true }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponse<PendingHeadType>> =>
			axiosPrivate
				.get(`${Appurls.institution_admin.department.head.pending}?page=${page}&page_size=${page_size}&search=${search}`)
				.then((response) => response.data),
		queryKey: ['institution-pending-department-head-list', { page, page_size, search }],
		enabled,
	});
};

export const useGetSignedUpDepartmentHeads = ({ page = 1, page_size = 5, search = '', enabled = true }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponse<ActiveHeadType>> =>
			axiosPrivate
				.get(`${Appurls.institution_admin.department.head.active}?page=${page}&page_size=${page_size}&search=${search}`)
				.then((response) => response.data),
		queryKey: ['institution-signedup-department-head-list', { page, page_size, search }],
		enabled,
	});
};

export const useChangeDepartmentHead = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation((data: { dept_head: string; dept_id: string }) =>
		axiosPrivate.post(Appurls.institution_admin.department.head.change_head(data.dept_id), { dept_head: data.dept_head })
	);
};

export const useCreateDepartmentHead = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation((data: { email: string; phone: string; name: string }[]) =>
		axiosPrivate.post(Appurls.institution_admin.department.head.onboard, { data })
	);
};

export const useReinviteDepartmentHead = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation((id: string) => axiosPrivate.get(Appurls.institution_admin.department.head.reinvite(id)));
};
