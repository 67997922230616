import { useMutation, useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';
import {
	ActiveInternsType,
	InstitutionInternProfileType,
	InstitutionInternSupervisor,
	PendingInternsType,
	ServerGetResponse,
} from '../types';

export const useInviteInstitutionIntern = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation((data: { departmentId: string; email: string; supervisorId: string }) =>
		axiosPrivate.post(Appurls.institution_admin.student.onboard(data.departmentId), {
			supervisor: data.supervisorId,
			data: [
				{
					email: data.email,
				},
			],
		})
	);
};

export const useBulkInviteInstitutionInterns = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation((data: { departmentId: string; file: string; supervisorId: string }) =>
		axiosPrivate.post(
			Appurls.institution_admin.student.bulk_onboard(data.departmentId),
			{
				supervisor: data.supervisorId,
				file: data.file,
			},
			{ headers: { 'Content-Type': 'multipart/form-data' } }
		)
	);
};

export const useGetPendingInterns = ({ page = 1, page_size = 5, search = '', enabled = true }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponse<PendingInternsType>> =>
			axiosPrivate
				.get(`${Appurls.institution_admin.student.pending}?page=${page}&page_size=${page_size}&search=${search}`)
				.then((response) => response.data),
		queryKey: ['institution-pending-intern-list', { page, page_size, search }],
		enabled,
	});
};

export const useGetSignedUpInterns = ({ page = 1, page_size = 5, search = '', enabled = true }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponse<ActiveInternsType>> =>
			axiosPrivate
				.get(`${Appurls.institution_admin.student.active}?page=${page}&page_size=${page_size}&search=${search}`)
				.then((response) => response.data),
		queryKey: ['institution-signedup-intern-list', { page, page_size, search }],
		enabled,
	});
};

export const useGetPendingInternsForDeptHead = ({ page = 1, page_size = 5, search = '', enabled = true }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponse<PendingInternsType>> =>
			axiosPrivate
				.get(`${Appurls.institution_admin.department.pending_students}?page=${page}&page_size=${page_size}&search=${search}`)
				.then((response) => response.data),
		queryKey: ['institution-pending-intern-list', { page, page_size, search }],
		enabled,
	});
};

export const useGetSignedUpInternsForDeptHead = ({ page = 1, page_size = 5, search = '', enabled = true }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponse<ActiveInternsType>> =>
			axiosPrivate
				.get(`${Appurls.institution_admin.department.active_students}?page=${page}&page_size=${page_size}&search=${search}`)
				.then((response) => response.data),
		queryKey: ['institution-signedup-intern-list', { page, page_size, search }],
		enabled,
	});
};

export const useReinviteInstitutionIntern = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation((id: string) => axiosPrivate.get(Appurls.institution_admin.student.reinvite(id)));
};

export const useGetInstitutionInternSupervisor = () => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<InstitutionInternSupervisor> =>
			axiosPrivate.get(Appurls.institution_admin.student.get_supervisor).then((response) => response?.data),
		queryKey: ['institution-intern-supervisor'],
	});
};

export const useGetMyInstitutionSupervisorStudents = ({ page = 1, page_size = 5, search = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<ServerGetResponse<ActiveInternsType>> =>
			axiosPrivate.get(Appurls.institution_admin.supervisor.get_my_students).then((response) => response.data),
		queryKey: ['my-institution-supervisor-students', { page, page_size, search }],
	});
};

export const useInstitutionStudent = ({ id }: { id: string }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<InstitutionInternProfileType> =>
			axiosPrivate.get(Appurls.institution_admin.get_student_profile(id)).then((response) => response.data),
		queryKey: ['institution-student', { id }],
	});
};
