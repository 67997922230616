import { useRef, useState } from 'react';
import { Container } from './styles';
import { Button, Loader, Pagination, PalletList, SearchBar } from '../../../../ui';
import {
	useDecodedUser,
	useGetDepartmentById,
	useGetInstitutionAdminDepartmentStats,
	useGetPendingInternsForDeptHead,
	useGetPendingSupervisors,
	useGetSignedUpInternsForDeptHead,
	useGetSignedUpSupervisorsAsHead,
	useGetUserById,
} from '../../../../hooks';
import {
	DashboardAmountPaid,
	DashboardAmountRefunded,
	DashboardBusiness,
	DashboardInterns,
	ProjectEmpty,
	EyeOpen,
} from '../../../../assets/svgs';
import { useCallback } from 'react';
import { FilterValue, GeneralChangeEventType } from '../../../../types';
import { DepartmentDetails } from '../../modals';
import { ActiveInterns, ActiveSupervisors, PendingInterns, PendingSupervisors } from '../../components';

type ControlType = 'supervisors' | 'interns';
type SubControlType = 'active' | 'pending';

const InstitutionHeadDepartmentDetailPage = () => {
	const pageSize = useRef(6);
	const user = useDecodedUser();
	const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
	const [page, setPage] = useState(1);
	const [formData, setFormData] = useState({
		search: '',
	});
	const [control, setControl] = useState<ControlType>('supervisors');
	const [subControl, setSubControl] = useState<SubControlType>('active');

	const { data: configDetails } = useGetUserById({ id: user?.user_id });
	const { data: stats, isLoading: statsLoading } = useGetInstitutionAdminDepartmentStats({
		departmentId: configDetails?.department_id || '',
	});
	const { data: department, isLoading: isLoadingDepartment } = useGetDepartmentById({ departmentId: configDetails?.department_id });
	const {
		data: pendingSupervisors,
		isLoading: isLoadingPendingSupervisors,
		isError: isPendingSupervisorError,
	} = useGetPendingSupervisors({ page, page_size: pageSize.current, search: formData.search, enabled: true });
	const {
		data: activeSupervisors,
		isLoading: isLoadingActiveSupervisors,
		isError: isActiveSupervisorError,
	} = useGetSignedUpSupervisorsAsHead({ page, page_size: pageSize.current, search: formData.search, dept_id: configDetails?.department_id });
	const {
		data: pendingInterns,
		isLoading: isLoadingPendingInterns,
		isError: isPendingInternsError,
	} = useGetPendingInternsForDeptHead({ page, page_size: pageSize.current, search: formData.search, enabled: true });
	const {
		data: activeInterns,
		isLoading: isLoadingActiveInterns,
		isError: isActiveInternsError,
	} = useGetSignedUpInternsForDeptHead({ page, page_size: pageSize.current, search: formData.search, enabled: true });

	const isFetchingData = isLoadingDepartment;

	const result =
		control === 'supervisors'
			? subControl === 'pending'
				? pendingSupervisors
				: activeSupervisors
			: control === 'interns'
			? subControl === 'pending'
				? pendingInterns
				: activeInterns
			: {
					results: [],
					total: 0,
			  };

	const isFetchingTableData =
		control === 'supervisors'
			? subControl === 'active'
				? isLoadingActiveSupervisors
				: isLoadingPendingSupervisors
			: control === 'interns'
			? subControl === 'pending'
				? isLoadingPendingInterns
				: isLoadingActiveInterns
			: false;

	const isFetchingTableError =
		control === 'supervisors'
			? subControl === 'active'
				? isActiveSupervisorError
				: isPendingSupervisorError
			: control === 'interns'
			? subControl === 'pending'
				? isPendingInternsError
				: isActiveInternsError
			: false;

	const statsData = [
		{
			title: 'Supervisors',
			body: stats?.data?.supervisor || 0,
			icon: <DashboardAmountRefunded />,
		},
		{
			title: 'Students',
			body: stats?.data?.students || 0,
			icon: <DashboardBusiness />,
		},
		{
			title: 'Invite sent',
			body: stats?.data?.total_invite || 0,
			icon: <DashboardInterns />,
		},
		{
			title: 'Completed Profile',
			body: stats?.data?.completed_profile || 0,
			icon: <DashboardAmountPaid />,
		},
	];

	const handleChange: GeneralChangeEventType = useCallback((event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name as string]: value }));
	}, []);
	const handleControl = (value: ControlType) => {
		setPage(1);
		setControl(value);
		handleChange(null, 'search', '');
	};
	const handleSubControl = (value: SubControlType) => {
		setPage(1);
		setSubControl(value);

		handleChange(null, 'search', '');
		handleChange(
			null,
			'filter',
			(() => {
				let state: FilterValue = {};
				(value === 'pending' ? [] : []).forEach(
					(option: { name: string | number; queryKey: any }) => (state[option?.name] = { value: '', queryKey: option?.queryKey })
				);

				return state;
			})()
		);
	};

	return (
		<>
			<Container>
				<div className="header">
					<div>
						<h4>{department?.name}</h4>
						<p>View all information about this department</p>
					</div>

					<Button onClick={() => setIsDetailsModalOpen(true)}>
						<EyeOpen /> Department Info
					</Button>
				</div>

				<PalletList loading={statsLoading} data={statsData} />

				<div className="table-header">
					<div className="controls">
						<button className={control === 'supervisors' ? 'active' : ''} onClick={() => handleControl('supervisors')}>
							Supervisors
						</button>
						<button className={control === 'interns' ? 'active' : ''} onClick={() => handleControl('interns')}>
							Interns
						</button>
					</div>

					<div className="filter-con">
						<SearchBar name="search" placeholder={`Search through ${control}`} handleChange={handleChange} value={formData.search} />
					</div>
				</div>

				<div className="controls">
					<button className={subControl === 'active' ? 'active' : ''} onClick={() => handleSubControl('active')}>
						Active
					</button>
					<button className={subControl === 'pending' ? 'active' : ''} onClick={() => handleSubControl('pending')}>
						Pending
					</button>
				</div>
				<br />

				{isFetchingTableData ? (
					<center>
						<Loader />
					</center>
				) : isFetchingTableError ? (
					<center>
						<div>
							<ProjectEmpty />
							<p className="error-message">
								Unable to fetch {subControl} {control} at the moment
							</p>
						</div>
					</center>
				) : !result?.results || result?.results?.length === 0 ? (
					<div className="empty-state">
						<ProjectEmpty />
						<p className="error-message">
							No {subControl} {control} to display
						</p>
					</div>
				) : control === 'supervisors' ? (
					subControl === 'pending' ? (
						<PendingSupervisors page={page} supervisors={pendingSupervisors?.results} />
					) : (
						<ActiveSupervisors page={page} supervisors={activeSupervisors?.results} />
					)
				) : control === 'interns' ? (
					subControl === 'pending' ? (
						<PendingInterns page={page} interns={pendingInterns?.results} />
					) : (
						<ActiveInterns page={page} interns={activeInterns?.results} />
					)
				) : null}

				<Pagination page={page} setPage={setPage} maxPages={Math.ceil((result?.total || 0) / pageSize.current)} disabled={isFetchingData} />
			</Container>

			{isDetailsModalOpen ? (
				<DepartmentDetails
					department={department}
					handleClose={() => setIsDetailsModalOpen(false)}
					handleViewSupervisors={() => {
						setIsDetailsModalOpen(false);
						handleControl('supervisors');
					}}
					handleViewInterns={() => {
						setIsDetailsModalOpen(false);
						handleControl('interns');
					}}
				/>
			) : null}
		</>
	);
};
export default InstitutionHeadDepartmentDetailPage;
