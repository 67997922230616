import styled from 'styled-components';
import { StyledButton } from '../../../ui/Button/styles';
import { maxQuery } from '../../../utilities';
import { StyledInputGroup } from '../../../ui/InputGroup/styles';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	inset: 0;
	z-index: 99;

	> form {
		border-radius: 0.25rem;
		background: #fff;
		padding: 2rem;
		padding: 2rem clamp(1.5rem, -0.4444rem + 6.2222vw, 5rem) clamp(1.5rem, -0.4444rem + 6.2222vw, 5rem);
		padding-top: 0;
		width: 53.625rem;
		max-width: calc(100vw - 2rem);
		max-height: calc(100vh - 2rem);
		overflow: auto;

		.header {
			position: sticky;
			top: 0;
			background-color: #fff;
			z-index: 2;
			padding-top: 2rem;

			svg {
				margin-left: auto;
				display: block;
				cursor: pointer;
				width: 1.25rem;
				width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);

				:active {
					outline: none;
				}
			}
			h2 {
				text-align: center;
				font-size: 2rem;
				font-size: clamp(1.75rem, 1.6111rem + 0.4444vw, 2rem);
				margin: 0.5rem 0 1.5rem;
				color: #070d19;
			}
			h2 + p {
				color: #a8a8a8;
				font-size: 1rem;
				font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
				line-height: 120%;
				margin: 0.5rem 0 1.5rem;
				text-align: center;

				span,
				a {
					color: #4060ea;
					font-weight: 600;
				}
			}
			hr {
				border-top: 1px solid #ebebeb;
			}
		}
		h4 {
			color: #070d19;
			font-family: 'Urbanist', sans-serif;
			font-size: 1.5rem;
			font-size: clamp(1.125rem, 0.9167rem + 0.6667vw, 1.5rem);
			font-weight: 600;
			margin-top: 2.5rem;
			text-align: center;
		}
		h4 + p {
			color: #787878;
			font-size: 1rem;
			font-size: clamp(0.875rem, 0.8056rem + 0.2222vw, 1rem);
			text-align: center;
		}

		.body > ${StyledInputGroup} {
			width: 19.1875rem;
			margin: 3rem auto 0;
		}
		.grid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 1rem 2rem;
			margin-top: 1.5rem;

			${maxQuery('md')} {
				grid-template-columns: 1fr;
			}
		}
		.promo-con {
			margin: 2rem auto 0;
			text-align: center;
			max-width: 12rem;

			button {
				font-family: 'Urbanist', sans-serif;
				font-weight: 500;
				font-size: 0.875rem;
				line-height: 1.36;
				text-transform: uppercase;
				color: #4060ea;
				padding: 0.5rem 1rem;
				background-color: transparent;
			}
			p {
				margin-top: 0.5rem;
				font-size: 0.75rem;
				line-height: 1.58;

				&.error {
					color: #ea2d2e;
				}
			}
		}
		${StyledButton} {
			width: 26rem;
			max-width: 100%;
			margin: 3rem auto 0;
			justify-content: center;
		}
	}
`;
