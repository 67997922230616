import styled from 'styled-components';
import { maxQuery, minQuery } from '../../utilities';
import { StyledPagination } from '../../ui/Pagination/styles';
import { StyledButton } from '../../ui/Button/styles';

export const Container = styled.div`
	padding: 2.5rem 6.25rem;
	padding: 2.5rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);

	.header {
		margin-bottom: 1.87rem;
		margin-bottom: clamp(1rem, -0.45rem + 4.64vw, 1.87rem);
		display: flex;
		align-items: flex-start;
		gap: 1.5rem;
		flex-wrap: wrap;

		${maxQuery('400px')} {
			gap: 1rem;
		}

		> div:first-child {
			margin-right: auto;

			${maxQuery('md')} {
				flex-basis: 100%;
			}

			h4 {
				color: #11071a;
				font-family: 'Urbanist', sans-serif;
				font-size: 1.5rem;
				font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
				font-weight: 700;
				margin-bottom: 0.25rem;
				line-height: 120%;
			}
			p {
				color: #828282;
				font-size: 0.75rem;
				line-height: 120%;
			}
		}
		${StyledButton} {
			padding: 0.86rem 1.5rem;
			padding: clamp(0.6rem, 0.4556rem + 0.4622vw, 0.86rem) clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);

			&.export path {
				fill: #4060ea;
			}

			span {
				display: flex;
				align-items: center;
				gap: 0.5rem;
			}
		}
	}
	center {
		height: calc(100vh - 24rem);
		display: grid;
		place-items: center;
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;
	}
	.empty-state {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: calc(100vh - 24rem);
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;

		p {
			color: #1e1e1e;
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 135%;
			margin-top: 1rem;
		}
		span {
			text-transform: capitalize;
		}
	}
	.table-container {
		overflow: auto;

		${maxQuery('lg')} {
			display: none;
		}
	}
	table {
		width: 100%;
		min-width: max-content;
		border-collapse: collapse;

		> * > tr > *:first-child {
			text-align: center;
		}
		${minQuery('300px')} {
			> * > tr > *:first-child {
				left: 0;
				position: sticky;
				text-align: center;
			}
		}

		thead {
			th {
				font-weight: 600;
				padding: 0.875rem 1rem;
				text-align: inherit;
				background-color: #fafafa;
			}
		}

		tbody {
			background-color: #fff;

			tr {
				:not(:last-child) {
					border-bottom: 1px solid #dde0e2;
				}
			}

			td {
				background-color: #fff;
				font-weight: 500;
				padding: 1.25rem 1rem;

				:last-child {
					display: flex;
					align-items: center;
					gap: 1rem;

					a {
						display: flex;
						align-items: center;
					}

					svg {
						cursor: pointer;

						path {
							fill: #4060ea;
						}
					}
				}
			}
		}
	}

	.mobile-card {
		background: #fff;
		border-radius: 0.25rem;
		padding: 0 1rem;
		display: none;

		:not(:last-child) {
			margin-bottom: 1rem;
		}

		${maxQuery('lg')} {
			display: block;
		}

		> div {
			padding: 0.8rem 0;
			padding: clamp(0.5rem, 0rem + 1.6vw, 0.8rem) 0;

			:not(:last-child) {
				border-bottom: 1px solid rgba(150, 150, 150, 0.11);
			}
			&.flexed {
				display: flex;
				justify-content: space-between;
			}

			.action-con {
				display: flex;
				align-items: center;
				padding: 0 0.5rem;
				cursor: pointer;

				svg path {
					fill: #4060ea;
				}
			}

			> div:last-child {
				text-align: right;
			}
		}

		b {
			color: #323232;
			font-size: 0.8125rem;
			font-size: clamp(0.8125rem, 0.5rem + 1vw, 1rem);
			font-weight: 600;
		}
		p {
			color: rgba(15, 15, 15, 0.46);
			font-size: 0.8125rem;
			font-size: clamp(0.8125rem, 0.5rem + 1vw, 1rem);
		}
	}
	.open,
	p.open {
		color: #24b314;
	}
	.closed,
	p.closed {
		color: #ea2d2e;
	}

	${StyledPagination} {
		justify-content: center;
		margin-top: 3rem;
	}
`;
