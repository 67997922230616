import { ApplicantAvatar, BillingCheckMark, ButtonPlus, CloseModal } from '../../../../assets/svgs';
import { Container } from './styles';
import { Button, Loader } from '../../../../ui';
import { getInternshipTimeLeft } from '../../../../utilities';
import { useGetResourcesInProject, useMoveResourceToProject } from '../../../../hooks';
import { useState } from 'react';

const ProjectResource = ({ projectId, projectResources, handleSubmit, handleClose }) => {
	const [lastAddedIntern, setLastAddedIntern] = useState('');
	const { data: resources, isLoading } = useGetResourcesInProject();

	const { mutate: addToProject, isLoading: isAdding } = useMoveResourceToProject();

	const handleAddToProject = (internId) => {
		setLastAddedIntern(internId);

		addToProject(
			{
				projectId,
				data: {
					resources: [internId],
				},
			},
			{
				onSuccess: () => {
					setLastAddedIntern('');
					handleSubmit();
				},
			}
		);
	};

	return (
		<Container>
			<div>
				<CloseModal tabIndex={0} onClick={handleClose} />
				<h2>Resource(s) In Projects</h2>
				<p>Choose your type of resources to invite</p>

				{isLoading ? (
					<center>
						<Loader />
					</center>
				) : (
					<div>
						{resources?.map((resource) => (
							<div key={resource?.id} className="resource-con">
								<img src={ApplicantAvatar} alt="" />
								<span className="name">{resource?.name}</span>

								<span className="duration">{getInternshipTimeLeft(new Date(), resource?.end_date, true, 'days')} days left</span>

								{projectResources?.find((intern) => intern?.id === resource?.id) ? (
									<Button disabled className="in-project-btn">
										<BillingCheckMark />
									</Button>
								) : (
									<Button
										disabled={isAdding}
										loading={isAdding && lastAddedIntern === resource?.id}
										onClick={() => handleAddToProject(resource?.id)}
									>
										<ButtonPlus />
									</Button>
								)}
							</div>
						))}
					</div>
				)}
			</div>
		</Container>
	);
};
export default ProjectResource;
