import { useQueryClient } from '@tanstack/react-query';
import { useNotify, useReinviteInstitutionIntern, useRevokeInvite } from '../../../../hooks';
import { PendingInternsType } from '../../../../types';
import { Button } from '../../../../ui';
import { getDateTime, handleApiError } from '../../../../utilities';
import { AxiosError } from 'axios';
import { useState } from 'react';

const PendingInterns = ({ page, interns }: { page: number; interns: PendingInternsType[] | undefined }) => {
	const notify = useNotify();
	const queryClient = useQueryClient();
	const [reInvitingFor, setReInvitingFor] = useState('');
	const [revokingFor, setRevokingFor] = useState('');

	const { mutate: reinviteIntern, isLoading: isReinviting } = useReinviteInstitutionIntern();
	const { mutate: revokeInvite, isLoading: isRevoking } = useRevokeInvite();

	const handleReinvite = (id: string) => {
		setReInvitingFor(id);

		reinviteIntern(id, {
			onSuccess: () => {
				notify({
					message: 'A new invite has been resent',
					status: 'success',
					toastOptions: { toastId: 'intern_reinvite_success' },
				});

				queryClient.invalidateQueries(['institution-pending-intern-list']);
			},
			onError: (error) =>
				notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'intern_reinvite_error' } }),
			onSettled: () => {
				setReInvitingFor('');
			},
		});
	};
	const handleRevoke = (id: string) => {
		setRevokingFor(id);

		revokeInvite(id, {
			onSuccess: () => {
				notify({
					message: 'The invite has been revoked',
					status: 'success',
					toastOptions: { toastId: 'intern_revoke_success' },
				});

				queryClient.invalidateQueries(['institution-pending-intern-list']);
			},
			onError: (error) =>
				notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'intern_revoke_error' } }),
			onSettled: () => {
				setRevokingFor('');
			},
		});
	};

	return (
		<>
			<div className="table-container">
				<table>
					<thead>
						<tr>
							<th>S/N</th>
							<th>Intern's Email</th>
							<th>Department</th>
							<th>Last Invited</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{interns?.map((intern, index) => (
							<tr key={intern?.id}>
								<td>{(page - 1) * 10 + index + 1}</td>
								<td>
									{intern?.email} {intern?.has_invitation_expired ? ' - (Invite expired)' : ''}
								</td>
								<td>{intern?.department_name || 'N/A'}</td>
								<td>
									{getDateTime(intern?.created_at, undefined, false)}, {getDateTime(intern?.created_at, 'mm dd yyyy')}
								</td>
								<td>
									<Button
										style={{ padding: '0.2rem 1rem' }}
										variant="secondary"
										disabled={!intern?.has_invitation_expired}
										onClick={() => handleReinvite(intern?.id)}
										loading={isReinviting && reInvitingFor === intern?.id}
									>
										Resend Invite
									</Button>
									<Button
										style={{ padding: '0.2rem 1rem', color: '#c70c0d', borderColor: '#c70c0d' }}
										variant="secondary"
										disabled={isReinviting && reInvitingFor === intern?.id}
										onClick={() => handleRevoke(intern?.id)}
										loading={isRevoking && revokingFor === intern?.id}
									>
										Revoke Invite
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{interns?.map((intern) => (
				<div key={intern?.id} className="mobile-card">
					<div className="flexed">
						<div>
							<b>Intern's Email</b>
							<p>
								{intern?.email} {intern?.has_invitation_expired ? ' - (Invite expired)' : ''}
							</p>
						</div>
						<div className="action-con">
							<Button
								style={{ padding: '0.2rem 1rem' }}
								variant="secondary"
								disabled={!intern?.has_invitation_expired}
								onClick={() => handleReinvite(intern?.id)}
								loading={isReinviting && reInvitingFor === intern?.id}
							>
								Resend Invite
							</Button>
						</div>
					</div>
					<div>
						<b>Department</b>
						<p>{intern?.department_name}</p>
					</div>
				</div>
			))}
		</>
	);
};
export default PendingInterns;
