import { Container } from './styles';

const Package = ({ title, body, price, ...rest }) => {
	return (
		<Container {...rest}>
			<b>
				{title} (£{price})
			</b>
			<p>{body}</p>
		</Container>
	);
};
export default Package;
