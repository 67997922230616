import { createContext, useState } from 'react';

const GeneralStoreContext = createContext({});

export const GeneralStoreDefaultState = { has_paid: false, has_provided_documents: false };

export const GeneralStoreProvider = ({ children }: { children: React.ReactNode }) => {
	const [store, setStore] = useState(GeneralStoreDefaultState);

	return <GeneralStoreContext.Provider value={{ store, setStore }}>{children}</GeneralStoreContext.Provider>;
};

export default GeneralStoreContext;
