import { useMutation, useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';
import { DepartmentType, ServerGetResponse } from '../types';

export const useGetDepartments = ({ page = 1, page_size = 5, search = '', enabled = true }) => {
	const axiosPrivate = useAxiosPrivate();

	const fetchDepartments = (): Promise<ServerGetResponse<DepartmentType>> =>
		axiosPrivate
			.get(`${Appurls.institution_admin.department.index}?page=${page}&page_size=${page_size}&search=${search}`)
			.then((response) => response.data);

	return useQuery({
		queryFn: fetchDepartments,
		queryKey: ['institution-department-list', { page, page_size, search }],
		enabled,
	});
};

export const useGetDepartmentById = ({ departmentId = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: (): Promise<DepartmentType> =>
			axiosPrivate.get(Appurls.institution_admin.department.single_department(departmentId)).then((response) => response?.data),
		queryKey: ['institution-department-id', { departmentId }],
	});
};

export const usePatchDepartment = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({
		mutationFn: ({
			id,
			data,
		}: {
			id: string;
			data: {
				id?: string;
				name?: string;
				phone?: string;
				head?: string;
			};
		}) => axiosPrivate.patch(Appurls.institution_admin.department.single_department(id), data),
	});
};

export const useCreateDepartment = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation((data: { name: string; phone: string; dept_head?: string }) =>
		axiosPrivate.post(Appurls.institution_admin.department.index, data)
	);
};

export const useActivateDepartment = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation((id: string) => axiosPrivate.post(Appurls.institution_admin.department.activate(id)));
};

export const useDeactivateDepartment = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation((id: string) => axiosPrivate.post(Appurls.institution_admin.department.deactivate(id)));
};
