import styled from 'styled-components';

export const Container = styled.div`
	padding: 2.5rem 6.25rem;
	padding: 2.5rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);

	.header {
		h4 {
			color: #11071a;
			font-family: 'Urbanist', sans-serif;
			font-size: 1.5rem;
			font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
			font-weight: 700;
			line-height: 120%;
		}
	}
	.controls {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		margin: 1.5rem 0 1.87rem;
		margin: clamp(1rem, 0.1667rem + 2.6667vw, 1.5rem) 0 clamp(1rem, -0.45rem + 4.64vw, 1.87rem);

		a {
			background-color: transparent;
			color: #bbbaba;
			font-size: 1rem;
			font-size: clamp(0.875rem, 0.6667rem + 0.6667vw, 1rem);
			font-weight: 600;
			border-bottom: 2px solid transparent;
			padding: 0.75rem 1.5rem 0.75rem 0;
			padding: clamp(0.5rem, 0.0833rem + 1.3333vw, 0.75rem) clamp(0rem, -2.5rem + 8vw, 1.5rem) clamp(0.5rem, 0.0833rem + 1.3333vw, 0.75rem) 0;

			&.active {
				color: #12091b;
				border-bottom: 2px solid #4060ea;
			}
		}
	}
`;
