import { useNotify, useUpdateInternProfile } from '../../../hooks';
import { Button, InputGroup, SelectGroup } from '../../../ui';
import { APPLICANT_SKILLS, INTERESTED_ROLES, INTERESTED_SECTOR, INTERNSHIP_DURATIONS } from '../../../constants';
import { addWeeksToDate, handleApiError } from '../../../utilities';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

const IDDocument = ({ formData, handleChange }) => {
	const notify = useNotify();
	const queryClient = useQueryClient();
	const [hasSubmitted, setHasSubmitted] = useState(false);

	const { mutate: updateProfile, isLoading } = useUpdateInternProfile();

	const handleSubmit = async (event) => {
		event.preventDefault();

		const axiosFormData = new FormData();

		formData.proficiency_level && axiosFormData.append('english_proficiency_level', formData.proficiency_level);
		formData.start_date && axiosFormData.append('start_date', formData.start_date);
		formData.duration && axiosFormData.append('program_duration', formData.duration);

		for (let i = 0; i < formData.skills.length; i++) {
			axiosFormData.append('skills', formData.skills[i]);
		}
		for (let i = 0; i < formData.interested_role.length; i++) {
			axiosFormData.append('interested_role', formData.interested_role[i]);
		}
		for (let i = 0; i < 1; i++) {
			axiosFormData.append('languages', 'English');
		}
		for (let i = 0; i < formData.sector.length; i++) {
			axiosFormData.append('interested_sector', formData.sector[i]);
		}

		updateProfile(axiosFormData, {
			onSuccess: () => {
				setHasSubmitted(true);
				queryClient.invalidateQueries(['user-id']);
				queryClient.invalidateQueries(['user']);
			},
			onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'profile_update' } }),
		});
	};

	return (
		<>
			<form onSubmit={handleSubmit}>
				<h2>Internship, Interest & Skills</h2>
				<p>Provide information to know your educational background & Your Exprience</p>

				<div>
					<InputGroup
						label="Preferred Internship Start Date"
						name="start_date"
						type="date"
						value={formData.start_date}
						onChange={handleChange}
						min={addWeeksToDate(new Date(), 2).toISOString().substring(0, 10)}
						moreInfo="This is used to determine your visibilty to startups and can be edited through your profile. You can only select a day 2 weeks and above from today."
						required
					/>
					<SelectGroup
						label="Internship Duration"
						name="duration"
						onChange={handleChange}
						placeholder="Select how long you'd like to intern for"
						value={formData.duration}
						options={INTERNSHIP_DURATIONS}
						required
						shouldFilterByDefault={false}
					/>
					<SelectGroup
						label="My Skills"
						name="skills"
						onChange={handleChange}
						placeholder="Select your skills"
						value={formData.skills}
						options={APPLICANT_SKILLS}
						required
						variant="multiple"
					/>
					<SelectGroup
						label="Interested Role"
						name="interested_role"
						onChange={handleChange}
						placeholder="Select Interested Role"
						value={formData.interested_role}
						options={INTERESTED_ROLES}
						required
						variant="multiple"
					/>
					<SelectGroup
						label="English Proficency Level"
						name="proficiency_level"
						onChange={handleChange}
						placeholder="Select Level"
						value={formData.proficiency_level}
						options={['Beginner', 'Intermediate', 'Advanced', 'Proficient']}
						required
					/>
					<SelectGroup
						label="Interested Sector (3 Max)"
						name="sector"
						onChange={handleChange}
						placeholder="Select Interested Sector"
						value={formData.sector}
						options={INTERESTED_SECTOR}
						required
						max={3}
						variant="multiple"
					/>
				</div>

				<div className="btn-group">
					<Button loading={isLoading || hasSubmitted} style={{ gridColumn: '1/3' }}>
						Submit Profile
					</Button>
				</div>
			</form>
		</>
	);
};

export default IDDocument;
