import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useDecodedUser, useGetUserById } from '../../hooks';
import {
	hasInternCompletedProfile,
	hasStartupCompletedProfile,
	hasInstitutionCompletedProfile,
	isInstitutionAdmin,
	isIntern,
	isStartUp,
} from '../../utilities';
import { Approutes } from '../../constants';
import { Loader } from '../../ui';
import { LoaderContainer } from './styles';

const RequireCompleteProfile = () => {
	const { pathname } = useLocation();
	const user = useDecodedUser();

	const { data: configDetails, isLoading } = useGetUserById({ id: user?.user_id });

	if (isLoading) {
		return (
			<LoaderContainer>
				<Loader variant="logo" size={'6rem'} />
			</LoaderContainer>
		);
	}

	if (pathname !== Approutes.complete_registration) {
		const from = pathname !== Approutes.complete_registration ? pathname : Approutes.home;

		if (
			(isIntern(user) && configDetails && !hasInternCompletedProfile(configDetails)) ||
			(isStartUp(user) && configDetails && !hasStartupCompletedProfile(configDetails)) ||
			(isInstitutionAdmin(user) && configDetails && !hasInstitutionCompletedProfile(configDetails))
		) {
			return <Navigate to={Approutes.complete_registration} state={{ from }} />;
		}
	}

	return <Outlet />;
};
export default RequireCompleteProfile;
