import { useCallback, useEffect, useState } from 'react';
import { useMessage } from '../../hooks';
import { Conversations, RoomMessage, EmptyMessage } from './components';
import { Container } from './styles';
import { useSearchParams } from 'react-router-dom';
import { queryStrings } from '../../constants';

const Messages = () => {
	const [searchParams] = useSearchParams();
	const newRoomId = searchParams.get(queryStrings.message_new_room_id);

	const [formData, setFormData] = useState({ room_id: newRoomId || '' });
	const [closedNotificationCount, setClosedNotificationCount] = useState(0);

	const { socketMessages, refetchAllConversations } = useMessage();

	const handleChange = useCallback((event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	}, []);

	useEffect(() => {
		if (!newRoomId) return;

		refetchAllConversations();
	}, [newRoomId, refetchAllConversations]);

	return (
		<Container>
			<section>
				<Conversations
					isMessageActive={formData.room_id}
					newMessages={socketMessages}
					handleConversationClick={(id) => handleChange(null, 'room_id', id)}
				/>

				{formData.room_id ? (
					<RoomMessage
						newMessages={socketMessages
							.filter((message) => message?.room_id === formData.room_id)
							?.map((message) => ({
								id: message?.message_id,
								user_message: message?.sent_by_me,
								name: message?.sender_name,
								created_at: message?.created_at,
								text: message?.content,
							}))}
						roomId={formData?.room_id}
						handleBack={() => handleChange(null, 'room_id', '')}
						closedNotificationCount={closedNotificationCount}
						increaseCount={() => setClosedNotificationCount((prev) => prev + 1)}
					/>
				) : (
					<EmptyMessage />
				)}
			</section>
		</Container>
	);
};
export default Messages;
