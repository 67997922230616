import { useQuery } from '@tanstack/react-query';
import { Appurls } from '../constants';
import { isInstitutionAdmin, isIntern, isStartUp } from '../utilities';
import { useAxiosPrivate } from './useAxiosPrivate';
import { useDecodedUser } from './useDecodedUser';

export const useStripeClientSecret = ({ payload }) => {
	const user = useDecodedUser();
	const axiosPrivate = useAxiosPrivate();

	const paymentUrl = isStartUp(user)
		? Appurls.payment.initialize_startup_payment
		: isIntern(user)
		? Appurls.payment.initialize_intern_payment
		: isInstitutionAdmin(user)
		? Appurls.payment.initialize_institution_payment
		: '';

	return useQuery({
		queryFn: () => axiosPrivate.post(paymentUrl, payload).then((response) => response.data),
		queryKey: ['initialize_stripe', { payload }],
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});
};
