import { useSearchParams } from 'react-router-dom';
import { ApplicantAvatar, ApplicantClose, ApplicantEye, ApplicantExtraBadge, ApplicantPlus, ApplicantDateIcon } from '../../assets/svgs';
import { getDateTime, getInternshipTimeLeft, getPreviousSearchParams } from '../../utilities';
import { StyledApplicantCard } from './styles';
import { queryStrings } from '../../constants';

const ApplicantCard = ({ applicant, isAdded, isExtra, handleAdd, handleRemove }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const handleViewApplicant = (id) => {
		let previousParams = getPreviousSearchParams(searchParams);

		previousParams = { ...previousParams, [queryStrings.applicantId]: id };
		setSearchParams(previousParams, { replace: true });
	};

	return (
		<StyledApplicantCard className={isAdded ? 'active' : ''}>
			{isExtra ? (
				<div className="extra-badge">
					<ApplicantExtraBadge />
					EXTRA PICK
				</div>
			) : null}
			<div className={`heading ${isExtra ? 'rm-mt' : ''}`}>
				<img src={ApplicantAvatar} alt="" />
				<b>{applicant?.applicant_id}</b>
				<ApplicantEye onClick={() => handleViewApplicant(applicant?.id)} />
			</div>

			<div className="duration-con">
				<span>
					<ApplicantDateIcon />
					{getInternshipTimeLeft(applicant?.start_date, applicant?.end_date)}
				</span>
				|<span>{new Date() > new Date(applicant?.start_date) ? 'Ready to start' : getDateTime(applicant?.start_date, 'mm dd yyyy')}</span>
			</div>

			<h6>INTERESTED ROLES</h6>
			<div className="role-list">
				{applicant?.interested_role?.map((role) => (
					<span key={role}>{role}</span>
				))}
			</div>

			<div className="bottom">
				<button type="button" onClick={handleAdd}>
					<ApplicantPlus />
				</button>
				<button type="button" onClick={handleRemove}>
					<ApplicantClose />
				</button>
			</div>
		</StyledApplicantCard>
	);
};

export default ApplicantCard;
