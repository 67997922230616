import { useDecodedUser } from '../../../hooks';
import { isStartUp } from '../../../utilities';
import StartupFlow from './StartupFlow';

const SubscriptionPlan = () => {
	const user = useDecodedUser();

	return isStartUp(user) ? <StartupFlow /> : null;
	// return isStartUp(user) ? <StartupFlow /> : isIntern(user) ? <InternFlow /> : null;
};
export default SubscriptionPlan;
