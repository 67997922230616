import { createContext, useState } from 'react';

type AuthProviderProps = {
	children: React.ReactNode;
};

type AuthState = {
	access?: string;
	refresh?: string;
};

type AuthContextType = {
	auth: AuthState | null;
	setAuth: React.Dispatch<React.SetStateAction<AuthState | null>>;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthDefaultState = { access: '', refresh: '' };

export const AuthProvider = ({ children }: AuthProviderProps) => {
	const [auth, setAuth] = useState<AuthState | null>(AuthDefaultState);

	return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
