import { useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';

export const useNotifications = ({ page = 1, page_size = 10, is_read = null }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(`${Appurls.notifications.get_notifications}?page=${page}&page_size=${page_size}&is_read=${is_read}`)
				.then((response) => response?.data),
		queryKey: ['notifications', { page, page_size, is_read }],
		refetchInterval: 60000,
	});
};
