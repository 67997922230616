import { Link } from 'react-router-dom';
import {
	ApplicantAvatar,
	ApplicantEye,
	ButtonPlus,
	ProjectEmpty,
	TaskAt,
	TaskCheckmark,
	EditTask as EditTaskSvg,
} from '../../../assets/svgs';
import { Button, FileDownloadGroup, Loader, Pagination, SearchBar, Tag } from '../../../ui';
import { Approutes } from '../../../constants';
import { Tags } from '../../../ui/Tag/styles';
import { getDateTime, getInternshipTimeLeft } from '../../../utilities';
import { Container, Range } from './styles';

const Details = ({
	project,
	isTasksLoading,
	isTasksError,
	isLoading,
	isPatchingProject,
	search,
	tasks,
	projectId,
	page,
	setPage,
	setEditState,
	setConfirmModal,
	setAddTask,
	handleChange,
	handleCompleteTask,
	handleAssignTask,
	handleViewApplicant,
}) => {
	return (
		<Container>
			<section>
				<article>
					<div className={'status ' + project?.status}>{project?.status === 'pending' ? 'Pending' : project?.status}</div>
					<div className="title">
						<b>{project?.name}</b> <span>{project?.project_sector}</span>
					</div>
					<p>{project?.description}</p>
				</article>

				<section className="attachments">
					<h6>Attachments</h6>

					{project?.requirement_docs.length ? (
						<FileDownloadGroup
							fileName={project?.requirement_docs[0]?.file_name}
							url={project?.requirement_docs[0]?.url}
							fileSizeInKb={project?.requirement_docs[0]?.file_size_in_kb}
						/>
					) : null}
				</section>

				<section className="tasks">
					<div>
						<h4>Tasks</h4>

						<SearchBar name="search" placeholder="Search through tasks" handleChange={handleChange} />
						<Button disabled={project?.status === 'completed'} onClick={() => setAddTask(true)}>
							<ButtonPlus />
							<span className="text-con">Add Task</span>
						</Button>
					</div>

					{isTasksLoading ? (
						<center>
							<Loader />
						</center>
					) : isTasksError ? (
						<div className="empty-state">
							<ProjectEmpty />
							<p>{search ? `Unable to find task with name: "${search}"` : 'Unable to fetch project tasks at the moment'}</p>
						</div>
					) : (
						<div>
							{tasks?.results?.map((task) => (
								<div key={task?.id}>
									<span className={task?.is_completed ? 'active' : ''} onClick={() => handleCompleteTask(task?.id, !task?.is_completed)}>
										<TaskCheckmark />
									</span>

									<span>{task?.name}</span>
									<span onClick={() => setEditState({ openModal: true, task_name: task.name, task_desc: task.description, task_id: task?.id })}>
										<EditTaskSvg className="edit-icon" />
									</span>
									<Link to={Approutes.startup.projects.use_task_details(projectId, task?.id)}>
										<ApplicantEye />
									</Link>
									<span onClick={() => handleAssignTask(task)}>
										<TaskAt />
									</span>
								</div>
							))}
						</div>
					)}

					<Pagination page={page} setPage={setPage} maxPages={Math.ceil(tasks?.total / 5)} disabled={isLoading} />
				</section>
			</section>
			<aside>
				<h4>Project Information</h4>

				<div className="timeline">
					<div>
						<h6>Start date</h6>
						<b>{getDateTime(project?.start_date)}</b>
					</div>
					<div>
						<h6>Project duration</h6>
						<b>{project?.timeline}</b>
					</div>
				</div>
				<div className="resources">
					<h6>Project Resource</h6>

					{project?.resources?.length ? (
						project?.resources.map((resource) => (
							<div key={resource?.id}>
								<img src={ApplicantAvatar} alt="" />
								<span className="name">{resource?.applicant_name}</span>

								<span className="duration">{getInternshipTimeLeft(new Date(), resource?.end_date, true, 'days')} days left</span>
								<ApplicantEye onClick={() => handleViewApplicant(resource?.id)} />
							</div>
						))
					) : (
						<span>No resource in this project</span>
					)}
				</div>
				{project?.resource_skills.length > 0 ? (
					<div className="resource-tags">
						<h6>Resource tags</h6>

						<Tags>
							{project?.resource_skills.map((tag) => (
								<Tag key={tag} tag={tag} hideClose />
							))}
						</Tags>
					</div>
				) : null}
				{/* <div className="supervisor">
							<h6>Project supervisor</h6>

							<div>
								<img src={Supervisor} alt="" /> <span>ToBeDone</span>
							</div>
						</div> */}
				<div className="completion-status">
					<h6>Tasks progress</h6>

					<div>
						<Range $percent={parseInt(project?.completion_percent)} />
						{parseInt(project?.completion_percent)}%
					</div>
				</div>

				<Button
					disabled={project?.status !== 'in-progress'}
					loading={isPatchingProject || isLoading}
					onClick={() => project?.status === 'in-progress' && setConfirmModal(true)}
				>
					Mark complete
				</Button>
			</aside>
		</Container>
	);
};
export default Details;
