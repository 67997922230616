import { useMutation } from '@tanstack/react-query';
import { Approutes, Appurls } from '../../../constants';
import { useNotify } from '../../../hooks';
import { Button, InputGroup } from '../../../ui';
import { Container } from './styles';
import { axios } from '../../../library';
import { handleApiError } from '../../../utilities';

const EnterEmail = ({ formData, setFormData, setStep }) => {
	const notify = useNotify();

	const { mutate: resetPassword, isLoading } = useMutation({
		mutationFn: (data) => axios.post(Appurls.auth.password.reset_password, data),
	});

	const handleChange = (event) => {
		let name = event.target.name || '';
		let value = event.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const handleSubmit = (event) => {
		event.preventDefault();

		resetPassword(
			{ email: formData.email },
			{
				onSuccess: () => setStep(2),
				onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'reset_password' } }),
			}
		);
	};
	return (
		<Container>
			<div>
				<h2>Forgot Password</h2>
				<p>Provide email address to reset Password</p>

				<form onSubmit={handleSubmit}>
					<InputGroup
						label="Email Address"
						type="email"
						name="email"
						onChange={handleChange}
						placeholder="Enter Email Address"
						value={formData.email}
						required
					/>

					<Button type="submit" loading={isLoading}>
						Reset Password
					</Button>

					<span className="login">
						I Know My Password. <a href={Approutes.login}>Login!</a>
					</span>
				</form>
			</div>
		</Container>
	);
};

export default EnterEmail;
