import { useNotify, useUpdateStartupProfile } from '../../../hooks';
import { Button, FileGroup, InputGroup } from '../../../ui';
import { handleApiError } from '../../../utilities';
import { FileImage } from '../../../assets/svgs';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

const AboutCompany = ({ formData, handleChange }) => {
	const queryClient = useQueryClient();
	const notify = useNotify();
	const { mutate: updateProfile, isLoading } = useUpdateStartupProfile();
	const [hasSubmitted, setHasSubmitted] = useState(false);

	const handleSubmit = (event) => {
		event.preventDefault();

		const axiosFormData = new FormData();

		formData.introduction && axiosFormData.append('about', formData.introduction);
		formData.company_website && axiosFormData.append('website', formData.company_website);
		formData.company_logo.file && axiosFormData.append('logo', formData.company_logo.file);

		updateProfile(axiosFormData, {
			onSuccess: () => {
				setHasSubmitted(true);
				queryClient.invalidateQueries(['user-id']);
				queryClient.invalidateQueries(['user']);
			},
			onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'profile_update' } }),
		});
	};

	return (
		<form onSubmit={handleSubmit}>
			<h2>About Company</h2>
			<p>Provide information about your company</p>

			<div>
				<FileGroup
					label="Company Logo (Optional)"
					name="company_logo"
					onChange={handleChange}
					helpTextTwo="JPG, GIF or PNG. Max size of 800Kb"
					accept="image/png, image/gif, image/jpeg"
					maxSize={0.8}
					value={formData.company_logo}
					icon={FileImage}
				/>
				<InputGroup
					label="Company's Website URL (Optional)"
					type="url"
					name="company_website"
					onChange={handleChange}
					placeholder="Enter Company's Url"
					value={formData.company_website}
				/>
				<InputGroup
					label="Brief Company Introduction"
					variant="textarea"
					name="introduction"
					onChange={handleChange}
					placeholder="Enter Brief Information about your company"
					value={formData.introduction}
					required
				/>
			</div>

			<div className="btn-group">
				<Button loading={isLoading || hasSubmitted} style={{ gridColumn: '1/3' }}>
					Submit
				</Button>
			</div>
		</form>
	);
};

export default AboutCompany;
