import { getDateDifference, getDateTime, getInitials } from '../../../../utilities';
import { Container } from './styles';

const UserMessageListItem = ({ profilePicture, name, dateTime, message, handleClick, isUnread }) => {
	const dateDifference = getDateDifference(new Date(), dateTime) || 0;
	const dateTimeOfMessage = dateDifference > 0 ? getDateTime(dateTime) : getDateTime(dateTime, null, false);

	return (
		<Container onClick={handleClick} className={isUnread ? 'unread' : ''}>
			{profilePicture ? <img src={profilePicture} alt="" /> : <div className="initials">{getInitials(name)}</div>}

			<div className="text-con">
				<div className="name-date-group">
					<b className="name">{name}</b>
					<span className="date">{dateTimeOfMessage}</span>
				</div>

				<div className="message-con" dangerouslySetInnerHTML={{ __html: message }} />
			</div>
		</Container>
	);
};
export default UserMessageListItem;
