import { useMutation } from '@tanstack/react-query';
import { Appurls } from '../constants';
import { useAxiosPrivate } from './useAxiosPrivate';

export const useUpdateInternProfile = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({
		mutationFn: (data) => axiosPrivate.patch(Appurls.user.update_intern, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
	});
};
export const useUpdateInternExperience = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({
		mutationFn: (data) => axiosPrivate.post(Appurls.user.update_intern_experience, data),
	});
};

export const useApproveInternProfile = ({ user_id }) => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({ mutationFn: (data) => axiosPrivate.post(Appurls.dashboard.approve_intern(user_id), data) });
};

export const useRejectInternProfile = ({ user_id }) => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({ mutationFn: (data) => axiosPrivate.post(Appurls.dashboard.reject_intern(user_id), data) });
};

export const useParseResumeToJson = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({
		mutationFn: (data) => axiosPrivate.post(Appurls.user.parse_resume, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
	});
};
