import { useEffect, useRef, useState } from 'react';
import { Button, InputGroup, SelectGroup } from '../../../ui';
import { CloseModal } from '../../../assets/svgs';
import { Container } from './styles';
import { useGetProjects, useInviteSupervisor, useNotify } from '../../../hooks';
import { handleApiError } from '../../../utilities';
import { useQueryClient } from '@tanstack/react-query';

const InviteSupervisor = ({ handleClose }) => {
	const errorRef = useRef();
	const notify = useNotify();
	const [formData, setFormData] = useState({
		email: '',
		name: '',
		institution: '',
		project: '',
	});
	const queryClient = useQueryClient();
	const { mutate: inviteSupervisor, isLoading } = useInviteSupervisor();
	const { data: projects, isLoading: isProjectsLoading } = useGetProjects({ page: 1, page_size: 10, search: '', status: 'in-progress' });
	const selectedProject = projects?.results?.find((project) => project?.name === formData.project) || {};

	const handleChange = (event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const onSubmit = (event) => {
		event.preventDefault();

		if (isProjectsLoading) return;

		if (formData.email.length === 0 || formData.name.length === 0 || formData.institution.length === 0 || !formData.project?.length) {
			errorRef.current.innerText = 'One or more required field is missing';
			return;
		}
		if (!projects?.results?.length) {
			notify({
				message: 'You need to have been added to a project before you can invite a supervisor',
				toastOptions: { toastId: 'invite_supervisor_info' },
			});
			handleClose();

			return;
		}

		inviteSupervisor(
			{
				projectId: selectedProject?.id,
				data: {
					email: formData.email,
					name: formData.name,
					institution: formData.institution,
				},
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries(['supervisors']);
					notify({
						message: `${formData.name} has been invited to supervise you on ${projects?.results[0].name}`,
						status: 'success',
						toastOptions: { toastId: 'invite_supervisor_success' },
					});
					handleClose();
				},
				onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'invite_supervisor_error' } }),
			}
		);
	};

	useEffect(() => {
		setFormData((prev) => ({ ...prev, project: projects?.results?.[0]?.name }));
	}, [projects]);

	return (
		<Container>
			<form onSubmit={onSubmit}>
				<CloseModal tabIndex={0} onClick={handleClose} />
				<h2>Invite Supervisor</h2>

				<SelectGroup
					label="Select project you wish to invite your supervisor to"
					name="project"
					placeholder="Select Project"
					onChange={handleChange}
					value={formData.project}
					loading={isProjectsLoading}
					options={projects?.results?.map((project) => project?.name)}
					shouldFilterByDefault={false}
					required
				/>

				<InputGroup
					label="Supervisor's Name (Mentors Included)"
					name="name"
					onChange={handleChange}
					placeholder="Enter Supervisor's Name"
					value={formData.name}
					required
				/>
				<InputGroup
					label="Email Address"
					type="email"
					name="email"
					onChange={handleChange}
					placeholder="Enter Email Address"
					value={formData.email}
					required
				/>
				<InputGroup
					label="Institution Name"
					name="institution"
					onChange={handleChange}
					placeholder="Enter Institution Name"
					value={formData.institution}
					required
				/>
				<div className="error" ref={errorRef}></div>

				<Button type="submit" loading={isLoading}>
					Send Invite
				</Button>
			</form>
		</Container>
	);
};
export default InviteSupervisor;
