import { useEffect, useRef, useState } from 'react';
import { FlagButtonIcon, ProfileImgEdit, ProfileStartupIllustration } from '../../../assets/svgs';
import { Button, SelectGroup } from '../../../ui';
import { Container } from './styles';
import { getInitials, getPreviousSearchParams, handleApiError } from '../../../utilities';
import { useNotify, useUpdateStartupProfile } from '../../../hooks';
import { useSearchParams } from 'react-router-dom';
import { STARTUP_PROFILE_CONTROLS, queryStrings } from '../../../constants';
import Overview from './Overview';
import Information from './Information';
import { FlagIntern } from '../modals';

const StartupProfile = ({ decodedUser, userDetails, refetchUserDetails }) => {
	const notify = useNotify();
	const logoFileRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isEditing, setIsEditing] = useState(searchParams.get(queryStrings.should_edit_profile) === 'true');
	const [isRaisingConcernModalOpen, setIsRaisingConcernModalOpen] = useState(false);
	const [activeState, setActiveState] = useState(
		STARTUP_PROFILE_CONTROLS.find((control) => control.key === searchParams.get(queryStrings.profile_tab))?.key ||
			STARTUP_PROFILE_CONTROLS[0]?.key
	);
	const [formData, setFormData] = useState({
		logo: {
			imgSrc: '',
			file: null,
		},
		company_name: '',
		company_phone: '',
		about: '',
		website_url: '',
		sector: '',
		size: '',
	});
	const { mutate: updateProfile, isLoading } = useUpdateStartupProfile();

	const handleChange = (event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const handleControlClick = (value) => {
		setActiveState(value);
		let previousParams = getPreviousSearchParams(searchParams);

		previousParams = { ...previousParams, [queryStrings.profile_tab]: value };
		setSearchParams(previousParams, { replace: true });
	};
	const handleLogoFileChange = (event) => {
		const imgFile = event.target.files?.item(0);

		handleChange(null, event.target.name, imgFile ? { imgSrc: URL.createObjectURL(imgFile), file: imgFile } : { imgSrc: '', file: null });
	};
	const handleSubmit = () => {
		const axiosFormData = new FormData();

		formData.logo.file && axiosFormData.append('logo', formData.logo.file);
		formData.company_name && axiosFormData.append('company_name', formData.company_name);
		formData.company_phone && axiosFormData.append('company_phone', formData.company_phone);
		formData.about && axiosFormData.append('about', formData.about);
		formData.website_url && axiosFormData.append('website_url', formData.website_url);
		formData.sector && axiosFormData.append('sector', formData.sector);
		formData.size && axiosFormData.append('size', formData.size);

		updateProfile(axiosFormData, {
			onSuccess: () => {
				refetchUserDetails();

				setIsEditing(false);
				setFormData({
					logo: { imgSrc: '', file: null },
					company_name: '',
					company_phone: '',
					about: '',
					website_url: '',
					sector: '',
					size: '',
				});

				notify({ message: 'Your details have been updated', status: 'success', toastOptions: { toastId: 'profile_update_success' } });
			},
			onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'profile_update_error' } }),
		});
	};

	useEffect(() => {
		setFormData({
			logo: { imgSrc: '', file: null },
			company_name: userDetails?.company_name || '',
			company_phone: userDetails?.company_phone || '',
			about: userDetails?.about || '',
			website_url: userDetails?.website_url || '',
			sector: userDetails?.sector || '',
			size: userDetails?.size || '',
		});
	}, [userDetails]);

	return (
		<Container
			onSubmit={(event) => {
				event.preventDefault();

				if (isEditing) handleSubmit();
			}}
		>
			<div className="header">
				<div>
					<h4>Profile</h4>
					<p>Update your photo and personal details</p>
				</div>

				{/* 
				<Button variant="secondary" className="deactivate">
					Deactivate Account
				</Button>
			*/}
				{isEditing ? (
					<>
						<Button
							variant="secondary"
							onClick={() => {
								setIsEditing(false);
								setFormData((prev) => ({ ...prev, logo: { imgSrc: '', file: null } }));
							}}
						>
							Cancel
						</Button>
						<Button loading={isLoading} onClick={handleSubmit}>
							Save Changes
						</Button>
					</>
				) : (
					<>
						<Button variant="secondary" onClick={() => setIsRaisingConcernModalOpen(true)}>
							<FlagButtonIcon /> Flag Interns
						</Button>
						<Button loading={isLoading} onClick={() => setIsEditing(true)}>
							Edit Profile
						</Button>
					</>
				)}
			</div>

			<section>
				<aside>
					<ProfileStartupIllustration />
				</aside>
				<main>
					<div className="img-name-group">
						<div className="img-con-con">
							{formData.logo.imgSrc || userDetails?.logo ? (
								<div className="img-con">
									<img src={formData.logo.imgSrc || userDetails?.logo} alt="" />
								</div>
							) : (
								<span>{getInitials(userDetails?.company_name)}</span>
							)}

							{isEditing ? (
								<>
									<input type="file" name="logo" ref={logoFileRef} onChange={handleLogoFileChange} accept="image/png, image/gif, image/jpeg" />
									<ProfileImgEdit onClick={() => logoFileRef.current.click()} />
								</>
							) : null}
						</div>

						<div className="name-group">
							<h5>{userDetails?.company_name}</h5>
							<div>
								<span>{userDetails?.company_phone}</span>
								<span>{decodedUser?.email}</span>
							</div>
						</div>
					</div>

					<div className="controls">
						{STARTUP_PROFILE_CONTROLS.map(({ key, value: control }) => (
							<button key={key} className={key === activeState ? 'active' : ''} onClick={() => handleControlClick(key)}>
								{control}
							</button>
						))}
						<SelectGroup
							value={activeState}
							options={STARTUP_PROFILE_CONTROLS.map((control) => control.value)}
							shouldFilterByDefault={false}
							onChange={(_event, _name, value) => {
								setActiveState(value);

								handleControlClick(STARTUP_PROFILE_CONTROLS.find((control) => control.value === value)?.key);
							}}
						/>
					</div>

					{activeState === STARTUP_PROFILE_CONTROLS[0].key ? (
						<Overview
							isEditing={isEditing}
							formData={formData}
							userDetails={userDetails}
							handleChange={handleChange}
							isSubmitting={isLoading}
							handleSubmit={handleSubmit}
						/>
					) : activeState === STARTUP_PROFILE_CONTROLS[1].key ? (
						<Information
							isEditing={isEditing}
							formData={formData}
							userDetails={userDetails}
							handleChange={handleChange}
							isSubmitting={isLoading}
							handleSubmit={handleSubmit}
						/>
					) : null}
				</main>
			</section>

			{isRaisingConcernModalOpen ? <FlagIntern handleClose={() => setIsRaisingConcernModalOpen(false)} /> : null}
		</Container>
	);
};

export default StartupProfile;
