import styled from 'styled-components';
import { UnderConstruction } from '../../../assets/images';

export const Container = styled.div`
	height: 100vh;
	background-image: url(${UnderConstruction});
	background-position: center;
	background-size: cover;
	padding: 2rem 6.25rem;
	padding: 2rem clamp(1rem, -1.9167rem + 9.3333vw, 6.25rem);

	> button {
		color: #252b33;
		font-size: 0.9375rem;
		font-weight: 600;
		border-radius: 0.25rem;
		border: 1px solid #ebebeb;
		background: #fff;
		padding: 1rem 1.5rem;
		display: inline-flex;
		margin-bottom: 1.3rem;
		align-items: center;
		gap: 1rem;

		svg {
			width: 0.8125rem;
			transform: rotate(90deg);
		}
	}
`;
