export const LOCAL_STORAGE_KEYS = {
	has_paid: 'has_paid',
	access_key: 'access',
	refresh_key: 'refresh',
	has_provided_documents: 'has_provided_documents',
	has_resubmitted_profile: 'has_resubmitted_profile',
};

export const SESSION_STORAGE_KEYS = {
	has_closed_subscription: 'has_closed_subscription',
};
