import styled from 'styled-components';
import { StyledButton } from '../../../ui/Button/styles';
import { maxQuery } from '../../../utilities';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	inset: 0;
	z-index: 99;

	> div {
		border-radius: 0.25rem;
		background: #fff;
		padding: 2rem;
		padding: 2rem clamp(1.5rem, -0.4444rem + 6.2222vw, 5rem) clamp(1.5rem, -0.4444rem + 6.2222vw, 5rem);
		padding-top: 0;
		width: 53.625rem;
		max-width: calc(100vw - 2rem);
		max-height: calc(100vh - 2rem);
		overflow: auto;

		.header {
			position: sticky;
			top: 0;
			background-color: #fff;
			z-index: 2;
			padding-top: 2rem;

			svg {
				margin-left: auto;
				display: block;
				cursor: pointer;
				width: 1.25rem;
				width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);

				:active {
					outline: none;
				}
			}
			h2 {
				text-align: center;
				font-size: 2rem;
				font-size: clamp(1.75rem, 1.6111rem + 0.4444vw, 2rem);
				margin-top: 0.5rem;
				color: #070d19;
			}
			h2 + p {
				color: #a8a8a8;
				font-size: 1rem;
				font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
				line-height: 120%;
				margin: 0.5rem 0 1.5rem;
				text-align: center;
			}
			hr {
				border-top: 1px solid #ebebeb;
			}
		}
		h4 {
			color: #070d19;
			font-family: 'Urbanist', sans-serif;
			font-size: 1.5rem;
			font-size: clamp(1.125rem, 0.9167rem + 0.6667vw, 1.5rem);
			font-weight: 600;
			margin-top: 2rem;
		}

		.grid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 1rem 2rem;
			margin-top: 1.5rem;

			${maxQuery('md')} {
				grid-template-columns: 1fr;
			}
		}
		h4 + summary {
			margin-top: 0.87rem;
		}
		.summary {
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: #787878;
			font-size: 1.125rem;
			font-size: clamp(1rem, 0.9306rem + 0.2222vw, 1.125rem);
			padding: 1rem 0;
			gap: 1rem;

			span {
				min-width: max-content;
			}

			b {
				color: #4060ea;
				font-weight: 700;
				text-transform: uppercase;
				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				text-align: right;

				${maxQuery('md')} {
					padding-right: 0.75rem;
				}
			}
		}
		.btn-group {
			display: flex;
			gap: 1rem;
			margin-top: 2rem;
		}
		${StyledButton} {
			flex: 1;
		}
	}
`;
