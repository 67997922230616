import styled, { keyframes } from 'styled-components';

const animate = keyframes`
from{
    transform: rotate(0deg);
}
to{
    transform: rotate(360deg);
}
`;
const logoAnimationRight = keyframes`
0%{
    transform: rotate(0deg);
}
50%, 100%{
    transform: rotate(720deg);
}
`;
const logoAnimationLeft = keyframes`
0%{
    transform: rotate(0deg);
}
50%, 100%{
    transform: rotate(-720deg);
}
`;

export const StyledLoader = styled.div`
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	border: 0.4rem solid #fff;
	border-top-color: #4060ea;
	animation: ${animate} 1s infinite linear;
	box-shadow: 0 0px 4px rgba(18, 18, 18, 0.15);
`;

export const StyledLogoLoader = styled.div`
	--size: ${({ $size }) => $size || '4rem'};
	--duration: 3s;
	--animationTiming: cubic-bezier(0.42, 0, 0.23, 1.2);

	position: relative;
	width: var(--size);
	height: var(--size);
	transform: translateY(-2rem);

	svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		:nth-child(1) {
			animation: ${logoAnimationRight} var(--duration) infinite var(--animationTiming);
		}
		:nth-child(2) {
			animation: ${logoAnimationLeft} var(--duration) infinite var(--animationTiming);
		}
		:nth-child(3) {
			animation: ${logoAnimationRight} var(--duration) infinite var(--animationTiming);
		}
	}
`;
