import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from './useAuth';
import { Approutes } from '../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthDefaultState, GeneralStoreDefaultState } from '../context';
import { useGeneralStore } from './useGeneralStore';

export const useLogout = () => {
	const { setAuth } = useAuth();
	const { setStore } = useGeneralStore();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const logout = (shouldNavigateBackToPreviousUrl = false) => {
		queryClient.clear();
		localStorage.clear();
		sessionStorage.clear();
		console.clear();

		setAuth(AuthDefaultState);
		setStore(GeneralStoreDefaultState);

		navigate(shouldNavigateBackToPreviousUrl ? `${Approutes.login}?next=${pathname}` : Approutes.login);
	};

	return logout;
};
