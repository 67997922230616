import styled from 'styled-components';

export const Container = styled.div`
	.declined {
		text-transform: capitalize;
		color: #ea2d2e !important;
	}
	.approved {
		text-transform: capitalize;
		color: #017d34 !important;
	}
	.pending {
		text-transform: capitalize;
		color: #c08030 !important;
	}
`;
