import { LogoDark, Logout } from '../../../../assets/svgs';
import { Container } from './styles';
import { useNotify, useLogout } from '../../../../hooks';

const CompletingProfileHeader = () => {
	const notify = useNotify();
	const logout = useLogout();

	const handleLogout = () => {
		logout();

		notify({ message: "You've been logged out successfully", status: 'success', toastOptions: { toastId: 'logged_out' } });
	};

	return (
		<Container>
			<div>
				<LogoDark />

				<Logout className="logout-icon" onClick={handleLogout} />
			</div>
		</Container>
	);
};

export default CompletingProfileHeader;
