import { Outlet, useSearchParams } from 'react-router-dom';
import { useDecodedUser } from '../../hooks';
import { PageNotFound } from '../../views';
import { isAdmin } from '../../utilities';
import { queryStrings } from '../../constants';
import { BusinessDetails, ConcernDetails, InstitutionDetails, InternDetails } from '../../views/Dashboard/modals';
import { UserType } from '../../types';

const AdminOnly = () => {
	const user = useDecodedUser();
	const [searchParams] = useSearchParams();

	return isAdmin(user as UserType) ? (
		<>
			<Outlet />

			{searchParams.get(queryStrings.applicantId) ? (
				<InternDetails />
			) : searchParams.get(queryStrings.businessId) ? (
				<BusinessDetails />
			) : searchParams.get(queryStrings.institutionId) ? (
				<InstitutionDetails />
			) : searchParams.get(queryStrings.concernsId.intern) || searchParams.get(queryStrings.concernsId.startup) ? (
				<ConcernDetails />
			) : null}
		</>
	) : (
		<PageNotFound />
	);
};

export default AdminOnly;
