import styled from 'styled-components';
import { StyledButton } from '../../../../ui/Button/styles';

export const Container = styled.div`
	border-radius: 0.25rem;
	background: #fff;
	box-shadow: -9px 19px 40px 0px rgba(0, 0, 0, 0.02);
	padding: 2rem;
	/* padding: clamp(1.5rem, 0.3889rem + 3.5556vw, 3.5rem); */
	min-height: calc(100vh - 18rem);

	> div {
		border-radius: 0.125rem;
		border: 1px solid rgba(18, 18, 18, 0.05);
		background: #fff;
		padding: 1.5rem 2rem;

		:first-child {
			margin-bottom: 1.5rem;
		}

		> div {
			display: flex;
			align-items: center;
			justify-content: space-between;

			:not(:last-child) {
				margin-bottom: 1.25rem;
			}

			b {
				color: #12091b;
				font-size: 1rem;
				font-weight: 600;
				display: block;
			}
			span {
				color: #bbbaba;
				font-size: 0.875rem;
			}
		}

		h4 {
			color: #12091b;
			font-family: 'Urbanist', sans-serif;
			font-size: 1.125rem;
			font-weight: 700;
			margin-bottom: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid rgba(18, 18, 18, 0.05);
		}

		${StyledButton} {
			font-size: 0.875rem;
			padding: 0.75rem 2rem;
			margin-top: 2rem;
		}
	}
`;
