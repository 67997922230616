import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { Approutes, LOCAL_STORAGE_KEYS } from '../../constants';

const RequireNoAuth = () => {
	const { auth } = useAuth();

	if (auth?.access || localStorage.getItem(LOCAL_STORAGE_KEYS.access_key)) {
		return <Navigate to={Approutes.home} replace={true} />;
	}

	return <Outlet />;
};

export default RequireNoAuth;
