import { ApplicantAvatar, CloseModal } from '../../../assets/svgs';
import { useDecodedUser } from '../../../hooks';
import { getDateTime, isIntern } from '../../../utilities';
import { Container } from './styles';

const LogSummary = ({ log, handleClose }) => {
	const user = useDecodedUser();

	return (
		<Container>
			<div>
				<div className="header">
					<CloseModal tabIndex={0} onClick={handleClose} />
					<h2>Log Summary</h2>
					<hr />
				</div>

				<article className="legend">
					<div>
						<span>{log?.log_name}</span>
						<p>{getDateTime(log?.created_at, 'mm dd yyyy')}</p>
					</div>
					{isIntern(user) ? (
						<div></div>
					) : (
						<div>
							<span>Log submitted by</span>
							<p>
								<img src={ApplicantAvatar} alt="" /> {log?.submitted_by?.user_name || log?.submitted_by?.applicant_id}
							</p>
						</div>
					)}
				</article>
				<article className="task">
					<div>
						<span>Task Name</span>
						<p>{log?.related_task?.name}</p>
					</div>
					<div>
						<span>Task Status</span>
						{log?.related_task?.is_completed ? <p className="open">Open</p> : <p className="closed">Closed</p>}
					</div>
				</article>

				<article>
					<span>Key Achievement</span>
					<p>{log?.achievement}</p>
				</article>

				<article>
					<span>Additional description of activity</span>
					<p>{log?.description}</p>
				</article>

				<article>
					<span>Project</span>
					<p>{log?.related_project?.name}</p>
				</article>
			</div>
		</Container>
	);
};
export default LogSummary;
