import { useState } from 'react';
import CreateAccount from './CreateAccount';
import { OTP } from '../../../components';
import { useNavigate } from 'react-router-dom';
import { useNotify, useSignupAsInstitution } from '../../../hooks';
import { useMutation } from '@tanstack/react-query';
import { axios } from '../../../library';
import { Approutes, Appurls } from '../../../constants';
import { handleApiError } from '../../../utilities';
import { AxiosError } from 'axios';

export type FormData = {
	institution_email: string;
	password: string;
	confirm_password: string;
	otp: string[];
};
export type SetFormData = React.Dispatch<React.SetStateAction<FormData>>;

export type SetStep = React.Dispatch<React.SetStateAction<number>>;

const Institution = () => {
	const navigate = useNavigate();
	const notify = useNotify();
	const { mutate: verifyAccount, isLoading } = useMutation({
		mutationFn: (otp: string) => axios.post(Appurls.auth.verify_account, { otp, email: formData.institution_email }),
	});

	const { mutate: signup, isLoading: isSigningUp } = useSignupAsInstitution();
	const [step, setStep] = useState(1);
	const [formData, setFormData] = useState<FormData>({
		institution_email: '',
		password: '',
		confirm_password: '',
		otp: ['', '', '', ''],
	});

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		verifyAccount(formData.otp.join(''), {
			onSuccess: () => {
				navigate(Approutes.login);
				notify({
					message: 'Account created successfully. Log in with your details',
					status: 'success',
					toastOptions: { toastId: 'verify_account_success' },
				});
			},
			onError: (error) => {
				let errorMessage = (error as AxiosError)?.response?.status === 400 ? 'Invalid OTP supplied' : handleApiError(error as AxiosError);

				notify({ message: errorMessage, status: 'error', toastOptions: { toastId: 'verify_account_failed' } });
			},
		});
	};

	return step === 1 ? (
		<CreateAccount formData={formData} setFormData={setFormData} setStep={setStep} />
	) : step === 2 ? (
		<OTP
			title="INSTITUTIONAL PORTAL"
			value={formData.otp}
			setValue={(value: string[]) => setFormData((prev) => ({ ...prev, otp: value }))}
			isSubmitting={isLoading}
			handleSubmit={handleSubmit}
			isSendingOTP={isSigningUp}
			resendOTP={() =>
				signup(
					{ email: formData.institution_email, password: formData.password },
					{
						onSuccess: () =>
							notify({
								message: 'A new OTP has been resent',
								variant: 'minor',
								toastOptions: { toastId: 'otp_success', position: 'bottom-center' },
							}),
						onError: (error) => notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'signup' } }),
					}
				)
			}
		/>
	) : null;
};

export default Institution;
