import styled from 'styled-components';
import { StyledApplicantCard } from '../../components/ApplicantCard/styles';
import { StyledPagination } from '../../ui/Pagination/styles';
import { StyledSearchBar } from '../../ui/SearchBar/styles';
import { maxQuery } from '../../utilities';
import { StyledButton } from '../../ui/Button/styles';
import { StyledTag } from '../../ui/Tag/styles';

export const Container = styled.div`
	padding: 2.75rem 6.25rem;
	padding: 2.75rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);
	min-height: calc(100vh - 5.3rem);
	display: flex;
	flex-direction: column;

	.header {
		display: flex;
		align-items: flex-start;
		gap: 1.5rem 1rem;
		flex-wrap: wrap;
		margin-bottom: 1.5rem;

		${maxQuery('400px')} {
			gap: 0.5rem;
		}

		> div:first-child {
			margin-right: auto;

			${maxQuery('md')} {
				flex-basis: 100%;
			}
			${maxQuery('400px')} {
				margin-bottom: 0.75rem;
			}

			h4 {
				color: #11071a;
				font-family: 'Urbanist', sans-serif;
				font-size: 1.5rem;
				font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
				font-weight: 700;
				margin-bottom: 0.25rem;
				line-height: 120%;
			}
			p {
				color: #828282;
				font-size: 0.75rem;
				line-height: 120%;
			}
		}
		${StyledSearchBar} {
			${maxQuery('md')} {
				flex: 1;
			}
			${maxQuery('400px')} {
				flex-basis: 100%;
			}
		}
		/* ${StyledButton} {
			padding: 0.86rem 1.5rem;

			span {
				display: flex;
				align-items: center;
				gap: 0.5rem;
			}
		} */
	}
	.sub-heading-one {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 1rem;
		gap: 1rem;
		flex-wrap: wrap;
	}
	.invite-con {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 1rem;

		&.mobile {
			display: none;
			justify-content: center;
			margin: auto 0 1rem;
		}

		${maxQuery('sm')} {
			display: none;

			&.mobile {
				display: flex;
			}
		}

		${StyledButton} {
			padding: 1rem;
			line-height: 130%;
			font-size: 0.875rem;
			font-size: clamp(0.75rem, 0.6806rem + 0.2222vw, 0.875rem);
			font-weight: 700;

			span {
				display: flex;
				align-items: center;
				gap: 0.5rem;
			}
		}
	}

	.results-con {
		display: flex;
		flex-wrap: wrap;
		gap: 0.75rem;
		margin-bottom: 2rem;

		> span {
			color: #2f2f2f;
			flex-shrink: 0;
			font-size: 0.875rem;
			font-size: clamp(0.75rem, 0.6806rem + 0.2222vw, 0.875rem);
			font-weight: 600;
			line-height: 2.375rem;
		}

		${StyledTag} {
			font-size: 0.875rem;
			font-weight: 500;
		}
	}

	> center {
		min-height: calc(100vh - 25rem);
		display: grid;
		place-items: center;
	}
	.talent-list {
		overflow-x: auto;
		gap: 1.75rem;
		padding-bottom: 3rem;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

		${StyledApplicantCard} {
			flex: 1;
			min-width: 13.75rem;
		}
	}
	.empty-state {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: calc(100vh - 24rem);
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;

		p {
			color: #1e1e1e;
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 135%;
			margin-top: 1rem;
		}
	}
	${StyledPagination} {
		justify-content: center;
		margin-top: auto;
	}
`;
