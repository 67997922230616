import { useQueryClient } from '@tanstack/react-query';
import { COMPANY_SECTORS, COMPANY_SIZE, COUNTRY } from '../../../constants';
import { Button, InputGroup, SelectGroup } from '../../../ui';
import { useState } from 'react';
import { useNotify, useUpdateStartupProfile } from '../../../hooks';
import { handleApiError } from '../../../utilities';

const CompleteProfile = ({ setStep, formData, handleChange, handleLogout }) => {
	const queryClient = useQueryClient();
	const [isLoadingFor, setIsLoadingFor] = useState('');
	const notify = useNotify();
	const { mutate: updateProfile, isLoading } = useUpdateStartupProfile();

	const handleSaveAndExit = () => {
		setIsLoadingFor('EXIT');
		handleDataSubmission({
			onSuccess: () => {
				handleLogout();
				setIsLoadingFor('');
			},
		});
	};
	const handleDataSubmission = ({ onSuccess }) => {
		const axiosFormData = new FormData();

		formData.company_name && axiosFormData.append('company_name', formData.company_name);
		formData.country_registered && axiosFormData.append('country_of_reg', formData.country_registered);
		formData.company_registration_number && axiosFormData.append('company_registration_no', formData.company_registration_number);
		formData.company_sector && axiosFormData.append('sector', formData.company_sector);
		formData.company_size && axiosFormData.append('size', formData.company_size);

		updateProfile(axiosFormData, {
			onSuccess,
			onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'profile_update' } }),
		});
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		setIsLoadingFor('CONTINUE');

		handleDataSubmission({
			onSuccess: () => {
				setStep(2);
				queryClient.invalidateQueries(['user']);
				queryClient.invalidateQueries(['user-id']);
				setIsLoadingFor('');
			},
		});
	};

	return (
		<form onSubmit={handleSubmit}>
			<h2>Company Profile</h2>
			<p>Provide information to complete your company profile</p>

			<div>
				<SelectGroup
					label="Country Registered In"
					name="country_registered"
					onChange={handleChange}
					placeholder="Select Country"
					value={formData.country_registered}
					options={COUNTRY}
					required
				/>
				<InputGroup
					label="Registered Company Name"
					name="company_name"
					onChange={handleChange}
					placeholder="Enter Company's Name"
					value={formData.company_name}
					required
				/>
				<InputGroup
					label="Company Registration Number"
					name="company_registration_number"
					onChange={handleChange}
					placeholder="Enter CRN"
					value={formData.company_registration_number}
					moreInfo="You must provide your Company(s) Registration Number (CRN) here. This is for company registration validation only."
					required
				/>
				<SelectGroup
					label="Company Sector"
					name="company_sector"
					onChange={handleChange}
					placeholder="Select Company Sector"
					value={formData.company_sector}
					options={COMPANY_SECTORS}
					required
				/>
				<SelectGroup
					label="Current Company Size"
					name="company_size"
					onChange={handleChange}
					placeholder="Select Company Size Range"
					value={formData.company_size}
					options={COMPANY_SIZE}
					required
				/>
			</div>

			<div className="btn-group">
				<Button
					loading={isLoading && isLoadingFor === 'EXIT' ? isLoading : false}
					disabled={isLoading}
					type="button"
					variant="secondary"
					onClick={handleSaveAndExit}
				>
					Save and Exit
				</Button>
				<Button loading={isLoading && isLoadingFor === 'CONTINUE' ? isLoading : false} disabled={isLoading}>
					Save & Continue
				</Button>
			</div>
		</form>
	);
};

export default CompleteProfile;
