import { Loader, Pagination } from '../../ui';
import { ApplicantEye, ChevronDown, ProjectDate, ProjectEmpty, TaskCheckmark } from '../../assets/svgs';
import { useState } from 'react';
import { Container, StyledProject } from './styles';
import { useGetProjects } from '../../hooks';
import { getDateTime } from '../../utilities';
import { useRef } from 'react';
import Tasks from './Tasks';
import { Link } from 'react-router-dom';
import { Approutes } from '../../constants';

const Overview = () => {
	const [page, setPage] = useState(1);
	const [viewProject, setViewProject] = useState('');
	const pageSize = useRef(5);
	const { data: projects, isLoading, isError } = useGetProjects({ page, page_size: pageSize.current, status: '' });

	return (
		<Container>
			<div className="header">
				<h4>Overview</h4>
				<p>Get insight on all you project and tasks</p>
			</div>

			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>Unable to fetch available projects at the moment</p>
				</div>
			) : !projects?.results || projects?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>You haven't been added to any project yet</p>
				</div>
			) : (
				<>
					<div className="projects">
						{projects?.results?.map((project) => (
							<StyledProject
								key={project?.id}
								className={project?.id === viewProject ? 'active' : ''}
								$percent={parseFloat(project?.completion_percent)}
								$sector="E-Commerce"
							>
								<div className="project-con">
									<div>
										<div>
											<b>{project?.name}</b> <span>{project?.project_sector}</span>
										</div>
										<div className={'status ' + project?.status}>{project?.status === 'pending' ? 'Pending' : project?.status}</div>
									</div>
									<div>
										<ProjectDate />
										<div>
											<b>{getDateTime(project?.start_date)}</b>
											<span>Start Date</span>
										</div>
									</div>
									<div>
										<ProjectDate />
										<div>
											<b>{project?.timeline}</b>
											<span>Duration</span>
										</div>
									</div>
									<div>
										<span>
											<TaskCheckmark /> {project?.total_task_count}
										</span>
										<span>
											<span>
												<span></span>
											</span>{' '}
											{parseInt(project?.completion_percent)}%
										</span>
									</div>
									<div>
										<Link to={Approutes.intern.projects.use_project_details(project?.id)}>
											<ApplicantEye />
										</Link>
										<button onClick={() => setViewProject((prev) => (prev !== project?.id ? project?.id : ''))}>
											<ChevronDown />
										</button>
									</div>
								</div>

								{project?.id === viewProject ? <Tasks projectId={project?.id} /> : null}
							</StyledProject>
						))}
					</div>

					<Pagination page={page} setPage={setPage} maxPages={Math.ceil(projects?.total / pageSize.current)} disabled={isLoading} />
				</>
			)}
		</Container>
	);
};

export default Overview;
