import { useEffect, useState } from 'react';
import { Button, FileGroup, InputGroup, SelectGroup } from '../../../ui';
import { CloseModal, FileImage } from '../../../assets/svgs';
import { Container } from './styles';
import { useAssignTask, useNotify } from '../../../hooks';
import { getDateDifference, handleApiError } from '../../../utilities';

const AssignTask = ({
	taskId,
	resources = [],
	taskName,
	taskDetail,
	defaultValues: { start_date, end_date, docs, tags, project_supervisor, assigned_to },
	handleClose,
	handleSuccess,
}) => {
	const [formData, setFormData] = useState({
		project_supervisor: project_supervisor || '',
		assign_to: resources.find((resource) => resource?.id === assigned_to)?.applicant_name || '',
		start_date: start_date || '',
		end_date: end_date || '',
		document: {
			name: '',
			file: null,
		},
		tags: tags || [],
	});
	const notify = useNotify();
	const { mutate: assignTask, isLoading } = useAssignTask();

	const handleChange = (event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const handleSubmit = (event) => {
		event.preventDefault();

		const axiosFormData = new FormData();

		axiosFormData.append('start_date', formData.start_date);
		axiosFormData.append('end_date', formData.end_date);
		formData.document.file && axiosFormData.append('docs', formData.document.file);
		for (let i = 0; i < formData.tags.length; i++) {
			axiosFormData.append('tags', formData.tags[i]);
		}
		axiosFormData.append('assigned_to', resources.find((resource) => resource?.applicant_name === formData.assign_to)?.id);
		axiosFormData.append('project_supervisor', formData.project_supervisor);

		assignTask(
			{ taskId, data: axiosFormData },
			{
				onSuccess: () => {
					notify({
						message: `Task has been assigned to ${formData.assign_to}`,
						status: 'success',
						toastOptions: { toastId: 'assign_task_success' },
					});
					handleSuccess();
					handleClose();
				},
				onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'assign_task_error' } }),
			}
		);
	};

	useEffect(() => {
		if (!formData.start_date) return;

		if (getDateDifference(formData.start_date, formData.end_date, false) > 0) {
			handleChange(null, 'end_date', formData.start_date);
		}
	}, [formData]);

	return (
		<Container>
			<form onSubmit={handleSubmit}>
				<div className="header">
					<CloseModal tabIndex={0} onClick={handleClose} />
					<h2>Assign Task</h2>
				</div>

				<article>
					<h4>{taskName}</h4>
					<p>{taskDetail}</p>
				</article>

				<div className="grid">
					{/* <InputGroup
						label="Project Supervisor"
						name="project_supervisor"
						onChange={handleChange}
						placeholder="Enter Supervisor's Name"
						value={formData.project_supervisor}
						required
					/> */}
					<SelectGroup
						label="Assign to"
						name="assign_to"
						onChange={handleChange}
						placeholder="Select Resource"
						value={formData.assign_to}
						options={resources.map((resource) => resource?.applicant_name)}
						emptyOptionsMessage="You haven't added any applicant to this project"
						required
					/>
					<InputGroup
						label="Tags"
						variant="input-multiple"
						name="tags"
						onChange={handleChange}
						placeholder="Add task tags"
						value={formData.tags}
						autoComplete="off"
					/>
					<InputGroup
						label="Start Date"
						name="start_date"
						type="date"
						onChange={handleChange}
						placeholder="Select Start Date"
						value={formData.start_date}
						min={new Date().toISOString().substring(0, 10)}
					/>
					<InputGroup
						label="End Date"
						name="end_date"
						type="date"
						onChange={handleChange}
						placeholder="Select End Date"
						value={formData.end_date}
						min={formData.start_date ? new Date(formData.start_date).toISOString().substring(0, 10) : new Date().toISOString().substring(0, 10)}
					/>
					<FileGroup
						label="Upload document (Optional)"
						name="document"
						onChange={handleChange}
						helpTextTwo="JPG, GIF or PNG. Max size of 800Kb"
						accept="image/png, image/gif, image/jpeg"
						maxSize={0.8}
						value={formData.document}
						icon={FileImage}
					/>
				</div>

				<Button loading={isLoading}>Assign task</Button>
			</form>
		</Container>
	);
};
export default AssignTask;
