import { Spinner } from '../../assets/svgs';
import { StyledButton } from './styles';

const Button = ({ variant = 'primary', loading = false, disabled = false, children, ...rest }) => {
	return (
		<StyledButton $variant={variant} $loading={loading} disabled={loading || disabled} {...rest}>
			{loading ? <Spinner className="spinner" /> : null}
			<span style={{ opacity: loading ? 0 : 1 }}>{children}</span>
		</StyledButton>
	);
};

export default Button;
