import { CloseModal } from '../../../../assets/svgs';
import { DepartmentType } from '../../../../types';
import { getDateTime } from '../../../../utilities';
import { Container } from './styles';

type DepartmentDetailsProp = {
	department: DepartmentType | undefined;
	handleClose: () => void;
	handleViewSupervisors: () => void;
	handleViewInterns: () => void;
};

const DepartmentDetails = ({ department, handleClose, handleViewSupervisors, handleViewInterns }: DepartmentDetailsProp) => {
	return (
		<Container>
			<div>
				<div className="header">
					<CloseModal tabIndex={0} onClick={handleClose} />

					<h2>Department Information</h2>
					<p>View all the information about this department</p>
				</div>
				<br />

				<article className="top">
					<div>
						<b>{department?.head || 'UnAssigned'}</b>
						<p>{department?.head_email || 'N/A'}</p>
					</div>
					<div>
						<p></p>
						<p></p>
					</div>
					<div>
						<b>{department?.phone || 'N/A'}</b>
						<p>Department's Contact</p>
					</div>
					<div>
						<b>{department ? getDateTime(department.created_at, 'mm dd yyyy') : 'N/A'}</b>
						<p>Date Created</p>
					</div>
				</article>
				<br />
				<h4>Other Department Information</h4>

				<article className="bottom">
					<div>
						<b>{department?.supervisor_count || 0}</b>
						<p>
							Supervisors <button onClick={handleViewSupervisors}>View</button>
						</p>
					</div>
					<div>
						<b>{department?.intern_count || 0}</b>
						<p>
							<button onClick={handleViewInterns}>View</button> Interns
						</p>
					</div>
				</article>
			</div>
		</Container>
	);
};
export default DepartmentDetails;
