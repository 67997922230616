import { Loader, Pagination } from '../../ui';
import { ChevronDown, ProjectEmpty } from '../../assets/svgs';
import { useState } from 'react';
import { Container, StyledResource } from './styles';
import Resource from './Resource';
import { useGetResources } from '../../hooks';
import { useRef } from 'react';

const Resources = () => {
	const pageSize = useRef(5);
	const [page, setPage] = useState(1);

	const [viewLogs, setViewLogs] = useState('');
	const { data: resources, isLoading, isError } = useGetResources({ page, page_size: pageSize.current });

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Resources</h4>
					{/* <p>Update your photo and personal details</p> */}
				</div>
			</div>

			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>Unable to fetch resources at the moment</p>
				</div>
			) : !resources?.results || resources?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>You haven't been invited by any resource yet</p>
				</div>
			) : (
				<>
					<div className="supervisors">
						{resources?.results?.map((resource) => (
							<StyledResource key={resource?.id} className={resource?.id === viewLogs ? 'active' : ''}>
								<div className="supervisor-con">
									<div>
										<b>{resource?.intern?.name}</b> <span>Intern</span>
									</div>
									<div>
										<div>
											<b>{resource?.related_project?.name}</b> <span>{resource?.related_project?.project_sector}</span>
										</div>
										<span>{resource?.related_project?.timeline}</span>
									</div>
									<div>
										<b>{resource?.intern?.email}</b>
										<span>Email Address</span>
									</div>
									<div>
										<b>{resource?.logs_rate?.completed} Tasks</b>
										<span>Logs Approved </span>
									</div>
									<div>
										<b>{resource?.logs_rate?.pending} Tasks</b>
										<span>Logs Pending </span>
									</div>
									<div>
										<button onClick={() => setViewLogs((prev) => (prev !== resource?.id ? resource?.id : ''))}>
											<ChevronDown />
										</button>
									</div>
								</div>

								{resource?.id === viewLogs ? <Resource projectId={resource?.related_project?.id} internId={resource?.intern?.id} /> : null}
							</StyledResource>
						))}
					</div>

					<Pagination page={page} setPage={setPage} maxPages={Math.ceil(resources?.total / pageSize.current)} disabled={isLoading} />
				</>
			)}
		</Container>
	);
};

export default Resources;
