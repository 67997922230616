import { EditorProvider, useCurrentEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import {
	// MessageAt,
	// MessageEmoji,
	// MessagePin,
	MessageBold,
	MessageBulletList,
	MessageItalic,
	MessageNumberList,
	MessageSend,
	MessageStrikeThrough,
} from '../../../../assets/svgs';
import { useNotify, useSendMessage } from '../../../../hooks';
import { Loader } from '../../../../ui';

const extensions = [
	Placeholder.configure({
		placeholder: 'Write a message....',
	}),
	StarterKit.configure({
		bulletList: {
			keepMarks: true,
			keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
		},
		orderedList: {
			keepMarks: true,
			keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
		},
	}),
];

const content = '';

const RichTextArea = ({ receiverId, scrollToMessageEnd }) => {
	const [isFocused, setIsFocused] = useState(false);

	return (
		<Container className={isFocused ? 'focused' : ''}>
			<EditorProvider
				extensions={extensions}
				content={content}
				slotAfter={<MenuBar receiverId={receiverId} setIsFocused={setIsFocused} scrollToMessageEnd={scrollToMessageEnd} />}
			></EditorProvider>
		</Container>
	);
};

const MenuBar = ({ receiverId, setIsFocused, scrollToMessageEnd }) => {
	const { editor } = useCurrentEditor();
	const { mutate: sendMessage, isLoading } = useSendMessage();
	const notify = useNotify();

	useEffect(() => {
		setIsFocused(editor?.isFocused || false);
	}, [editor?.isFocused, setIsFocused]);

	if (!editor) {
		return null;
	}

	return (
		<div className="bottom-con">
			<div>
				<button
					type="button"
					onClick={() => editor.chain().focus().toggleBold().run()}
					disabled={!editor.can().chain().focus().toggleBold().run()}
					className={editor.isActive('bold') ? 'is-active' : ''}
				>
					<MessageBold />
				</button>
				<button
					type="button"
					onClick={() => editor.chain().focus().toggleItalic().run()}
					disabled={!editor.can().chain().focus().toggleItalic().run()}
					className={editor.isActive('italic') ? 'is-active' : ''}
				>
					<MessageItalic />
				</button>
				<button
					type="button"
					onClick={() => editor.chain().focus().toggleBulletList().run()}
					className={editor.isActive('bulletList') ? 'is-active' : ''}
				>
					<MessageBulletList />
				</button>
				<button
					type="button"
					onClick={() => editor.chain().focus().toggleOrderedList().run()}
					className={editor.isActive('orderedList') ? 'is-active' : ''}
				>
					<MessageNumberList />
				</button>
				<button
					type="button"
					onClick={() => editor.chain().focus().toggleStrike().run()}
					disabled={!editor.can().chain().focus().toggleStrike().run()}
					className={editor.isActive('strike') ? 'is-active' : ''}
				>
					<MessageStrikeThrough />
				</button>
			</div>
			<div>
				<button
					type="submit"
					disabled={isLoading || editor.getText()?.trim()?.length === 0}
					onClick={() => {
						if (!editor.getText()?.trim()?.length) return;

						sendMessage(
							{
								text: editor.getHTML(),
								receiver: receiverId,
							},
							{
								onSuccess: () => {
									editor.commands.clearContent();
									scrollToMessageEnd();
								},
								onError: (error) =>
									notify({
										message: 'Failed to send message',
										status: 'error',
										variant: 'minor',
										toastOptions: { toastId: 'send_message_error', position: 'bottom-center' },
									}),
							}
						);
					}}
				>
					{isLoading ? <Loader /> : <MessageSend />}
				</button>
			</div>
		</div>
	);
};

export default RichTextArea;
