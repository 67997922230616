export const getInitials = (name: string) => {
	let initials = '';

	if (!name) {
		return 'JD';
	}

	const splittedName = name.split(' ');

	initials = splittedName[0][0];
	if (splittedName[1]) {
		initials += splittedName[1][0];
	} else if (splittedName[0]?.[1]) {
		initials += splittedName[0][1];
	}

	return initials;
};
