import { useRef, useState } from 'react';
import { Container } from './styles';
import { Button, Loader, Pagination, SearchBar } from '../../../../ui';
import { useGetPendingDepartmentHeads, useGetSignedUpDepartmentHeads } from '../../../../hooks';
import { ButtonPlus, ProjectEmpty } from '../../../../assets/svgs';
import { Filter } from '../../../../components';
import { useCallback } from 'react';
import { GeneralChangeEventType, FilterValue } from '../../../../types';
import { ActiveDepartmentHeads, PendingDepartmentHeads } from '../../components';
import { InviteDepartmentHeadModal } from '../../modals';

type SubControlType = 'pending' | 'active';

const InstitutionDepartmentHead = () => {
	const pageSize = useRef(6);
	const [page, setPage] = useState(1);
	const [isInviteDepartmentHeadModalOpen, setIsInviteDepartmentHeadModalOpen] = useState(false);
	const [subControl, setSubControl] = useState<SubControlType>('active');
	const [formData, setFormData] = useState({
		search: '',
		filter: (() => {
			let state: FilterValue = {};
			(subControl === 'pending' ? [] : []).forEach(
				(option: { name: string | number; queryKey: any }) => (state[option?.name] = { value: '', queryKey: option?.queryKey })
			);

			return state;
		})(),
	});

	const {
		data: pendingDepartmentHeads,
		isLoading: isLoadingPendingDepartmentHeads,
		isError: isPendingDepartmentHeadError,
	} = useGetPendingDepartmentHeads({ page, page_size: pageSize.current, search: formData.search, enabled: true });
	const {
		data: activeDepartmentHeads,
		isLoading: isLoadingActiveDepartmentHeads,
		isError: isActiveDepartmentHeadError,
	} = useGetSignedUpDepartmentHeads({ page, page_size: pageSize.current, search: formData.search, enabled: true });

	const isFetchingData = subControl === 'pending' ? isLoadingPendingDepartmentHeads : isLoadingActiveDepartmentHeads;
	const isDataError = subControl === 'pending' ? isPendingDepartmentHeadError : isActiveDepartmentHeadError;

	const result = subControl === 'pending' ? pendingDepartmentHeads : activeDepartmentHeads;

	const handleChange: GeneralChangeEventType = useCallback((event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name as string]: value }));
	}, []);

	const handleSubControl = (value: SubControlType) => {
		setPage(1);
		setSubControl(value);

		handleChange(null, 'search', '');
		handleChange(
			null,
			'filter',
			(() => {
				let state: FilterValue = {};
				(value === 'pending' ? [] : []).forEach(
					(option: { name: string | number; queryKey: any }) => (state[option?.name] = { value: '', queryKey: option?.queryKey })
				);

				return state;
			})()
		);
	};

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Department Heads</h4>
					{/* <p>Update your photo and personal details</p> */}
				</div>

				<Button onClick={() => setIsInviteDepartmentHeadModalOpen(true)}>
					<ButtonPlus /> Invite Head
				</Button>
			</div>

			<div className="table-header">
				<div className="controls">
					<button className={subControl === 'active' ? 'active' : ''} onClick={() => handleSubControl('active')}>
						Active
					</button>
					<button className={subControl === 'pending' ? 'active' : ''} onClick={() => handleSubControl('pending')}>
						Pending
					</button>
				</div>

				<div className="filter-con">
					<SearchBar name="search" placeholder={`Search through ${subControl} heads`} handleChange={handleChange} value={formData.search} />
					<Filter name="filter" onChange={handleChange} value={formData.filter} options={subControl === 'pending' ? [] : []} />
				</div>
			</div>

			{isFetchingData ? (
				<center>
					<Loader />
				</center>
			) : isDataError ? (
				<center>
					<div>
						<ProjectEmpty />
						<p className="error-message">Unable to fetch {subControl} heads at the moment</p>
					</div>
				</center>
			) : !result?.results || result?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p className="error-message">No {subControl} heads to display</p>
				</div>
			) : subControl === 'pending' ? (
				<PendingDepartmentHeads page={page} heads={pendingDepartmentHeads?.results} />
			) : subControl === 'active' ? (
				<ActiveDepartmentHeads page={page} heads={activeDepartmentHeads?.results} />
			) : null}

			<Pagination page={page} setPage={setPage} maxPages={Math.ceil((result?.total || 0) / pageSize.current)} disabled={isFetchingData} />

			{isInviteDepartmentHeadModalOpen ? <InviteDepartmentHeadModal handleClose={() => setIsInviteDepartmentHeadModalOpen(false)} /> : null}
		</Container>
	);
};
export default InstitutionDepartmentHead;
