import { CloseModal } from '../../../assets/svgs';
import { Container } from './styles';
import { Button, InputGroup } from '../../../ui';
import { useEffect, useRef, useState } from 'react';

const StartupPromoCode = ({
	formData,
	isCodeValidating,
	isCodeInvalid,
	finalPrice,
	codeMessage,
	handleChange,
	handleClose,
	handleBack,
	handleSubmit,
}) => {
	const [tempCode, setTempCode] = useState('');
	const promoConRef = useRef();

	useEffect(() => {
		setTempCode(formData.promo_code);
	}, [formData.promo_code]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			handleChange(null, 'promo_code', tempCode);
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [tempCode, handleChange]);

	return (
		<Container>
			<div>
				<CloseModal tabIndex={0} onClick={handleClose} />
				<h2>Promo Code</h2>
				{/* <p>Provide banking information details to make refund when internship is completed.</p> */}
				<hr />

				<div>
					<h4>Enter your promo code below if you have one</h4>
					{/* <p>Below are information needed to process refund of commitment fee.</p> */}

					<div className="promo-con" ref={promoConRef}>
						<InputGroup
							name="promo_code"
							value={tempCode}
							onChange={(event) => setTempCode(event.target.value)}
							placeholder="Enter your promo code"
							disabled={formData.commitment?.key ? false : true}
						/>
						{!formData.commitment?.key ? (
							<p className="error">You need to select a plan to use a Promo Code</p>
						) : isCodeInvalid ? (
							<p className="error">Invalid/Expired Promo Code</p>
						) : codeMessage ? (
							<p>{codeMessage}</p>
						) : null}
					</div>

					<div className="btn-group">
						<Button onClick={handleBack}>Go Back</Button>
						{formData?.promo_code ? (
							<Button disabled={isCodeInvalid} loading={isCodeValidating} onClick={handleSubmit}>
								Pay £{finalPrice}
							</Button>
						) : (
							<Button onClick={handleSubmit}>Skip and Pay £{finalPrice}</Button>
						)}
						{/* <Button disabled={isCodeValidating || isCodeInvalid}>Proceed</Button> */}
					</div>
				</div>
			</div>
		</Container>
	);
};
export default StartupPromoCode;
