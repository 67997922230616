import { Link } from 'react-router-dom';
import { TableThreeDots } from '../../../../../assets/svgs';
import { DepartmentType } from '../../../../../types';
import { getDateTime, handleApiError } from '../../../../../utilities';
import { Approutes } from '../../../../../constants';
import { Container } from './styles';
import { useActivateDepartment, useDeactivateDepartment, useNotify } from '../../../../../hooks';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

type DepartmentTableProp = {
	page: number;
	departments: DepartmentType[] | undefined;
	handleChangeHead: (departmentId: string) => void;
};

const DepartmentTable = ({ page, departments, handleChangeHead }: DepartmentTableProp) => {
	const notify = useNotify();
	const queryClient = useQueryClient();

	const { mutate: activate, isLoading: isActivating } = useActivateDepartment();
	const { mutate: deactivate, isLoading: isDeactivating } = useDeactivateDepartment();

	const handleActivateDepartment = (id: string) => {
		if (!id) return;

		activate(id, {
			onSuccess: () => {
				notify({
					message: 'Department has been activated!',
					status: 'success',
					toastOptions: { toastId: 'department_activate_success' },
				});

				queryClient.invalidateQueries(['institution-department-list']);
			},
			onError: (error) =>
				notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'department_activate_error' } }),
		});
	};
	const handleDeactivateDepartment = (id: string) => {
		if (!id) return;

		deactivate(id, {
			onSuccess: () => {
				notify({
					message: 'Department has been deactivated!',
					status: 'success',
					toastOptions: { toastId: 'department_deactivate_success' },
				});

				queryClient.invalidateQueries(['institution-department-list']);
			},
			onError: (error) =>
				notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'department_deactivate_error' } }),
		});
	};
	return (
		<Container>
			<div className="table-container" style={{ paddingBottom: (departments?.length || 3) < 3 ? '120px' : '' }}>
				<table>
					<thead>
						<tr>
							<th>S/N</th>
							<th>Department's Name</th>
							<th>Department's Number</th>
							<th>Date Created</th>
							<th>Department Head</th>
							<th>Status</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{departments?.map((department, index) => (
							<tr key={department?.id}>
								<td>{(page - 1) * 10 + index + 1}</td>
								<td>{department?.name || 'N/A'}</td>
								<td>{department?.phone || 'N/A'}</td>
								<td>{getDateTime(department?.created_at, 'mm dd yyyy') || 'N/A'}</td>
								<td>{department?.head ? department?.head : 'N/A'}</td>
								<td>{department?.is_active ? 'Active' : 'Deactivated'}</td>
								<td>
									<div className="dropdown-con">
										<button>
											<TableThreeDots />
										</button>

										<ul className={index >= departments.length - 2 && index > 1 ? 'top' : ''}>
											<li tabIndex={0}>
												<Link to={Approutes.institution_admin.use_deparment(department?.id)}>View</Link>
											</li>
											<li tabIndex={0} onClick={() => handleChangeHead(department?.id)}>
												{department?.head ? 'Change' : 'Assign'} department head
											</li>
											<li
												tabIndex={0}
												onClick={() => {
													if (isActivating || isDeactivating) return;

													department?.is_active ? handleDeactivateDepartment(department?.id) : handleActivateDepartment(department?.id);
												}}
											>
												{isActivating || isDeactivating ? 'Loading...' : department?.is_active ? 'Deactivate department' : 'Reactivate department'}
											</li>
										</ul>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{departments?.map((department) => (
				<div key={department?.id} className="mobile-card">
					<div className="flexed">
						<div>
							<b>Department's Name</b>
							<p>{department?.name || 'N/A'}</p>
						</div>
						<div className="action-con">
							<div className="dropdown-con">
								<button>
									<TableThreeDots />
								</button>

								<ul>
									<li tabIndex={0}>
										<Link to={Approutes.institution_admin.use_deparment(department?.id)}>View</Link>
									</li>
									<li tabIndex={0} onClick={() => handleChangeHead(department?.id)}>
										{department?.head ? 'Change' : 'Assign'} department head
									</li>
									<li
										tabIndex={0}
										onClick={() => {
											if (isActivating || isDeactivating) return;

											department?.is_active ? handleDeactivateDepartment(department?.id) : handleActivateDepartment(department?.id);
										}}
									>
										{isActivating || isDeactivating ? 'Loading...' : department?.is_active ? 'Deactivate department' : 'Reactivate department'}
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>Department's Number</b>
							<p>{department?.phone || 'N/A'}</p>
						</div>
						<div>
							<b>Department Head</b>
							<p>{department?.head ? department?.head : 'N/A'}</p>
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>Date Created</b>
							<p>{getDateTime(department?.created_at, 'mm dd yyyy') || 'N/A'}</p>
						</div>
						<div>
							<b>Status</b>
							<p>{department?.is_active ? 'Active' : 'Deactivated'}</p>
						</div>
					</div>
				</div>
			))}
		</Container>
	);
};
export default DepartmentTable;
