import { useEffect, useState } from 'react';
import { Button, Switch } from '../../../../ui';
import { Container } from './styles';
import { useDecodedUser, useGetUserById, useNotify, usePatchUser } from '../../../../hooks';
import { handleApiError } from '../../../../utilities';
import { useQueryClient } from '@tanstack/react-query';

const Notifications = () => {
	const [formData, setFormData] = useState({
		onUpdateEmail: true,
		onUpdatePush: true,

		onBillingEmail: true,
		onBillingPush: true,

		newsletterEmail: true,
		newsletterPush: true,

		tipsEmail: true,
		tipsPush: true,

		remindersEmail: true,
		remindersPush: true,
	});

	const notify = useNotify();
	const user = useDecodedUser();
	const queryClient = useQueryClient();
	const { data: configDetails } = useGetUserById({ id: user?.user_id });
	const { mutate: patchUser, isLoading } = usePatchUser();

	const handleSave = () => {
		patchUser(
			{
				all_updates_activity_notif: formData.onUpdateEmail,
				billing_sub_notif: formData.onBillingEmail,
				newsletter_notif: formData.newsletterEmail,
				tips_tutorial_notif: formData.tipsEmail,
				reminders_notif: formData.remindersEmail,
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries(['user-id']);
					queryClient.invalidateQueries(['user']);
					notify({ message: "You're updates have been saved", status: 'success', toastOptions: { toastId: 'update_notification_success' } });
				},
				onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'update_notification_error' } }),
			}
		);
	};

	useEffect(() => {
		setFormData((prev) => ({
			...prev,
			onUpdateEmail: formData.onBillingEmail && formData.newsletterEmail && formData.tipsEmail && formData.remindersEmail ? true : false,
		}));
	}, [formData.onBillingEmail, formData.newsletterEmail, formData.tipsEmail, formData.remindersEmail]);

	useEffect(() => {
		if (!configDetails) return;

		setFormData((prev) => ({
			...prev,
			onUpdateEmail: configDetails?.all_updates_activity_notif ?? true,
			onBillingEmail: configDetails?.billing_sub_notif ?? true,
			newsletterEmail: configDetails?.newsletter_notif ?? true,
			tipsEmail: configDetails?.tips_tutorial_notif ?? true,
			remindersEmail: configDetails?.reminders_notif ?? true,
		}));
	}, [configDetails]);

	return (
		<Container>
			<div>
				<h4>Email notification</h4>

				<div>
					<div>
						<b>Notify on all updates and activity</b>
						<span>You will be notified when some share a report or invites you to a project</span>
					</div>
					<Switch
						active={formData.onUpdateEmail}
						onClick={() => {
							setFormData((prev) => ({
								...prev,
								onUpdateEmail: !prev.onUpdateEmail,
								onBillingEmail: !prev.onUpdateEmail ? true : false,
								newsletterEmail: !prev.onUpdateEmail ? true : false,
								tipsEmail: !prev.onUpdateEmail ? true : false,
								remindersEmail: !prev.onUpdateEmail ? true : false,
							}));
							setFormData((prev) => ({ ...prev, onUpdateEmail: !prev.onUpdateEmail }));
						}}
					/>
				</div>
				<div>
					<div>
						<b>Billing and subscription</b>
						<span>Get notified about issues regarding your usage or billing</span>
					</div>
					<Switch active={formData.onBillingEmail} onClick={() => setFormData((prev) => ({ ...prev, onBillingEmail: !prev.onBillingEmail }))} />
				</div>
				<div>
					<div>
						<b>Newsletter</b>
						<span>Get notified about issues regarding what the company is doing.</span>
					</div>
					<Switch
						active={formData.newsletterEmail}
						onClick={() => setFormData((prev) => ({ ...prev, newsletterEmail: !prev.newsletterEmail }))}
					/>
				</div>
				<div>
					<div>
						<b>Tips and tutorials</b>
						<span>Tips on getting more out of Descinder</span>
					</div>
					<Switch active={formData.tipsEmail} onClick={() => setFormData((prev) => ({ ...prev, tipsEmail: !prev.tipsEmail }))} />
				</div>
				<div>
					<div>
						<b>Reminders</b>
						<span>Notifications to remind you of updates you might have missed</span>
					</div>
					<Switch active={formData.remindersEmail} onClick={() => setFormData((prev) => ({ ...prev, remindersEmail: !prev.remindersEmail }))} />
				</div>

				<Button onClick={handleSave} loading={isLoading}>
					Save Changes
				</Button>
			</div>

			{/* <div>
				<h4>Push notification</h4>

				<div>
					<div>
						<b>Notify on all updates and activity</b>
						<span>You will be notified when some share a report or invites you to a project</span>
					</div>
					<Switch active={formData.onUpdatePush} onClick={() => setFormData((prev) => ({ ...prev, onUpdatePush: !prev.onUpdatePush }))} />
				</div>
				<div>
					<div>
						<b>Billing and subscription</b>
						<span>Get notified about issues regarding your usage or billing</span>
					</div>
					<Switch active={formData.onBillingPush} onClick={() => setFormData((prev) => ({ ...prev, onBillingPush: !prev.onBillingPush }))} />
				</div>
				<div>
					<div>
						<b>Newsletter</b>
						<span>Get notified about issues regarding what the company is doing.</span>
					</div>
					<Switch active={formData.newsletterPush} onClick={() => setFormData((prev) => ({ ...prev, newsletterPush: !prev.newsletterPush }))} />
				</div>
				<div>
					<div>
						<b>Tips and tutorials</b>
						<span>Tips on getting more out of Descinder</span>
					</div>
					<Switch active={formData.tipsPush} onClick={() => setFormData((prev) => ({ ...prev, tipsPush: !prev.tipsPush }))} />
				</div>
				<div>
					<div>
						<b>Reminders</b>
						<span>Notifications to remind you of updates you might have missed</span>
					</div>
					<Switch active={formData.remindersPush} onClick={() => setFormData((prev) => ({ ...prev, remindersPush: !prev.remindersPush }))} />
				</div>
				<Button>Save Changes</Button>
			</div> */}
		</Container>
	);
};
export default Notifications;
