import { useRef, useState } from 'react';
import { Container } from './styles';
import { Loader, Pagination, PalletList, SearchBar } from '../../../../ui';
import { useGetMyInstitutionSupervisorStats, useGetMyInstitutionSupervisorStudents } from '../../../../hooks';
import { DashboardAmountPaid, DashboardAmountRefunded, DashboardBusiness, DashboardInterns, ProjectEmpty } from '../../../../assets/svgs';
import { useCallback } from 'react';
import { FilterValue, GeneralChangeEventType } from '../../../../types';
import { statisticsFilters } from '../../../../constants';
import { ActiveInterns } from '../../components';

type ControlType = 'interns' | 'logs';
// type SubControlType = 'active' | 'pending';

const InstitutionSupervisorDashboard = () => {
	const pageSize = useRef(6);
	const [page, setPage] = useState(1);
	const [formData, setFormData] = useState({
		search: '',
		filter_stats: (() => {
			let state: FilterValue = {};
			statisticsFilters.forEach((option) => (state[option?.name] = { value: '', queryKey: option?.queryKey }));

			return state;
		})(),
	});
	const [control, setControl] = useState<ControlType>('interns');
	// const [subControl, setSubControl] = useState<SubControlType>('active');

	const { data: stats, isLoading: statsLoading } = useGetMyInstitutionSupervisorStats();
	const {
		data: activeInterns,
		isLoading: isLoadingActiveInterns,
		isError: isActiveInternsError,
	} = useGetMyInstitutionSupervisorStudents({ page, page_size: pageSize.current, search: formData.search });

	const isFetchingData = control === 'interns' ? isLoadingActiveInterns : false;
	const isDataError = control === 'interns' ? isActiveInternsError : false;

	const result = control === 'interns' ? activeInterns : { total: 0, results: [] };

	const statsData = [
		{
			title: 'Interns',
			body: stats?.data?.students || 0,
			icon: <DashboardBusiness />,
		},
		{
			title: 'Invites sent',
			body: stats?.data?.invitations || 0,
			icon: <DashboardInterns />,
		},

		{
			title: 'Approved Profiles',
			body: stats?.data?.approved || 0,
			icon: <DashboardAmountPaid />,
		},
		{
			title: 'Pending Approvals',
			body: stats?.data?.pending || 0,
			icon: <DashboardAmountRefunded />,
		},
	];

	const handleChange: GeneralChangeEventType = useCallback((event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name as string]: value }));
	}, []);
	const handleControl = (value: ControlType) => {
		setPage(1);
		setControl(value);

		handleChange(null, 'search', '');
	};
	// const handleSubControl = (value: SubControlType) => {
	// 	setPage(1);
	// 	setSubControl(value);

	// 	handleChange(null, 'search', '');
	// };

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Dashboard</h4>
				</div>

				{/* <Filter name="filter_stats" onChange={handleChange} value={formData.filter_stats} options={[]} /> */}
			</div>

			<PalletList loading={statsLoading} data={statsData} />

			<div className="table-header">
				<div className="controls">
					<button className={control === 'interns' ? 'active' : ''} onClick={() => handleControl('interns')}>
						Interns
					</button>
					<button className={control === 'logs' ? 'active' : ''} onClick={() => handleControl('logs')}>
						Logs
					</button>
				</div>

				<div className="filter-con">
					<SearchBar name="search" placeholder={`Search through ${control}`} handleChange={handleChange} value={formData.search} />
				</div>
			</div>
			{/* <div className="controls">
				<button className={subControl === 'active' ? 'active' : ''} onClick={() => handleSubControl('active')}>
					Active
				</button>
				<button className={subControl === 'pending' ? 'active' : ''} onClick={() => handleSubControl('pending')}>
					Pending
				</button>
			</div>
			<br /> */}

			{isFetchingData ? (
				<center>
					<Loader />
				</center>
			) : isDataError ? (
				<center>
					<div>
						<ProjectEmpty />
						<p className="error-message">Unable to fetch {control} at the moment</p>
					</div>
				</center>
			) : !result?.results || result?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p className="error-message">No {control} to display</p>
				</div>
			) : // TOBEREMOVED
			control === 'interns' ? (
				<ActiveInterns page={page} interns={activeInterns?.results} />
			) : control === 'logs' ? null : null}

			<Pagination page={page} setPage={setPage} maxPages={Math.ceil((result?.total || 0) / pageSize.current)} disabled={isFetchingData} />
		</Container>
	);
};
export default InstitutionSupervisorDashboard;
