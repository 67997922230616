import { useMutation, useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';
import { useNotify } from './useNotify';
import { handleApiError } from '../utilities';

export const useGetProjects = ({ page = 1, page_size = 5, status = '', search = '' }) => {
	const notify = useNotify();
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(`${Appurls.project.index}?page=${page}&page_size=${page_size}&search=${search}${status ? `&status=${status}` : ''}`)
				.then((response) => response?.data),
		queryKey: ['projects', page, page_size, status, search],
		onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'projects_fetch' } }),
	});
};

export const useGetProject = ({ id }) => {
	const notify = useNotify();
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () => (id ? axiosPrivate.get(Appurls.project.single_project(id)).then((response) => response?.data) : {}),
		queryKey: ['project', id],
		onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'project_fetch' } }),
	});
};

export const usePatchProject = ({ projectId, headers }) => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation((data) => axiosPrivate.patch(Appurls.project.single_project(projectId), data, headers ? { headers } : {}));
};

export const useInviteSupervisor = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation(({ projectId, data }) => axiosPrivate.post(Appurls.project.invite_supervisor(projectId), data));
};

export const useGetSupervisors = ({ page = 1, page_size = 5, search = '' }) => {
	const notify = useNotify();
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(`${Appurls.project.get_supervisors}?page=${page}&page_size=${page_size}&search=${search}`)
				.then((response) => response?.data),
		queryKey: ['supervisors', { page, page_size, search }],
		onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'supervisors_fetch' } }),
	});
};

export const useMoveResourceToProject = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation(({ projectId, data }) => axiosPrivate.post(Appurls.project.move_resource(projectId), data));
};
