import { useState } from 'react';
import { useGetStartupsIAmWith, useInternRaiseConcern, useNotify } from '../../../../hooks';
import { Container } from './styles';
import { CloseModal } from '../../../../assets/svgs';
import { Button, InputGroup, SelectGroup } from '../../../../ui';
import { handleApiError } from '../../../../utilities';
import { INTERN_CONCERNS } from '../../../../constants';

const ReportStartup = ({ handleClose }) => {
	const notify = useNotify();
	const [formData, setFormData] = useState({
		startup: '',
		issue: '',
		more_info: '',
	});

	const { data: startups, isLoading } = useGetStartupsIAmWith();
	const { mutate: raiseConcern, isLoading: isSubmitting } = useInternRaiseConcern();

	const handleChange = (event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		raiseConcern(
			{
				concern_type: formData.issue,
				description: formData.more_info,
				raised_against: startups?.results?.find((startup) => startup?.name === formData.startup)?.startup,
			},
			{
				onSuccess: () => {
					notify({
						message: 'Report has been submitted',
						status: 'success',
						toastOptions: {
							toastId: 'concern_success',
						},
					});
					handleClose();
				},
				onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'concern_error' } }),
			}
		);
	};

	return (
		<Container>
			<form onSubmit={handleSubmit}>
				<CloseModal tabIndex={0} onClick={handleClose} />

				<h2>Raise Concerns</h2>
				<p>Kindly drop information to raise a complaint or dispute</p>

				<SelectGroup
					label="Who's the concern against"
					name="startup"
					onChange={handleChange}
					placeholder="Select the business"
					value={formData.startup}
					options={startups?.results?.map((startup) => startup?.name)}
					emptyOptionsMessage="You aren't yet in a project"
					required
					loading={isLoading}
				/>
				<br />

				<SelectGroup
					label="Concern Type"
					name="issue"
					onChange={handleChange}
					placeholder="Select type of concern"
					value={formData.issue}
					options={INTERN_CONCERNS}
					required
				/>
				<br />

				<InputGroup
					label="More Information"
					variant="textarea"
					name="more_info"
					onChange={handleChange}
					placeholder="Enter More Information about the  dispute or complaint"
					value={formData.more_info}
				/>

				<Button type="submit" loading={isSubmitting}>
					Send Concerns
				</Button>
			</form>
		</Container>
	);
};
export default ReportStartup;
