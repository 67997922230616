import { useEffect, useState } from 'react';
import { useAuth, useDecodedUser } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { Approutes, LOCAL_STORAGE_KEYS } from '../../constants';
import {
	isIntern,
	isInternSupervisor,
	isStartUp,
	isAdmin,
	isInstitutionAdmin,
	isInstitutionDepartmentHead,
	isInstitutionSupervisor,
} from '../../utilities';
import { SomethingWentWrong } from '../ErrorPages';

const Home = () => {
	const user = useDecodedUser();
	const navigate = useNavigate();
	const { auth } = useAuth();
	const [error, setError] = useState(false);

	useEffect(() => {
		if (!(auth?.access || localStorage.getItem(LOCAL_STORAGE_KEYS.access_key))) {
			return navigate(`${Approutes.login}?next=/`);
		} else if (isInternSupervisor(user)) {
			navigate(Approutes.supervisor.resources, { replace: true });
		} else if (isStartUp(user)) {
			if (user?.has_created_project) {
				navigate(Approutes.startup.projects.index, { replace: true });
			} else {
				navigate(Approutes.welcome, { replace: true });
			}
		} else if (isIntern(user)) {
			if (user?.has_provided_documents && user?.is_intern_profile_approved === true && user?.intern_assigned_to_project === true) {
				navigate(Approutes.intern.tasks.overview, { replace: true });
			} else {
				navigate(Approutes.welcome, { replace: true });
			}
		} else if (isAdmin(user)) {
			navigate(Approutes.dashboard.index, { replace: true });
		} else if (isInstitutionAdmin(user)) {
			navigate(Approutes.institution_admin.index, { replace: true });
		} else if (isInstitutionDepartmentHead(user)) {
			navigate(Approutes.institution_department_head.index, { replace: true });
		} else if (isInstitutionSupervisor(user)) {
			navigate(Approutes.institution_supervisor.index, { replace: true });
		} else {
			setError(true);
		}
	}, [user, auth, navigate]);

	if (error) return <SomethingWentWrong />;

	return <></>;
};

export default Home;
