import styled from 'styled-components';

export const Container = styled.div`
	border-radius: 0.5rem;
	border: 2px solid rgba(221, 224, 226, 0.33);
	background: rgba(249, 249, 249, 0.9);
	box-shadow: -9px 19px 40px 0px rgba(0, 0, 0, 0.02);
	padding: 1.87rem 1.5rem;
	padding: clamp(1.25rem, 0.9056rem + 1.1022vw, 1.87rem) clamp(1.25rem, 1.1111rem + 0.4444vw, 1.5rem);
	text-align: center;
	cursor: pointer;

	&.active,
	:hover {
		border: 2px solid #4060ea;
	}

	b {
		color: #4060ea;
		font-size: 1.125rem;
		font-size: clamp(1rem, 0.9306rem + 0.2222vw, 1.125rem);
		font-weight: 700;
		text-transform: uppercase;
	}
	p {
		color: #787878;
		font-size: 0.875rem;
		font-size: clamp(0.75rem, 0.6806rem + 0.2222vw, 0.875rem);
		line-height: 130%;
		margin-top: 0.25rem;
	}
`;
