import { useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';

export const useGetInstitutionAdminDashboardStats = () => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () => axiosPrivate.get(Appurls.institution_admin.stats.dashboard).then((response) => response.data),
		queryKey: ['institution-dashboard-stats'],
	});
};

export const useGetInstitutionAdminDepartmentsStats = () => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () => axiosPrivate.get(Appurls.institution_admin.stats.departments).then((response) => response.data),
		queryKey: ['institution-departments-stats'],
	});
};

export const useGetInstitutionAdminDepartmentStats = ({ departmentId }: { departmentId: string }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () => axiosPrivate.get(Appurls.institution_admin.stats.department(departmentId)).then((response) => response.data),
		queryKey: ['institution-department-stats', departmentId],
	});
};
