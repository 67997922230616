import { useMutation, useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { useDecodedUser } from './useDecodedUser';
import { Appurls } from '../constants';
import { handleApiError, isIntern, isStartUp } from '../utilities';
import { useNotify } from './useNotify';

export const useSubscriptionPayment = () => {
	const user = useDecodedUser();
	const axiosPrivate = useAxiosPrivate();

	return useMutation({
		mutationFn: (data) =>
			axiosPrivate.post(isIntern(user) ? Appurls.payment.intern_payment : isStartUp(user) ? Appurls.payment.startup_payment : null, data),
	});
};

export const useGetPayment = ({ page = 1, page_size = 5, search = '' }) => {
	const axiosPrivate = useAxiosPrivate();
	const notify = useNotify();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(`${Appurls.payment.get_payments}?page=${page}&page_size=${page_size}&search=${search}`)
				.then((response) => response?.data),
		queryKey: ['payments', { page, page_size, search }],
		onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'payment_fetch_error' } }),
	});
};
