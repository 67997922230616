import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useDecodedUser } from '../../hooks';
import { isInstitutionAdmin, isIntern, isStartUp } from '../../utilities';
import { Approutes } from '../../constants';

const RequireApprovedProfile = () => {
	const location = useLocation();
	const user = useDecodedUser();

	if (
		location.pathname !== Approutes.welcome &&
		!location.pathname.startsWith(Approutes.settings.index) &&
		!location.pathname.startsWith(Approutes.profile) &&
		((isStartUp(user) && (user?.startup_profile_status === 'DECLINED' || user?.startup_profile_status === 'PENDING')) ||
			(isIntern(user) && (user?.intern_profile_status === 'DECLINED' || user?.intern_profile_status === 'PENDING')) ||
			(isInstitutionAdmin(user) &&
				(user?.institution_admin_profile_status === 'DECLINED' || user?.institution_admin_profile_status === 'PENDING')))
	) {
		return <Navigate to={Approutes.welcome} state={{ from: location }} />;
	}

	return <Outlet />;
};
export default RequireApprovedProfile;
