import { Button, Loader, Pagination } from '../../ui';
import { ButtonPlus, EyeOpen, ProjectEmpty } from '../../assets/svgs';
import { useState } from 'react';
import { Container } from './styles';
import { useGetApplicantLogs, useGetProject } from '../../hooks';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getDateTime } from '../../utilities';
import { LogSummary, LogTimesheet } from '../../components';

const Timesheet = () => {
	const pageSize = useRef(5);
	const [logState, setLogState] = useState({
		viewLogDetails: false,
		submitLog: false,
		log: {},
	});
	const { projectId } = useParams();
	const [page, setPage] = useState(1);

	const { data: project } = useGetProject({ id: projectId });
	const { data: timesheets, isLoading, isError, refetch: refetchLogs } = useGetApplicantLogs({ projectId, page, page_size: 5 });

	const handleViewLog = (log) => {
		setLogState({
			viewLogDetails: true,
			submitLog: false,
			log,
		});
	};
	const handleNewLog = () => {
		setLogState({
			viewLogDetails: false,
			submitLog: true,
			log: {},
		});
	};

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Timesheet {project?.name ? `- ${project?.name}` : ''}</h4>
					<p>View and get more information about your logs</p>
				</div>

				{/* <Button variant="secondary" className="export">
					Export Report
				</Button> */}
				<Button onClick={handleNewLog}>
					<ButtonPlus />
					New Report
				</Button>
			</div>

			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>Unable to fetch available logs at the moment</p>
				</div>
			) : !timesheets?.results || timesheets?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>You haven't reported any logs {projectId ? 'for this project' : ''}</p>
				</div>
			) : (
				<>
					<div className="table-container">
						<table>
							<thead>
								<tr>
									<th>S/N</th>
									<th>Log Name</th>
									<th>Key Achievement</th>
									<th>Report Date </th>
									<th>Task</th>
									<th>Task Status</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{timesheets?.results?.map((log, index) => (
									<tr key={log?.id}>
										<td>{index + 1}</td>
										<td>{log?.log_name}</td>
										<td>{log?.achievement}</td>
										<td>{getDateTime(log?.created_at, 'mm dd yyyy')}</td>
										<td>{log?.related_task?.name}</td>
										<td className={log?.related_task?.is_completed ? 'completed' : 'active'}>
											{log?.related_task?.is_completed ? 'Completed' : 'Active'}
										</td>
										<td>
											<EyeOpen onClick={() => handleViewLog(log)} />
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{timesheets?.results?.map((log) => (
						<div className="mobile-card" key={log?.id}>
							<div className="flexed">
								<div>
									<b>Log Name</b>
									<p>{log?.log_name}</p>
								</div>
								<div className="action-con" onClick={() => handleViewLog(log)}>
									<EyeOpen />
								</div>
							</div>
							<div>
								<b>Key Achievement</b>
								<p>{log?.achievement}</p>
							</div>
							<div>
								<b>Report Date</b>
								<p>{getDateTime(log?.created_at, 'mm dd yyyy')}</p>
							</div>
							<div className="flexed">
								<div>
									<b>Task</b>
									<p>{log?.related_task?.name}</p>
								</div>
								<div>
									<b>Status</b>
									<p className={log?.related_task?.is_completed ? 'completed' : 'active'}>
										{log?.related_task?.is_completed ? 'Completed' : 'Active'}
									</p>
								</div>
							</div>
						</div>
					))}

					<Pagination page={page} setPage={setPage} maxPages={Math.ceil(timesheets?.total / pageSize.current)} disabled={isLoading} />
				</>
			)}

			{logState.viewLogDetails ? <LogSummary log={logState.log} handleClose={() => setLogState({ viewLogDetails: false, log: {} })} /> : null}
			{logState.submitLog ? (
				<LogTimesheet
					handleClose={() => {
						setLogState({ submitLog: false });
						refetchLogs();
					}}
				/>
			) : null}
		</Container>
	);
};

export default Timesheet;
