import styled from 'styled-components';
import { StyledButton } from '../../../ui/Button/styles';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	inset: 0;
	z-index: 99;

	> div {
		border-radius: 0.25rem;
		background: #fff;
		padding: 2rem;
		padding: 2rem clamp(1.5rem, -0.4444rem + 6.2222vw, 5rem) 2rem;
		padding-top: 0;
		width: 57rem;
		max-width: calc(100vw - 2rem);
		max-height: calc(100vh - 2rem);
		overflow: auto;

		.header {
			position: sticky;
			top: 0;
			background-color: #fff;
			z-index: 2;
			padding-top: 2rem;
			margin-bottom: 1rem;

			svg {
				margin-left: auto;
				display: block;
				cursor: pointer;
				width: 1.25rem;
				width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);

				:active {
					outline: none;
				}
			}
			h2 {
				text-align: center;
				font-size: 2rem;
				font-size: clamp(1.75rem, 1.6111rem + 0.4444vw, 2rem);
				color: #070d19;
			}
			h2 + p {
				color: #a8a8a8;
				font-size: 1rem;
				font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
				line-height: 120%;
				margin: 0.5rem 0 1.5rem;
				text-align: center;

				span,
				a {
					color: #4060ea;
					font-weight: 600;
				}
			}
			hr {
				border-top: 1px solid #ebebeb;
			}
		}

		.back-btn {
			margin-bottom: 2rem;
			color: #252b33;
			font-size: 0.9375rem;
			font-weight: 600;
			border-radius: 0.25rem;
			border: 1px solid #ebebeb;
			background: #fff;
			padding: 1rem 1.5rem;
			display: inline-flex;
			margin-bottom: 1.3rem;
			align-items: center;
			gap: 1rem;

			svg {
				width: 0.8125rem;
				transform: rotate(90deg);
			}
		}

		.body {
			line-height: 1.8;
		}

		ol {
			padding-left: 1.325rem;
		}

		${StyledButton} {
			width: 100%;
			margin: 3rem auto 0;
			justify-content: center;
		}
	}
`;
