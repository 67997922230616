import { useMutation } from '@tanstack/react-query';
import { FileDoc } from '../../assets/svgs';
import { useAxiosPrivate, useDecodedUser, useGeneralStore, useNotify } from '../../hooks';
import { Button, FileGroup, InputGroup, SelectGroup } from '../../ui';
import { Appurls, PROJECT_DURATIONS, APPLICANT_SKILLS, COMPANY_SECTORS } from '../../constants';
import { handleApiError, hasStartupPaid } from '../../utilities';

const ProjectDetails = ({ setStep, formData, handleChange }) => {
	const notify = useNotify();
	const user = useDecodedUser();
	const axiosPrivate = useAxiosPrivate();
	const { store } = useGeneralStore();

	const { mutate: createProject, isLoading } = useMutation((data) =>
		axiosPrivate.post(Appurls.project.index, data, { headers: { 'Content-Type': 'multipart/form-data' } })
	);

	const handleSubmit = (event) => {
		event.preventDefault();

		const axiosFormData = new FormData();

		axiosFormData.append('name', formData.project_name);
		axiosFormData.append('start_date', formData.project_start_date);
		axiosFormData.append('timeline', formData.timeline);
		axiosFormData.append('project_sector', formData.sector);
		axiosFormData.append('description', formData.description);
		axiosFormData.append('resource_experience_level', formData.resource_experience_level);
		for (let i = 0; i < formData.skill_tag.length; i++) {
			axiosFormData.append('resource_skills', formData.skill_tag[i]);
		}
		axiosFormData.append('status', hasStartupPaid(user) || store?.has_paid === true ? 'in-progress' : 'pending');
		axiosFormData.append('requirement_docs', formData.required_document.file);

		createProject(axiosFormData, {
			onSuccess: (data) => {
				handleChange(null, 'project_id', data.data?.id);
				notify({ message: 'Project created successfully', status: 'success', toastOptions: { toastId: 'project_create_success' } });
				setStep(2);
			},
			onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'project_create_error' } }),
		});
	};

	return (
		<form onSubmit={handleSubmit}>
			<h2>Project Details</h2>
			<p>Provide information to know your educational background & Your Exprience</p>

			<div>
				<InputGroup
					label="Project Name"
					name="project_name"
					onChange={handleChange}
					placeholder="Enter Project Name"
					value={formData.project_name}
					required
				/>
				<InputGroup
					label="Project Start Date"
					type="date"
					name="project_start_date"
					onChange={handleChange}
					value={formData.project_start_date}
					min={new Date().toISOString().split('T')[0]}
					required
				/>
				<SelectGroup
					label="Project Sector"
					name="sector"
					onChange={handleChange}
					placeholder="Select Project Sector"
					value={formData.sector}
					options={COMPANY_SECTORS}
					required
				/>
				<SelectGroup
					label="Timeline"
					name="timeline"
					onChange={handleChange}
					placeholder="Select Project Duration"
					value={formData.timeline}
					options={PROJECT_DURATIONS}
					required
				/>
				<FileGroup
					label="Requirement Document"
					name="required_document"
					onChange={handleChange}
					helpTextOne="Choose BRD/PRD"
					helpTextTwo="Doc, PDF Max size of 4MB"
					accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
					maxSize={4}
					value={formData.required_document}
					icon={FileDoc}
					required
				/>
				<InputGroup
					label="Brief Project Description"
					variant="textarea"
					name="description"
					onChange={handleChange}
					placeholder="Enter Brief description about your company"
					value={formData.description}
					required
				/>
				<SelectGroup
					label="Resource Experience level (Optional)"
					name="resource_experience_level"
					onChange={handleChange}
					placeholder="Select Project Duration"
					value={formData.resource_experience_level}
					options={['Beginner', 'Intermediate', 'Expert']}
				/>
				<SelectGroup
					label="Resource skill tag for project"
					name="skill_tag"
					onChange={handleChange}
					placeholder="Select Resource Skill tags"
					value={formData.skill_tag}
					options={APPLICANT_SKILLS}
					required
					variant="multiple"
				/>
			</div>

			<div className="btn-group">
				<Button style={{ gridColumn: '1/3' }} loading={isLoading}>
					Next
				</Button>
			</div>
		</form>
	);
};

export default ProjectDetails;
