import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { useDecodedUser } from '../../hooks';
import { PageNotFound } from '../../views';
import { Approutes, SESSION_STORAGE_KEYS, queryStrings } from '../../constants';
import { ApplicantDetails } from '../Modals';
import { getPreviousSearchParams, hasStartupPaid, isStartUp } from '../../utilities';
import { useEffect } from 'react';

const StartupOnly = () => {
	const user = useDecodedUser();
	const [searchParams, setSearchParams] = useSearchParams();
	const { pathname } = useLocation();

	useEffect(() => {
		const ALLOWED_ROUTES = [Approutes.startup.projects.index, Approutes.startup.early_starters];

		if (
			hasStartupPaid(user) ||
			sessionStorage.getItem(SESSION_STORAGE_KEYS.has_closed_subscription) ||
			!ALLOWED_ROUTES.includes(pathname) ||
			user?.startup_profile_status !== 'APPROVED'
		)
			return;

		let previousParams = getPreviousSearchParams(searchParams);

		previousParams = { ...previousParams, [queryStrings.subscription_plan]: 'open' };
		setSearchParams(previousParams, { replace: true });
	}, [user, searchParams, setSearchParams, pathname]);

	return isStartUp(user) ? (
		<>
			<Outlet />

			{searchParams.get(queryStrings.applicantId) ? <ApplicantDetails /> : null}
		</>
	) : (
		<PageNotFound />
	);
};

export default StartupOnly;
