import { Link } from 'react-router-dom';
import { Approutes } from '../../constants';
import { Button, Loader, Pagination, SearchBar } from '../../ui';
import { ButtonPlus, ButtonView, ProjectDate, ProjectEmpty, TaskCheckmark } from '../../assets/svgs';
import { useState } from 'react';
import { Container, StyledProject } from './styles';
import { useDecodedUser, useGetProjects } from '../../hooks';
import { getDateTime, hasStartupPaid } from '../../utilities';
import { useRef } from 'react';
import { useCallback } from 'react';

const Project = () => {
	const user = useDecodedUser();
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState('');
	const [projectState, setProjectState] = useState(hasStartupPaid(user) ? 'in-progress' : 'pending');
	const pageSize = useRef(5);
	const { data: projects, isLoading, isError } = useGetProjects({ page, page_size: pageSize.current, search: search, status: projectState });

	const handleChange = useCallback((_event, _name, value) => setSearch(value), []);

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Projects</h4>
					<p>Insight on all activities on the platform</p>
				</div>

				<SearchBar name="search" placeholder="Search through projects" handleChange={handleChange} />

				<Link to={Approutes.startup.projects.create_project} style={{ width: 'fit-content', display: 'block' }}>
					<Button>
						<ButtonPlus />
						New Project
					</Button>
				</Link>
			</div>
			<div className="controls">
				<button
					className={projectState === 'in-progress' ? 'active' : ''}
					onClick={() => {
						setPage(1);
						setProjectState('in-progress');
					}}
				>
					Active
				</button>
				<button
					className={projectState === 'completed' ? 'active' : ''}
					onClick={() => {
						setPage(1);
						setProjectState('completed');
					}}
				>
					Completed
				</button>
				<button
					className={projectState === 'archived' ? 'active' : ''}
					onClick={() => {
						setPage(1);
						setProjectState('archived');
					}}
				>
					Archived
				</button>
				<button
					className={projectState === 'pending' ? 'active' : ''}
					onClick={() => {
						setPage(1);
						setProjectState('pending');
					}}
				>
					Pending
				</button>
			</div>

			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>Unable to fetch available projects at the moment</p>
				</div>
			) : !projects?.results || projects?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>
						No Data for <span>{projectState === 'in-progress' ? 'active' : projectState}</span> Projects
					</p>
				</div>
			) : (
				<>
					<div className="projects">
						{projects?.results?.map((project) => (
							<StyledProject key={project?.id} $percent={parseFloat(project?.completion_percent)} $sector="E-Commerce">
								<div>
									<b>{project?.name}</b> <span>{project?.project_sector}</span>
								</div>
								<div>
									<ProjectDate />
									<div>
										<b>{getDateTime(project?.start_date)}</b>
										<span>Start Date</span>
									</div>
								</div>
								<div>
									<ProjectDate />
									<div>
										<b>{project?.timeline}</b>
										<span>Duration</span>
									</div>
								</div>
								<div>
									<span>
										<TaskCheckmark /> {project?.total_task_count}
									</span>
									<span>
										<span>
											<span></span>
										</span>{' '}
										{parseInt(project?.completion_percent)}%
									</span>
								</div>
								<div>
									<Link to={Approutes.startup.projects.use_project_details(project?.id)}>
										<Button>
											<ButtonView /> View
										</Button>
									</Link>
								</div>
							</StyledProject>
						))}
					</div>

					<Pagination page={page} setPage={setPage} maxPages={Math.ceil(projects?.total / pageSize.current)} disabled={isLoading} />
				</>
			)}
		</Container>
	);
};

export default Project;
