import { useState } from 'react';
import { useDecodedUser, useGetExtraApplicants, useGetProjects, useInviteExtraApplicant, useNotify } from '../../hooks';
import { filterApplicants, queryStrings } from '../../constants';
import { Button, Loader, Pagination, SearchBar, SelectGroup, SelectedApplicants, Tag } from '../../ui';
import { ApplicantCard, Filter } from '../../components';
import { useRef } from 'react';
import { ButtonPlus, ProjectEmpty } from '../../assets/svgs';
import { Container } from './styles';
import { useCallback } from 'react';
import { getPreviousSearchParams, handleApiError, hasStartupPaid } from '../../utilities';
import { useSearchParams } from 'react-router-dom';

const ExtraPicks = () => {
	const notify = useNotify();
	const user = useDecodedUser();
	const [searchParams, setSearchParams] = useSearchParams();
	const pageSize = useRef(8);

	const [page, setPage] = useState(1);
	const [formData, setFormData] = useState({
		selected_applicants: [],
		search: '',
		project: '',
		filter: (() => {
			let state = {};
			filterApplicants.forEach((option) => (state[option?.name] = { value: '', queryKey: option?.queryKey }));

			return state;
		})(),
	});

	const { data: projects, isLoading: isProjectsLoading } = useGetProjects({ page: 1, page_size: 2, search: '', status: 'in-progress' });
	const selectedProject = projects?.results?.find((project) => project?.name === formData.project) || {};

	const {
		data: applicants,
		isLoading,
		isError,
		refetch,
	} = useGetExtraApplicants({
		page,
		page_size: pageSize.current,
		search: formData.search,
		extraQuery: Object.values(formData.filter).reduce(
			(prev, current) => (current?.value ? `${prev}&${current?.queryKey}=${current?.value}` : prev),
			''
		),
	});
	const { mutate: inviteExtraApplicant, isLoading: isInviting } = useInviteExtraApplicant();

	const handleChange = useCallback((event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	}, []);
	const handleInviteApplicant = () => {
		if (!hasStartupPaid(user)) {
			let previousParams = getPreviousSearchParams(searchParams);

			previousParams = { ...previousParams, [queryStrings.subscription_plan]: 'open' };
			setSearchParams(previousParams, { replace: true });

			return;
		}
		if (formData?.selected_applicants.length === 0) {
			notify({
				message: 'You need to select at least one applicant before proceeding',
				toastOptions: { toastId: 'invite_extra_applicant_info' },
			});
		} else if (!selectedProject?.id) {
			notify({ message: 'You need to select a project before proceeding', toastOptions: { toastId: 'invite_extra_applicant_info' } });
		} else {
			inviteExtraApplicant(
				{ id: selectedProject?.id, data: { resources: formData.selected_applicants } },
				{
					onSuccess: () => {
						refetch();
						handleChange(null, 'selected_applicants', []);
						notify({
							message:
								formData.selected_applicants.length > 1 ? 'Applicants have been added to your project' : 'Applicant has been added to your project',
							status: 'success',
							toastOptions: { toastId: 'add_extra_applicant_success' },
						});
					},
					onError: (error) =>
						notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'add_extra_applicant_error' } }),
				}
			);
		}
	};

	return (
		<Container>
			<div className="toast">Add interns for free without affecting your resource limit, as long as you assign them tasks promptly.</div>
			<div className="header">
				<div>
					<h4>Extra Picks</h4>
					<p>View all etxra pick that can be added to your project resources</p>
				</div>

				<SearchBar name="search" placeholder="Search through applicants" handleChange={handleChange} />

				<Filter name="filter" onChange={handleChange} value={formData.filter} options={filterApplicants} />
			</div>
			<div className="sub-heading-one">
				<SelectGroup
					name="project"
					placeholder="Select Project"
					onChange={(event, name, value) => {
						handleChange(event, name, value);
						handleChange(null, 'selected_applicants', []);
					}}
					value={formData.project}
					loading={isProjectsLoading}
					options={projects?.results?.map((project) => project?.name)}
					shouldHaveEmptyOption
					shouldFilterByDefault={false}
				/>

				<div className="invite-con">
					<SelectedApplicants applicants={formData.selected_applicants} />
					<Button disabled={isLoading} loading={isInviting} onClick={handleInviteApplicant}>
						<ButtonPlus />
						Invite Applicant(s)
					</Button>
				</div>
			</div>
			{Object.values(formData.filter).filter((val) => val?.value !== '')?.length ? (
				<div className="results-con">
					{Object.values(formData.filter).filter((val) => val?.value !== '').length || formData.search ? (
						<span>{applicants?.results?.length || 0} results found</span>
					) : null}
					{Object.values(formData.filter)
						.filter((val) => val?.value !== '')
						.map(({ value: tag }) => (
							<Tag
								key={tag}
								tag={tag}
								handleRemove={(tag) => {
									let keyToRemove = Object.keys(formData.filter)
										.map((key) => ({ key, value: formData.filter[key]?.value }))
										.find((term) => term?.value === tag)?.key;

									setFormData((prev) => ({
										...prev,
										filter: {
											...prev.filter,
											[keyToRemove]: { ...prev.filter[keyToRemove], value: '' },
										},
									}));
								}}
							/>
						))}
				</div>
			) : null}
			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<center>
					<div>
						<ProjectEmpty />
						<p className="error-message">Unable to fetch resources at the moment</p>
					</div>
				</center>
			) : !applicants?.results || applicants?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>No Applicant to Display</p>
				</div>
			) : (
				<>
					<div className="talent-list">
						{applicants?.results?.map((applicant) => (
							<ApplicantCard
								key={applicant?.id}
								applicant={applicant}
								isExtra={true}
								isAdded={formData.selected_applicants.find((selectedApp) => selectedApp === applicant?.id)}
								handleAdd={() => handleChange(null, 'selected_applicants', [...formData.selected_applicants, applicant?.id])}
								handleRemove={() =>
									handleChange(
										null,
										'selected_applicants',
										formData.selected_applicants?.filter((selectedApp) => selectedApp !== applicant?.id)
									)
								}
							/>
						))}
					</div>

					<div className="invite-con mobile">
						<SelectedApplicants applicants={formData.selected_applicants} />
						<Button disabled={isLoading} onClick={handleInviteApplicant}>
							<ButtonPlus />
							Invite Applicant(s)
						</Button>
					</div>

					<Pagination page={page} setPage={setPage} maxPages={Math.ceil(applicants?.total / pageSize.current)} disabled={isLoading} />
				</>
			)}
		</Container>
	);
};
export default ExtraPicks;
