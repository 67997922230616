import styled from 'styled-components';
import { StyledButton } from '../../../../ui/Button/styles';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	inset: 0;
	z-index: 99;

	> center {
		height: 30rem;
		display: flex;
		flex-direction: column;

		> div {
			flex: 1;
			display: grid;
			place-items: center;
		}
	}
	> div,
	> center {
		border-radius: 0.25rem;
		background: #fff;
		padding: 2rem;
		padding: 2rem clamp(1.5rem, 1.2222rem + 0.8889vw, 2rem);
		width: 37.9375rem;
		max-width: calc(100vw - 2rem);
		max-height: calc(100vh - 2rem);
		overflow: auto;

		> svg {
			margin-left: auto;
			display: block;
			cursor: pointer;
			width: 1.25rem;
			width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);
			position: sticky;
			top: 0;
			background-color: #fff;
			z-index: 2;

			:active {
				outline: none;
			}
		}
		h2 {
			text-align: center;
			font-size: 2rem;
			font-size: clamp(1.75rem, 1.6111rem + 0.4444vw, 2rem);
			margin: 0.5rem 0 1.5rem;
			color: #070d19;
		}
		.header {
			display: flex;
			justify-content: space-between;
			padding: 1.25rem 1rem;
			border-bottom: 1px solid #ebebeb;

			> div:nth-child(1) {
				display: flex;
				gap: 0.88rem;

				.img {
					width: 3.5rem;
					height: 3.5rem;
					flex-shrink: 0;
					background-color: #d9d9d9;
					border-radius: 50%;
				}
			}
			> div:nth-child(2) {
				text-align: right;
			}

			b {
				color: #a1a0a0;
				font-size: 0.875rem;
				font-weight: 600;
				margin-bottom: 0;
			}
			p {
				color: #11071a;
				font-size: 1.5rem;
				font-weight: 700;
				font-size: clamp(1.25rem, 1.1111rem + 0.4444vw, 1.5rem);
			}
		}
		.nav {
			display: flex;
			align-items: center;
			gap: 1.5rem;
			gap: clamp(1rem, 0.1rem + 1.6vw, 1.5rem);
			border-bottom: 1px solid #ebebeb;

			button {
				display: inline-block;
				font-weight: 500;
				line-height: 120%;
				padding: 0.75rem 0.25rem;
				font-size: 0.825rem;
				background: transparent;
				color: #a1a0a0;
				border-bottom: 2px solid transparent;

				&:hover,
				&.active {
					color: #4060ea;
				}
				&.active {
					border-bottom: 2px solid #4060ea;
				}
			}
		}
		hr {
			border-top: 1px solid #ebebeb;
		}
		article {
			border-radius: 0.25rem;
			border-bottom: 1px solid #ebebeb;
			padding: 1.25rem 0;

			&:nth-last-child(2) {
				border-bottom: none;
			}

			&.flexed {
				display: flex;
				justify-content: space-between;

				> div:nth-child(2) {
					text-align: right;
				}
			}
			&.project {
				display: flex;
				justify-content: space-between;
				text-align: center;
			}

			b {
				display: block;
				min-width: max-content;
				margin-bottom: 0.325rem;
			}

			p,
			li {
				color: #070d19;
				line-height: 140%;
				font-weight: 500;
			}
			ul {
				list-style-type: disc;
				padding-left: 1rem;

				li {
					margin-bottom: 0.75rem;

					.declined {
						text-transform: capitalize;
						color: #ea2d2e !important;
					}
					.approved {
						text-transform: capitalize;
						color: #017d34 !important;
					}
					.pending {
						text-transform: capitalize;
						color: #c08030 !important;
					}

					b {
						display: inline-block;
						margin: 0;
					}
					p {
						padding: 0.5rem 0 0.25rem;
					}
					span {
						display: block;
						color: #a1a0a0;
					}
				}
			}
		}
		.btn-group {
			display: flex;
			gap: 1rem;
			margin-top: 2.5rem;

			${StyledButton} {
				flex: 1;

				:first-child {
					color: #ea2d2e;
					border-color: #ea2d2e;
				}
			}
		}
	}
`;
