import { Link } from 'react-router-dom';
import { TableThreeDots } from '../../../../../assets/svgs';
import { ActiveSupervisorType } from '../../../../../types';
import { Container } from './styles';
import { Approutes } from '../../../../../constants';

const ActiveSupervisors = ({ page, supervisors }: { page: number; supervisors: ActiveSupervisorType[] | undefined }) => {
	return (
		<Container>
			<div className="table-container" style={{ paddingBottom: (supervisors?.length || 3) < 3 ? '80px' : '' }}>
				<table>
					<thead>
						<tr>
							<th>S/N</th>
							<th>Supervisor's Name</th>
							<th>Supervisor's Email</th>
							<th>Supervisor's Number</th>
							<th>Department</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{supervisors?.map((supervisor, index) => (
							<tr key={supervisor?.id}>
								<td>{(page - 1) * 10 + index + 1}</td>
								<td>{supervisor?.dept_supervisor_name}</td>
								<td>{supervisor?.email || 'N/A'}</td>
								<td>{supervisor?.dept_supervisor_phone || 'N/A'}</td>
								<td>{supervisor?.department_name || 'N/A'}</td>
								<td>
									<div className="dropdown-con">
										<button>
											<TableThreeDots />
										</button>

										<ul className={index >= supervisors.length - 1 && index > 1 ? 'top' : ''}>
											<li tabIndex={0}>
												<Link to={Approutes.institution_admin.use_department_supervisor(supervisor.department, supervisor.id)}>View</Link>
											</li>
											{/* <li tabIndex={0}>Assign Interns</li> */}
										</ul>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{supervisors?.map((supervisor) => (
				<div key={supervisor?.id} className="mobile-card">
					<div className="flexed">
						<div>
							<b>Supervisor's Name</b>
							<p>{supervisor?.dept_supervisor_name}</p>
						</div>
						<div>
							<div className="dropdown-con">
								<button>
									<TableThreeDots />
								</button>

								<ul>
									<li tabIndex={0}>
										<Link to={Approutes.institution_admin.use_department_supervisor(supervisor.department, supervisor.id)}>View</Link>
									</li>
									{/* <li tabIndex={0}>Assign Interns</li> */}
								</ul>
							</div>
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>Supervisor's Number</b>
							<p>{supervisor?.dept_supervisor_phone || 'N/A'}</p>
						</div>
						<div>
							<b>Supervisor's Email</b>
							<p>{supervisor?.email || 'N/A'}</p>
						</div>
					</div>
					<div>
						<b>Department</b>
						<p>{supervisor?.department_name || 'N/A'}</p>
					</div>
				</div>
			))}
		</Container>
	);
};
export default ActiveSupervisors;
