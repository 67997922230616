import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';

export const useGetApplicants = ({
	page = 1,
	page_size = 10,
	search = '',
	project_startdate = '',
	project_enddate = '',
	extraQuery = '',
	enabled = true,
}) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(
					`${Appurls.user.applicants}?page=${page}&page_size=${page_size}&search=${search}${
						project_startdate ? `&project_startdate=${project_startdate}` : ''
					}${project_enddate ? `&project_enddate=${project_enddate}` : ''}${extraQuery}`
				)
				.then((response) => response?.data),
		queryKey: ['applicants', { page, page_size, search, extraQuery, project_startdate, project_enddate }],
		enabled,
	});
};
export const useGetExtraApplicants = ({ page = 1, page_size = 10, search = '', extraQuery = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(`${Appurls.user.extra_applicants}?page=${page}&page_size=${page_size}&search=${search}${extraQuery}`)
				.then((response) => response?.data),
		queryKey: ['extra-applicants', { page, page_size, search, extraQuery }],
	});
};
export const useGetApplicant = ({ applicantId, enabled = true, shouldFetchExperience = true }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQueries({
		queries: [
			{
				queryFn: () => axiosPrivate.get(Appurls.user.applicant(applicantId)).then((response) => response?.data),
				queryKey: ['applicant', applicantId],
				enabled,
			},
			{
				queryFn: () => axiosPrivate.get(Appurls.user.applicant_experience(applicantId)).then((response) => response?.data),
				queryKey: ['applicant_experience', applicantId],
				enabled: enabled && shouldFetchExperience,
			},
		],
	});
};

export const useDeleteExperience = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation(({ id }) => axiosPrivate.delete(Appurls.user.delete_applicant_experience(id)));
};

export const useInviteApplicant = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation(({ id, data }) => axiosPrivate.post(Appurls.project.add_resources(id), data));
};
export const useInviteExtraApplicant = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation(({ id, data }) => axiosPrivate.post(Appurls.project.add_extra_resources(id), data));
};
