import { useNavigate, useParams } from 'react-router-dom';
import { ChevronDown, ProjectEmpty } from '../../assets/svgs';
import { Container, Range } from './styles';
import { FileDownloadGroup, Loader, Tag } from '../../ui';
import { getDateTime } from '../../utilities';
import { Tags } from '../../ui/Tag/styles';
import { useGetProject } from '../../hooks';

const ProjectDetailsForIntern = () => {
	const navigate = useNavigate();

	const { projectId } = useParams();
	const { data: project, isLoading, isError } = useGetProject({ id: projectId });

	return (
		<Container $sector="e-commerce">
			<button onClick={() => navigate(-1)}>
				<ChevronDown /> Back to Projects
			</button>

			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>Unable to fetch project details at the moment</p>
				</div>
			) : (
				<section>
					<section>
						<article>
							<div className={'status ' + project?.status}>{project?.status === 'pending' ? 'Pending' : project?.status}</div>
							<div className="title">
								<b>{project?.name}</b> <span>{project?.project_sector}</span>
							</div>
							<p>{project?.description}</p>
						</article>

						<section className="attachments">
							<h6>Attachments</h6>

							{project?.requirement_docs.length ? (
								<FileDownloadGroup
									fileName={project?.requirement_docs[0]?.file_name}
									url={project?.requirement_docs[0]?.url}
									fileSizeInKb={project?.requirement_docs[0]?.file_size_in_kb}
								/>
							) : null}
						</section>
					</section>
					<aside>
						<h4>Project Information</h4>

						<div className="timeline">
							<div>
								<h6>Start date</h6>
								<b>{getDateTime(project?.start_date)}</b>
							</div>
							<div>
								<h6>Project duration</h6>
								<b>{project?.timeline}</b>
							</div>
						</div>
						{project?.resource_skills.length > 0 ? (
							<div className="resource-tags">
								<h6>Resource tags</h6>

								<Tags>
									{project?.resource_skills.map((tag) => (
										<Tag key={tag} tag={tag} hideClose />
									))}
								</Tags>
							</div>
						) : null}
						{/* <div className="supervisor">
							<h6>Project supervisor</h6>

							<div>
								<img src={Supervisor} alt="" /> <span>ToBeDone</span>
							</div>
						</div> */}
						<div className="completion-status">
							<h6>Project status</h6>

							<div>
								<Range $percent={parseInt(project?.completion_percent)} />
								{parseInt(project?.completion_percent)}%
							</div>
						</div>
					</aside>
				</section>
			)}
		</Container>
	);
};
export default ProjectDetailsForIntern;
