import styled from 'styled-components';
import { StyledInputGroup } from '../../../ui/InputGroup/styles';
import { StyledButton } from '../../../ui/Button/styles';
import { StyledSelectGroup } from '../../../ui/SelectGroup/styles';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	inset: 0;
	z-index: 99;

	form {
		border-radius: 0.25rem;
		background: #fff;
		padding: 2rem;
		padding: 2rem clamp(1.5rem, 1.2222rem + 0.8889vw, 2rem);
		width: 35rem;
		max-width: calc(100vw - 2rem);

		> svg {
			margin-left: auto;
			display: block;
			cursor: pointer;
			width: 1.25rem;
			width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);

			:active {
				outline: none;
			}
		}
		h2 {
			text-align: center;
			font-size: 2rem;
			font-size: clamp(1.75rem, 1.6111rem + 0.4444vw, 2rem);
			margin: 0.5rem 0 1.5rem;
			color: #070d19;
		}

		${StyledSelectGroup},
		${StyledInputGroup} {
			margin-bottom: 1rem;
		}
		${StyledButton} {
			margin-top: 2rem;
			width: 100%;
			justify-content: center;
		}
		.error {
			color: #ff7542;
			font-weight: 600;
			font-size: 0.875rem;
			margin: 0;
		}
	}
`;
