import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';
import { useContext } from 'react';
import { MessageContext } from '../context';

export const useMessage = () => useContext(MessageContext);

export const useGetConversations = ({ pageSize = 20, search = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	const getConversations = ({ pageParam = 1 }) =>
		axiosPrivate
			.get(`${Appurls.messages.conversations}?page=${pageParam}&page_size=${pageSize}&search=${search}`)
			.then((response) => response?.data);

	return useInfiniteQuery({
		queryKey: ['conversations', { pageSize, search }],
		queryFn: getConversations,
		getNextPageParam: (lastPage, allPages) => {
			return allPages?.length < Math.ceil(lastPage?.total / pageSize) ? allPages?.length + 1 : undefined;
		},
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});
};

export const useGetMessages = ({ pageSize = 15, search, room_id }) => {
	const axiosPrivate = useAxiosPrivate();

	const getMessages = ({ pageParam = 1 }) =>
		axiosPrivate
			.get(`${Appurls.messages.index}?page=${pageParam}&page_size=${pageSize}&search=${search}&room_id=${room_id}`)
			.then((response) => response?.data);

	return useInfiniteQuery({
		queryKey: ['messages', { pageSize, search, room_id }],
		queryFn: getMessages,
		getNextPageParam: (lastPage, allPages) => {
			return allPages?.length < Math.ceil(lastPage?.total / pageSize) ? allPages?.length + 1 : undefined;
		},
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		staleTime: Infinity,
	});
};

export const useMarkMessageAsRead = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation((id) => axiosPrivate.get(Appurls.messages.read_message(id)));
};

export const useSendMessage = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation((data) => axiosPrivate.post(Appurls.messages.index, data));
};

export const useCreateRoom = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation((data) => axiosPrivate.post(Appurls.messages.create_room, data));
};
