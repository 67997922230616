import { FormEvent, useRef, useState } from 'react';
import { Button, SelectGroup } from '../../../../ui';
import { CloseModal } from '../../../../assets/svgs';
import { Container } from './styles';
import { GeneralChangeEventType } from '../../../../types';
import { useQueryClient } from '@tanstack/react-query';
import { handleApiError } from '../../../../utilities';
import { AxiosError } from 'axios';
import { useChangeDepartmentHead, useGetSignedUpDepartmentHeads, useNotify } from '../../../../hooks';

type ChangeDepartmentHeadModalProp = {
	handleClose: () => void;
	departmentId: string;
};

const ChangeDepartmentHeadModal = ({ handleClose, departmentId }: ChangeDepartmentHeadModalProp) => {
	const errorRef = useRef<HTMLDivElement>(null!);
	const queryClient = useQueryClient();
	const notify = useNotify();

	const [formData, setFormData] = useState({
		dept_head_name: '',
	});
	const { data: activeDepartmentHeads, isLoading: isLoadingActiveDepartmentHeads } = useGetSignedUpDepartmentHeads({
		page: 1,
		page_size: 1000,
		enabled: true,
	});
	const { mutate: changeHead, isLoading } = useChangeDepartmentHead();

	const handleChange: GeneralChangeEventType = (event, name, value) => {
		errorRef.current.innerText = '';

		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name as string]: value }));
	};

	const onSubmit = (event: FormEvent) => {
		event.preventDefault();

		if (formData.dept_head_name.length === 0) {
			errorRef.current.innerText = 'One or more required field needs to be filled';
			return;
		}

		const deptHeadId = activeDepartmentHeads?.results?.find((head) => head.dept_head_name === formData.dept_head_name)?.id || '';

		if (!deptHeadId) {
			errorRef.current.innerText = 'Unable to submit change head at this moment';
			return;
		}

		changeHead(
			{ dept_head: deptHeadId, dept_id: departmentId },
			{
				onSuccess: () => {
					notify({
						message: 'Department head has been changed!',
						status: 'success',
						toastOptions: { toastId: 'department_head_change_success' },
					});

					queryClient.invalidateQueries(['institution-department-id']);
					handleClose();
				},
				onError: (error) =>
					notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'department_head_change_error' } }),
			}
		);
	};

	return (
		<Container>
			<form onSubmit={onSubmit}>
				<CloseModal tabIndex={0} onClick={handleClose} />
				<h2>Change Department Head</h2>
				<p>Assign new department head</p>
				<br />

				<SelectGroup
					label="Department Head's Name"
					name="dept_head_name"
					onChange={handleChange}
					options={activeDepartmentHeads?.results?.map((head) => head?.dept_head_name) || []}
					loading={isLoadingActiveDepartmentHeads}
					emptyOptionsMessage="You currently have no signed up head"
					placeholder="Enter Head's Name"
					value={formData.dept_head_name}
				/>
				<div className="error" ref={errorRef}></div>

				<Button type="submit" loading={isLoading}>
					Submit
				</Button>
			</form>
		</Container>
	);
};
export default ChangeDepartmentHeadModal;
