import { useState } from 'react';
import { CloseModal, ProjectDate, ProjectEmpty, ProjectUser, ProjectUnChecked, ProjectChecked } from '../../../assets/svgs';
import { Button, Loader } from '../../../ui';
import { Container } from './styles';
import { getDateTime } from '../../../utilities';

const ProjectSelection = ({ projects, isProjectsLoading, isProjectError, handleContinue, handleClose }) => {
	const [selectedProject, setSelectedProject] = useState({});

	return (
		<Container>
			{isProjectsLoading ? (
				<center>
					<CloseModal tabIndex={0} onClick={handleClose} />

					<div>
						<Loader />
					</div>
				</center>
			) : isProjectError ? (
				<center>
					<CloseModal tabIndex={0} onClick={handleClose} />

					<div>
						<div>
							<ProjectEmpty />
							<p>Unable to fetch projects at the moment</p>
						</div>
					</div>
				</center>
			) : (
				<div>
					<CloseModal tabIndex={0} onClick={handleClose} />
					<h2>Project Selection</h2>
					<p>Select project to add applicant(s) to</p>

					{projects?.results?.map((project) => (
						<article
							key={project?.id}
							onClick={() => (selectedProject?.id === project?.id ? setSelectedProject({}) : setSelectedProject(project))}
						>
							<div className={selectedProject?.id === project?.id ? 'active' : ''}>
								<ProjectUnChecked />
								<ProjectChecked />
							</div>
							<div>
								<b>{project?.name}</b>
								<span>{project?.project_sector}</span>
							</div>
							<div>
								{project?.resources.length}
								<ProjectUser />
							</div>
							<div>
								<ProjectDate />
								<div>
									<b>{getDateTime(project?.start_date, 'mm dd yyyy')}</b>
									<span>Start Date</span>
								</div>
							</div>
						</article>
					))}

					<Button onClick={() => handleContinue(selectedProject)}>Continue</Button>
				</div>
			)}
		</Container>
	);
};
export default ProjectSelection;
