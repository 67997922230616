import { useEffect, useRef, useState } from 'react';
import { FlagButtonIcon, ProfileImgEdit, ProfileInternIllustration } from '../../../assets/svgs';
import { Button, SelectGroup } from '../../../ui';
import { Container } from './styles';
import { getInitials, getPreviousSearchParams, handleApiError } from '../../../utilities';
import { useGeneralStore, useNotify, useUpdateInternProfile } from '../../../hooks';
import { useSearchParams } from 'react-router-dom';
import { APPLICANT_PROFILE_CONTROLS, LOCAL_STORAGE_KEYS, queryStrings } from '../../../constants';
import ProfileInfo from './ProfileInfo';
import EducationInterest from './EducationInterest';
import Documentation from './Documentation';
import { ReportStartup } from '../modals';

const InternProfile = ({ decodedUser, userDetails, refetchUserDetails }) => {
	const notify = useNotify();
	const { setStore } = useGeneralStore();
	const avatarFileRef = useRef();
	const [documentType, setDocumentType] = useState('enrollment');
	const [isRaisingConcernModalOpen, setIsRaisingConcernModalOpen] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [isEditing, setIsEditing] = useState(searchParams.get(queryStrings.should_edit_profile) === 'true');
	const [activeState, setActiveState] = useState(
		APPLICANT_PROFILE_CONTROLS.find((control) => control.key === searchParams.get(queryStrings.profile_tab))?.key ||
			APPLICANT_PROFILE_CONTROLS?.[0]?.key
	);
	const [formData, setFormData] = useState({
		avatar: {
			imgSrc: '',
			file: null,
		},
		name: '',
		about: '',
		contact_number: '',
		profile_link: '',
		skills: [],
		highest_edu_level: '',
		applicant_type: '',
		area_of_study: '',
		recent_institution: '',
		start_date: '',
		duration: '',
		interested_sector: [],
		interested_role: [],
		enrolment_document: {
			name: '',
			file: null,
		},
		supplementary_document: {
			name: '',
			file: null,
		},
		certification_document: {
			name: '',
			file: null,
		},
	});
	const { mutate: updateProfile, isLoading } = useUpdateInternProfile();

	const handleChange = (event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const handleControlClick = (value) => {
		setActiveState(value);
		let previousParams = getPreviousSearchParams(searchParams);

		previousParams = { ...previousParams, [queryStrings.profile_tab]: value };
		setSearchParams(previousParams, { replace: true });
	};
	const handleAvatarFileChange = (event) => {
		const imgFile = event.target.files?.item(0);

		handleChange(null, event.target.name, imgFile ? { imgSrc: URL.createObjectURL(imgFile), file: imgFile } : { imgSrc: '', file: null });
	};
	const handleSubmit = () => {
		const axiosFormData = new FormData();

		formData.avatar.file && axiosFormData.append('avatar', formData.avatar.file);
		formData.name && axiosFormData.append('name', formData.name);
		formData.about && axiosFormData.append('about', formData.about);
		formData.contact_number && axiosFormData.append('contact_number', formData.contact_number);
		formData.start_date && axiosFormData.append('start_date', formData.start_date);
		formData.duration && axiosFormData.append('program_duration', formData.duration);
		formData.highest_edu_level && axiosFormData.append('highest_edu_level', formData.highest_edu_level);
		formData.applicant_type && axiosFormData.append('applicant_type', formData.applicant_type);
		formData.area_of_study && axiosFormData.append('area_of_study', formData.area_of_study);
		formData.recent_institution && axiosFormData.append('recent_institution', formData.recent_institution);

		for (let i = 0; i < formData.skills.length; i++) {
			axiosFormData.append('skills', formData.skills[i]);
		}
		for (let i = 0; i < formData.interested_sector.length; i++) {
			axiosFormData.append('interested_sector', formData.interested_sector[i]);
		}
		for (let i = 0; i < formData.interested_role.length; i++) {
			axiosFormData.append('interested_role', formData.interested_role[i]);
		}

		formData.profile_link && axiosFormData.append('profile_link', formData.profile_link);

		if (documentType === 'enrollment' && formData.enrolment_document.file) {
			axiosFormData.append('enrollment_doc', formData.enrolment_document.file);
		} else if (documentType === 'certification' && formData.certification_document.file) {
			axiosFormData.append('certification_docs', formData.certification_document.file);
		}

		formData.supplementary_document.file && axiosFormData.append('supplimentary_docs', formData.supplementary_document.file);

		updateProfile(axiosFormData, {
			onSuccess: () => {
				refetchUserDetails();
				let previousParams = getPreviousSearchParams(searchParams);

				delete previousParams[queryStrings.should_edit_profile];

				setSearchParams(previousParams, { replace: true });

				setIsEditing(false);
				setFormData({
					avatar: { imgSrc: '', file: null },
					name: '',
					about: '',
					contact_number: '',
					start_date: '',
					duration: '',
					profile_link: '',
					skills: [],
					highest_edu_level: '',
					applicant_type: '',
					area_of_study: '',
					recent_institution: '',
					interested_sector: [],
					interested_role: [],
					enrolment_document: {
						name: '',
						file: null,
					},
					supplementary_document: {
						name: '',
						file: null,
					},
					certification_document: {
						name: '',
						file: null,
					},
				});

				setStore((prev) => ({ ...prev, has_provided_documents: true }));
				localStorage.setItem(LOCAL_STORAGE_KEYS.has_provided_documents, 'true');
				notify({ message: 'Your details have been updated', status: 'success', toastOptions: { toastId: 'profile_update_success' } });
			},
			onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'profile_update_error' } }),
		});
	};

	useEffect(() => {
		setFormData({
			avatar: { imgSrc: '', file: null },
			name: userDetails?.name || '',
			about: userDetails?.about || '',
			contact_number: userDetails?.contact_number || '',
			start_date: userDetails?.start_date || '',
			duration: userDetails?.program_duration || '',
			profile_link: userDetails?.profile_link || '',
			skills: userDetails?.skills || [],
			highest_edu_level: userDetails?.highest_edu_level || '',
			applicant_type: userDetails?.applicant_type || '',
			area_of_study: userDetails?.area_of_study || '',
			recent_institution: userDetails?.recent_institution || '',
			interested_sector: userDetails?.interested_sector || [],
			interested_role: userDetails?.interested_role || [],
			enrolment_document: {
				name: userDetails?.enrollment_doc?.[0]?.file_name || '',
				file: null,
			},
			supplementary_document: {
				name: userDetails?.supplimentary_docs?.[0]?.file_name || '',
				file: null,
			},
			certification_document: {
				name: userDetails?.certification_docs?.[0]?.file_name || '',
				file: null,
			},
		});
	}, [userDetails]);

	return (
		<Container
			onSubmit={(event) => {
				event.preventDefault();

				if (isEditing) handleSubmit();
			}}
		>
			<div className="header">
				<div>
					<h4>Profile</h4>
					<p>Update your photo and personal details</p>
				</div>

				{/* 
				<Button variant="secondary" className="deactivate">
					Deactivate Account
				</Button>
			*/}
				{isEditing ? (
					<>
						<Button
							variant="secondary"
							onClick={() => {
								setIsEditing(false);
								setFormData((prev) => ({ ...prev, avatar: { imgSrc: '', file: null } }));
							}}
						>
							Cancel
						</Button>
						<Button loading={isLoading} onClick={handleSubmit}>
							Save Changes
						</Button>
					</>
				) : (
					<>
						<Button variant="secondary" onClick={() => setIsRaisingConcernModalOpen(true)}>
							<FlagButtonIcon /> Raise Concern
						</Button>
						<Button loading={isLoading} onClick={() => setIsEditing(true)}>
							Edit Profile
						</Button>
					</>
				)}
			</div>

			<section>
				<aside>
					<ProfileInternIllustration />
				</aside>
				<main>
					<div className="img-name-group">
						<div className="img-con-con">
							{formData.avatar.imgSrc || userDetails?.avatar ? (
								<div className="img-con">
									<img src={formData.avatar.imgSrc || userDetails?.avatar} alt="" />
								</div>
							) : (
								<span>{getInitials(userDetails?.name)}</span>
							)}

							{isEditing ? (
								<>
									<input type="file" name="avatar" ref={avatarFileRef} onChange={handleAvatarFileChange} accept="image/png, image/gif, image/jpeg" />
									<ProfileImgEdit onClick={() => avatarFileRef.current.click()} />
								</>
							) : null}
						</div>

						<div className="name-group">
							<h5>{userDetails?.name}</h5>
							<div>
								<span>{userDetails?.contact_number}</span>
								<span>{decodedUser?.email}</span>
							</div>
						</div>
					</div>

					<div className="controls">
						{APPLICANT_PROFILE_CONTROLS.map(({ key, value: control }) => (
							<button key={key} className={key === activeState ? 'active' : ''} onClick={() => handleControlClick(key)}>
								{control}
							</button>
						))}
						<SelectGroup
							value={APPLICANT_PROFILE_CONTROLS.find((control) => control.key === activeState)?.value}
							options={APPLICANT_PROFILE_CONTROLS.map((control) => control.value)}
							shouldFilterByDefault={false}
							onChange={(_event, _name, value) => {
								handleControlClick(APPLICANT_PROFILE_CONTROLS.find((control) => control.value === value)?.key);
							}}
						/>
					</div>

					{activeState === APPLICANT_PROFILE_CONTROLS?.[0]?.key ? (
						<ProfileInfo
							isEditing={isEditing}
							formData={formData}
							userDetails={userDetails}
							handleChange={handleChange}
							isSubmitting={isLoading}
							handleSubmit={handleSubmit}
						/>
					) : activeState === APPLICANT_PROFILE_CONTROLS[1].key ? (
						<EducationInterest
							isEditing={isEditing}
							formData={formData}
							userDetails={userDetails}
							handleChange={handleChange}
							isSubmitting={isLoading}
							handleSubmit={handleSubmit}
						/>
					) : (
						<Documentation
							isEditing={isEditing}
							formData={formData}
							userDetails={userDetails}
							handleChange={handleChange}
							documentType={documentType}
							setDocumentType={setDocumentType}
							handleEdit={() => setIsEditing(true)}
							isSubmitting={isLoading}
							handleSubmit={handleSubmit}
						/>
					)}
				</main>
			</section>

			{isRaisingConcernModalOpen ? <ReportStartup handleClose={() => setIsRaisingConcernModalOpen(false)} /> : null}
		</Container>
	);
};

export default InternProfile;
