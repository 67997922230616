import { useState } from 'react';
import { useGetResourcesInProject, useNotify, useStartupRaiseConcern } from '../../../../hooks';
import { Container } from './styles';
import { CloseModal } from '../../../../assets/svgs';
import { Button, InputGroup, SelectGroup } from '../../../../ui';
import { handleApiError } from '../../../../utilities';
import { STARTUP_CONCERNS } from '../../../../constants';

const FlagIntern = ({ handleClose }) => {
	const notify = useNotify();
	const [formData, setFormData] = useState({
		intern: '',
		issue: '',
		more_info: '',
	});

	const { data: resources, isLoading } = useGetResourcesInProject();
	const { mutate: raiseConcern, isLoading: isSubmitting } = useStartupRaiseConcern();

	const handleChange = (event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		raiseConcern(
			{
				concern_type: formData.issue,
				description: formData.more_info,
				raised_against: resources?.find((resource) => resource?.name === formData.intern)?.id,
			},
			{
				onSuccess: () => {
					notify({
						message: 'Report has been submitted',
						status: 'success',
						toastOptions: {
							toastId: 'concern_success',
						},
					});
					handleClose();
				},
				onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'concern_error' } }),
			}
		);
	};

	return (
		<Container>
			<form onSubmit={handleSubmit}>
				<CloseModal tabIndex={0} onClick={handleClose} />

				<h2>Flag Interns</h2>
				<p>Kindly drop information to raise a complaint or dispute</p>

				<SelectGroup
					label="Intern to report"
					name="intern"
					onChange={handleChange}
					placeholder="Select Intern"
					value={formData.intern}
					options={resources?.map((resource) => resource?.name)}
					emptyOptionsMessage="You haven't added any applicant to your projects"
					required
					loading={isLoading}
				/>
				<br />

				<SelectGroup
					label="Intern Issues"
					name="issue"
					onChange={handleChange}
					placeholder="Select type of issue"
					value={formData.issue}
					options={STARTUP_CONCERNS}
					required
				/>
				<br />

				<InputGroup
					label="More Information"
					variant="textarea"
					name="more_info"
					onChange={handleChange}
					placeholder="Enter More Information about the issues"
					value={formData.more_info}
				/>

				<Button type="submit" loading={isSubmitting}>
					Flag Intern
				</Button>
			</form>
		</Container>
	);
};
export default FlagIntern;
