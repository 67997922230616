import { Button, InputGroup } from '../../../ui';
import { useNavigate } from 'react-router-dom';
import { useNotify } from '../../../hooks';
import { useMutation } from '@tanstack/react-query';
import { Approutes, Appurls } from '../../../constants';
import { axios } from '../../../library';
import { handleApiError } from '../../../utilities';
import { Container } from './styles';
import { useRef } from 'react';

const CreatePassword = ({ formData, setFormData }) => {
	const errorRef = useRef();
	const notify = useNotify();
	const navigate = useNavigate();

	const { mutate: createPassword, isLoading } = useMutation({
		mutationFn: (data) => axios.post(Appurls.auth.password.create_password, data),
	});

	const handleChange = (event) => {
		let name = event.target.name || '';
		let value = event.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
		errorRef.current.innerText = '';
	};
	const handleSubmit = (event) => {
		event.preventDefault();

		if (formData.password !== formData.confirm_password) {
			errorRef.current.innerText = 'Passwords do not match.';

			return;
		}

		createPassword(
			{ otp: formData.otp.join(''), new_password: formData.password },
			{
				onSuccess: () => {
					navigate(Approutes.login);
					notify({
						message: 'Password reset successfully. Log in with your details',
						status: 'success',
						toastOptions: { toastId: 'create_password_success' },
					});
				},
				onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'create_password_failed' } }),
			}
		);
	};

	return (
		<Container>
			<div>
				<h2>Reset your Password</h2>
				<p>Create your new password.</p>

				<form onSubmit={handleSubmit}>
					<InputGroup
						label="Password"
						type="password"
						name="password"
						onChange={handleChange}
						placeholder="Password"
						value={formData.password}
						required
						minLength={4}
					/>
					<br />
					<InputGroup
						label="Confirm Password"
						type="password"
						name="confirm_password"
						onChange={handleChange}
						placeholder="Confirm Password"
						value={formData.confirm_password}
						required
					/>
					<p className="error" ref={errorRef}></p>

					<Button type="submit" loading={isLoading}>
						Reset Password
					</Button>
				</form>
			</div>
		</Container>
	);
};

export default CreatePassword;
