import { createContext, useCallback, useEffect, useState } from 'react';
import { useAuth, useDecodedUser, useGetConversations } from '../hooks';
import useWebSocket from 'react-use-websocket';
import { MESSAGE_SOCKET_URL } from '../config';
import { LOCAL_STORAGE_KEYS } from '../constants';

const MessageContext = createContext({});

export const MessageProvider = ({ children }) => {
	const { auth } = useAuth();
	const user = useDecodedUser();

	const [formData, setFormData] = useState({ conversationSearchTerm: '' });
	const [socketMessages, setSocketMessages] = useState([]);
	const [socketShouldReconnect, setSocketShouldReconnect] = useState(true);
	const [conversations, setConversations] = useState([]);

	const { lastJsonMessage } = useWebSocket(
		MESSAGE_SOCKET_URL(auth?.access || localStorage.getItem(LOCAL_STORAGE_KEYS.access_key) || ''),
		{},
		socketShouldReconnect
	);

	const {
		data: conversationsResponse,
		refetch: refetchAllConversations,
		isLoading: isConversationsLoading,
		isError: isConversationsError,
		fetchNextPage: fetchMoreConversations,
		isFetchingNextPage: isFetchingMoreConversations,
		hasNextPage: conversationsHasNextPage,
	} = useGetConversations({ search: formData.conversationSearchTerm });

	const numberOfUnreadMessages = conversations?.reduce(
		(acc, current) => (current?.last_message?.sender?.id === user?.user_id ? acc : acc + current?.unread_count || 0),
		0
	);

	const handleConversationSearchChange = useCallback((event, name, value) => {
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, conversationSearchTerm: value }));
	}, []);
	const handleHasMarkedMessageAsRead = useCallback((roomId) => {
		setConversations((prev) =>
			prev.map((conversation) => (conversation?.id === roomId ? { ...conversation, unread_count: 0 } : conversation))
		);
	}, []);

	useEffect(() => {
		if (lastJsonMessage !== null) {
			lastJsonMessage?.message?.type === 'new_message' && setSocketMessages((prev) => [...prev, lastJsonMessage?.message]);

			if (lastJsonMessage?.message?.type === 'new_room') {
				refetchAllConversations();
				setSocketShouldReconnect(false);
			}
		}
	}, [lastJsonMessage, refetchAllConversations]);

	useEffect(() => {
		if (socketShouldReconnect !== false) return;

		setSocketShouldReconnect(true);
	}, [socketShouldReconnect]);

	useEffect(() => {
		let tempConversations = conversationsResponse?.pages
			?.reduce((previous, current) => [...previous, ...current?.results], [])
			// To be removed
			?.filter((message) => message?.room_type !== 'PROJECT')
			?.map((conversation) =>
				conversation?.last_message?.sender?.id === user?.user_id ? { ...conversation, unread_count: 0 } : conversation
			);

		if (socketMessages.length) {
			tempConversations = tempConversations
				.map((conversation) => {
					const updatedConversation = socketMessages?.findLast((message) => message?.room_id === conversation?.id);

					return updatedConversation
						? {
								...conversation,
								last_message: {
									...conversation?.last_message,
									media: updatedConversation?.media,
									sender: { ...conversation?.last_message?.sender, name: updatedConversation?.sender_name, id: updatedConversation?.sender_id },
									text: updatedConversation?.content,
									created_at: updatedConversation?.created_at,
								},
								unread_count: updatedConversation?.sent_by_me ? 0 : conversation?.unread_count + 1,
						  }
						: conversation;
				})
				?.filter((message) => (message?.participants?.role?.[0] === 'STARTUP' && !message?.last_message ? false : true))
				.sort((a, b) => new Date(b?.last_message?.created_at) - new Date(a?.last_message?.created_at));
		}

		tempConversations = tempConversations?.filter((message) =>
			message?.participants?.role?.[0] === 'STARTUP' && !message?.last_message ? false : true
		);

		setConversations(tempConversations);
	}, [conversationsResponse?.pages, socketMessages, user]);

	return (
		<MessageContext.Provider
			value={{
				conversationSearchTerm: formData.conversationSearchTerm,
				socketMessages,
				numberOfUnreadMessages,
				conversations,
				isConversationsLoading,
				isConversationsError,
				isFetchingMoreConversations,
				conversationsHasNextPage,
				fetchMoreConversations,
				refetchAllConversations,
				handleConversationSearchChange,
				handleHasMarkedMessageAsRead,
			}}
		>
			{children}
		</MessageContext.Provider>
	);
};

export default MessageContext;
