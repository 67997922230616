import styled from 'styled-components';
import { maxQuery } from '../../../utilities';
import { StyledFileGroup } from '../../../ui/FileGroup/styles';
import { StyledButton } from '../../../ui/Button/styles';

export const Container = styled.form`
	border-radius: 0.25rem;
	background: #fff;
	box-shadow: -9px 19px 40px 0px rgba(0, 0, 0, 0.02);
	padding: 3rem 4rem;
	padding: clamp(2rem, 1.4444rem + 1.7778vw, 3rem) clamp(2rem, 0.8889rem + 3.5556vw, 4rem);
	min-height: calc(100vh - (5.3rem + 134px));

	h3 {
		color: #070d19;
		font-size: 2rem;
		font-size: clamp(1.75rem, 1.6111rem + 0.4444vw, 2rem);
	}
	h3 + p {
		color: #a8a8a8;
		font-size: 1rem;
		font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
		line-height: 120%;
		border-bottom: 1px solid #ebebeb;
		margin: 0.5rem 0 2.5rem;
		padding-bottom: 1.5rem;
	}

	> div {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1.5rem;

		${maxQuery('md')} {
			grid-template-columns: 1fr;
		}
	}
	textarea {
		resize: none;
	}
	${StyledFileGroup} .file-group {
		height: 5.5125rem;

		.text-con {
			align-self: center;

			p:not(.selected) {
				color: #1e1e1e;
				font-size: 0.875rem;
				line-height: 135%;
			}
			span {
				font-size: 0.75rem;
				line-height: 160%;
			}
		}
	}
	> ${StyledButton} {
		margin-top: 3rem;
		width: 100%;
	}
`;
