import styled from 'styled-components';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	inset: 0;
	z-index: 99;

	> div {
		border-radius: 0.25rem;
		background: #fff;
		padding: 0 2rem 2rem;
		padding: 0 clamp(1.5rem, 1.2222rem + 0.8889vw, 2rem) 2rem;
		width: 33.5rem;
		max-width: calc(100vw - 2rem);
		max-height: calc(100vh - 2rem);
		overflow: auto;

		.header {
			position: sticky;
			top: 0;
			background-color: #fff;
			z-index: 2;
			padding-top: 2rem;

			h2 {
				text-align: center;
				font-size: 2rem;
				font-size: clamp(1.75rem, 1.6111rem + 0.4444vw, 2rem);
				margin-top: 0.5rem;
				color: #070d19;
			}
			h2 + p {
				color: #a8a8a8;
				font-size: 1rem;
				font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
				line-height: 120%;
				margin: 0.5rem 0 1.5rem;
				text-align: center;
			}
		}
		.header svg {
			margin-left: auto;
			display: block;
			cursor: pointer;
			width: 1.25rem;
			width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);

			:active {
				outline: none;
			}
		}
		article {
			display: grid;
			grid-template-columns: 1fr 1fr;
			font-size: 1rem;
			line-height: 140%;
			padding: 1.25rem 0;

			&:first-child {
				padding-top: 0;
			}

			&:not(:last-child) {
				border-bottom: 1px solid #ebebeb;
			}

			> div {
				&:nth-child(even) {
					text-align: right;
				}
				&.name-con {
					b {
						font-size: 1.5rem;
						font-weight: 700;
					}
					p {
						font-size: 0.825rem;
						font-weight: 600;
						color: #a1a0a0;
					}
				}
			}

			.intern-con {
				display: flex;
				align-items: center;
				gap: 10px;
			}
			button {
				display: flex;
				align-items: center;
				padding: 3px 10px;
				background: #f1f4fd;
				border-radius: 4px;

				path {
					fill: #4060ea;
				}
			}
			b {
				display: block;

				:first-child {
					margin-bottom: 0.25rem;
				}
			}
			p {
				font-weight: 500;

				:first-child {
					margin-bottom: 0.25rem;
				}
			}
		}
	}
`;
