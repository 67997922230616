import { Link } from 'react-router-dom';
import { Button } from '../../../ui';
import { APPLICANT_PROFILE_KEYS, Approutes, LOCAL_STORAGE_KEYS, queryStrings } from '../../../constants';
import { WelcomeHourGlass, WelcomeRejected } from '../../../assets/svgs';
import { useDecodedUser, useGeneralStore, useNotify, useResubmitProfile } from '../../../hooks';
import { handleApiError } from '../../../utilities';
import { useState } from 'react';

const InternFlow = () => {
	const notify = useNotify();
	const user = useDecodedUser();
	const { store } = useGeneralStore();

	const [hasResubmittedProfile, setHasResubmittedProfile] = useState(false);
	// const [searchParams, setSearchParams] = useSearchParams();

	const { mutate: resubmitProfile, isLoading: isResubmitting } = useResubmitProfile();

	const handleResubmitProfile = () => {
		resubmitProfile(user?.user_id, {
			onSuccess: () => {
				setHasResubmittedProfile(true);
				localStorage.setItem(LOCAL_STORAGE_KEYS.has_resubmitted_profile, 'true');
			},
			onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'resubmit_profile_error' } }),
		});
	};

	return (
		<>
			{user?.intern_profile_status === 'DECLINED' &&
			!hasResubmittedProfile &&
			!localStorage.getItem(LOCAL_STORAGE_KEYS.has_resubmitted_profile) ? (
				<div className="verification-con">
					<WelcomeRejected />
					<h1>Profile Declined</h1>
					<p>
						You profile has been declined by an admin. The reason has been sent to your email. Make the needed changes in{' '}
						<Link to={Approutes.profile}>your profile</Link> and reapply for verification.
					</p>
					<Button onClick={handleResubmitProfile} loading={isResubmitting}>
						Reapply for verification
					</Button>
				</div>
			) : user?.intern_profile_status === 'PENDING' ||
			  hasResubmittedProfile ||
			  localStorage.getItem(LOCAL_STORAGE_KEYS.has_resubmitted_profile) === 'true' ? (
				<div className="verification-con">
					<WelcomeHourGlass className="hourglass" />
					<h1>Awaiting Verification</h1>
					<p>
						{store?.has_provided_documents !== true &&
						(!user?.has_provided_documents || !localStorage.getItem(LOCAL_STORAGE_KEYS.has_provided_documents)) ? (
							<>
								You need to provide some documents to be used while verifying your profile. <br />
							</>
						) : null}
						Your profile is currently going through the verification stage by Descinder's admin. Check back within 72 Hours.
					</p>

					{store?.has_provided_documents !== true &&
					(!user?.has_provided_documents || !localStorage.getItem(LOCAL_STORAGE_KEYS.has_provided_documents)) ? (
						<Link
							to={`${Approutes.profile}?${queryStrings.profile_tab}=${APPLICANT_PROFILE_KEYS.documentation}&${queryStrings.should_edit_profile}=true`}
						>
							<Button>Provide Documents</Button>
						</Link>
					) : null}
				</div>
			) : // ) : user?.intern_profile_status === 'APPROVED' && store?.has_paid === false && !hasInternPaid(user) ? (
			// 	<div className="verification-con">
			// 		<WelcomeCheckMark />
			// 		<h1>Profile Verified</h1>
			// 		<p>You profile has been verified and you can now select a subscription plan to kick start your project and start adding resources</p>

			// 		<Button
			// 			onClick={() => {
			// 				let previousParams = getPreviousSearchParams(searchParams);

			// 				previousParams = { ...previousParams, [queryStrings.subscription_plan]: 'open' };
			// 				setSearchParams(previousParams, { replace: true });
			// 			}}
			// 		>
			// 			Select Subscription
			// 		</Button>
			// 	</div>
			user?.intern_profile_status === 'APPROVED' && user?.intern_assigned_to_project === false ? (
				<div>
					<h1>Welcome To Descinder</h1>
					<p>We're glad to have you onboard</p>

					<b></b>
					<p className="wider">You will receive notifications upon your addition to a project and when there are tasks assigned to you.</p>

					{/* <Link to={Approutes.intern.supervisor}>
						<Button>Invite your Supervisor</Button>
					</Link> */}
				</div>
			) : user?.intern_profile_status === 'APPROVED' && user?.intern_assigned_to_project === true ? (
				<div>
					<h1>Welcome To Descinder</h1>
					<p>We're glad to have you onboard</p>

					<b></b>
					<p className="wider">You can now view your project information and tasks</p>

					<Link to={Approutes.intern.tasks.overview}>
						<Button>Overview</Button>
					</Link>
				</div>
			) : (
				<div>
					<h1>Welcome To Descinder</h1>
					<p>We're glad to have you onboard</p>

					<b></b>
					{/* <p className="wider">You can now view your project information and tasks</p> */}

					<Link to={Approutes.intern.tasks.overview}>
						<Button>Overview</Button>
					</Link>
				</div>
			)}
		</>
	);
};
export default InternFlow;
