import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	gap: 0.625rem;
	width: 100%;
	padding: 1rem 0;
	cursor: pointer;
	position: relative;

	:not(:last-child) {
		border-bottom: 1px solid #ebebeb;
	}

	&.unread::after {
		content: '';
		position: absolute;
		top: 50%;
		right: 0.5rem;
		transform: translateY(0.5rem);
		width: 0.5rem;
		height: 0.5rem;
		background-color: #4060ea;
		border-radius: 50%;
	}

	img {
		flex-shrink: 0;
		width: 2.3125rem;
		height: 2.3125rem;
		object-fit: cover;
		border-radius: 50%;
	}
	.initials {
		color: #4060ea;
		font-size: 1.125rem;
		font-weight: 700;
		line-height: 132%;
		width: 2.3125rem;
		height: 2.3125rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background: #dae0fb;
		border: 2px solid #fff;
		flex-shrink: 0;
	}
	.text-con {
		flex: 1;
	}
	.name-date-group {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.name {
		font-weight: 600;
		font-size: 0.875rem;
		line-height: 1.2;
	}
	.date {
		color: #bbbaba;
		font-size: 0.875rem;
		line-height: 1.2;
		flex-shrink: 0;
	}
	.message-con {
		color: #bbbaba;
		font-size: 0.875rem;
		line-height: 1.2;
		font-weight: 500;
		margin-top: 0.375rem;

		> * {
			display: inline;
			padding-right: 0.25rem;
		}
	}
	.name,
	.message-con {
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}
`;
