import styled from 'styled-components';
import { StyledButton } from '../../../ui/Button/styles';
// import { maxQuery } from '../../../utilities';

export const Container = styled.header`
	position: sticky;
	top: 0;
	z-index: 50;
	background: #fff;
	padding: 0.5rem 6.25rem;
	padding: 0.5rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);
	max-height: 108px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

	> div {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.logo-con {
		display: grid;
		place-items: center;

		svg {
			flex-shrink: 0;
			width: clamp(8.3rem, 6.1191rem + 6.9787vw, 12.4rem);
			height: 60px;
			height: clamp(2.8125rem, 2.2917rem + 1.6667vw, 3.75rem);
		}
	}

	${StyledButton} {
		padding: 0.75rem 2.5rem;
		padding: 0.75rem clamp(1.8rem, 1.1rem + 2.24vw, 2.5rem);
	}
`;
