import { useEffect, useState } from 'react';
import { ButtonPlus, ProjectEmpty } from '../../assets/svgs';
import { ApplicantCard, Filter } from '../../components';
import { Button, Loader, Pagination, SearchBar, SelectedApplicants } from '../../ui';
import { useDecodedUser, useGeneralStore, useGetApplicants, useInviteApplicant, useNotify } from '../../hooks';
import { Approutes, filterApplicants } from '../../constants';
import { handleApiError, hasStartupPaid } from '../../utilities';
import { useNavigate } from 'react-router-dom';

const AddResource = ({ formData, handleChange }) => {
	const notify = useNotify();
	const navigate = useNavigate();
	const user = useDecodedUser();
	const { store } = useGeneralStore();

	const [page, setPage] = useState(1);

	const {
		data: applicants,
		isLoading,
		isError,
	} = useGetApplicants({
		page,
		page_size: 3,
		search: formData.search,
		extraQuery: Object.values(formData.filter).reduce(
			(prev, current) => (current?.value ? `${prev}&${current?.queryKey}=${current?.value}` : prev),
			''
		),
	});
	const { mutate: inviteApplicant, isLoading: isSubmitting } = useInviteApplicant();

	const handleSubmit = (event) => {
		event.preventDefault();

		inviteApplicant(
			{ id: formData.project_id, data: { resources: formData.selected_applicants } },
			{
				onSuccess: () => {
					notify({
						message: 'Applicants have successfully been invited to your project',
						status: 'success',
						toastOptions: { toastId: 'add_applicant_success' },
					});
					navigate(Approutes.startup.projects.index);
				},
				onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'add_applicant_error' } }),
			}
		);
	};

	useEffect(() => {
		if (!(store?.has_paid === true || hasStartupPaid(user))) {
			notify({
				message: 'You need to subscribe to a package before adding resources',
				toastOptions: { toastId: 'payment_required' },
			});
			navigate(Approutes.startup.projects.index);
		}
	}, [store, user, notify, navigate]);

	return (
		<form className="resource-form" onSubmit={handleSubmit}>
			<h2>Add Resource</h2>
			<p>Select and add Applicant to your project</p>
			<div className="filter-con">
				<SearchBar name="search" placeholder="Search through applicants" handleChange={handleChange} />

				<Filter name="filter" onChange={handleChange} value={formData.filter} options={filterApplicants} />
			</div>

			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<p className="error-message">Unable to fetch resources at the moment</p>
			) : !applicants?.results || applicants?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>No Applicant to Display</p>
				</div>
			) : (
				<div className="talent-list">
					{applicants?.results?.map((applicant) => (
						<ApplicantCard
							key={applicant?.id}
							applicant={applicant}
							isAdded={formData.selected_applicants.find((selectedApp) => selectedApp === applicant?.id)}
							handleAdd={() => handleChange(null, 'selected_applicants', [...formData.selected_applicants, applicant?.id])}
							handleRemove={() =>
								handleChange(
									null,
									'selected_applicants',
									formData.selected_applicants?.filter((selectedApp) => selectedApp !== applicant?.id)
								)
							}
						/>
					))}
				</div>
			)}
			<div className="bottom-row">
				<Pagination page={page} setPage={setPage} maxPages={Math.ceil(applicants?.total / 3)} disabled={isLoading || isSubmitting} />

				<SelectedApplicants applicants={formData.selected_applicants} />

				<Button disabled={isLoading} loading={isSubmitting}>
					<ButtonPlus />
					Invite Applicants
				</Button>
			</div>
		</form>
	);
};

export default AddResource;
