import styled from 'styled-components';
import { maxQuery } from '../../../utilities';
import { StyledButton } from '../../../ui/Button/styles';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	inset: 0;
	z-index: 99;

	> center {
		height: 30rem;
		display: flex;
		flex-direction: column;

		> div {
			flex: 1;
			display: grid;
			place-items: center;
		}
	}
	> div,
	> center {
		border-radius: 0.25rem;
		background: #fff;
		padding: 2rem;
		padding: 2rem clamp(1.5rem, 1.2222rem + 0.8889vw, 2rem);
		width: 37.9375rem;
		max-width: calc(100vw - 2rem);
		max-height: calc(100vh - 2rem);
		overflow: auto;

		> svg {
			margin-left: auto;
			display: block;
			width: 1.25rem;
			width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);

			:active {
				outline: none;
			}
		}
		h2 {
			text-align: center;
			font-size: 2rem;
			font-size: clamp(1.75rem, 1.6111rem + 0.4444vw, 2rem);
			margin-top: 0.5rem;
			color: #070d19;
		}
		h2 + p {
			color: #a8a8a8;
			font-size: 1rem;
			font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
			line-height: 120%;
			margin: 0.5rem 0 1.5rem;
			text-align: center;
		}
		article {
			border-radius: 0.25rem;
			border: 1px solid rgba(18, 18, 18, 0.03);
			padding: 1rem;
			margin: 1rem 0;
			display: flex;
			gap: 1rem;
			cursor: pointer;

			:last-child {
				margin-bottom: 0;
			}

			${maxQuery('xs')} {
				display: grid;
				grid-template-columns: 1.5rem auto 3rem;
			}

			div {
				:nth-child(1) {
					display: flex;
					align-items: center;

					${maxQuery('xs')} {
						order: 1;
					}

					&.active {
						svg:first-child {
							display: none;
						}
						svg:last-child {
							display: block;
						}
					}
					svg {
						cursor: pointer;

						:last-child {
							display: none;
						}
					}
				}
				:nth-child(2) {
					flex-basis: 45%;

					${maxQuery('xs')} {
						order: 2;
						grid-column: 2/4;
					}

					b {
						display: block;
						font-size: clamp(0.8125rem, 0.2917rem + 1.6667vw, 1.125rem);
					}
					span {
						display: block;
						color: rgba(15, 15, 15, 0.46);
						font-size: 1.0625rem;
						font-size: clamp(0.8125rem, 0.3958rem + 1.3333vw, 1.0625rem);
						font-weight: 500;
						color: #c08030;
						font-weight: 700;
						line-height: 120%;
					}
				}
				:nth-child(3) {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 0.2rem;
					font-weight: 700;
					font-size: clamp(0.8125rem, 0.2917rem + 1.6667vw, 1.125rem);

					${maxQuery('xs')} {
						grid-column: 3/4;
						order: 4;
					}
				}
				:nth-child(4) {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 0.5rem;
					margin-left: auto;

					${maxQuery('xs')} {
						justify-content: flex-start;
						grid-column: 2/3;
						order: 3;
						margin-left: 0;
					}

					b,
					span {
						display: block;
						text-align: center;
					}
					b {
						width: max-content;
						font-size: clamp(0.8125rem, 0.2917rem + 1.6667vw, 1.125rem);
					}
					span {
						color: rgba(15, 15, 15, 0.46);
						font-size: 1.0625rem;
						font-size: clamp(0.8125rem, 0.3958rem + 1.3333vw, 1.0625rem);
						font-weight: 500;
					}
				}
				:nth-child(2) {
					/* ${maxQuery('sm')} {
				justify-content: flex-start;
			} */
				}
			}
		}
		${StyledButton} {
			width: 100%;
			margin-top: 2.5rem;

			span {
			}
		}
	}
`;
