import { FileDoc } from '../../../assets/svgs';
import { Button, FileGroup, InputGroup, SelectGroup } from '../../../ui';
import { APPLICANT_SKILLS, COMPANY_SECTORS, PROJECT_DURATIONS } from '../../../constants';
import { Container } from './styles';
import { useState } from 'react';
import { useNotify, usePatchProject } from '../../../hooks';
import { handleApiError } from '../../../utilities';

const EditProject = ({ project, projectId, refetchProject, setIsEditingProject, setIsLoadingEditingProject }) => {
	const notify = useNotify();
	const [formData, setFormData] = useState({
		project_id: projectId || '',
		project_name: project?.name || '',
		project_start_date: project?.start_date || '',
		sector: project?.project_sector || '',
		timeline: project?.timeline || '',
		resource_experience_level: project?.resource_experience_level || '',
		required_document: project?.requirement_docs?.length
			? {
					name: project?.requirement_docs?.[0]?.file_name || '',
					file: null,
			  }
			: {
					name: '',
					file: null,
			  },
		description: project?.description || '',
		project_duration: project?.timeline || '',
		skill_tag: project?.resource_skills || [],
	});

	const { mutate: patchProject, isLoading } = usePatchProject({ projectId, headers: { 'Content-Type': 'multipart/form-data' } });

	const handleChange = (event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const handleSubmit = (event) => {
		event.preventDefault();

		setIsLoadingEditingProject(true);

		const axiosFormData = new FormData();

		axiosFormData.append('name', formData.project_name);
		// axiosFormData.append('start_date', formData.project_start_date);
		axiosFormData.append('timeline', formData.timeline);
		axiosFormData.append('project_sector', formData.sector);
		axiosFormData.append('description', formData.description);
		axiosFormData.append('resource_experience_level', formData.resource_experience_level);
		for (let i = 0; i < formData.skill_tag.length; i++) {
			axiosFormData.append('resource_skills', formData.skill_tag[i]);
		}
		formData.required_document.file && axiosFormData.append('requirement_docs', formData.required_document.file);

		patchProject(axiosFormData, {
			onSuccess: () => {
				refetchProject();
				setIsEditingProject(false);

				notify({ message: 'Project has been updated successfully', status: 'success', toastOptions: { toastId: 'project_patch_success' } });
			},
			onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'project_patch_error' } }),
			onSettled: () => setIsLoadingEditingProject(false),
		});
	};

	return (
		<Container onSubmit={handleSubmit}>
			<h3>Edit Project</h3>
			<p>Update your project information</p>

			<div>
				<InputGroup
					label="Project Name"
					name="project_name"
					onChange={handleChange}
					placeholder="Enter Project Name"
					value={formData.project_name}
					required
				/>
				<InputGroup
					label="Project Start Date"
					type="date"
					name="project_start_date"
					onChange={handleChange}
					value={formData.project_start_date}
					min={new Date().toISOString().split('T')[0]}
					// required
					disabled
				/>
				<SelectGroup
					shouldFilterByDefault={false}
					label="Project Sector"
					name="sector"
					onChange={handleChange}
					placeholder="Select Project Sector"
					value={formData.sector}
					options={COMPANY_SECTORS}
					required
				/>
				<SelectGroup
					shouldFilterByDefault={false}
					label="Timeline"
					name="timeline"
					onChange={handleChange}
					placeholder="Select Project Duration"
					value={formData.timeline}
					options={PROJECT_DURATIONS}
					required
				/>
				<FileGroup
					label="Requirement Document"
					name="required_document"
					onChange={handleChange}
					helpTextOne="Choose BRD/PRD"
					helpTextTwo="Doc, PDF Max size of 4MB"
					accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
					maxSize={4}
					value={formData.required_document}
					icon={FileDoc}
					required
				/>
				<InputGroup
					label="Brief Project Description"
					variant="textarea"
					name="description"
					onChange={handleChange}
					placeholder="Enter Brief description about your company"
					value={formData.description}
					required
				/>
				<SelectGroup
					shouldFilterByDefault={false}
					label="Resource Experience level (Optional)"
					name="resource_experience_level"
					onChange={handleChange}
					placeholder="Select Project Duration"
					value={formData.resource_experience_level}
					options={['Beginner', 'Intermediate', 'Expert']}
					dropdownPosBottom={false}
				/>
				<SelectGroup
					shouldFilterByDefault={false}
					label="Resource skill tag for project"
					name="skill_tag"
					onChange={handleChange}
					placeholder="Select Resource Skill tags"
					value={formData.skill_tag}
					options={APPLICANT_SKILLS}
					required
					variant="multiple"
					dropdownPosBottom={false}
				/>
			</div>

			<Button id="submit-edit" type="submit" loading={isLoading}>
				Submit
			</Button>
		</Container>
	);
};
export default EditProject;
