import { StyledPagination } from './styles';

const Pagination = ({ page, setPage, maxPages = 1, disabled = false }) => {
	maxPages = isNaN(maxPages) ? 1 : maxPages;

	if (maxPages === 1) return null;

	return (
		<StyledPagination>
			{Array.from({ length: 7 }, (_, i) => i + page - 3)
				.filter(
					(num) => num > 0 && !(page === maxPages ? num < page - 3 : page === maxPages - 1 ? num < page - 2 : num < page - 1) && num <= maxPages
				)
				.slice(0, 4)
				.map((key) => (
					<button key={key} onClick={() => setPage(key)} className={page === key ? 'active' : ''} disabled={disabled}>
						{key}
					</button>
				))}
		</StyledPagination>
	);
};

export default Pagination;
