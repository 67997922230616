import styled from 'styled-components';
import { StyledInputGroup } from '../../../../ui/InputGroup/styles';
import { StyledButton } from '../../../../ui/Button/styles';

export const Container = styled.div`
	width: 100%;

	h6 {
		color: #4060ea;
		font-size: 1.25rem;
		margin-bottom: 1.5rem;
		font-family: 'Urbanist', sans-serif;
	}
	h2 {
		color: #292d2d;
		font-size: 2.5rem;
		font-size: clamp(2rem, 1.7222rem + 0.8889vw, 2.5rem);
		line-height: 100%;
	}
	p {
		color: #3a3a3a;
		font-size: 0.9375rem;
		font-size: clamp(0.75rem, 0.6458rem + 0.3333vw, 0.9375rem);
		line-height: 170%;
		margin: 0.5rem 0 2.3rem;
		margin: 0.5rem 0 clamp(1.75rem, 1.4444rem + 0.9778vw, 2.3rem);
	}
	${StyledInputGroup} {
		margin-bottom: 1.25rem;
	}
	${StyledButton} {
		margin: 2rem 0 1rem;
		justify-content: center;
		width: 100%;
	}

	.login {
		color: #504f4f;
		font-size: 0.875rem;
		text-align: center;
		display: block;

		a {
			color: #4060ea;
			font-weight: 600;
		}
	}

	form {
		margin-bottom: 2rem;
		padding-bottom: 3rem;
		position: relative;
		width: 100%;

		::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			background: #ebebeb;
			width: 18.75rem;
			max-width: 100%;
			height: 1px;
		}

		.terms-and-condition {
			font-size: 0.75rem;
			color: #504f4f;
			margin-top: 1rem;
			display: flex;
			align-items: center;
			gap: 0.625rem;

			${StyledInputGroup} {
				margin-bottom: 0;

				input {
					width: 1.25rem;
					height: 1.25rem;
				}
			}

			a {
				color: ${({ theme }) => theme.colors.primary500};

				display: inline-block;

				:hover {
					text-decoration: underline;
				}
			}
		}
	}

	form + a {
		color: #4060ea;
		font-size: 1rem;
		font-weight: 700;
		text-align: center;
		display: block;
	}
	.error {
		color: #ff7542;
		font-weight: 600;
		font-size: 0.875rem;
		margin: 0;
	}
`;
