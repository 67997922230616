import { Outlet } from 'react-router-dom';
import { useDecodedUser } from '../../hooks';
import { PageNotFound } from '../../views';
import { isIntern } from '../../utilities';
import { UserType } from '../../types';

const InternOnly = () => {
	const user = useDecodedUser();
	// const [searchParams, setSearchParams] = useSearchParams();
	// const { pathname } = useLocation();

	// ---------------------------------------------------
	// Bring up subscription modal asking intern to pay
	// ---------------------------------------------------
	// useEffect(() => {
	// 	const ALLOWED_ROUTES = [Approutes.intern.tasks.overview, Approutes.intern.tasks.my_tasks, Approutes.intern.tasks.logs];

	// 	if (
	// 		hasInternPaid(user) ||
	// 		sessionStorage.getItem(SESSION_STORAGE_KEYS.has_closed_subscription) ||
	// 		!ALLOWED_ROUTES.includes(pathname) ||
	// 		user?.intern_profile_status !== 'APPROVED'
	// 	)
	// 		return;

	// 	let previousParams = getPreviousSearchParams(searchParams);

	// 	previousParams = { ...previousParams, [queryStrings.subscription_plan]: 'open' };
	// 	setSearchParams(previousParams, { replace: true });
	// }, [user, searchParams, setSearchParams, pathname]);

	return isIntern(user as UserType) ? (
		<>
			<Outlet />
		</>
	) : (
		<PageNotFound />
	);
};

export default InternOnly;
