import { useQueryClient } from '@tanstack/react-query';
import { FileDoc, FileImage } from '../../../assets/svgs';
import { APPLICANT_TYPE } from '../../../constants';
import { useParseResumeToJson, useNotify, useUpdateInternProfile, useUpdateInternExperience } from '../../../hooks';
import { Button, FileGroup, InputGroup, Loader, SelectGroup } from '../../../ui';
import { handleApiError } from '../../../utilities';
import { useState } from 'react';

const ApplicantProfile = ({ setStep, formData, setFormData, handleChange, handleLogout }) => {
	const queryClient = useQueryClient();
	const notify = useNotify();
	const [isLoadingFor, setIsLoadingFor] = useState('');

	const { mutate: updateProfile, isLoading } = useUpdateInternProfile();
	const { mutate: resumeToJson, isLoading: isConverting } = useParseResumeToJson();
	const { mutate: updateExperience } = useUpdateInternExperience();

	const handleSaveAndExit = () => {
		setIsLoadingFor('EXIT');
		handleDataSubmission({
			onSuccess: () => {
				handleLogout();
				setIsLoadingFor('');
			},
		});
	};
	const handleDataSubmission = ({ onSuccess }) => {
		const axiosFormData = new FormData();

		formData.applicant_name && axiosFormData.append('name', formData.applicant_name);
		formData.applicant_avatar.file && axiosFormData.append('avatar', formData.applicant_avatar.file);
		formData.introduction && axiosFormData.append('about', formData.introduction);
		formData.applicant_type && axiosFormData.append('applicant_type', formData.applicant_type);
		formData.level_of_education && axiosFormData.append('highest_edu_level', formData.level_of_education);
		formData.area_of_study && axiosFormData.append('area_of_study', formData.area_of_study);
		formData.institution && axiosFormData.append('recent_institution', formData.institution);
		formData.proficiency_level && axiosFormData.append('english_proficiency_level', formData.proficiency_level);

		for (let i = 0; i < formData.skills.length; i++) {
			axiosFormData.append('skills', formData.skills[i]);
		}

		updateProfile(axiosFormData, {
			onSuccess,
			onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'profile_update' } }),
		});

		if (formData.experiences?.[0]?.employer_name !== '') {
			updateExperience({ experience: formData.experiences });
		}
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		setIsLoadingFor('CONTINUE');

		handleDataSubmission({
			onSuccess: () => {
				setStep(2);
				queryClient.invalidateQueries(['user']);
				queryClient.invalidateQueries(['user-id']);
				setIsLoadingFor('');
			},
		});
	};

	const handleFileConversionToJson = (file) => {
		const axiosFormData = new FormData();

		if (!file) return;

		axiosFormData.append('file', file);

		resumeToJson(axiosFormData, {
			onSuccess: ({ data }) => {
				if (!data) return;

				setFormData((prev) => ({
					...prev,
					applicant_name: data?.applicant_name || prev.applicant_name || '',
					introduction: data?.introduction || prev.introduction || '',
					area_of_study: data?.area_of_study || prev.area_of_study || '',
					level_of_education: data?.highest_level_of_education || prev.level_of_education || '',
					institution: data?.institution || prev.institution || '',
					experiences: data?.experiences || prev.experiences || [],
					skills: data?.skills || prev.skills || [],
					proficiency_level: data?.english_proficiency_level || prev.proficiency_level || '',
				}));
			},
			onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'profile_update' } }),
		});
	};

	return (
		<form onSubmit={handleSubmit}>
			<h2>Applicant Profile</h2>
			<p>Provide information to create your profile as an applicant</p>

			{isConverting ? (
				<div className="cv-loader">
					<Loader />
				</div>
			) : null}

			<div>
				<FileGroup
					label="Upload CV to populate your data"
					name="cv"
					onChange={(event, name, value) => {
						handleChange(event, name, value);

						value?.file && handleFileConversionToJson(value?.file);
					}}
					helpTextTwo="PDF Max size of 2MB"
					accept=".pdf"
					maxSize={2}
					value={formData.cv}
					icon={FileDoc}
				/>
				<div></div>

				<InputGroup
					label="Applicant's Name"
					name="applicant_name"
					onChange={handleChange}
					placeholder="Enter Applicant's Name"
					value={formData.applicant_name}
					required
				/>
				<FileGroup
					label="Applicant's Avatar"
					name="applicant_avatar"
					onChange={handleChange}
					helpTextTwo="JPG, GIF or PNG. Max size of 800Kb"
					accept="image/png, image/gif, image/jpeg"
					maxSize={0.8}
					value={formData.applicant_avatar}
					icon={FileImage}
					required
				/>
				<InputGroup
					label="Brief Introduction About Yourself"
					variant="textarea"
					name="introduction"
					onChange={handleChange}
					placeholder="Enter Brief introduction about yourself"
					value={formData.introduction}
					required
				/>
				<SelectGroup
					label="Applicant Type"
					name="applicant_type"
					onChange={handleChange}
					placeholder="Internship"
					value={formData.applicant_type}
					options={APPLICANT_TYPE}
					required
				/>
			</div>

			<div className="btn-group">
				<Button
					loading={isLoading && isLoadingFor === 'EXIT' ? isLoading : false}
					disabled={isLoading}
					type="button"
					variant="secondary"
					onClick={handleSaveAndExit}
				>
					Save and Exit
				</Button>
				<Button loading={isLoading && isLoadingFor === 'CONTINUE' ? isLoading : false} disabled={isLoading}>
					Save & Continue
				</Button>
			</div>
		</form>
	);
};

export default ApplicantProfile;
