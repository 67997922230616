import styled from 'styled-components';
import { StyledLoader } from '../../ui/Loader/styles';

export const LoaderContainer = styled.div`
	height: 100vh;
	display: grid;
	place-items: center;

	${StyledLoader} {
		border-width: 0.3rem;
	}
`;
