import { useSearchParams } from 'react-router-dom';
import { ApplicantAvatar, CloseModal, NotificationFilter } from '../../../assets/svgs';
import { Container } from './styles';
import { getDateTime, getPreviousSearchParams } from '../../../utilities';
import { queryStrings } from '../../../constants';
import { useState } from 'react';
import { useNotifications } from '../../../hooks';
import { Loader } from '../../../ui';
import NotificationContainer from './NotificationContainer';

const NotificationsModal = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [filter, setFilter] = useState('all');

	const {
		data: notifications,
		refetch,
		isLoading,
		isError,
	} = useNotifications({ page: 1, page_size: 5, is_read: filter === 'all' ? null : false });

	const datesObject = notifications?.results?.reduce((group, notification) => {
		let date = notification?.created_at?.split('T')[0];

		const today = new Date();
		const yesterday = new Date();
		yesterday.setDate(yesterday.getDate() - 1);

		if (today.toDateString() === new Date(date).toDateString()) {
			date = 'Today';
		} else if (yesterday.toDateString() === new Date(date).toDateString()) {
			date = 'Yesterday';
		} else {
			date = getDateTime(date, 'mm dd yyyy');
		}

		if (!group[date]) {
			group[date] = [];
		}
		group[date].push(notification);

		return group;
	}, {});

	const groupedByDateNotifications = datesObject
		? Object.keys(datesObject).map((date) => ({
				date,
				children: datesObject[date],
		  }))
		: [];

	const handleClose = () => {
		let previousParams = getPreviousSearchParams(searchParams);
		delete previousParams[queryStrings.notifications];

		setSearchParams(previousParams, { replace: true });
	};

	return (
		<Container onClick={(event) => (event.target === event.currentTarget ? handleClose() : null)}>
			<div>
				<div className="header">
					<header>
						<h2>Notification </h2>
						<CloseModal tabIndex={0} onClick={handleClose} />
					</header>
					<hr />
					<nav>
						<button className={filter === 'all' ? 'active' : ''} onClick={() => setFilter('all')}>
							All
						</button>
						<button className={filter === 'unread' ? 'active' : ''} onClick={() => setFilter('unread')}>
							Unread
						</button>
						<NotificationFilter />
					</nav>
					<hr />
				</div>

				{isLoading ? (
					<center>
						<Loader />
					</center>
				) : isError ? (
					<div className="empty-state">
						<p>Unable to fetch notifications at the moment</p>
					</div>
				) : !notifications?.results || notifications?.results?.length === 0 ? (
					<div className="empty-state">
						<p>You don't have any {filter === 'unread' ? 'unread' : 'new'} notifications</p>
					</div>
				) : (
					<div className="content">
						{groupedByDateNotifications.map((group) => (
							<div className="group" key={group?.date}>
								<div className="day">
									<span>{group?.date}</span>
								</div>

								{group.children.map((notification) => (
									<NotificationContainer
										notification={notification}
										refetch={refetch}
										handleCloseModal={handleClose}
										key={notification?.id}
										className={notification?.is_read === false ? 'notification unread' : 'notification'}
									>
										<img src={ApplicantAvatar} alt="" />

										<div>
											<p dangerouslySetInnerHTML={{ __html: notification?.content }}></p>
											<span>
												{getDateTime(notification.created_at, 'mm dd yyyy')}, {getDateTime(notification.created_at, '', false)}
											</span>
										</div>
									</NotificationContainer>
								))}
							</div>
						))}
					</div>
				)}
			</div>
		</Container>
	);
};
export default NotificationsModal;
