import { Outlet } from 'react-router-dom';
import { useDecodedUser } from '../../../../hooks';
import { isInstitutionAdmin, isInstitutionDepartmentHead, isInstitutionSupervisor } from '../../../../utilities';
import { PageNotFound } from '../../../../views';
import { UserType } from '../../../../types';

const InstitutionUserOnly = () => {
	const user = useDecodedUser();

	return isInstitutionAdmin(user as UserType) ||
		isInstitutionDepartmentHead(user as UserType) ||
		isInstitutionSupervisor(user as UserType) ? (
		<>
			<Outlet />
		</>
	) : (
		<PageNotFound />
	);
};

export default InstitutionUserOnly;
