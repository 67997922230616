import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	gap: 0.625rem;
	align-items: flex-start;
	max-width: 80%;

	&.mine {
		flex-direction: row-reverse;
		margin-left: auto;
	}

	:not(:last-child) {
		margin-bottom: 1.25rem;
	}

	img {
		flex-shrink: 0;
		width: 1.25rem;
		height: auto;
		border-radius: 50%;
	}
	.initials {
		color: #4060ea;
		font-size: 1.125rem;
		font-weight: 700;
		line-height: 132%;
		width: 2.5rem;
		height: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background: #dae0fb;
		border: 2px solid #fff;
		flex-shrink: 0;

		display: none;
	}
	.text-con {
		/* flex: 1; */
		width: max-content;
		max-width: 500px;
		padding: 0.5rem 0.75rem;
		/* background-color: #d3e7d6; */
		background-color: #f5f6f9;
		border-radius: 8px 8px 8px 0;

		&.mine {
			border-radius: 8px 8px 0 8px;
		}
	}
	/* .name-date-group {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.name {
		font-weight: 600;
		font-size: 0.875rem;
		line-height: 1.2;
	} */
	.date {
		color: #bbbaba;
		font-size: 0.75rem;
		line-height: 1.2;
		display: block;
		width: max-content;
		margin-left: auto;
	}
	.message-con {
		font-size: 0.875rem;
		margin-top: 0.375rem;

		ul,
		ol {
			padding: 0 1rem;
		}
		ul {
			list-style: disc;
		}
	}
	.name {
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}
`;
