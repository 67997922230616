import { NavLink } from 'react-router-dom';
import { Approutes } from '../../../../constants';
import { useMessage } from '../../../../hooks';

const InternLinks = ({ closeMobileNav }) => {
	const { numberOfUnreadMessages } = useMessage();

	return (
		<>
			<li>
				<NavLink to={Approutes.intern.tasks.overview} onClick={closeMobileNav} end>
					Overview
				</NavLink>
			</li>
			<li>
				<NavLink to={Approutes.intern.tasks.my_tasks} onClick={closeMobileNav}>
					My Tasks
				</NavLink>
			</li>
			{/* <li>
								<NavLink to={Approutes.messages} onClick={closeMobileNav}>
									Messages
								</NavLink>
							</li> */}
			<li>
				<NavLink to={Approutes.intern.tasks.logs} onClick={closeMobileNav}>
					Logs
				</NavLink>
			</li>
			<li>
				<NavLink to={Approutes.intern.supervisor} onClick={closeMobileNav}>
					Supervisor
				</NavLink>
			</li>
			<li>
				<NavLink to={Approutes.messages.index} onClick={closeMobileNav} className={numberOfUnreadMessages > 0 ? 'unread' : ''}>
					Messages
				</NavLink>
			</li>
			<li>
				<NavLink to={Approutes.profile} onClick={closeMobileNav}>
					Profile
				</NavLink>
			</li>
			{/* <li>
								<NavLink to={Approutes.intern.add_ons} onClick={closeMobileNav}>
									Add-Ons
								</NavLink>
							</li> */}
			<li>
				<NavLink to={Approutes.settings.index} onClick={closeMobileNav}>
					Settings
				</NavLink>
			</li>
		</>
	);
};
export default InternLinks;
