import styled from 'styled-components';

export const StyledSelectedApplicants = styled.div`
	display: flex;
	align-items: center;
	gap: 0.56rem;
	flex-shrink: 0;

	> span {
		color: #4060ea;
		font-size: 0.875rem;
		font-weight: 700;
		line-height: 132%;
	}
	div {
		display: flex;

		span {
			color: #4060ea;
			font-size: 1.125rem;
			font-weight: 900;
			line-height: 132%;
			width: 2.5rem;
			height: 2.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background: #dae0fb;
			border: 2px solid #fff;
			flex-shrink: 0;
		}
	}
`;
