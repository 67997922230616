import { useState } from 'react';
import { Button, InputGroup, SelectGroup } from '../../../ui';
import { CloseModal } from '../../../assets/svgs';
import { Container } from './styles';
import { useGetApplicantTask, useGetProjects, useNotify, useSubmitLog } from '../../../hooks';
import { useEffect } from 'react';
import { handleApiError } from '../../../utilities';

const LogTimesheet = ({ projectId, taskId, handleClose }) => {
	const [formData, setFormData] = useState({
		project_name: '',
		log_name: '',
		task: '',
		task_description: '',
		key_achievements: '',
	});
	const notify = useNotify();

	const { mutate: submitLog, isLoading: isSubmittingLog } = useSubmitLog();
	const { data: projects, isLoading: isProjectLoading } = useGetProjects({ page: 1, page_size: 5, status: 'in-progress' });
	const { data: tasks, isLoading: isTasksLoading } = useGetApplicantTask({
		projectId: projects?.results?.find((project) => project?.name === formData?.project_name)?.id || '',
		page: 1,
		page_size: 30,
		is_completed: false,
	});

	const handleChange = (event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const handleSubmit = (event) => {
		event.preventDefault();

		let taskId = tasks?.results?.find((task) => task?.name === formData.task)?.id;

		submitLog(
			{
				id: taskId,
				data: {
					achievement: formData.key_achievements,
					description: formData.task_description,
					log_name: formData.log_name,
					related_task: taskId,
					related_project: projects?.results[0]?.id,
				},
			},
			{
				onSuccess: () => {
					notify({ message: 'Report was logged successfully', status: 'success', toastOptions: { toastId: 'log_submission_success' } });
					handleClose();
				},
				onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'log_submission_error' } }),
			}
		);
	};

	useEffect(
		() =>
			setFormData((prev) => ({
				...prev,
				project_name: projects?.results?.find((project) => project?.id === projectId)?.name || projects?.results[0]?.name || '',
			})),
		[projects, projectId]
	);
	useEffect(
		() => setFormData((prev) => ({ ...prev, task: tasks?.results?.find((task) => task?.id === taskId)?.name || '' })),
		[taskId, tasks]
	);

	return (
		<Container>
			<form onSubmit={handleSubmit}>
				<CloseModal tabIndex={0} onClick={handleClose} />
				<h2>Log Timesheet</h2>
				<p>Provide information below to log timesheet for remarks</p>

				<div className="grid">
					<SelectGroup
						label="Project Name"
						name="project_name"
						onChange={handleChange}
						placeholder="Select Project"
						value={formData.project_name}
						options={projects?.results?.map((project) => project?.name)}
						required
						emptyOptionsMessage="You're currently not in any active project"
					/>
					<InputGroup label="Log Name" name="log_name" onChange={handleChange} placeholder="Log Name" value={formData.log_name} required />
					<SelectGroup
						label="Tasks Name"
						name="task"
						onChange={handleChange}
						placeholder="Select Task"
						value={formData.task}
						loading={isTasksLoading}
						options={tasks?.results?.map((task) => task?.name)}
						required
						emptyOptionsMessage="You don't have any active task"
					/>
					<InputGroup
						label="Task Description"
						name="task_description"
						onChange={handleChange}
						variant="textarea"
						placeholder="Enter Your Task Description"
						value={formData.task_description}
						required
					/>
					<InputGroup
						label="Key Achievements"
						name="key_achievements"
						onChange={handleChange}
						variant="textarea"
						placeholder="Enter Your Key Achievements"
						value={formData.key_achievements}
						required
					/>
				</div>

				<Button disabled={isProjectLoading || isTasksLoading} loading={isSubmittingLog}>
					Submit For Remarks
				</Button>
			</form>
		</Container>
	);
};
export default LogTimesheet;
