import { ActiveHeadType } from '../../../../types';

const ActiveDepartmentHeads = ({ page, heads }: { page: number; heads: ActiveHeadType[] | undefined }) => {
	return (
		<>
			<div className="table-container">
				<table>
					<thead>
						<tr>
							<th>S/N</th>
							<th>Head's Name</th>
							<th>Head's Email</th>
							<th>Head's Number</th>
							<th>Department</th>
						</tr>
					</thead>
					<tbody>
						{heads?.map((head, index) => (
							<tr key={head?.id}>
								<td>{(page - 1) * 10 + index + 1}</td>
								<td>{head?.dept_head_name}</td>
								<td>{head?.email || 'N/A'}</td>
								<td>{head?.phone_number || 'N/A'}</td>
								<td>{head?.department_name || 'N/A'}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{heads?.map((head) => (
				<div key={head?.id} className="mobile-card">
					<div className="flexed">
						<div>
							<b>Department's Name</b>
							<p>{head?.dept_head_name}</p>
						</div>
						{/* <div className="action-con">
						</div> */}
					</div>
					<div className="flexed">
						<div>
							<b>Department's Number</b>
							<p>{head?.phone_number || 'N/A'}</p>
						</div>
						<div>
							<b>Department Email</b>
							<p>{head?.email || 'N/A'}</p>
						</div>
					</div>
					<div>
						<b>Department</b>
						<p>{head?.department_name || 'N/A'}</p>
					</div>
				</div>
			))}
		</>
	);
};
export default ActiveDepartmentHeads;
