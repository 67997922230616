import { Button, InputGroup } from '../../../../ui';
import { Container } from './styles';
import { Approutes } from '../../../../constants';
import { useNotify, useSignup } from '../../../../hooks';
import { handleApiError } from '../../../../utilities';

const CreateAccount = ({ formData, setFormData, setStep }) => {
	const notify = useNotify();
	const { mutate: signup, isLoading } = useSignup();

	const handleChange = (event) => {
		let name = event.target.name || '';
		let value = event.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const handleSubmit = (event) => {
		event.preventDefault();

		signup(
			{ email: formData.company_email, phone_number: formData.phone_number, password: formData.password, role: 'STARTUP' },
			{
				onSuccess: () => setStep(2),
				onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'signup' } }),
			}
		);
	};

	return (
		<Container>
			<h6>START-UP</h6>
			<h2>Create an Account</h2>
			<p>Provide Credentials below to create an Account</p>

			<form onSubmit={handleSubmit}>
				<InputGroup
					label="Company's Email Address"
					type="email"
					name="company_email"
					onChange={handleChange}
					placeholder="Enter Email Address"
					value={formData.company_email}
					required
				/>
				<InputGroup
					label="Contact Number"
					type="tel"
					name="phone_number"
					onChange={handleChange}
					placeholder="Enter Mobile Number"
					value={formData.phone_number}
					required
				/>
				<InputGroup
					label="Password"
					type="password"
					name="password"
					onChange={handleChange}
					placeholder="Password"
					value={formData.password}
					required
					minLength={4}
				/>

				<div className="terms-and-condition">
					<InputGroup type="checkbox" name="agree" onChange={handleChange} required />
					<span>
						I agree to the{' '}
						<a href={Approutes.docs.terms_of_use} target="_blank" rel="noreferrer">
							Terms and Condition
						</a>
					</span>
				</div>

				<Button type="submit" loading={isLoading}>
					Create an Account
				</Button>

				<span className="login">
					Already have an account? <a href={Approutes.login}>Login!</a>
				</span>
			</form>

			<a href={Approutes.signup.intern}>Create Intern /Fresh Graduate Account</a>
		</Container>
	);
};

export default CreateAccount;
