import { UserType } from '../types';

export const isStartUp = (user: UserType | undefined) => user?.role?.includes('STARTUP');

export const isPureIntern = (user: UserType | undefined) => user?.role?.includes('INTERN');

export const isIntern = (user: UserType | undefined) => user?.role?.includes('INTERN') || user?.role?.includes('INSTITUTION_INTERN');

export const isInternSupervisor = (user: UserType | undefined) => user?.role?.includes('INTERN_SUPERVISOR');

export const isAdmin = (user: UserType | undefined) => user?.role?.includes('ADMIN');

export const isInstitutionAdmin = (user: UserType | undefined) => user?.role?.includes('INSTITUTION_ADMIN');

export const isInstitutionDepartmentHead = (user: UserType | undefined) => user?.role?.includes('INSTITUTION_DEPARTMENTAL_HEAD');

export const isInstitutionSupervisor = (user: UserType | undefined) => user?.role?.includes('INSTITUTION_SUPERVISOR');

export const isInstitutionIntern = (user: UserType | undefined) => user?.role?.includes('INSTITUTION_INTERN');
