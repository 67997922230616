import { useRef, useState } from 'react';
import { Container } from './styles';
import { Button, Loader, Pagination, SearchBar } from '../../../../ui';
import { useGetPendingSupervisors, useGetSignedUpSupervisors } from '../../../../hooks';
import { ButtonPlus, ProjectEmpty } from '../../../../assets/svgs';
import { Filter } from '../../../../components';
import { useCallback } from 'react';
import { GeneralChangeEventType, FilterValue } from '../../../../types';
import { ActiveSupervisors, PendingSupervisors } from '../../components';
import { InviteSupervisorModal } from '../../modals';

type SubControlType = 'pending' | 'active';

const InstitutionSupervisors = () => {
	const pageSize = useRef(6);
	const [page, setPage] = useState(1);
	const [isAddSupervisorModalOpen, setIsAddSupervisorModalOpen] = useState(false);
	const [subControl, setSubControl] = useState<SubControlType>('active');
	const [formData, setFormData] = useState({
		search: '',
		filter: (() => {
			let state: FilterValue = {};
			(subControl === 'pending' ? [] : []).forEach(
				(option: { name: string | number; queryKey: any }) => (state[option?.name] = { value: '', queryKey: option?.queryKey })
			);

			return state;
		})(),
	});

	const {
		data: pendingSupervisors,
		isLoading: isLoadingPendingSupervisors,
		isError: isPendingSupervisorError,
	} = useGetPendingSupervisors({ page, page_size: pageSize.current, search: formData.search, enabled: true });
	const {
		data: activeSupervisors,
		isLoading: isLoadingActiveSupervisors,
		isError: isActiveSupervisorError,
	} = useGetSignedUpSupervisors({ page, page_size: pageSize.current, search: formData.search, enabled: true });

	const isFetchingData = subControl === 'pending' ? isLoadingPendingSupervisors : isLoadingActiveSupervisors;
	const isDataError = subControl === 'pending' ? isPendingSupervisorError : isActiveSupervisorError;

	const result = subControl === 'pending' ? pendingSupervisors : activeSupervisors;

	const handleChange: GeneralChangeEventType = useCallback((event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name as string]: value }));
	}, []);

	const handleSubControl = (value: SubControlType) => {
		setPage(1);
		setSubControl(value);

		handleChange(null, 'search', '');
		handleChange(
			null,
			'filter',
			(() => {
				let state: FilterValue = {};
				(value === 'pending' ? [] : []).forEach(
					(option: { name: string | number; queryKey: any }) => (state[option?.name] = { value: '', queryKey: option?.queryKey })
				);

				return state;
			})()
		);
	};

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Supervisors</h4>
					{/* <p>Update your photo and personal details</p> */}
				</div>

				<Button onClick={() => setIsAddSupervisorModalOpen(true)}>
					<ButtonPlus /> Invite Supervisor
				</Button>
			</div>

			<div className="table-header">
				<div className="controls">
					<button className={subControl === 'active' ? 'active' : ''} onClick={() => handleSubControl('active')}>
						Active
					</button>
					<button className={subControl === 'pending' ? 'active' : ''} onClick={() => handleSubControl('pending')}>
						Pending
					</button>
				</div>

				<div className="filter-con">
					<SearchBar
						name="search"
						placeholder={`Search through ${subControl} supervisors`}
						handleChange={handleChange}
						value={formData.search}
					/>
					<Filter name="filter" onChange={handleChange} value={formData.filter} options={subControl === 'pending' ? [] : []} />
				</div>
			</div>

			{isFetchingData ? (
				<center>
					<Loader />
				</center>
			) : isDataError ? (
				<center>
					<div>
						<ProjectEmpty />
						<p className="error-message">Unable to fetch {subControl} supervisors at the moment</p>
					</div>
				</center>
			) : !result?.results || result?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p className="error-message">No {subControl} supervisors to display</p>
				</div>
			) : subControl === 'pending' ? (
				<PendingSupervisors page={page} supervisors={pendingSupervisors?.results} />
			) : subControl === 'active' ? (
				<ActiveSupervisors page={page} supervisors={activeSupervisors?.results} />
			) : null}

			<Pagination page={page} setPage={setPage} maxPages={Math.ceil((result?.total || 0) / pageSize.current)} disabled={isFetchingData} />

			{isAddSupervisorModalOpen ? <InviteSupervisorModal handleClose={() => setIsAddSupervisorModalOpen(false)} /> : null}
		</Container>
	);
};
export default InstitutionSupervisors;
