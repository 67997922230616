import { useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from '.';
import { Appurls } from '../constants';

export const useValidateCouponCode = ({ code, plan_package }: { code: string; plan_package: string }) => {
	const axiosPrivate = useAxiosPrivate();

	const query = useQuery({
		queryFn: () =>
			axiosPrivate.get(`${Appurls.coupon_code.validate_code}?code=${code}&package=${plan_package}`).then((response) => response?.data),
		queryKey: ['validate-coupon-code', code, plan_package],
		enabled: code ? true : false,
		retry: false,
	});

	return {
		...query,
		isLoading: query.isLoading && query.fetchStatus !== 'idle',
	};
};
