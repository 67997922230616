export const hasInternCompletedProfile = (configDetails) =>
	configDetails?.intern_profile_step_1_complete === true &&
	configDetails?.intern_profile_step_2_complete === true &&
	configDetails?.intern_profile_step_3_complete === true;

export const hasStartupCompletedProfile = (configDetails) =>
	configDetails?.startup_profile_step_1_complete === true && configDetails?.startup_profile_step_2_complete === true;

export const hasInstitutionCompletedProfile = (configDetails) =>
	configDetails?.institution_profile_step_1_complete === true && configDetails?.institution_profile_step_2_complete === true;
