import { Outlet } from 'react-router-dom';
import Header from './Header';
import { Container } from './styles';

const AuthLayout = () => {
	return (
		<Container>
			<Header />

			<section>
				<article>
					<h1>Empowering Start-Ups with Fresh Talent.</h1>
					<p>Fueling Start-Ups with Eager Interns and Graduates, to drive growth and empower Innovation.</p>
				</article>

				<div className="outlet-con">
					<Outlet />
				</div>
			</section>

			<div></div>
		</Container>
	);
};

export default AuthLayout;
