import { Outlet } from 'react-router-dom';
import { useDecodedUser } from '../../../../hooks';
import { isInstitutionAdmin } from '../../../../utilities';
import { PageNotFound } from '../../../../views';
import { UserType } from '../../../../types';

const InstitutionAdminOnly = () => {
	const user = useDecodedUser();

	return isInstitutionAdmin(user as UserType) ? (
		<>
			<Outlet />
		</>
	) : (
		<PageNotFound />
	);
};

export default InstitutionAdminOnly;
