export const NOTIFICATION_TYPES = {
	// Handled
	project_resource_invitation: 'PROJECT_RESOURCE_INVITATION',
	task_assignment: 'TASK_ASSIGNMENT',
	password_changed: 'PASSWORD_CHANGED',
	timesheet_logged: 'TIMESHEET_LOGGED',
	profile_declined: 'PROFILE_DECLINED',

	// Unhandled
	subscription: 'SUBSCRIPTION',
};
