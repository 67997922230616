import { StyledSelectedApplicants } from './styles';

const SelectedApplicants = ({ applicants = [], handleClick }) => {
	let calculatedLength = applicants.length <= 2 ? applicants.length : 2;

	return (
		<StyledSelectedApplicants>
			{applicants.length > 2 ? <span>{applicants.length - 2} More +</span> : null}

			<div style={{ width: `${calculatedLength * 2 + 0.5}rem` }}>
				{Array(calculatedLength)
					.fill('')
					.map((_el, index) => (
						<span
							key={index}
							style={{ transform: `translateX(-${index * 0.5}rem)`, cursor: handleClick ? 'pointer' : 'auto' }}
							onClick={() => handleClick && handleClick(applicants[index])}
						>
							{index + 1}
						</span>
					))}
			</div>
		</StyledSelectedApplicants>
	);
};
export default SelectedApplicants;
