import styled from 'styled-components';

export const StyledFilter = styled.div`
	position: relative;
	display: flex;
	width: 9.2rem;

	:focus-within {
		input {
			outline-color: #4060ea;
		}
		.chevron-con svg {
			transform: rotate(180deg);
		}
		> ul {
			display: block;
		}
	}

	> svg {
		position: absolute;
		top: 1.26rem;
		left: 1.5rem;
		z-index: 2;
		pointer-events: none;
	}
	.chevron-con {
		position: absolute;
		bottom: 0.85rem;
		right: 0.75rem;
		display: grid;
		padding: 0.5rem;
		pointer-events: none;
		z-index: 2;

		svg {
			width: 0.7rem;
		}
	}
	input {
		width: 100%;
		background-color: #fff;
		cursor: pointer;
		display: block;
		padding: 1rem 1.5rem 1rem 3rem;
		font-family: inherit;
		font-size: 0.875rem;
		line-height: 130%;
		border-radius: 0.125rem;
		border: 1.5px solid rgba(229, 229, 229, 0.7);
	}
	ul {
		background: #fff;
		border: 1px solid rgba(18, 18, 18, 0.07);
		border-radius: 0.25rem;
		position: absolute;
		width: 100%;
		z-index: 3;
		display: none;

		li {
			color: #504f4f;
			text-align: center;
			font-size: 0.75rem;
			font-weight: 500;
			cursor: pointer;
			outline: none;
			padding: 0.5rem 1.25rem;
			position: relative;

			:first-child {
				border-top-left-radius: 0.25rem;
				border-top-right-radius: 0.25rem;
			}
			:last-child {
				border-bottom-left-radius: 0.25rem;
				border-bottom-right-radius: 0.25rem;
			}

			:hover,
			:focus {
				background: #f9fafb;
			}
			&.active {
				background: #4060ea;
				color: #fff;
				font-weight: 600;
			}
			:hover ul {
				display: block;
			}
		}

		li > ul {
			right: 100%;
			top: 0;
			overflow: auto;
			max-width: 14rem;
			min-width: 100%;
			width: max-content;
			max-height: 15rem;
		}
	}
	> ul {
		left: 50%;
		top: calc(100% + 0.5rem);
		min-width: max-content;
		transform: translateX(-50%);

		:hover {
			display: block;
		}
	}
`;
