import styled from 'styled-components';
import { StyledButton } from '../../../ui/Button/styles';
import { maxQuery } from '../../../utilities';
import { StyledInputGroup } from '../../../ui/InputGroup/styles';
import { StyledSelectGroup } from '../../../ui/SelectGroup/styles';
import { Tags } from '../../../ui/Tag/styles';
import { StyledFileDownloadGroup } from '../../../ui/FileDownloadGroup/styles';

export const Container = styled.div`
	padding: 2.5rem 6.25rem;
	padding: 2.5rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);

	.header {
		margin-bottom: 1.5rem;
		display: flex;
		align-items: flex-start;
		gap: 1.5rem;
		flex-wrap: wrap;

		${maxQuery('400px')} {
			gap: 1rem;
		}

		> div:first-child {
			margin-right: auto;

			${maxQuery('md')} {
				flex-basis: 100%;
			}

			h4 {
				color: #11071a;
				font-family: 'Urbanist', sans-serif;
				font-size: 1.5rem;
				font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
				font-weight: 700;
				margin-bottom: 0.25rem;
				line-height: 120%;
			}
			p {
				color: #828282;
				font-size: 0.75rem;
				line-height: 120%;
			}
		}
		${StyledButton} {
			padding: 0.86rem 1.5rem;
			padding: clamp(0.6rem, 0.4556rem + 0.4622vw, 0.86rem) clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);

			&.deactivate path {
				fill: #4060ea;
			}

			span {
				display: flex;
				align-items: center;
				gap: 0.5rem;
			}
		}
	}

	> section {
		display: flex;
		min-height: calc(100vh - 16rem);

		aside {
			border-radius: 0.25rem 0rem 0rem 0.25rem;
			background: #4060ea;
			min-height: 100%;
			width: 30%;
			display: grid;
			place-items: center;
			max-width: 21.25rem;
			padding-top: 5rem;
			flex-shrink: 0;

			${maxQuery('xl')} {
				display: none;
			}

			svg {
				max-width: 100%;
				max-height: 100%;
			}
		}
		main {
			overflow: hidden;
			min-height: 100%;
			flex: 1;
			background: #fff;
			border-radius: 0 0.25rem 0.25rem 0;
			padding: 3rem 5rem;
			padding: clamp(1.5rem, 0.6667rem + 2.6667vw, 3rem) clamp(1.5rem, -0.4444rem + 6.2222vw, 5rem);

			.img-name-group {
				display: flex;
				align-items: center;
				gap: 1rem;
				margin-bottom: 1rem;

				${maxQuery('sm')} {
					flex-direction: column;
					justify-content: center;
				}

				h5 {
					font-size: 1.125rem;
					font-weight: 600;
					margin-bottom: 0.65rem;

					${maxQuery('xs')} {
						text-align: center;
					}
				}

				.name-group div {
					display: flex;

					${maxQuery('xs')} {
						flex-direction: column;
						justify-content: center;
						text-align: center;
					}

					span {
						color: #504f4f;
						font-size: 0.8125rem;

						:first-child {
							padding-right: 1rem;
							margin-right: 1rem;
							border-right: 1px solid #dadada80;

							${maxQuery('xs')} {
								padding-right: unset;
								margin-right: unset;
								border-right: unset;
							}
						}
					}
				}
			}

			.img-con-con {
				border-radius: 50%;
				background: lightgray;
				width: 8.5rem;
				height: 8.5rem;
				display: grid;
				place-items: center;
				border: 6px solid #f6f7fe;
				position: relative;

				.img-con {
					position: relative;
					overflow: hidden;
					width: 100%;
					height: 100%;
					border-radius: 50%;
				}
				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					border-radius: 50%;
					width: 100%;
					height: 100%;
					background-size: cover;
					object-fit: cover;
				}
				span {
					font-size: 3rem;
					font-weight: 900;
					line-height: 132%;
				}

				svg {
					position: absolute;
					bottom: 0.4rem;
					right: 0;
					z-index: 2;
					cursor: pointer;
				}
				input {
					width: 0;
					opacity: 0;
					visibility: hidden;
					position: absolute;
				}
			}

			.controls {
				display: flex;
				gap: 3rem;
				gap: clamp(1rem, -4rem + 8vw, 3rem);

				button {
					font-size: 1.125rem;
					font-size: clamp(1rem, 0.6875rem + 0.5vw, 1.125rem);
					font-weight: 600;
					color: #bbbaba;
					background-color: transparent;
					padding-bottom: 0.5rem;
					position: relative;

					${maxQuery('sm')} {
						display: none;
					}

					&.active {
						color: #12091b;

						::before {
							position: absolute;
							content: '';
							bottom: 0;
							left: 0;
							background: #4060ea;
							width: 4rem;
							height: 0.125rem;
						}
					}
				}

				${StyledSelectGroup} {
					display: none;
					width: 100%;

					${maxQuery('sm')} {
						display: block;
					}
				}
			}

			.inputs-group {
				margin-top: 2rem;
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 1.5rem;

				${maxQuery('md')} {
					grid-template-columns: 1fr;
				}

				.document-group {
					overflow: hidden;

					> button {
						color: #4060ea;
						font-size: 0.75rem;
						font-weight: 500;
						text-align: left;
						text-decoration-line: underline;
						background-color: transparent;
					}
				}
				.submit-btn {
					grid-column: 1/3;
					margin-top: 2rem;
				}
			}
			.info-con {
				margin-top: 1rem;

				> b {
					color: #000;
					font-size: 1rem;
					font-weight: 600;
				}

				> div {
					display: flex;
					padding-top: 1rem;
					gap: 1rem;

					:not(:last-child) {
						padding-bottom: 1rem;
						border-bottom: 1px solid #dadada80;
					}
					&.ignore-border {
						border-bottom: none;
					}
					&.ignore-align > div:nth-child(2) {
						text-align: initial;
					}

					${maxQuery('md')} {
						gap: 0;
						flex-direction: column;
					}

					> div {
						overflow: hidden;
						flex: 1;

						${Tags} {
							margin-top: 0.5rem;
						}
						:nth-child(2) {
							text-align: right;

							${StyledFileDownloadGroup} {
								text-align: left;
							}

							${Tags} {
								justify-content: flex-end;

								${maxQuery('md')} {
									justify-content: flex-start;
								}
							}

							${maxQuery('md')} {
								text-align: left;
								margin-top: 1rem;
								padding-top: 1rem;
								border-top: 1px solid #dadada80;
							}
						}

						> span {
							color: #a1a0a0;
							font-size: 0.875rem;
							font-weight: 600;
							display: block;
						}
					}

					${StyledInputGroup} input {
						background-color: #fff;
					}
				}
				${StyledFileDownloadGroup} {
					width: auto;
				}
			}
		}
	}
`;
