import { Outlet } from 'react-router-dom';
import { useDecodedUser } from '../../hooks';
import { PageNotFound } from '../../views';
import { isInternSupervisor } from '../../utilities';

const SupervisorOnly = () => {
	const user = useDecodedUser();

	return isInternSupervisor(user) ? <Outlet /> : <PageNotFound />;
};

export default SupervisorOnly;
