import styled from 'styled-components';
import { maxQuery } from '../../utilities';

export const StyledFileDownloadGroup = styled.a`
	display: flex;
	position: relative;
	border-radius: 0.25rem;
	border: 1px solid rgba(229, 229, 229, 0.7);
	background: #fff;
	width: 19.1875rem;

	${maxQuery('xs')} {
		width: 100%;
	}

	div:first-child {
		border-radius: 0.25rem;
		border: 1px solid rgba(51, 51, 51, 0.1);
		background: rgba(227, 232, 252, 0.1);
		padding: 0 1rem;
		display: grid;
		place-items: center;
	}

	div:nth-child(2) {
		padding: 0.82rem 1rem;
		margin-right: auto;
		overflow: hidden;
		text-align: left;

		p {
			color: #11071a;
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 130%;

			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
		}
		span {
			color: #1e1e1e;
			font-size: 0.75rem;
			line-height: 158%;
		}
	}
	div:nth-child(3) {
		display: grid;
		place-items: center;
		padding-right: 1.2rem;
	}
`;
