import styled from 'styled-components';
import { maxQuery } from '../../../utilities';
import { StyledButton } from '../../../ui/Button/styles';
import { CheckMark } from '../../../assets/svgs';
import { SubcriptionBg } from '../../../assets/images';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	inset: 0;
	z-index: 99;

	> div {
		border-radius: 0.25rem;
		background: #fff;
		padding: 0 6.25rem 2rem;
		padding: 0 clamp(3rem, -5.125rem + 13vw, 6.25rem) clamp(1.5rem, 1.2222rem + 0.8889vw, 2rem);
		width: 73.875rem;
		max-width: calc(100vw - 4rem);
		max-height: calc(100vh - 4rem);
		overflow: auto;
		text-align: center;

		&.intern {
			width: 60rem;
			padding: clamp(1.5rem, 0.6667rem + 2.6667vw, 3rem) clamp(1.5rem, -0.4444rem + 6.2222vw, 5rem) clamp(1.5rem, 1.2222rem + 0.8889vw, 2rem);

			.plans {
				grid-template-columns: 1fr 1fr;
			}
		}

		.header {
			position: sticky;
			top: 0;
			background-color: #fff;
			z-index: 2;
			padding-top: 2rem;
			padding-top: clamp(1.5rem, 0.6667rem + 2.6667vw, 3rem);

			svg {
				margin-left: auto;
				display: block;
				cursor: pointer;
				width: 1.25rem;
				width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);

				:active {
					outline: none;
				}
			}
			h2 {
				text-align: center;
				font-size: 2rem;
				font-size: clamp(1.75rem, 1.6111rem + 0.4444vw, 2rem);
				margin-top: 0.5rem;
				color: #070d19;
			}
		}
		h2 + p,
		.bottom {
			color: #070d19;
			font-size: 1rem;
			font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
			line-height: 120%;
			padding: 0.5rem 0 1.5rem;
			text-align: center;
			border-bottom: 1px solid #ebebeb;

			a {
				color: #4060ea;
				font-weight: 600;
			}
		}
		.bottom {
			padding: 0;
			border: none;
		}
		.hidden {
			background: url(${SubcriptionBg});
		}
		.plans {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 1rem 2rem;
			margin: 3rem 0 2.5rem;
			padding: 0 2px;
			margin: clamp(1.5rem, 0.6667rem + 2.6667vw, 3rem) 0 clamp(1.5rem, 0.9444rem + 1.7778vw, 2.5rem);

			${maxQuery('lg')} {
				display: none;
			}

			> form,
			> div {
				border-radius: 0.3125rem;
				background: #f1f4fe;
				padding: 2rem;
				padding: clamp(1rem, 0.4444rem + 1.7778vw, 2rem);
				cursor: pointer;
				border: 2px solid transparent;
				display: flex;
				flex-direction: column;

				&.has-promo {
					padding-top: 0;

					.price {
						color: #4060ea;
						margin: 0 auto 1.5rem;
						font-size: 2.22rem;
						font-size: clamp(1rem, 0.3333rem + 2.1333vw, 2.2rem);
					}
				}

				&.active {
					border: 2px solid #4060ea;
				}

				:hover {
					background: url(${SubcriptionBg});

					&.active {
						outline: 2px solid #4060ea;
					}

					.duration,
					.price,
					.video,
					.condition,
					ul {
						color: #fff;
					}
					.promo {
						color: #4060ea;
						span {
							background-color: #f2c94c;

							svg path {
								fill: #4060ea;
							}
						}

						.bg path {
							fill: #f2c94c;
						}
					}

					${StyledButton} {
						color: #4060ea;
						background-color: #fff;
						border: 1px solid #fff;
					}
				}

				span {
					display: block;
				}
				.promo {
					width: max-content;
					margin: auto;
					color: #fff;
					font-family: 'Rethink Sans', sans-serif;
					font-size: 0.902rem;
					font-weight: 700;
					letter-spacing: 0.09769rem;
					position: relative;
					margin-bottom: 3rem;

					span {
						display: flex;
						align-items: center;
						gap: 0.23rem;
						background-color: #4060ea;
						padding: 0.4rem 1.2rem 0;
						position: relative;
						z-index: 2;
					}

					.bg {
						position: absolute;
						z-index: 1;
						width: calc(100% + 2px);
						height: auto;
						top: 0;
						left: 0;
					}
				}
				.duration {
					color: #4f5362;
					font-size: 0.88425rem;
					font-weight: 700;
					line-height: 150%;
					letter-spacing: 0.05894rem;
					text-transform: uppercase;
					text-decoration-line: underline;
				}
				.price {
					color: #070d19;
					font-size: 2.4rem;
					font-size: clamp(1rem, 0.2222rem + 2.4889vw, 2.4rem);
					line-height: 120%;
					font-weight: 500;
					margin: 1rem 0 0.5rem;

					&.old {
						color: #ea2d2e;
						font-size: 3.6rem;
						font-size: clamp(1.5rem, 0.3333rem + 3.7333vw, 3.6rem);
						position: relative;
						width: fit-content;
						margin: 1.25rem auto 0;

						::before {
							content: '';
							position: absolute;
							top: 50%;
							left: 0;
							width: 100%;
							border-top: 2px solid #ea2d2e;
						}
					}
				}
				.refund {
					border-radius: 6.25rem;
					background: #d1f5e2;
					padding: 0.4rem 1rem;
					color: #25b169;
					font-size: 0.75rem;
					font-weight: 700;
					line-height: 150%;
					letter-spacing: 0.02456rem;
					text-transform: uppercase;
					width: max-content;
					margin: 0 auto 1.56rem;
				}
				.video {
					color: #4060ea;
					font-size: 0.75rem;
					font-weight: 700;
					line-height: 150%;
					text-transform: uppercase;

					:hover,
					:focus {
						text-decoration-line: underline;
					}
				}
				ul {
					color: #4f5362;
					font-size: 0.88425rem;
					line-height: 150%;
					letter-spacing: 0.02456rem;
					text-align: left;
					width: fit-content;
					margin: 1.5rem auto 2rem;

					li {
						margin-bottom: 0.5rem;
						position: relative;

						::before {
							content: '';
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							left: -1.3rem;
							background-image: url(${CheckMark});
							background-size: cover;
							background-repeat: no-repeat;
							background-position: center;
							width: 0.8rem;
							height: 0.8rem;
						}
					}
				}
			}
			.condition {
				display: flex;
				justify-content: center;
				gap: 0.5rem;
				color: #101010;
				font-size: 0.75rem;
				font-weight: 500;
				line-height: 120%;
				margin-top: auto;

				input {
					width: 1rem;
					height: 1rem;
				}
				a {
					color: #4060ea;
				}
			}
		}
		.plans.mobile {
			display: none;
			grid-template-columns: 1fr;
			gap: 1rem 2rem;
			margin: 3rem 0 2.5rem;
			margin: clamp(1.5rem, 0.6667rem + 2.6667vw, 3rem) 0 clamp(1.5rem, 0.9444rem + 1.7778vw, 2.5rem);

			${maxQuery('lg')} {
				display: grid;
			}
			> div.has-promo {
				padding-top: 4rem;
				position: relative;
			}
			.promo {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
			}

			.content {
				display: grid;
				grid-template-columns: 1fr auto;

				> div:first-child {
					text-align: left;
				}
				> div:last-child {
					text-align: right;
				}

				${StyledButton} {
					margin: 0.63rem 0 0 auto;
					width: max-content;
					padding: 0.7rem 1.2rem;
				}
			}
			.duration {
				color: #090909;
				font-weight: 700;
			}
			ul {
				color: #090909;
				margin: 0.5rem 0 0 1.4rem;
				font-size: 0.75rem;
				font-size: clamp(0.75rem, 0.7rem + 0.16vw, 0.8rem);
			}
			.price {
				margin: 0 0 0.5rem 0 !important;
				font-weight: 600;

				&.old {
					margin: 0 0 0.5rem auto !important;
					font-weight: 500;
				}
			}
			.refund {
				margin: 0 0 0.5rem auto;
				padding: 0;
				background-color: transparent;
				position: relative;

				::before {
					position: absolute;
					content: '';
					background: #52db94;
					width: 0.75rem;
					height: 0.75rem;
					top: 50%;
					transform: translateY(-50%);
					left: -1rem;
					border-radius: 50%;
				}
			}
			.condition {
				justify-content: flex-start;
				margin-top: 0.5rem;
			}
			${StyledButton} {
				margin-top: 0.5rem;
			}
		}
		${StyledButton} {
			width: 100%;
			margin-top: 2rem;
			color: #fff;
			font-size: 0.68775rem;
			line-height: 125.5%;
			letter-spacing: 0.10663rem;
			text-transform: uppercase;
			padding-left: 0;
			padding-right: 0;
		}
	}
`;
