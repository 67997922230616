import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';
import { useNotify } from './useNotify';
import { handleApiError } from '../utilities';

export const useGetTasks = ({ projectId, page = 1, page_size = 5, search = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(`${Appurls.project.get_tasks(projectId)}?page=${page}&page_size=${page_size}&search=${search}`)
				.then((response) => response?.data),
		queryKey: ['tasks', { projectId, page, page_size, search }],
	});
};
export const useGetTask = ({ taskId }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () => axiosPrivate.get(Appurls.tasks.task(taskId)).then((response) => response?.data),
		queryKey: ['tasks', taskId],
	});
};
export const useOptimisticPatchTask = () => {
	const notify = useNotify();
	const queryClient = useQueryClient();
	const axiosPrivate = useAxiosPrivate();

	return useMutation(({ id, data }) => axiosPrivate.patch(Appurls.tasks.task(id), data), {
		onMutate: async ({ id, data, queryKey }) => {
			await queryClient.cancelQueries({ queryKey: ['tasks', queryKey] });

			const previousTasks = queryClient.getQueryData(['tasks', queryKey]);

			queryClient.setQueryData(['tasks', queryKey], (oldQueryData) => ({
				...oldQueryData,
				results: oldQueryData.results?.map((result) => (result.id === id ? { ...result, ...data } : result)),
			}));

			return { previousTasks };
		},
		onError: (error, { queryKey }, context) => {
			queryClient.setQueryData(['tasks', queryKey], context.previousTasks);
			notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'projects_fetch' } });
		},
		onSettled: () => {
			queryClient.invalidateQueries(['tasks']);
		},
	});
};
export const usePatchTask = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation(({ id, data }) => axiosPrivate.patch(Appurls.tasks.task(id), data));
};
export const useAssignTask = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation(({ taskId, data }) =>
		axiosPrivate.patch(Appurls.tasks.assign_tasks(taskId), data, { headers: { 'Content-Type': 'multipart/form-data' } })
	);
};
export const useGetApplicantTask = ({ projectId = '', page = 1, page_size = 5, is_completed }) => {
	const axiosPrivate = useAxiosPrivate();
	const url = projectId ? Appurls.project.get_applicant_tasks(projectId) : Appurls.project.get_current_tasks;

	return useQuery({
		queryFn: () =>
			axiosPrivate.get(`${url}?page=${page}&page_size=${page_size}&is_completed=${is_completed}`).then((response) => response?.data),
		queryKey: ['applicant-tasks', { projectId, page, page_size, is_completed }],
	});
};
export const useGetApplicantLogs = ({ projectId, page = 1, page_size = 5 }) => {
	const axiosPrivate = useAxiosPrivate();
	const url = projectId ? Appurls.project.get_applicant_logs(projectId) : Appurls.project.get_current_logs;

	return useQuery({
		queryFn: () => axiosPrivate.get(`${url}?page=${page}&page_size=${page_size}`).then((response) => response?.data),
		queryKey: ['applicant-logs', { projectId, page, page_size }],
	});
};
export const useSubmitLog = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation(({ id, data }) => axiosPrivate.patch(Appurls.tasks.submit_log(id), data));
};
export const useAddTask = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation(({ id, data }) => axiosPrivate.post(Appurls.project.add_tasks(id), data));
};
