import styled from 'styled-components';
import { StyledButton } from '../../ui/Button/styles';
import { maxQuery } from '../../utilities';
import { StyledFileGroup } from '../../ui/FileGroup/styles';
import { StyledInputGroup } from '../../ui/InputGroup/styles';
import { StyledApplicantCard } from '../../components/ApplicantCard/styles';
import { StyledSelectedApplicants } from '../../ui/SelectedApplicants/styles';

export const Container = styled.div`
	padding: 2rem 6.25rem;
	padding: 2rem clamp(0rem, -3.4722rem + 11.1111vw, 6.25rem);

	> a {
		color: #252b33;
		font-size: 0.9375rem;
		font-weight: 600;
		border-radius: 0.25rem;
		border: 1px solid #ebebeb;
		background: #fff;
		padding: 1rem 1.5rem;
		display: inline-flex;
		margin-bottom: 1.3rem;
		align-items: center;
		gap: 1rem;

		svg {
			width: 0.8125rem;
			transform: rotate(90deg);
		}
	}

	section {
		border-radius: 0.25rem;
		background: #fff;
		box-shadow: -9px 19px 40px 0px rgba(0, 0, 0, 0.02);
		padding: 5rem 6.25rem;
		padding: clamp(2rem, 0.4043rem + 5.1064vw, 5rem) clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);
		display: grid;
		grid-template-columns: 240px 1fr;
		gap: 1rem;
		min-height: calc(100vh - (4rem + 76px));

		${maxQuery('xl')} {
			grid-template-columns: 1fr;
			gap: 2.5rem;
		}

		.steps-con {
			margin-top: 6.25rem;
			position: relative;
			height: max-content;

			::before {
				content: '';
				position: absolute;
				top: 0;
				left: 22.56px;
				width: 2px;
				height: 100%;
				background: #4060ea;
				z-index: 1;
			}
			${maxQuery('xl')} {
				display: flex;
				justify-content: space-between;
				gap: 6rem;
				overflow: hidden;
				margin-top: unset;

				::before {
					height: 2px;
					left: 0;
					top: 22.56px;
					width: 100%;
				}
			}

			.step {
				display: flex;
				gap: 1.1rem;
				position: relative;
				z-index: 2;
				background-color: #fff;
				padding: 0.5rem 0;
				min-width: max-content;

				:not(:last-child) {
					margin-bottom: 6rem;

					${maxQuery('xl')} {
						margin-bottom: 0rem;
					}
				}
				${maxQuery('xl')} {
					padding: 0 0.5rem;
				}
				&.active {
					b {
						color: #4060ea;
					}
					svg {
						:first-child {
							display: none;
						}
						:nth-child(2) {
							display: block;
						}
					}
				}
				svg:nth-child(2) {
					display: none;
				}

				b {
					color: #070d19;
					font-size: 1rem;
					line-height: 100%;
					display: block;
					margin-bottom: 0.5rem;
				}
				span {
					color: rgba(82, 80, 80, 0.4);
					font-size: 0.8125rem;
					font-weight: 600;
					text-transform: uppercase;
					display: block;
				}
			}
		}

		> form,
		> .form {
			h2 {
				color: #070d19;
				font-size: 2.25rem;
				font-size: clamp(2rem, 1.8611rem + 0.4444vw, 2.25rem);
			}
			h2 + p {
				color: #a8a8a8;
				font-size: 1rem;
				font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
				line-height: 120%;
				border-bottom: 1px solid #ebebeb;
				margin: 0.5rem 0 2.5rem;
				padding-bottom: 1.5rem;
			}
			.error {
				color: #ff7542;
				font-weight: 600;
				font-size: 0.875rem;
				margin: 0;
			}
			> div {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 1.5rem;

				${maxQuery('md')} {
					grid-template-columns: 1fr;
				}
			}
			.tasks-con {
				${StyledInputGroup}:first-child {
					margin-bottom: 1.5rem;
				}
				.error {
					margin-top: 0.75rem;
				}
				${StyledButton} {
					margin-top: 2rem;
				}
				h4 {
					color: #a8a8a8;
					font-family: 'Urbanist', sans-serif;
					font-size: 1rem;
					font-weight: 500;
					line-height: 120%;
					padding-bottom: 0.7rem;
					border-bottom: 1px solid #ebebeb;
					margin: 2rem 0 1.5rem;
				}
				.task {
					border-radius: 0.25rem;
					border: 1px solid #dadada;
					padding: 0.7rem;
					display: flex;
					align-items: center;

					:not(:last-child) {
						margin-bottom: 0.7rem;
					}

					svg {
						:first-child {
							margin-right: 0.7rem;
						}
						:nth-child(2) {
							cursor: pointer;
							margin: 0 0.5rem 0 auto;
							width: 20px;
						}
						:last-child {
							cursor: pointer;
						}
					}
				}
			}
			.btn-group {
				margin-top: 2rem;
				grid-template-columns: 1fr 1fr;

				${maxQuery('md')} {
					gap: 0.5rem;
					display: flex;
					flex-wrap: wrap;
				}

				${maxQuery('350px')} {
					flex-direction: column;
				}

				${StyledButton} {
					justify-content: center;
					flex: 1;
				}
			}
			${StyledFileGroup} .file-group {
				height: 5.5125rem;

				.text-con {
					align-self: center;

					p:not(.selected) {
						color: #1e1e1e;
						font-size: 0.875rem;
						line-height: 135%;
					}
					span {
						font-size: 0.75rem;
						line-height: 160%;
					}
				}
			}
		}
		.empty-state {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 14rem;
			background-color: #fff;
			border-radius: 0.125rem;
			padding: 1rem;

			p {
				color: #1e1e1e;
				font-size: 0.875rem;
				font-weight: 600;
				line-height: 135%;
				margin-top: 1rem;
			}
		}
		.resource-form {
			display: flex;
			flex-direction: column;
			overflow: hidden;

			.filter-con {
				position: relative;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				margin-bottom: 2.5rem;
			}

			.talent-list {
				overflow-x: auto;
				gap: 1rem;
				margin-bottom: 1rem;
				padding-bottom: 3rem;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;

				${StyledApplicantCard} {
					flex: 1;
					min-width: 13.75rem;
				}
			}
			.error-message {
				text-align: center;
				font-weight: 600;
				padding: 2rem 0;
			}
			center {
				margin-bottom: 4rem;
				padding: 5rem 0;
			}
			.bottom-row {
				display: flex;
				align-items: center;
				margin-top: auto;
				flex-wrap: wrap;
				gap: 1rem 0;

				${maxQuery('sm')} {
					flex-direction: column;
				}

				${StyledSelectedApplicants} {
					margin: 0 1rem 0 auto;

					${maxQuery('sm')} {
						margin: 0;
					}
				}
				${StyledButton} {
					padding: 1rem;

					span {
						display: flex;
						align-items: center;
						gap: 0.5rem;
					}
				}
			}
		}
	}
`;
