import { useRef, useState } from 'react';
import { Container } from './styles';
import { Loader, PalletList, SearchBar } from '../../../../ui';
import { useGetFinances, useGetDashboardStats } from '../../../../hooks';
import { useSearchParams } from 'react-router-dom';
import { getPreviousSearchParams } from '../../../../utilities';
import { businessesFilters, internsFilters, queryStrings, statisticsFilters } from '../../../../constants';
import { DashboardAmountPaid, DashboardAmountRefunded, ProjectEmpty } from '../../../../assets/svgs';
import { BusinessTable, InternTable } from './components';
import { Filter } from '../../../../components';
import { useCallback } from 'react';

const Finances = () => {
	const [page, setPage] = useState(1);
	const [control, setControl] = useState('businesses');
	const [formData, setFormData] = useState({
		selected_applicants: [],
		search: '',
		filter_stats: (() => {
			let state = {};
			statisticsFilters.forEach((option) => (state[option?.name] = { value: '', queryKey: option?.queryKey }));

			return state;
		})(),
		filter: (() => {
			let state = {};
			(control === 'businesses' ? businessesFilters : internsFilters).forEach(
				(option) => (state[option?.name] = { value: '', queryKey: option?.queryKey })
			);

			return state;
		})(),
	});

	const { data: stats, isLoading: statsLoading } = useGetDashboardStats();
	const statsData = [
		// {
		// 	title: 'Total Businesses',
		// 	body: stats?.data?.businesses || 0,
		// },
		// {
		// 	title: 'Total Interns',
		// 	body: stats?.data?.interns || 0,
		// },

		{
			title: 'Amount Paid',
			body: '£' + (stats?.data?.amount_paid || 0),
			icon: <DashboardAmountPaid />,
		},
		{
			title: 'Amount Refunded',
			body: '£' + (stats?.data?.amount_refunded || 0),
			icon: <DashboardAmountRefunded />,
		},
	];

	const pageSize = useRef(5);
	const { data: users, isLoading, isError } = useGetFinances({ page, page_size: pageSize.current, user: control });
	const [searchParams, setSearchParams] = useSearchParams();

	const handleItemClick = (id, isBusiness = true) => {
		let previousParams = getPreviousSearchParams(searchParams);

		delete previousParams[queryStrings.applicantId];
		delete previousParams[queryStrings.businessId];

		previousParams = { ...previousParams, [isBusiness ? queryStrings.businessId : queryStrings.applicantId]: id };
		setSearchParams(previousParams, { replace: true });
	};
	const handleChange = useCallback((event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	}, []);
	const handleControl = (value) => {
		setPage(1);
		setControl(value);
		handleChange(null, 'search', '');
		handleChange(
			null,
			'filter',
			(() => {
				let state = {};
				(value === 'businesses' ? businessesFilters : internsFilters).forEach(
					(option) => (state[option?.name] = { value: '', queryKey: option?.queryKey })
				);

				return state;
			})()
		);
	};

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Finances</h4>
					{/* <p>Update your photo and personal details</p> */}
				</div>

				<Filter name="filter_stats" onChange={handleChange} value={formData.filter_stats} options={statisticsFilters} />
			</div>

			<PalletList loading={statsLoading} data={statsData} />

			<div className="table-header">
				<div className="controls">
					<button className={control === 'businesses' ? 'active' : ''} onClick={() => handleControl('businesses')}>
						Businesses
					</button>
					<button className={control === 'interns' ? 'active' : ''} onClick={() => handleControl('interns')}>
						Interns
					</button>
				</div>

				<div className="filter-con">
					<SearchBar name="search" placeholder="Search through applicants" handleChange={handleChange} />
					<Filter
						name="filter"
						onChange={handleChange}
						value={formData.filter}
						options={control === 'businesses' ? businessesFilters : internsFilters}
					/>
				</div>
			</div>

			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<center>
					<div>
						<ProjectEmpty />
						<p className="error-message">Unable to fetch {control} at the moment</p>
					</div>
				</center>
			) : !users?.results || users?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p className="error-message">No {control === 'businesses' ? 'business' : 'intern'} to display</p>
				</div>
			) : control === 'businesses' ? (
				<BusinessTable users={users} handleItemClick={handleItemClick} />
			) : (
				<InternTable users={users} handleItemClick={handleItemClick} />
			)}
		</Container>
	);
};
export default Finances;
