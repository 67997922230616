import { ChevronDown, FilterIcon } from '../../assets/svgs';
import { FilterProps } from '../../types';
import { StyledFilter } from './styles';

/* Value is returned as:
{
	[name]: {
		"value": [subOption],
		queryKey: [queryKey]
	}.
    "Level of Education": {
        "value": "BSc",
        "queryKey": "level_of_edu"
    },
    "Years of Experience": {
        "value": "3 Months",
        "queryKey": "years_of_experience"
    },
    "Industry Interest": {
        "value": "E-commerce and Online Retail",
        "queryKey": "industry_interest"
    },
    "Language": {
        "value": "Abkhazian",
        "queryKey": "language"
    },
    "Applicant Type": {
        "value": "University Internship",
        "queryKey": "applicant_type"
    }
}
*/

const Filter = ({ value, name, placeholder = 'Filter', options = [], onChange }: FilterProps) => {
	return (
		<StyledFilter>
			<FilterIcon />
			<div className="chevron-con">
				<ChevronDown />
			</div>

			<input type="text" placeholder={placeholder} name={name} readOnly />

			<ul>
				{options?.length ? (
					options.map((mainOption) => (
						<li
							key={mainOption?.name}
							tabIndex={0}
							className={!mainOption?.options?.length && value[mainOption?.name]?.value === mainOption?.name ? 'active' : ''}
							onClick={() => {
								if (mainOption?.options?.length) {
									return;
								}

								onChange(null, name, {
									[mainOption?.name]: {
										value: value[mainOption?.name]?.value === mainOption?.name ? '' : mainOption?.name,
										queryKey: mainOption?.queryKey,
									},
								});
							}}
						>
							{mainOption?.name}

							{mainOption?.options?.length ? (
								<ul>
									{mainOption?.options?.map((subOption) => (
										<li
											key={subOption}
											tabIndex={0}
											className={value[mainOption?.name]?.value === subOption ? 'active' : ''}
											onClick={() =>
												onChange(null, name, {
													...value,
													[mainOption?.name]: { value: value[mainOption?.name]?.value === subOption ? '' : subOption, queryKey: mainOption?.queryKey },
												})
											}
										>
											{subOption}
										</li>
									))}
								</ul>
							) : null}
						</li>
					))
				) : (
					<li>No filter</li>
				)}
			</ul>
		</StyledFilter>
	);
};
export default Filter;
