import { useNavigate, useParams } from 'react-router-dom';
import { ChevronDown, ProjectEmpty } from '../../assets/svgs';
import { Container } from './styles';
// import { Supervisor } from '../../assets/images';
import { Button, FileDownloadGroup, Loader, Tag } from '../../ui';
import { useGetProject, useGetTask } from '../../hooks';
import { getDateTime } from '../../utilities';
import { Tags } from '../../ui/Tag/styles';
import { useState } from 'react';
import { LogTimesheet } from '../../components';

const MyTaskDetails = () => {
	const navigate = useNavigate();
	const [logState, setLogState] = useState({
		submitLog: false,
		taskId: '',
	});

	const { projectId, taskId } = useParams();

	const { data: task, isLoading: isTasksLoading, isError: isTasksError } = useGetTask({ taskId });
	const { data: project, isLoading: isProjectLoading, isError: isProjectError } = useGetProject({ id: projectId });

	const handleSubmitLog = (taskId) => {
		setLogState({
			submitLog: true,
			taskId,
		});
	};
	return (
		<Container $sector="e-commerce">
			<button onClick={() => navigate(-1)}>
				<ChevronDown /> Back to Tasks
			</button>

			{isTasksLoading || isProjectLoading ? (
				<center>
					<Loader />
				</center>
			) : isTasksError || isProjectError ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>Unable to fetch project details at the moment</p>
				</div>
			) : (
				<section>
					<section>
						<article>
							<div className={`status ${task?.is_completed ? '' : 'in-progress'}`}>{task?.is_completed ? 'Completed' : 'In progress'}</div>

							<div className="title">{task?.name}</div>
							<p>{task?.description}</p>
						</article>
						<div className="timeline">
							<div>
								<b>Start date</b>
								<b>{task?.start_date ? getDateTime(task?.start_date) : 'Not set'}</b>
							</div>
							<div>
								<b>End date</b>
								<b>{task?.end_date ? getDateTime(task?.end_date) : 'Not set'}</b>
							</div>
						</div>
						<div className="supervisor">
							<b>Project supervisor</b>

							<div>
								{/*<img src={Supervisor} alt="" />*/} <span>{task?.project_supervisor || 'Unassigned'}</span>
							</div>
						</div>
						{task?.docs.length ? (
							<div className="attachments">
								<b>Attachments</b>

								<FileDownloadGroup fileName={task?.docs[0]?.file_name} url={task?.docs[0]?.url} fileSizeInKb={task?.docs[0]?.file_size_in_kb} />
							</div>
						) : null}

						<div>
							<b>Tags</b>
							<Tags>
								{task?.tags.map((tag) => (
									<Tag hideClose key={tag} tag={tag} />
								))}
							</Tags>
						</div>

						<Button onClick={() => handleSubmitLog(task?.id)}>Submit Log</Button>
					</section>

					<aside>
						<h4>Project Information</h4>

						<div className="title">
							<h6>Project Name</h6>

							<div>
								<b>{project?.name}</b> <span>{project?.project_sector}</span>
							</div>
						</div>

						<div className="timeline">
							<div>
								<h6>Start date</h6>
								<b>{getDateTime(project?.start_date)}</b>
							</div>
							<div>
								<h6>Project duration</h6>
								<b>{project?.timeline}</b>
							</div>
						</div>
						{/* <div className="supervisor">
							<h6>Project supervisor</h6>

							<div>
								<img src={Supervisor} alt="" /> <span>ToBeDone</span>
							</div>
						</div> */}
						<div className="description">
							<h6>Description</h6>
							<p>{project?.description}</p>
						</div>
						{project?.requirement_docs.length ? (
							<div className="attachments">
								<h6>Attachments</h6>

								<FileDownloadGroup
									fileName={project?.requirement_docs[0]?.file_name}
									url={project?.requirement_docs[0]?.url}
									fileSizeInKb={project?.requirement_docs[0]?.file_size_in_kb}
								/>
							</div>
						) : null}
					</aside>
				</section>
			)}

			{logState.submitLog ? <LogTimesheet taskId={logState.taskId} handleClose={() => setLogState({ submitLog: false, taskId: '' })} /> : null}
		</Container>
	);
};
export default MyTaskDetails;
