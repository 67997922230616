import { useRef, useState } from 'react';
import { Container } from './styles';
import { Button, Loader, Pagination, PalletList, SearchBar } from '../../../../ui';
import {
	useGetInstitutionAdminDashboardStats,
	useGetDepartments,
	useGetPendingDepartmentHeads,
	useGetSignedUpDepartmentHeads,
	useGetPendingSupervisors,
	useGetSignedUpSupervisors,
	useGetPendingInterns,
	useGetSignedUpInterns,
} from '../../../../hooks';
import { statisticsFilters } from '../../../../constants';
import {
	ButtonPlus,
	DashboardAmountPaid,
	DashboardAmountRefunded,
	DashboardBusiness,
	DashboardInterns,
	ProjectEmpty,
} from '../../../../assets/svgs';
import { Filter } from '../../../../components';
import { useCallback } from 'react';
import { GeneralChangeEventType, FilterValue } from '../../../../types';
import {
	ActiveDepartmentHeads,
	ActiveInterns,
	ActiveSupervisors,
	DepartmentTable,
	PendingDepartmentHeads,
	PendingInterns,
	PendingSupervisors,
} from '../../components';
import { AddDepartmentModal, ChangeDepartmentHeadModal } from '../../modals';

type ControlType = 'departments' | 'heads' | 'supervisors' | 'interns';
type SubControlType = 'pending' | 'active';

const InstitutionDashboard = () => {
	const pageSize = useRef(6);
	const [page, setPage] = useState(1);
	const [isAddDepartmentModalOpen, setIsAddDepartmentModalOpen] = useState(false);
	const [isChangeHeadModalOpen, setIsChangeHeadModalOpen] = useState(false);
	const [control, setControl] = useState<ControlType>('departments');
	const [departmentIdToChangeHead, setDepartmentIdToChangeHead] = useState('');
	const [subControl, setSubControl] = useState<SubControlType>('active');
	const [formData, setFormData] = useState({
		search: '',
		filter_stats: (() => {
			let state: FilterValue = {};
			statisticsFilters.forEach((option) => (state[option?.name] = { value: '', queryKey: option?.queryKey }));

			return state;
		})(),
		filter: (() => {
			let state: FilterValue = {};
			(control === 'departments' ? [] : []).forEach(
				(option: { name: string | number; queryKey: any }) => (state[option?.name] = { value: '', queryKey: option?.queryKey })
			);

			return state;
		})(),
	});

	const { data: stats, isLoading: statsLoading } = useGetInstitutionAdminDashboardStats();
	const {
		data: departments,
		isLoading: isLoadingDepartments,
		isError: isDepartmentError,
	} = useGetDepartments({ page, page_size: pageSize.current, search: formData.search, enabled: control === 'departments' });
	const {
		data: pendingDepartmentHeads,
		isLoading: isLoadingPendingDepartmentHeads,
		isError: isPendingDepartmentHeadError,
	} = useGetPendingDepartmentHeads({ page, page_size: pageSize.current, search: formData.search, enabled: control === 'heads' });
	const {
		data: activeDepartmentHeads,
		isLoading: isLoadingActiveDepartmentHeads,
		isError: isActiveDepartmentHeadError,
	} = useGetSignedUpDepartmentHeads({ page, page_size: pageSize.current, search: formData.search, enabled: control === 'heads' });

	const {
		data: pendingSupervisors,
		isLoading: isLoadingPendingSupervisors,
		isError: isPendingSupervisorError,
	} = useGetPendingSupervisors({ page, page_size: pageSize.current, search: formData.search, enabled: control === 'supervisors' });
	const {
		data: activeSupervisors,
		isLoading: isLoadingActiveSupervisors,
		isError: isActiveSupervisorError,
	} = useGetSignedUpSupervisors({ page, page_size: pageSize.current, search: formData.search, enabled: control === 'supervisors' });
	const {
		data: pendingInterns,
		isLoading: isLoadingPendingInterns,
		isError: isPendingInternsError,
	} = useGetPendingInterns({ page, page_size: pageSize.current, search: formData.search, enabled: true });
	const {
		data: activeInterns,
		isLoading: isLoadingActiveInterns,
		isError: isActiveInternsError,
	} = useGetSignedUpInterns({ page, page_size: pageSize.current, search: formData.search, enabled: true });

	const isFetchingData =
		control === 'departments'
			? isLoadingDepartments
			: control === 'heads'
			? subControl === 'pending'
				? isLoadingPendingDepartmentHeads
				: isLoadingActiveDepartmentHeads
			: control === 'supervisors'
			? subControl === 'pending'
				? isLoadingPendingSupervisors
				: isLoadingActiveSupervisors
			: control === 'interns'
			? subControl === 'pending'
				? isLoadingPendingInterns
				: isLoadingActiveInterns
			: false;
	const isDataError =
		control === 'departments'
			? isDepartmentError
			: control === 'heads'
			? subControl === 'pending'
				? isPendingDepartmentHeadError
				: isActiveDepartmentHeadError
			: control === 'supervisors'
			? subControl === 'pending'
				? isPendingSupervisorError
				: isActiveSupervisorError
			: control === 'interns'
			? subControl === 'pending'
				? isPendingInternsError
				: isActiveInternsError
			: false;

	const result =
		control === 'departments'
			? departments
			: control === 'heads'
			? subControl === 'pending'
				? pendingDepartmentHeads
				: activeDepartmentHeads
			: control === 'supervisors'
			? subControl === 'pending'
				? pendingSupervisors
				: activeSupervisors
			: control === 'interns'
			? subControl === 'pending'
				? pendingInterns
				: activeInterns
			: {
					results: [],
					total: 0,
			  };
	const statsData = [
		{
			title: 'Total Department',
			body: stats?.data?.dept || 0,
			icon: <DashboardBusiness />,
		},
		{
			title: 'Profile Created',
			body: stats?.data?.profile_created || 0,
			icon: <DashboardInterns />,
		},
		{
			title: 'Internship Done',
			body: stats?.data?.amount || 0,
			icon: <DashboardAmountPaid />,
		},
		{
			title: 'Amount Paid',
			body: stats?.data?.internship_done || 0,
			icon: <DashboardAmountRefunded />,
		},
	];

	const handleChange: GeneralChangeEventType = useCallback((event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name as string]: value }));
	}, []);

	const handleControl = (value: ControlType) => {
		setPage(1);
		setControl(value);

		handleChange(null, 'search', '');
		handleChange(
			null,
			'filter',
			(() => {
				let state: FilterValue = {};
				(value === 'departments' ? [] : []).forEach(
					(option: { name: string | number; queryKey: any }) => (state[option?.name] = { value: '', queryKey: option?.queryKey })
				);

				return state;
			})()
		);
	};
	const handleSubControl = (value: SubControlType) => {
		setPage(1);
		setSubControl(value);

		handleChange(null, 'search', '');
		handleChange(
			null,
			'filter',
			(() => {
				let state: FilterValue = {};
				(value === 'pending' ? [] : []).forEach(
					(option: { name: string | number; queryKey: any }) => (state[option?.name] = { value: '', queryKey: option?.queryKey })
				);

				return state;
			})()
		);
	};

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Dashboard</h4>
					{/* <p>Update your photo and personal details</p> */}
				</div>

				<Button onClick={() => setIsAddDepartmentModalOpen(true)}>
					<ButtonPlus /> Add Department
				</Button>
				<Filter name="filter_stats" onChange={handleChange} value={formData.filter_stats} options={[]} />
			</div>

			<PalletList loading={statsLoading} data={statsData} />

			<div className="table-header">
				<div className="controls">
					<button className={control === 'departments' ? 'active' : ''} onClick={() => handleControl('departments')}>
						Departments
					</button>
					<button className={control === 'heads' ? 'active' : ''} onClick={() => handleControl('heads')}>
						Department Heads
					</button>
					<button className={control === 'supervisors' ? 'active' : ''} onClick={() => handleControl('supervisors')}>
						Supervisors
					</button>
					<button className={control === 'interns' ? 'active' : ''} onClick={() => handleControl('interns')}>
						Interns
					</button>
				</div>

				<div className="filter-con">
					<SearchBar name="search" placeholder={`Search through ${control}`} handleChange={handleChange} value={formData.search} />
					<Filter name="filter" onChange={handleChange} value={formData.filter} options={control === 'departments' ? [] : []} />
				</div>
			</div>
			{control === 'departments' ? <br /> : null}
			{control !== 'departments' ? (
				<>
					<div className="controls">
						<button className={subControl === 'active' ? 'active' : ''} onClick={() => handleSubControl('active')}>
							Active
						</button>
						<button className={subControl === 'pending' ? 'active' : ''} onClick={() => handleSubControl('pending')}>
							Pending
						</button>
					</div>
					<br />
				</>
			) : null}

			{isFetchingData ? (
				<center>
					<Loader />
				</center>
			) : isDataError ? (
				<center>
					<div>
						<ProjectEmpty />
						<p className="error-message">Unable to fetch {control} at the moment</p>
					</div>
				</center>
			) : !result?.results || result?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p className="error-message">No {control.slice(0, -1)} to display</p>
				</div>
			) : control === 'departments' ? (
				<DepartmentTable
					page={page}
					departments={departments?.results}
					handleChangeHead={(id: string) => {
						setDepartmentIdToChangeHead(id);
						setIsChangeHeadModalOpen(true);
					}}
				/>
			) : control === 'heads' ? (
				subControl === 'pending' ? (
					<PendingDepartmentHeads page={page} heads={pendingDepartmentHeads?.results} />
				) : (
					<ActiveDepartmentHeads page={page} heads={activeDepartmentHeads?.results} />
				)
			) : control === 'supervisors' ? (
				subControl === 'pending' ? (
					<PendingSupervisors page={page} supervisors={pendingSupervisors?.results} />
				) : (
					<ActiveSupervisors page={page} supervisors={activeSupervisors?.results} />
				)
			) : control === 'interns' ? (
				subControl === 'pending' ? (
					<PendingInterns page={page} interns={pendingInterns?.results} />
				) : (
					<ActiveInterns page={page} interns={activeInterns?.results} />
				)
			) : null}

			<Pagination page={page} setPage={setPage} maxPages={Math.ceil((result?.total || 0) / pageSize.current)} disabled={isFetchingData} />

			{isAddDepartmentModalOpen ? <AddDepartmentModal handleClose={() => setIsAddDepartmentModalOpen(false)} /> : null}
			{isChangeHeadModalOpen && departmentIdToChangeHead ? (
				<ChangeDepartmentHeadModal departmentId={departmentIdToChangeHead} handleClose={() => setIsChangeHeadModalOpen(false)} />
			) : null}
		</Container>
	);
};
export default InstitutionDashboard;
