import { Approutes, Appurls, LOCAL_STORAGE_KEYS, NOTIFICATION_TYPES } from '../../../constants';
import { Link } from 'react-router-dom';
import { useAxiosPrivate, useDecodedUser } from '../../../hooks';
import { isIntern, isStartUp } from '../../../utilities';
import { useMutation } from '@tanstack/react-query';

const NotificationContainer = ({ notification, handleCloseModal, refetch, children, ...rest }) => {
	let TagName = 'div';
	let attributes = {};
	let shouldCloseModal = false;

	const user = useDecodedUser();
	const axiosPrivate = useAxiosPrivate();

	const { mutate: markAsRead } = useMutation((data) => axiosPrivate.put(Appurls.notifications.bulk_update, data));

	const handleMarkAsRead = (id) => {
		markAsRead(
			{
				notifications: [id],
				is_read: true,
			},
			{
				onSuccess: () => refetch(),
			}
		);
	};

	if (notification?.type === NOTIFICATION_TYPES.project_resource_invitation) {
		TagName = Link;

		attributes.to = isStartUp(user)
			? Approutes.startup.projects.use_project_details(notification?.project_id)
			: isIntern(user)
			? Approutes.intern.projects.use_project_details(notification?.project_id)
			: '';
	} else if (notification?.type === NOTIFICATION_TYPES.task_assignment) {
		TagName = Link;

		attributes.to = isStartUp(user)
			? Approutes.startup.projects.use_task_details(notification?.project_id, notification?.task_id)
			: isIntern(user)
			? Approutes.intern.tasks.use_task_details(notification?.project_id, notification?.task_id)
			: '';
	} else if (notification?.type === NOTIFICATION_TYPES.timesheet_logged) {
		TagName = Link;

		attributes.to = isStartUp(user) ? Approutes.startup.projects.use_task_details(notification?.project_id, notification?.task_id) : '';
	} else if (notification?.type === NOTIFICATION_TYPES.profile_declined) {
		if (
			(user?.intern_profile_status === 'DECLINED' || user?.startup_profile_status === 'DECLINED') &&
			!localStorage.getItem(LOCAL_STORAGE_KEYS.has_resubmitted_profile)
		) {
			TagName = Link;

			attributes.to = Approutes.welcome;
		}
	}

	// Render div if no link
	if (attributes.to === '') {
		TagName = 'div';
	}

	// Close modal on click of link
	if (TagName === Link) {
		shouldCloseModal = true;
	}

	return (
		<TagName
			onClick={() => {
				handleMarkAsRead(notification?.id);
				shouldCloseModal && handleCloseModal();
			}}
			{...attributes}
			{...rest}
		>
			{children}
		</TagName>
	);
};
export default NotificationContainer;
// project_id
// task_id
// log_id
