import styled from 'styled-components';

export const StyledSearchBar = styled.div`
	position: relative;
	width: 15rem;

	> svg {
		position: absolute;
		z-index: 2;
		pointer-events: none;
		top: 50%;
		left: 1.25rem;
		transform: translateY(-50%);
		width: 1rem;
	}
	input {
		padding-left: 3rem;
		width: 100%;
		border-radius: 0.25rem;
	}
`;
