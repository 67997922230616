import { FileDoc, FileDownload } from '../../assets/svgs';
import { StyledFileDownloadGroup } from './styles';

const FileDownloadGroup = ({ fileName = 'Your file', url = '#', fileSizeInKb = 0 }) => {
	return (
		<StyledFileDownloadGroup href={url} target="_blank" rel="noreferrer" className="file-group">
			<div>
				<FileDoc />
			</div>

			<div>
				<p title={fileName || ''}>{fileName}</p>
				<span>{parseInt(fileSizeInKb) > 1000 ? `${parseInt(fileSizeInKb) / 1000} MB` : `${parseInt(fileSizeInKb)} KB`}</span>
			</div>

			{fileSizeInKb > 0 ? (
				<div>
					<FileDownload />
				</div>
			) : null}
		</StyledFileDownloadGroup>
	);
};

export default FileDownloadGroup;
