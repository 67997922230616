export const APPLICANT_PROFILE_KEYS = {
	profile: 'profile_info',
	education: 'education_and_interest',
	documentation: 'documentation',
};
export const APPLICANT_PROFILE_CONTROLS = [
	{ key: APPLICANT_PROFILE_KEYS.profile, value: 'Profile Information' },
	{ key: APPLICANT_PROFILE_KEYS.education, value: 'Education & Interest' },
	{ key: APPLICANT_PROFILE_KEYS.documentation, value: 'Documentation' },
];

export const STARTUP_PROFILE_KEYS = {
	overview: 'overview',
	information: 'information',
};
export const STARTUP_PROFILE_CONTROLS = [
	{ key: STARTUP_PROFILE_KEYS.overview, value: 'Overview' },
	{ key: STARTUP_PROFILE_KEYS.information, value: 'Information' },
];
