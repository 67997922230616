import { EyeOpen } from '../../../assets/svgs';
import { getDateTime } from '../../../utilities';

const InstitutionTable = ({ page, users, handleItemClick }) => {
	return (
		<>
			<div className="table-container">
				<table>
					<thead>
						<tr>
							<th>S/N</th>
							<th>Institution Name</th>
							<th>No of Department</th>
							<th>Mobile Contact</th>
							<th>Institution Email</th>
							<th>Residing Address</th>
							<th>Country</th>
							<th>Registration Date</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{users?.results?.map((user, index) => (
							<tr key={user?.id}>
								<td>{(page - 1) * 10 + index + 1}</td>
								<td>{user?.['Institution Name']}</td>
								<td>{user?.['No of Department']}</td>
								<td>{user?.['Mobile Contact']}</td>
								<td>{user?.email}</td>
								<td>{user?.['Residing Address']}</td>
								<td>{user?.['Country']}</td>
								<td>
									{getDateTime(user?.created_at, 'mm dd yyyy')}, {getDateTime(user?.created_at, '', false)}
								</td>
								<td>
									<EyeOpen onClick={() => handleItemClick(user?.id)} />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{users?.results?.map((user) => (
				<div key={user?.id} className="mobile-card">
					<div className="flexed">
						<div>
							<b>Institution Name</b>
							<p>{user?.['Institution Name']}</p>
						</div>
						<div className="action-con" onClick={() => handleItemClick(user?.id)}>
							<EyeOpen />
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>No of Department</b>
							<p>{user?.['No of Department']}</p>
						</div>
						<div>
							<b>Mobile Contact</b>
							<p>{user?.['Mobile Contact']}</p>
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>Institution Email</b>
							<p>{user?.['Institution Email']}</p>
						</div>
						<div>
							<b>Country</b>
							<p>{user?.['Country']}</p>
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>Registration Date</b>
							<p>{getDateTime(user?.created_at, 'mm dd yyyy')}</p>
						</div>
						<div>
							<b>Residing Address</b>
							<p>{user?.['Residing Address']}</p>
						</div>
					</div>
					<div>
						<b>Registration Time</b>
						<p>
							{getDateTime(user?.created_at, 'mm dd yyyy')}, {getDateTime(user?.created_at, '', false)}
						</p>
					</div>
				</div>
			))}
		</>
	);
};
export default InstitutionTable;
