import { Button, Loader, Pagination } from '../../ui';
import { ButtonPlus, ChevronDown, ProjectEmpty } from '../../assets/svgs';
import { useRef, useState } from 'react';
import { Container, StyledSupervisor } from './styles';
import Logs from './Logs';
import { InviteSupervisor } from '../../components/Modals';
import { useGetSupervisors } from '../../hooks';

const Supervisor = () => {
	const pageSize = useRef(10);
	const [page, setPage] = useState(1);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [viewSupervisorLog, setViewSupervisorLog] = useState('');
	const { data: supervisors, isLoading, isError } = useGetSupervisors({ page, page_size: pageSize.current, search: '' });

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Supervisor</h4>
					{/* <p>Update your photo and personal details</p> */}
				</div>

				<Button onClick={() => setIsModalOpen(true)}>
					<ButtonPlus />
					Add Supervisor
				</Button>
			</div>

			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>Unable to fetch supervisors at the moment</p>
				</div>
			) : supervisors?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>You haven't added any supervisor yet</p>
				</div>
			) : (
				<>
					<div className="supervisors">
						{supervisors?.results?.map((supervisor) => (
							<StyledSupervisor key={supervisor?.id} className={supervisor?.related_project?.id === viewSupervisorLog ? 'active' : ''}>
								<div className="supervisor-con">
									<div>
										<b>{supervisor?.supervisor_name}</b> <span>Supervisor</span>
									</div>
									<div>
										<b>{supervisor?.supervisor_institution}</b>
										<span>Institution Name</span>
									</div>
									<div>
										<b>{supervisor?.supervisor_email}</b>
										<span>Email Address</span>
									</div>
									<div>
										<b>{supervisor?.logs_rate?.completed} Tasks</b>
										<span>Logs Approved </span>
									</div>
									<div>
										<b>{supervisor?.logs_rate?.pending} Tasks</b>
										<span>Logs Pending </span>
									</div>
									<div>
										<button
											onClick={() => setViewSupervisorLog((prev) => (prev !== supervisor?.related_project?.id ? supervisor?.related_project?.id : ''))}
										>
											<ChevronDown />
										</button>
									</div>
								</div>

								{supervisor?.related_project?.id === viewSupervisorLog ? <Logs projectId={supervisor?.related_project?.id} /> : null}
							</StyledSupervisor>
						))}
					</div>

					<Pagination page={page} setPage={setPage} maxPages={1} disabled={false} />
				</>
			)}

			{isModalOpen ? <InviteSupervisor handleClose={() => setIsModalOpen(false)} /> : null}
		</Container>
	);
};

export default Supervisor;
