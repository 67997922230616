import { Loader, Pagination } from '../../ui';
import { EyeOpen, ProjectEmpty } from '../../assets/svgs';
import { useState } from 'react';
import { Container } from './styles';
import { useGetApplicantLogsForSupervisor, usePatchLogs } from '../../hooks';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getDateTime } from '../../utilities';
import { LogSummary } from '../../components';

const TimesheetForSupervisor = () => {
	const pageSize = useRef(5);
	const [logState, setLogState] = useState({
		viewLogDetails: false,
		log: {},
	});
	const { projectId, internId } = useParams();
	const [page, setPage] = useState(1);

	const { data: timesheets, isLoading, isError } = useGetApplicantLogsForSupervisor({ projectId, internId, page, page_size: 5 });
	const { mutate: patchLogs } = usePatchLogs();

	const handleViewLog = (log) => {
		patchLogs({ id: log?.id, data: { is_viewed: true } });

		setLogState({
			viewLogDetails: true,
			log,
		});
	};

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Timesheet {timesheets?.results[0]?.submitted_by?.user_name ? `- ${timesheets?.results[0]?.submitted_by?.user_name}` : null}</h4>
					<p>View and get more information about your resource logs</p>
				</div>
			</div>

			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>Unable to fetch available logs at the moment</p>
				</div>
			) : !timesheets?.results || timesheets?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>Your resources haven't reported any logs {projectId ? 'for this project' : ''}</p>
				</div>
			) : (
				<>
					<div className="table-container">
						<table>
							<thead>
								<tr>
									<th>S/N</th>
									<th>Task Name</th>
									<th>Key Achievement</th>
									<th>Description</th>
									<th>Report Date </th>
									<th>Status</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{timesheets?.results?.map((log, index) => (
									<tr key={log?.id}>
										<td>{index + 1}</td>
										<td>{log?.related_task?.name}</td>
										<td>{log?.achievement}</td>
										<td>{log?.description}</td>
										<td>{getDateTime(log?.created_at, 'mm dd yyyy')}</td>
										<td className={log?.related_task?.is_completed ? 'open' : 'closed'}>{log?.related_task?.is_completed ? 'Open' : 'Closed'}</td>
										<td>
											<EyeOpen onClick={() => handleViewLog(log)} />
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{timesheets?.results?.map((log) => (
						<div className="mobile-card" key={log?.id}>
							<div className="flexed">
								<div>
									<b>Task Name</b>
									<p>{log?.related_task?.name}</p>
								</div>
								<div className="action-con" onClick={() => handleViewLog(log)}>
									<EyeOpen />
								</div>
							</div>
							<div>
								<b>Key Achievement</b>
								<p>{log?.achievement}</p>
							</div>
							<div>
								<b>Description</b>
								<p>{log?.description}</p>
							</div>
							<div className="flexed">
								<div>
									<b>Report Date</b>
									<p>{getDateTime(log?.created_at, 'mm dd yyyy')}</p>
								</div>
								<div>
									<b>Status</b>
									<p className={log?.related_task?.is_completed ? 'open' : 'closed'}>{log?.related_task?.is_completed ? 'Open' : 'Closed'}</p>
								</div>
							</div>
						</div>
					))}

					<Pagination page={page} setPage={setPage} maxPages={Math.ceil(timesheets?.total / pageSize.current)} disabled={isLoading} />
				</>
			)}

			{logState.viewLogDetails ? <LogSummary log={logState.log} handleClose={() => setLogState({ viewLogDetails: false, log: {} })} /> : null}
		</Container>
	);
};

export default TimesheetForSupervisor;
