import styled from 'styled-components';
import { StyledButton } from '../../ui/Button/styles';

export const Container = styled.div`
	display: flex;
	align-items: center;

	&,
	> div {
		width: 100%;
	}

	h6 {
		color: #4060ea;
		font-size: 1.25rem;
		margin-bottom: 1.5rem;
		font-family: 'Urbanist', sans-serif;
	}
	h2 {
		color: #292d2d;
		font-size: 2.5rem;
		font-size: clamp(2rem, 1.7222rem + 0.8889vw, 2.5rem);
		line-height: 100%;
	}
	p {
		color: #3a3a3a;
		font-size: 0.9375rem;
		font-size: clamp(0.75rem, 0.6458rem + 0.3333vw, 0.9375rem);
		line-height: 170%;
		margin: 0.5rem 0 2.3rem;
		margin: 0.5rem 0 clamp(1.75rem, 1.4444rem + 0.9778vw, 2.3rem);
	}
	${StyledButton} {
		margin: 2rem 0 1rem;
		justify-content: center;
		width: 100%;

		:last-child {
			margin: 1rem 0;
			border-color: transparent;

			:hover {
				border-color: initial;
			}
		}
	}

	form {
		width: 100%;

		.input-group {
			:focus-within label {
				color: #4060ea;
			}

			label {
				color: #494848;
				font-weight: 500;
				font-size: 0.875rem;
				line-height: 120%;
				margin-bottom: 0.5rem;
				display: block;
			}
			div {
				display: flex;
				gap: 1.25rem;
			}

			input {
				width: 2.5rem;
				height: 4.5625rem;
				border-radius: 0.25rem;
				border: 1px solid rgba(229, 229, 229, 0.7);
				background: rgba(229, 229, 229, 0.1);
				text-align: center;
				flex: 1;
				font-family: inherit;
				font-size: 2rem;
				line-height: 130%;

				:focus {
					outline: 1.5px solid #4060ea;
				}
				::-webkit-outer-spin-button,
				::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
				&[type='number'] {
					appearance: textfield;
					-moz-appearance: textfield;
				}
			}
		}
	}
`;
