import styled from 'styled-components';
import { maxQuery, minQuery } from '../../../../utilities';
import { StyledSearchBar } from '../../../../ui/SearchBar/styles';
import { StyledFilter } from '../../../../components';

export const Container = styled.div`
	padding: 2.5rem 6.25rem;
	padding: 2.5rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;
		flex-wrap: wrap;
		margin-bottom: 1.5rem;

		${maxQuery('400px')} {
			gap: 0.5rem;
		}

		h4 {
			color: #11071a;
			font-family: 'Urbanist', sans-serif;
			font-size: 1.5rem;
			font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
			font-weight: 700;
			margin-bottom: 0.25rem;
			line-height: 120%;
		}
		p {
			color: #828282;
			font-size: 0.75rem;
			line-height: 120%;
		}
	}
	.table-header {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		gap: 1rem;
		margin: 1.5rem 0 1.87rem;
		margin: clamp(1rem, 0.1667rem + 2.6667vw, 1.5rem) 0 clamp(1rem, -0.45rem + 4.64vw, 1.87rem);

		${maxQuery('md')} {
			flex-direction: column;
			align-items: flex-start;
		}
	}
	.filter-con {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem 1.5rem;

		${maxQuery('md')} {
			width: 100%;
		}
		${StyledSearchBar} {
			${maxQuery('md')} {
				flex: 1;
			}
			${maxQuery('400px')} {
				flex-basis: 100%;
			}
		}
		${StyledFilter} {
			${maxQuery('md')} {
				margin-left: auto;
			}
		}
	}
	.controls {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem 2rem;
		gap: 1rem clamp(1.25rem, 0rem + 4vw, 2rem);

		button {
			background-color: transparent;
			color: #bbbaba;
			font-size: 1.25rem;
			font-size: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);
			font-weight: 600;
			border-bottom: 2px solid transparent;
			padding: 0.75rem 1.5rem 0.75rem 0;
			padding: clamp(0.5rem, 0.0833rem + 1.3333vw, 0.75rem) clamp(0rem, -2.5rem + 8vw, 1.5rem) clamp(0.5rem, 0.0833rem + 1.3333vw, 0.75rem) 0;

			&.active {
				color: #12091b;
				border-bottom: 2px solid #4060ea;
			}
		}
	}
	center {
		height: calc(100vh - 24rem);
		display: grid;
		place-items: center;
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;
	}
	.error-message {
		text-align: center;
		font-weight: 600;
		padding: 0.5rem 0;
	}
	.empty-state {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: calc(100vh - 24rem);
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;
	}
	.table-container {
		overflow: auto;

		${maxQuery('lg')} {
			display: none;
		}
	}
	table {
		width: 100%;
		min-width: max-content;
		border-collapse: collapse;

		> * > tr > *:first-child {
			text-align: center;
		}
		${minQuery('300px')} {
			> * > tr > *:first-child {
				left: 0;
				position: sticky;
				text-align: center;
			}
		}

		thead {
			th {
				font-weight: 600;
				padding: 0.875rem 1rem;
				text-align: inherit;
				background-color: #fafafa;
			}
		}

		tbody {
			background-color: #fff;

			tr {
				:not(:last-child) {
					border-bottom: 1px solid #dde0e2;
				}
			}

			td {
				background-color: #fff;
				font-weight: 500;
				padding: 1.25rem 1rem;

				:last-child {
					display: flex;
					align-items: center;
					gap: 1rem;

					a {
						display: flex;
						align-items: center;
					}

					svg {
						cursor: pointer;

						path {
							fill: #4060ea;
						}
					}
				}
			}
		}
	}
	.mobile-card {
		background: #fff;
		border-radius: 0.25rem;
		padding: 0 1rem;
		display: none;

		:not(:last-child) {
			margin-bottom: 1rem;
		}

		${maxQuery('lg')} {
			display: block;
		}

		> div {
			padding: 0.8rem 0;
			padding: clamp(0.5rem, 0rem + 1.6vw, 0.8rem) 0;

			:not(:last-child) {
				border-bottom: 1px solid rgba(150, 150, 150, 0.11);
			}
			&.flexed {
				display: flex;
				justify-content: space-between;
			}

			.action-con {
				display: flex;
				align-items: center;
				padding: 0 0.5rem;
				cursor: pointer;

				svg path {
					fill: #4060ea;
				}
			}

			> div:last-child {
				text-align: right;
			}
		}

		b {
			color: #323232;
			font-size: 0.8125rem;
			font-size: clamp(0.8125rem, 0.5rem + 1vw, 1rem);
			font-weight: 600;
		}
		p {
			color: rgba(15, 15, 15, 0.46);
			font-size: 0.8125rem;
			font-size: clamp(0.8125rem, 0.5rem + 1vw, 1rem);
		}
	}
`;
