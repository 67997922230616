import styled from 'styled-components';

export const StyledTag = styled.div`
	display: flex;
	padding: 0.625rem 1rem;
	align-items: center;
	gap: 0.25rem;
	border-radius: 0.25rem;
	background: #f6f7fe;
	color: #12091b;
	font-size: 0.75rem;
	font-weight: 700;
	line-height: 132%;

	svg {
		cursor: pointer;
	}
`;

export const Tags = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.75rem 1rem;
`;
