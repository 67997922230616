import styled from 'styled-components';

export const Container = styled.div`
	padding: 3rem 6.25rem;
	padding: 3rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);

	h1 {
		font-size: 2rem;
		line-height: 140%;
	}
	p {
		font-size: 1.2rem;
		line-height: 120%;

		span {
			text-decoration: underline;
		}
	}
`;
