import { useMutation } from '@tanstack/react-query';
import { axios } from '../library';
import { Appurls } from '../constants';

export const useSignup = () =>
	useMutation({
		mutationFn: (data: { email: string; phone_number: string; password: string; role: string }) => axios.post(Appurls.user.index, data),
	});

export const useSignupAsInstitution = () =>
	useMutation({
		mutationFn: (data: { email: string; password: string }) => axios.post(Appurls.user.onboard_institution, data),
	});
