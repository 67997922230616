import { TagClose } from '../../assets/svgs';
import { StyledTag } from './styles';

const Tag = ({ tag, hideClose = false, handleRemove }) => {
	return (
		<StyledTag>
			<span>{tag}</span> {!hideClose ? <TagClose onClick={() => handleRemove(tag)} /> : null}
		</StyledTag>
	);
};
export default Tag;
