import { useState } from 'react';
import CreateAccount from './CreateAccount';
import { useNavigate } from 'react-router-dom';
import { useAuth, useNotify, useSignup } from '../../../hooks';
import { useMutation } from '@tanstack/react-query';
import { axios } from '../../../library';
import { Approutes, Appurls, LOCAL_STORAGE_KEYS } from '../../../constants';
import { handleApiError } from '../../../utilities';
import { OTP } from '../../../components';

const Startup = () => {
	const navigate = useNavigate();
	const notify = useNotify();
	const { setAuth } = useAuth();
	const [step, setStep] = useState(1);
	const [formData, setFormData] = useState({
		email: '',
		password: '',
		contact_number: '',
		otp: ['', '', '', ''],
	});
	const { mutate: verifyAccount, isLoading } = useMutation({
		mutationFn: (otp) => axios.post(Appurls.auth.verify_account, { otp, email: formData.email }),
	});
	const { mutate: login, isLoading: isLogingIn } = useMutation({ mutationFn: (data) => axios.post(Appurls.auth.login, data) });

	const { mutate: signup, isLoading: isSigningUp } = useSignup();

	const handleSubmit = (event) => {
		event.preventDefault();

		verifyAccount(formData.otp.join(''), {
			onSuccess: () => {
				login(
					{ email: formData?.email?.toLowerCase()?.trim(), password: formData?.password },
					{
						onSuccess: (response) => {
							notify({
								message: 'Account created successfully. Log in with your details',
								status: 'success',
								toastOptions: { toastId: 'verify_account_success' },
							});

							setAuth(response?.data);
							navigate(Approutes.home);
							localStorage.setItem(LOCAL_STORAGE_KEYS.access_key, response?.data?.access);
							localStorage.setItem(LOCAL_STORAGE_KEYS.refresh_key, response?.data?.refresh);
						},
						onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'login' } }),
					}
				);
			},
			onError: (error) => {
				let errorMessage = error?.response.status === 400 ? 'Invalid OTP supplied' : handleApiError(error);

				notify({ message: errorMessage, status: 'error', toastOptions: { toastId: 'verify_account_failed' } });
			},
		});
	};

	return step === 1 ? (
		<CreateAccount formData={formData} setFormData={setFormData} setStep={setStep} />
	) : step === 2 ? (
		<OTP
			title="FRESH GRADUATE | INTERNS"
			value={formData.otp}
			setValue={(value) => setFormData((prev) => ({ ...prev, otp: value }))}
			isSubmitting={isLoading || isLogingIn}
			handleSubmit={handleSubmit}
			isSendingOTP={isSigningUp}
			resendOTP={() =>
				signup(
					{ email: formData.email, password: formData.password, phone_number: formData.phone_number, role: 'INTERN' },
					{
						onSuccess: () =>
							notify({
								message: 'A new OTP has been resent',
								variant: 'minor',
								toastOptions: { toastId: 'otp_success', position: 'bottom-center' },
							}),
						onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'signup' } }),
					}
				)
			}
		/>
	) : null;
};

export default Startup;
