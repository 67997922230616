import styled from 'styled-components';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	inset: 0;
	z-index: 99;

	> div {
		border-radius: 0.25rem;
		background: #fff;
		padding: 2rem;
		padding: 2rem clamp(1.5rem, 1.2222rem + 0.8889vw, 2rem);
		padding-top: 0;
		width: 37.9375rem;
		max-width: calc(100vw - 2rem);
		max-height: calc(100vh - 2rem);
		overflow: auto;

		.header {
			position: sticky;
			top: 0;
			background-color: #fff;
			z-index: 2;
			padding-top: 2rem;

			svg {
				margin-left: auto;
				display: block;
				cursor: pointer;
				width: 1.25rem;
				width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);

				:active {
					outline: none;
				}
			}
			h2 {
				text-align: center;
				font-size: 2rem;
				font-size: clamp(1.75rem, 1.6111rem + 0.4444vw, 2rem);
				margin: 0.5rem 0 1.5rem;
				color: #070d19;
			}
			hr {
				border-top: 1px solid #ebebeb;
			}
		}
		article {
			padding: 1rem;
			border-radius: 0.25rem;
			border: 1px solid rgba(18, 18, 18, 0.03);
			margin: 1rem 0;

			:last-child {
				margin-bottom: 0;
			}

			&.legend {
				> div:first-child span {
					color: #333;
					font-size: 1rem;
					font-weight: 600;
					margin-bottom: 0.75rem;
				}
				> div:last-child p {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					gap: 0.5rem;
				}
			}
			&.legend,
			&.task {
				display: grid;
				grid-template-columns: 1fr 1fr;

				> div:last-child {
					text-align: right;
				}
				.closed {
					color: #ea2d2e;
				}
				.open {
					color: #017d34;
				}
			}

			span {
				display: block;
				margin-bottom: 0.5rem;
				color: #959595;
				font-size: 0.875rem;
			}

			p {
				color: #252122;
				font-size: 0.875rem;
				line-height: 140%;
				font-weight: 600;
			}
		}
	}
`;
