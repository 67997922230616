import { FormEvent, useRef, useState } from 'react';
import { Button, InputGroup } from '../../../../ui';
import { CloseModal } from '../../../../assets/svgs';
import { Container } from './styles';
import { GeneralChangeEventType } from '../../../../types';
import { useQueryClient } from '@tanstack/react-query';
import { handleApiError } from '../../../../utilities';
import { AxiosError } from 'axios';
import { useInviteInstitutionSupervisor, useNotify, useDecodedUser, useGetUserById } from '../../../../hooks';

type InviteSupervisorModalProp = {
	handleClose: () => void;
};

const InviteSupervisorModal = ({ handleClose }: InviteSupervisorModalProp) => {
	const errorRef = useRef<HTMLDivElement>(null!);
	const queryClient = useQueryClient();
	const notify = useNotify();
	const user = useDecodedUser();

	const [formData, setFormData] = useState({
		name: '',
		email: '',
		phone: '',
	});
	const { data: configDetails } = useGetUserById({ id: user?.user_id });

	const { mutate: inviteSupervisor, isLoading } = useInviteInstitutionSupervisor();

	const handleChange: GeneralChangeEventType = (event, name, value) => {
		errorRef.current.innerText = '';

		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name as string]: value }));
	};
	const onSubmit = (event: FormEvent) => {
		event.preventDefault();

		if (formData.name.length === 0 || formData.email.length === 0 || formData.phone.length === 0) {
			errorRef.current.innerText = 'One or more required field needs to be filled';
			return;
		}
		const deptId = configDetails?.department_id || '';

		if (!deptId) {
			errorRef.current.innerText = 'Unable to invite supervisor at this moment';
			return;
		}

		inviteSupervisor(
			{
				department: deptId,
				supervisors: [{ name: formData.name, email: formData.email, phone: formData.phone }],
			},
			{
				onSuccess: () => {
					notify({
						message: 'Supervisor has been invited successfully',
						status: 'success',
						toastOptions: { toastId: 'supervisor_create_success' },
					});

					queryClient.invalidateQueries(['institution-pending-supervisor-head-list']);
					handleClose();
				},
				onError: (error) =>
					notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'supervisor_create_error' } }),
			}
		);
	};

	return (
		<Container>
			<form onSubmit={onSubmit}>
				<div className="form-header">
					<CloseModal tabIndex={0} onClick={handleClose} />
					<h2>Invite Supervisor</h2>
					<br />
				</div>

				<InputGroup
					label="Supervisors' Name"
					name="name"
					onChange={handleChange}
					placeholder="Enter Supervisor's Name"
					value={formData.name}
					required
				/>
				<InputGroup
					label="Supervisor Email"
					name="email"
					onChange={handleChange}
					placeholder="Enter Supervisor Email"
					value={formData.email}
					required
				/>
				<InputGroup
					label="Supervisor's Mobile Number"
					name="phone"
					onChange={handleChange}
					placeholder="Enter Supervisor's Mobile Number"
					value={formData.phone}
					required
				/>
				<div className="error" ref={errorRef}></div>

				<Button type="submit" loading={isLoading}>
					Invite Supervisor
				</Button>
			</form>
		</Container>
	);
};
export default InviteSupervisorModal;
