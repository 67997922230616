import { CloseModal } from '../../../assets/svgs';
import { Button } from '../../../ui';
import { Container } from './styles';

const ConfirmModal = ({ title = 'Confirm Action', body = 'Are you sure you want to proceed ?', handleClose, handleNo, handleYes }) => {
	return (
		<Container>
			<div>
				<CloseModal tabIndex={0} onClick={handleClose} />
				<h2>{title}</h2>
				<p>{body}</p>

				<div className="btn-group">
					<Button variant="secondary" onClick={() => handleNo()}>
						No
					</Button>
					<Button onClick={() => handleYes()}>Yes</Button>
				</div>
			</div>
		</Container>
	);
};
export default ConfirmModal;
