import styled from 'styled-components';
import { StyledButton } from '../../../ui/Button/styles';
import { maxQuery } from '../../../utilities';

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: grid;
	place-items: center;
	position: fixed;
	inset: 0;
	z-index: 99;

	> div {
		border-radius: 0.25rem;
		background: #fff;
		width: 65rem;
		max-width: calc(100vw - 2rem);
		max-height: calc(100vh - 2rem);
		overflow: auto;

		${maxQuery(1050)} {
			width: 40rem;
		}

		.header {
			padding-bottom: 0;
			position: sticky;
			top: 0;
			background-color: #fff;
			z-index: 2;
		}
		> div {
			padding: 2rem;
		}
		.stripe-con {
			padding: 0 0 2rem;
		}
		.back-btn {
			margin: 1rem 0 2rem 2rem;
			color: #252b33;
			font-size: 0.9375rem;
			font-weight: 600;
			border-radius: 0.25rem;
			border: 1px solid #ebebeb;
			background: #fff;
			padding: 1rem 1.5rem;
			display: inline-flex;
			margin-bottom: 1.3rem;
			align-items: center;
			gap: 1rem;

			svg {
				width: 0.8125rem;
				transform: rotate(90deg);
			}
		}

		.close-icon {
			margin-left: auto;
			display: block;
			cursor: pointer;
			width: 1.25rem;
			width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);

			:active {
				outline: none;
			}
		}
		h2 {
			text-align: center;
			font-size: 2rem;
			font-size: clamp(1.75rem, 1.6111rem + 0.4444vw, 2rem);
			margin-top: 0.5rem;
			color: #070d19;
		}
		h2 + p {
			color: #a8a8a8;
			font-size: 1rem;
			font-size: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
			line-height: 120%;
			margin: 0.5rem 0 1.5rem;
			text-align: center;
		}
		h4 {
			color: #070d19;
			font-family: 'Urbanist', sans-serif;
			font-size: 1.5rem;
			font-size: clamp(1.125rem, 0.9167rem + 0.6667vw, 1.5rem);
			font-weight: 600;
			margin-top: 2rem;
		}
		h4 + p {
			color: #787878;
			font-size: 1rem;
			font-size: clamp(0.875rem, 0.8056rem + 0.2222vw, 1rem);
		}
		hr {
			border-top: 1px solid #ebebeb;
		}
		.cost {
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: #787878;
			font-size: 1.125rem;
			font-size: clamp(1rem, 0.9306rem + 0.2222vw, 1.125rem);
			padding: 2rem 0 1rem;

			b {
				color: #4060ea;
				font-weight: 700;
			}
		}
		.loading-con {
			display: grid;
			place-items: center;
			min-height: 13rem;
		}
		.error-msg {
			text-align: center;
			color: #1e1e1e;
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 135%;
			padding: 2rem 0;
		}

		.braintree-sheet__content--form .braintree-form__flexible-fields {
			display: block;

			a {
				text-decoration: underline;
				color: #4060ea;
			}
		}
		.btn-group {
			display: flex;
			gap: 1rem;
			margin-top: 3rem;
		}
		${StyledButton} {
			flex: 1;
		}
	}
`;
