import { Link, useSearchParams } from 'react-router-dom';
import { Button } from '../../../ui';
import { Approutes, LOCAL_STORAGE_KEYS, queryStrings } from '../../../constants';
import { WelcomeCheckMark, WelcomeHourGlass, WelcomeRejected } from '../../../assets/svgs';
import { useDecodedUser, useGeneralStore, useNotify, useResubmitProfile } from '../../../hooks';
import { getPreviousSearchParams, handleApiError, hasStartupPaid } from '../../../utilities';
import { useState } from 'react';

const StartupFlow = () => {
	const notify = useNotify();
	const user = useDecodedUser();
	const [hasResubmittedProfile, setHasResubmittedProfile] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const { store } = useGeneralStore();

	const { mutate: resubmitProfile, isLoading: isResubmitting } = useResubmitProfile();

	const handleResubmitProfile = () => {
		resubmitProfile(user?.user_id, {
			onSuccess: () => {
				setHasResubmittedProfile(true);
				localStorage.setItem(LOCAL_STORAGE_KEYS.has_resubmitted_profile, 'true');
			},
			onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'resubmit_profile_error' } }),
		});
	};

	return user?.startup_profile_status === 'DECLINED' &&
		!hasResubmittedProfile &&
		!localStorage.getItem(LOCAL_STORAGE_KEYS.has_resubmitted_profile) ? (
		<div className="verification-con">
			<WelcomeRejected />
			<h1>Profile Declined</h1>
			<p>
				You profile has been declined by an admin. The reason has been sent to your email. Make the needed changes in{' '}
				<Link to={Approutes.profile}>your profile</Link> and reapply for verification.
			</p>
			<Button onClick={handleResubmitProfile} loading={isResubmitting}>
				Reapply for verification
			</Button>
		</div>
	) : user?.startup_profile_status === 'PENDING' ||
	  hasResubmittedProfile ||
	  localStorage.getItem(LOCAL_STORAGE_KEYS.has_resubmitted_profile) === 'true' ? (
		<div className="verification-con">
			<WelcomeHourGlass className="hourglass" />
			<h1>Awaiting Verification</h1>
			<p>
				You are profile is currently going through the verification stage by <br />
				Descinder's admin. Check back within 72 Hours.
			</p>
		</div>
	) : user?.startup_profile_status === 'APPROVED' && store?.has_paid === false && !hasStartupPaid(user) ? (
		<div className="verification-con">
			<WelcomeCheckMark />
			<h1>Profile Verified</h1>
			<p>You profile has been verified and you can now select a subscription plan to kick start your project and start adding resources</p>

			<Button
				onClick={() => {
					let previousParams = getPreviousSearchParams(searchParams);

					previousParams = { ...previousParams, [queryStrings.subscription_plan]: 'open' };
					setSearchParams(previousParams, { replace: true });
				}}
			>
				Select Subscription
			</Button>
		</div>
	) : (
		<div>
			<h1>Welcome to Descinder</h1>
			<p></p>

			{user?.startup_has_project ? (
				<>
					<b>View Projects</b>
					<p>To view already created projects, click on the button below</p>

					<Link to={Approutes.startup.projects.index}>
						<Button>View Projects</Button>
					</Link>
				</>
			) : (
				<>
					<b>Create New Project</b>
					<p>To create a new project, click on the button below to get started</p>

					<Link to={Approutes.startup.projects.create_project}>
						<Button>Create Project</Button>
					</Link>
				</>
			)}
		</div>
	);
};
export default StartupFlow;
