import { useEffect } from 'react';
import { CloseModal, EducationIcon, ExperienceIcon, ProjectEmpty } from '../../../assets/svgs';
import { Approutes, queryStrings } from '../../../constants';
import { Button, Loader } from '../../../ui';
import { Container } from './styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getDateTime, getInternshipTimeLeft, getPreviousSearchParams } from '../../../utilities';
import { useCreateRoom, useGetApplicant, useNotify } from '../../../hooks';

const ApplicantDetails = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const applicantId = searchParams.get(queryStrings.applicantId) || '';

	const notify = useNotify();
	const navigate = useNavigate();

	const result = useGetApplicant({ applicantId });
	const { mutate: startConversation, isLoading: isStartingConversation } = useCreateRoom();
	const {
		data: applicant,
		isLoading,
		isError,
	} = {
		data: {
			...result[0]?.data,
			experiences: result[1]?.data,
		},
		isLoading: (result[0]?.isLoading || result[1]?.isLoading) === undefined ? true : result[0]?.isLoading || result[1]?.isLoading,
		isError: result[0]?.isError || result[1]?.isError || false,
	};

	const handleClose = () => {
		let previousParams = getPreviousSearchParams(searchParams);
		delete previousParams[queryStrings.applicantId];

		setSearchParams(previousParams, { replace: true });
	};

	const handleStartConversation = () => {
		startConversation(
			{
				user_id: applicantId,
			},
			{
				onSuccess: (data) => {
					navigate(`${Approutes.messages.index}?${queryStrings.message_new_room_id}=${data?.data?.id}`);
				},
				onError: (error) =>
					notify({
						message: 'Something went wrong',
						variant: 'minor',
						toastOptions: { toastId: 'start_conversation_error', position: 'bottom-center' },
					}),
			}
		);
	};

	useEffect(() => {
		if (applicantId) return;

		let previousParams = getPreviousSearchParams(searchParams);
		delete previousParams[queryStrings.applicantId];

		setSearchParams(previousParams, { replace: true });
	}, [applicantId, searchParams, setSearchParams]);

	return (
		<Container>
			{isLoading ? (
				<center>
					<CloseModal tabIndex={0} onClick={handleClose} />

					<div>
						<Loader />
					</div>
				</center>
			) : isError ? (
				<center>
					<CloseModal tabIndex={0} onClick={handleClose} />

					<div>
						<div>
							<ProjectEmpty />
							<p>Unable to fetch resource details at the moment</p>
						</div>
					</div>
				</center>
			) : (
				<div>
					<div className="header">
						<CloseModal tabIndex={0} onClick={handleClose} />
						<h2>{applicant?.is_in_project ? applicant?.name : `Resource ${applicant?.applicant_id}`}</h2>
						<hr />
					</div>

					<article>
						<b>About</b>
						<p>{applicant?.about}</p>
					</article>

					<article className="internship">
						<div>
							<b>Internship Start Date </b>
							<p>{new Date() > new Date(applicant?.start_date) ? 'Ready to start' : getDateTime(applicant?.start_date, 'mm dd yyyy')}</p>
						</div>
						<div>
							<b>Internship End Date</b>
							<p>{getDateTime(applicant?.end_date, 'mm dd yyyy')}</p>
						</div>
						<div>
							<b>Internship Duration</b>
							<p>{getInternshipTimeLeft(applicant?.start_date, applicant?.end_date)}</p>
						</div>
					</article>

					{applicant?.experiences?.length ? (
						<article className="experiences">
							<b>Experiences</b>
							{applicant?.experiences?.map((experience) => (
								<div key={experience?.id}>
									<ExperienceIcon />

									<div>
										<p>{experience?.employer_name}</p>
										<span>
											{experience?.role} - {experience?.duration}
										</span>
									</div>
								</div>
							))}
						</article>
					) : null}

					<article className="education">
						<b>Education</b>
						<div>
							<EducationIcon />
							<div>
								<p>{applicant?.recent_institution}</p>
								<span>
									{applicant?.area_of_study} - {applicant?.applicant_type}
								</span>
							</div>
						</div>
					</article>

					<article className="skills">
						<b>Interested Roles</b>

						<div>
							{applicant?.interested_role?.map((role) => (
								<span key={role}>{role}</span>
							))}
						</div>
					</article>

					<article className="skills">
						<b>Skills</b>

						<div>
							{applicant?.skills?.map((skill) => (
								<span key={skill}>{skill}</span>
							))}
						</div>
					</article>

					<article className="languages">
						<b>Languages</b>

						<div>
							<div>
								<b>English</b>
								<span>{applicant?.languages[0]}</span>
							</div>
						</div>
					</article>

					<div className="btn-group">
						<Button loading={isStartingConversation} onClick={handleStartConversation}>
							{applicant?.is_in_project ? 'Send a message' : 'Start Conversation'}
						</Button>
						{/* <Button>Add Intern to Project</Button> */}
					</div>
				</div>
			)}
		</Container>
	);
};
export default ApplicantDetails;
