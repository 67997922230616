import styled from 'styled-components';
import { maxQuery } from '../../../../utilities';
import { StyledButton } from '../../../../ui/Button/styles';

export const Container = styled.section`
	margin: 2rem 6.25rem;
	margin: 2rem clamp(1rem, -1.9167rem + 9.3333vw, 6.25rem);

	center {
		height: 100%;
		display: grid;
		place-items: center;
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;
		grid-column: 1/3;
	}
	.empty-state {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		grid-column: 1/3;
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;

		p {
			color: #1e1e1e;
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 135%;
			margin-top: 1rem;
		}
		span {
			text-transform: capitalize;
		}
	}

	> a {
		display: block;
		width: fit-content;
		margin: 0 0 1rem auto;

		${StyledButton} {
			padding: 0.8rem 1.5rem;
		}
	}

	main {
		border-radius: 0.25rem;
		background: #fff;
		box-shadow: -9px 19px 40px 0px rgba(0, 0, 0, 0.02);
		padding: 1.5rem;
		display: grid;
		gap: 3rem;
		grid-template-columns: 31fr 19fr;
		min-height: calc(100vh - 8.5rem);

		${maxQuery('xl')} {
			grid-template-columns: 1fr;
			gap: 1rem;
			background-color: transparent;
			padding: 0;
		}

		> section {
			background: #fff;
			overflow: hidden;
			display: grid;

			${maxQuery('xl')} {
				padding: 1.5rem;
				border-radius: 0.25rem;
			}

			article {
				margin: 0.5rem 0;
				border: 1px solid rgba(18, 18, 18, 0.05);
				border-radius: 0.25rem;
				padding: 1rem;

				:last-child {
					margin-bottom: 0;
				}

				&.intro {
					b {
						font-size: 1.4rem;
					}
					p {
						font-size: 1rem;
					}
				}

				b {
					display: block;
					margin-bottom: 0.63rem;
				}

				p {
					color: #070d19;
					font-size: 0.875rem;
					line-height: 140%;
				}
				> div {
				}
			}
			.internship {
				display: flex;
				justify-content: space-between;
				text-align: center;
			}
			.experiences > div > div,
			.education > div > div {
				padding: 0.62rem 0;
				display: flex;
				align-items: center;
				gap: 0.87rem;

				:not(:last-child) {
					border-bottom: 1px solid rgba(18, 18, 18, 0.03);
				}

				p {
					font-weight: 500;
				}

				span {
					color: #070d19;
					font-size: 0.75rem;
					margin-top: 0.31rem;
				}
			}
			.skills > div,
			.languages > div {
				display: flex;
				flex-wrap: wrap;
				gap: 0.5rem;

				span {
					color: #070d19;
					font-size: 0.8125rem;
					padding: 0.5rem 0.625rem;
					border-radius: 0.1875rem;
					background: #f4f5f9;
					text-transform: capitalize;
				}
			}
			.languages > div div {
				color: #070d19;
				font-size: 0.8125rem;
				padding: 0.5rem 0.625rem;
				border-radius: 0.1875rem;
				background: #f4f5f9;
				text-transform: capitalize;

				b {
					margin-bottom: 0;
				}
				span {
					padding: 0;
				}
			}
		}

		aside {
			border-radius: 0.25rem;
			border: 1px solid rgba(18, 18, 18, 0.05);
			background: #fff;
			padding: 2rem 1.5rem 1.5rem;

			h4 {
				color: #11071a;
				font-family: 'Urbanist', sans-serif;
				font-size: 1.25rem;
				font-weight: 600;
				border-bottom: 1px solid #ebebeb;
				padding-bottom: 0.62rem;
				margin-bottom: 1.5rem;
			}

			.flexed {
				padding: 0.8rem 0;
				padding: clamp(0.5rem, 0rem + 1.6vw, 0.8rem) 0;

				:not(:last-child) {
					border-bottom: 1px solid rgba(150, 150, 150, 0.11);
				}
				&.flexed {
					display: flex;
					justify-content: space-between;
				}

				> div:last-child {
					text-align: right;
				}
				b {
					color: rgba(15, 15, 15, 0.46);
					font-size: 0.8125rem;
					font-size: clamp(0.8125rem, 0.5rem + 1vw, 1rem);
					font-weight: 600;
				}
				p {
					color: #323232;
					font-size: 0.8125rem;
					font-size: clamp(0.8125rem, 0.5rem + 1vw, 1rem);
					font-weight: 600;
				}
				.negative {
					text-transform: capitalize;
					color: #ea2d2e !important;
					text-transform: none;
				}
				.positive {
					text-transform: capitalize;
					color: #017d34 !important;
					text-transform: none;
				}
				.neutral {
					text-transform: capitalize;
					color: #c08030 !important;
					text-transform: none;
				}
			}

			section:nth-child(2) {
				margin-top: 2rem;

				> div {
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 0.875rem;
					font-weight: 500;
					border: 1px solid #dadada;
					border-radius: 0.25rem;
					padding: 1rem 1.25rem;

					:not(:last-child) {
						margin-bottom: 0.625rem;
					}
				}
			}
		}
	}
`;
