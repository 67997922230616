const getDateDifference = (firstDate: string, secondDate: string, returnAbsolute: boolean = true) => {
	const oneDay = 24 * 60 * 60 * 1000;

	const newFirstDate = new Date(firstDate);
	const newSecondDate = new Date(secondDate);

	newFirstDate.setHours(0, 0, 0);
	newSecondDate.setHours(0, 0, 0);

	const diffDays = returnAbsolute ? Math.round(Math.abs((newFirstDate.getTime() - newSecondDate.getTime()) / oneDay)) : Math.round((newFirstDate.getTime() - newSecondDate.getTime()) / oneDay);

	return diffDays;
};
export default getDateDifference;
