import styled from 'styled-components';
import { StyledButton } from '../../ui/Button/styles';
import { maxQuery } from '../../utilities';

export const Container = styled.div`
	padding: 2.5rem 6.25rem;
	padding: 2.5rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);

	.header {
		margin-bottom: 1.87rem;

		h4 {
			color: #11071a;
			font-family: 'Urbanist', sans-serif;
			font-size: 1.5rem;
			font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
			font-weight: 700;
			margin-bottom: 0.25rem;
			line-height: 120%;
		}
		p {
			color: #828282;
			font-size: 0.75rem;
			line-height: 120%;
		}
	}

	> section {
		border-radius: 0.25rem;
		background: #fff;
		box-shadow: -9px 19px 40px 0px rgba(0, 0, 0, 0.02);
		padding: 2.5rem 6.25rem;
		padding: 2.5rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);
		min-height: calc(100vh - (5.3rem + 134px));

		h4 {
			color: #070d19;
			font-family: 'Urbanist', sans-serif;
			font-size: 1.5rem;
			font-size: clamp(1.125rem, 0.9167rem + 0.6667vw, 1.5rem);
			font-weight: 600;
			margin-top: 2rem;
		}
		h4 + p {
			color: #787878;
			font-size: 1rem;
			font-size: clamp(0.875rem, 0.8056rem + 0.2222vw, 1rem);
		}

		.grid {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
			gap: 1rem 2rem;
			margin-top: 1.5rem;

			${maxQuery('xs')} {
				grid-template-columns: 1fr;
			}
		}
		h4 + summary {
			margin-top: 0.87rem;
		}
		.summary {
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: #787878;
			font-size: 1.125rem;
			font-size: clamp(1rem, 0.9306rem + 0.2222vw, 1.125rem);
			padding: 1rem 0;
			gap: 1rem;

			span {
				min-width: max-content;
			}

			b {
				color: #4060ea;
				font-weight: 700;
				text-transform: uppercase;
				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				text-align: right;

				${maxQuery('md')} {
					padding-right: 0.75rem;
				}
			}
		}
		${StyledButton} {
			width: 26rem;
			max-width: 100%;
			margin: 3rem auto 0;
			justify-content: center;
		}
	}
`;
