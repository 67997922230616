import styled, { css } from 'styled-components';
import { maxQuery, minMaxQuery, minQuery } from '../../utilities';
import { StyledPagination } from '../../ui/Pagination/styles';

export const Container = styled.div`
	padding: 2.5rem 6.25rem;
	padding: 2.5rem clamp(2rem, -0.3611rem + 7.5556vw, 6.25rem);

	.header {
		margin-bottom: 1.5rem;

		h4 {
			color: #11071a;
			font-family: 'Urbanist', sans-serif;
			font-size: 1.5rem;
			font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
			font-weight: 700;
			margin-bottom: 0.25rem;
			line-height: 120%;
		}
		p {
			color: #828282;
			font-size: 0.75rem;
			line-height: 120%;
		}
	}
	center {
		height: calc(100vh - 24rem);
		display: grid;
		place-items: center;
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;
	}
	.empty-state {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: calc(100vh - 24rem);
		background-color: #fff;
		border-radius: 0.125rem;
		padding: 1rem;

		p {
			color: #1e1e1e;
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 135%;
			margin-top: 1rem;
		}
		span {
			text-transform: capitalize;
		}
	}
	.supervisors {
		padding: 1.25rem 0;
		border-radius: 0.25rem;

		${maxQuery('xl')} {
			background-color: transparent;
			gap: 1rem;
			padding: 0;
		}
	}
	${StyledPagination} {
		justify-content: center;
		margin-top: 3rem;
	}
`;

export const StyledResource = styled.div`
	background-color: #fff;
	padding-left: 3rem;
	padding-right: 3rem;
	border-radius: 0.25rem;

	${maxQuery('xl')} {
		padding-left: 0rem;
		padding-right: 0rem;
	}

	&.active {
		padding-bottom: 1.5rem;

		.project-con > div:last-child svg {
			transform: rotate(180deg);
		}
		.table-container,
		> a {
			display: block;
		}
		center {
			display: grid;
		}
		.empty-state {
			display: flex;
		}
	}

	:not(:last-child) {
		margin-bottom: 1rem;
	}

	center,
	.empty-state {
		display: none;
		height: 8rem;
	}
	.supervisor-con {
		display: grid;
		grid-template-columns: 20% 20% 20% 15% 15% 10%;
		padding: 1.25rem 0;

		${maxQuery('xl')} {
			grid-template-columns: 1fr 1fr;
			background-color: #fff;
			padding: 0.5rem 1.5rem;
			border-radius: 0.25rem;
		}
		${maxQuery('360px')} {
			grid-template-columns: 1fr;
		}

		> div {
			:not(:last-child) {
				border-right: 1px solid rgba(206, 206, 206, 0.4);

				${maxQuery('xl')} {
					border-right: none;
				}
			}
			:nth-child(1) {
				display: flex;
				gap: 0.5rem;
				align-items: center;
				flex-wrap: wrap;

				${maxQuery('xl')} {
					padding: 1rem 0;
					border-bottom: 1px solid rgba(206, 206, 206, 0.4);
					grid-column: 1/3;
				}
				${minMaxQuery('sm', 'xl')} {
					position: relative;
					padding-left: 2.5rem;
					padding-left: clamp(2.5rem, -3.5rem + 16vw, 8.5rem);
				}
				${maxQuery('360px')} {
					grid-column: 1/2;
					justify-content: center;
				}

				span {
					background: #fcf8f3;
					color: #c08030;
					font-size: 0.75rem;
					font-weight: 700;
					line-height: 120%;
					text-transform: uppercase;
					border-radius: 6.25rem;
					padding: 0.3125rem 0.625rem;

					${({ $sector }) =>
						$sector?.toLowerCase() === 'gaming' &&
						css`
							background: #ebf9e9;
							color: #42b431;
						`}
					${({ $sector }) =>
						$sector?.toLowerCase() === 'payment' &&
						css`
							background: #e5f3fe;
							color: #2299f6;
						`}
				}
			}
			:nth-child(2),
			:nth-child(3),
			:nth-child(4),
			:nth-child(5) {
				${maxQuery('xl')} {
					padding: 1rem 0;
				}
				${maxQuery('360px')} {
					grid-row: unset;
					padding: 1rem 0;
					border-bottom: 1px solid rgba(206, 206, 206, 0.4);
				}

				b,
				span {
					display: block;
					text-align: center;
				}
				b {
					font-size: clamp(0.8125rem, 0.2917rem + 1.6667vw, 1.125rem);
				}
				> span {
					color: rgba(15, 15, 15, 0.46);
					font-size: 1.0625rem;
					font-size: clamp(0.8125rem, 0.3958rem + 1.3333vw, 1.0625rem);
					font-weight: 500;
				}
			}
			:nth-child(2) {
				${maxQuery('xl')} {
					border-bottom: 1px solid rgba(206, 206, 206, 0.4);
				}
				${maxQuery('sm')} {
					justify-content: flex-start;
				}

				> div {
					display: flex;
					gap: 0.5rem;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;
					padding: 0 1rem;

					span {
						background: #fcf8f3;
						color: #c08030;
						font-size: 0.75rem;
						font-weight: 700;
						line-height: 120%;
						text-transform: uppercase;
						border-radius: 6.25rem;
						padding: 0.3125rem 0.625rem;

						${({ $sector }) =>
							$sector?.toLowerCase() === 'gaming' &&
							css`
								background: #ebf9e9;
								color: #42b431;
							`}
						${({ $sector }) =>
							$sector?.toLowerCase() === 'payment' &&
							css`
								background: #e5f3fe;
								color: #2299f6;
							`}
					}
				}
			}
			:nth-child(3) {
				${maxQuery('xl')} {
					border-bottom: 1px solid rgba(206, 206, 206, 0.4);
				}
				${maxQuery('sm')} {
					justify-content: flex-end;
				}
			}
			:nth-child(4) {
				${maxQuery('xl')} {
					border-bottom: 1px solid rgba(206, 206, 206, 0.4);
				}

				b {
					color: #04cc56;
				}
			}
			:nth-child(5) {
				${maxQuery('xl')} {
					border-bottom: 1px solid rgba(206, 206, 206, 0.4);
				}

				b {
					color: #d6a05c;
				}
			}
			:nth-child(6) {
				display: flex;
				align-items: center;
				justify-content: center;

				${maxQuery('xl')} {
					padding: 1rem 0 0.5rem;
					grid-column: 1/3;
				}
				${maxQuery('sm')} {
					justify-content: flex-end;
				}
				${maxQuery('360px')} {
					grid-row: unset;
					border-bottom: none;
				}

				button {
					padding: 1rem;
					background-color: transparent;
				}
			}

			b {
				color: #323232;
				font-size: 1.125rem;
				font-size: clamp(1rem, 0.7917rem + 0.6667vw, 1.125rem);
				font-weight: 700;
				line-height: 132%;
			}
		}
	}
	.table-container {
		display: none;
		overflow: auto;
		padding: 0 1.5rem;

		${maxQuery('md')} {
			display: none;
		}
	}
	table {
		width: 100%;
		min-width: max-content;
		border-collapse: collapse;

		> * > tr > *:first-child {
			text-align: center;
		}
		${minQuery('300px')} {
			> * > tr > *:first-child {
				left: 0;
				position: sticky;
				text-align: center;
			}
		}

		thead {
			th {
				font-weight: 600;
				padding: 0.875rem 1rem;
				text-align: inherit;

				:last-child {
					text-align: right;
				}
			}
		}

		tbody {
			background-color: #fff;

			tr {
				:not(:last-child) {
					border-bottom: 1px solid #dde0e2;
				}
			}

			td {
				background-color: #fff;
				font-weight: 500;
				padding: 1.25rem 1rem;

				:last-child {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					gap: 1rem;

					a {
						display: flex;
						align-items: center;
					}

					svg {
						cursor: pointer;

						path {
							fill: #4060ea;
						}
					}
				}
			}
		}
	}
	> a {
		display: none;
		color: #4060ea;
		font-size: 1rem;
		font-weight: 600;
		margin: 1rem auto 0;
		width: max-content;
	}
	.open,
	p.open {
		color: #24b314;
	}
	.closed,
	p.closed {
		color: #ea2d2e;
	}
`;
