import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { queryStrings } from '../../constants';
import { ButtonPlus, ChevronDown, ProjectEmpty, EditTask as EditTaskIcon } from '../../assets/svgs';
import { Container } from './styles';
import { Button, Loader } from '../../ui';
import { useState } from 'react';
import { useGetProject, useGetTasks, useNotify, usePatchProject, useOptimisticPatchTask } from '../../hooks';
import { getPreviousSearchParams, handleApiError } from '../../utilities';
import { AddTask, AssignTask, ConfirmModal, EditTask } from '../../components/Modals';
import { useCallback } from 'react';
import Details from './Details';
import EditProject from './EditProject';
import { InviteResource, ProjectResource } from './modals';

const ProjectDetails = () => {
	const notify = useNotify();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isEditingProject, setIsEditingProject] = useState(false);
	const [isLoadingEditingProject, setIsLoadingEditingProject] = useState(false);

	const { projectId } = useParams();
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState('');
	const [assignState, setAssignState] = useState({
		isOpen: false,
		taskName: '',
		taskDetail: '',
		taskId: '',
		resources: [],
		task: {},
	});
	const [confirmModal, setConfirmModal] = useState(false);
	const [addTask, setAddTask] = useState(false);
	const [inviteResource, setInviteResource] = useState({
		inviteModal: false,
		resourceModal: false,
	});
	const [editState, setEditState] = useState({
		openModal: false,
		task_name: '',
		task_desc: '',
		task_id: '',
	});

	const { data: project, isLoading, isError, refetch: refetchProject } = useGetProject({ id: projectId });
	const {
		data: tasks,
		isLoading: isTasksLoading,
		isError: isTasksError,
		refetch: refetchTasks,
	} = useGetTasks({ projectId, page, page_size: 5, search });

	const { mutate: patchTask } = useOptimisticPatchTask();
	const { mutate: patchProject, isLoading: isPatchingProject } = usePatchProject({ projectId });

	const handleCompleteTask = (id, is_completed) => {
		patchTask({ id, data: { is_completed }, queryKey: { projectId, page, page_size: 5, search } }, { onSuccess: () => refetchProject() });
	};
	const handlePatchProject = (data, message = '') => {
		setConfirmModal(false);

		patchProject(data, {
			onSuccess: (_data) => {
				refetchProject();
				message && notify({ message, status: 'success', toastOptions: { toastId: 'project_patch_success' } });
			},
			onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'project_patch_error' } }),
		});
	};
	const handleAssignTask = (task) => {
		setAssignState({
			isOpen: true,
			taskName: task?.name,
			taskDetail: task?.description,
			taskId: task?.id,
			resources: project?.resources,
			task,
		});
	};
	const handleViewApplicant = (id) => {
		let previousParams = getPreviousSearchParams(searchParams);

		previousParams = { ...previousParams, [queryStrings.applicantId]: id };
		setSearchParams(previousParams, { replace: true });
	};
	const handleChange = useCallback((_event, _name, value) => setSearch(value), []);

	return (
		<Container>
			<div className="action-con">
				<button className="back-btn" onClick={() => navigate(-1)}>
					<ChevronDown /> <span>Back to Projects</span>
				</button>

				{!isLoading && !isError && project?.status !== 'completed' ? (
					<div>
						{isEditingProject ? (
							<>
								<Button className="cancel-btn" variant="secondary" onClick={() => setIsEditingProject(false)}>
									Cancel
								</Button>
								<Button loading={isLoadingEditingProject} onClick={() => document.querySelector('#submit-edit')?.click()}>
									Save Changes
								</Button>
							</>
						) : (
							<>
								<Button
									disabled={isPatchingProject}
									onClick={() => {
										if (isPatchingProject) return;

										setIsEditingProject(true);
									}}
									variant="secondary"
									className="edit-btn"
								>
									<EditTaskIcon />
									<span>Edit Project</span>
								</Button>
								<Button
									className="invite-btn"
									onClick={() =>
										setInviteResource({
											inviteModal: true,
											resourceModal: false,
										})
									}
								>
									<ButtonPlus /> <span>Invite Resource(s)</span>
								</Button>
							</>
						)}
					</div>
				) : null}
			</div>

			{isLoading ? (
				<center className="loading-con">
					<Loader />
				</center>
			) : isError ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>Unable to fetch project details at the moment</p>
				</div>
			) : isEditingProject ? (
				<EditProject
					project={project}
					projectId={projectId}
					refetchProject={refetchProject}
					setIsEditingProject={setIsEditingProject}
					setIsLoadingEditingProject={setIsLoadingEditingProject}
				/>
			) : (
				<Details
					project={project}
					isTasksLoading={isTasksLoading}
					isTasksError={isTasksError}
					isLoading={isLoading}
					isPatchingProject={isPatchingProject}
					search={search}
					tasks={tasks}
					projectId={projectId}
					page={page}
					setPage={setPage}
					setEditState={setEditState}
					setConfirmModal={setConfirmModal}
					setAddTask={setAddTask}
					handleChange={handleChange}
					handleCompleteTask={handleCompleteTask}
					handleAssignTask={handleAssignTask}
					handleViewApplicant={handleViewApplicant}
				/>
			)}

			{assignState.isOpen ? (
				<AssignTask
					taskId={assignState.taskId}
					taskName={assignState.taskName}
					taskDetail={assignState.taskDetail}
					resources={assignState?.resources}
					handleSuccess={() => refetchTasks()}
					handleClose={() =>
						setAssignState({
							isOpen: false,
							taskName: '',
							taskDetail: '',
							taskId: '',
							resources: [],
						})
					}
					defaultValues={{
						start_date: assignState.task.start_date,
						end_date: assignState.task.end_date,
						docs: assignState.task.docs,
						tags: assignState.task.tags,
						project_supervisor: assignState.task.project_supervisor,
						assigned_to: assignState.task.assigned_to,
					}}
				/>
			) : null}
			{addTask ? <AddTask projectId={projectId} refetchTasks={refetchTasks} handleClose={() => setAddTask(false)} /> : null}
			{inviteResource?.inviteModal ? (
				<InviteResource
					handleResourceClick={() =>
						setInviteResource({
							inviteModal: false,
							resourceModal: true,
						})
					}
					handleClose={() =>
						setInviteResource({
							inviteModal: false,
							resourceModal: false,
						})
					}
				/>
			) : null}
			{inviteResource?.resourceModal ? (
				<ProjectResource
					projectId={projectId}
					projectResources={project?.resources}
					handleSubmit={() => refetchProject()}
					handleClose={() =>
						setInviteResource({
							inviteModal: false,
							resourceModal: false,
						})
					}
				/>
			) : null}
			{editState.openModal ? (
				<EditTask
					taskName={editState.task_name}
					taskDesc={editState.task_desc}
					handleClose={() =>
						setEditState({
							openModal: false,
							task_name: '',
							task_desc: '',
							task_id: '',
						})
					}
					handleSubmit={(data) => {
						const { task_name, task_description } = data;

						patchTask(
							{
								id: editState.task_id,
								data: { name: task_name, description: task_description },
								queryKey: { projectId, page, page_size: 5, search },
							},
							{ onSuccess: () => refetchProject() }
						);

						setEditState({
							openModal: false,
							task_name: '',
							task_desc: '',
							index: null,
						});
					}}
				/>
			) : null}
			{confirmModal ? (
				<ConfirmModal
					title="Complete Project"
					body={
						<>
							Are you sure you want to mark <b>{project?.name}</b> as complete ?<br />
							This action <b style={{ color: '#ff7542' }}>cannot be reversed</b>
						</>
					}
					handleClose={() => setConfirmModal(false)}
					handleNo={() => setConfirmModal(false)}
					handleYes={() => handlePatchProject({ status: 'completed' }, 'Project has been marked as completed')}
				/>
			) : null}
		</Container>
	);
};
export default ProjectDetails;
