const EducationAndExperience = ({ user }) => {
	return (
		<>
			<article className="flexed">
				<div>
					<b>Highest Level of Education</b>
					<p>{user?.highest_edu_level}</p>
				</div>
				<div>
					<b>Area of Study</b>
					<p>{user?.area_of_study}</p>
				</div>
			</article>

			<article>
				<b>Institution</b>
				<p>{user?.recent_institution}</p>
			</article>

			<article className="attachments">
				<b>Institution ID Card</b>

				<div>{user?.id_card?.[0]?.url ? <img className="id-card-img" src={user?.id_card?.[0]?.url} alt="" /> : 'N/A'}</div>
			</article>
		</>
	);
};
export default EducationAndExperience;
