import { Outlet } from 'react-router-dom';
import { useDecodedUser } from '../../../../hooks';
import { isInstitutionDepartmentHead } from '../../../../utilities';
import { PageNotFound } from '../../..';
import { UserType } from '../../../../types';

const InstitutionDepartmentHeadOnly = () => {
	const user = useDecodedUser();

	return isInstitutionDepartmentHead(user as UserType) ? <Outlet /> : <PageNotFound />;
};

export default InstitutionDepartmentHeadOnly;
