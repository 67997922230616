import { Button, InputGroup } from '../../ui';
import { Container } from './styles';
import { Approutes, Appurls, LOCAL_STORAGE_KEYS } from '../../constants';
import { FormEvent, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { axios } from '../../library';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth, useNotify } from '../../hooks';
import { handleApiError } from '../../utilities';
import { useEffect } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { GeneralChangeEventType } from '../../types';

const Login = () => {
	const notify = useNotify();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { auth, setAuth } = useAuth();
	const [formData, setFormData] = useState({ email: '', password: '' });

	const { mutate: login, isLoading } = useMutation<AxiosResponse<{ access?: string; refresh?: string }>, AxiosError, typeof formData>({
		mutationFn: (data) => axios.post(Appurls.auth.login, data),
	});

	const handleChange: GeneralChangeEventType = (event) => {
		let name = event?.target.name || '';
		let value = event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();

		login(formData, {
			onSuccess: (response) => {
				setAuth(response?.data);
				navigate(searchParams.get('next') || Approutes.home);
				response?.data?.access && localStorage.setItem(LOCAL_STORAGE_KEYS.access_key, response?.data?.access);
				response?.data?.refresh && localStorage.setItem(LOCAL_STORAGE_KEYS.refresh_key, response?.data?.refresh);
			},
			onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'login' } }),
		});
	};

	useEffect(() => {
		if (auth?.access || localStorage.getItem(LOCAL_STORAGE_KEYS.access_key)) {
			navigate(searchParams.get('next') || Approutes.home);
		}
	}, [auth?.access, navigate, searchParams]);

	return (
		<Container>
			<div>
				<h2>Account Login</h2>
				<p>Provide Credentials below to login to your account</p>

				<form onSubmit={handleSubmit}>
					<InputGroup
						label="Email Address"
						type="email"
						name="email"
						onChange={handleChange}
						placeholder="Enter Email Address"
						value={formData.email}
						required
					/>
					<div className="password-group">
						<a href={Approutes.forgot_password}>Forgot Password?</a>
						<InputGroup
							label="Password"
							type="password"
							name="password"
							onChange={handleChange}
							placeholder="Password"
							value={formData.password}
							required
						/>
					</div>

					<Button type="submit" loading={isLoading}>
						Login to Account
					</Button>

					<span className="login">
						Don't have an account? <a href={Approutes.signup.index}>SignUp</a>
					</span>
				</form>
			</div>
		</Container>
	);
};

export default Login;
