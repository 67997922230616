import { useState, useEffect } from 'react';
import { ChevronDown, Company, CompanyActive, ProfileActive } from '../../../assets/svgs';
import CompleteProfile from './CompleteProfile';
import AboutCompany from './AboutCompany';
import { useDecodedUser, useGetUserById, useLogout, useNotify, useUser } from '../../../hooks';

const AsStartup = () => {
	const user = useDecodedUser();
	const logout = useLogout();
	const notify = useNotify();
	const { data: userDetails } = useUser();
	const { data: configDetails } = useGetUserById({ id: user?.user_id });

	const [step, setStep] = useState(1);
	const [formData, setFormData] = useState({
		country_registered: '',
		company_name: '',
		company_sector: '',
		company_size: '',
		company_registration_number: '',
		company_logo: {
			name: '',
			file: null,
		},
		company_website: '',
		introduction: '',
	});

	const handleChange = (event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const handleLogout = () => {
		logout(true);
		notify({ message: "You've been logged out successfully", toastOptions: { toastId: 'logout_success' } });
	};

	useEffect(() => {
		const currentStep = document.querySelector(`#step${step}`);

		currentStep?.parentElement?.scroll(currentStep.offsetLeft, 0);
	}, [step]);

	useEffect(() => {
		if (!userDetails) return;

		setFormData((prev) => ({
			...prev,
			country_registered: userDetails?.country_of_reg || '',
			company_name: userDetails?.company_name || '',
			company_sector: userDetails?.sector || '',
			company_size: userDetails?.size || '',
			company_registration_number: userDetails?.company_registration_no || '',
			company_website: userDetails?.website_url || '',
			introduction: userDetails?.about || '',
		}));
	}, [userDetails]);

	useEffect(() => {
		if (configDetails?.startup_profile_step_1_complete === false) {
			setStep(1);
		} else if (configDetails?.startup_profile_step_2_complete === false) {
			setStep(2);
		}
	}, [configDetails]);

	return (
		<>
			{step !== 1 ? (
				<button className="back-btn" onClick={() => setStep((prev) => prev - 1)}>
					<ChevronDown /> <span>Go Back</span>
				</button>
			) : null}

			<div className="steps-con">
				<div id="step1" className="step active">
					<ProfileActive />
					<ProfileActive />

					<div>
						<b>Company Profile</b>
						<span>Step 01</span>
					</div>
				</div>
				<div id="step2" className={step > 1 ? 'step active' : 'step'}>
					<Company />
					<CompanyActive />

					<div>
						<b>About Company</b>
						<span>Step 02</span>
					</div>
				</div>
			</div>

			{step === 1 ? (
				<CompleteProfile formData={formData} handleChange={handleChange} setStep={setStep} handleLogout={handleLogout} />
			) : (
				<AboutCompany formData={formData} handleChange={handleChange} />
			)}
		</>
	);
};

export default AsStartup;
