import { useState } from 'react';
import { Container } from './styles';
import { INTERN_PAYMENT_PACKAGE } from '../../constants';
import { Button, Package } from '../../ui';

const AddOns = () => {
	const [addOns, setAddOns] = useState([]);

	const handleClick = (addonPackage) => {
		setAddOns((prev) => {
			if (addonPackage.is_compound) {
				return prev.find((addOn) => addOn.key === addonPackage.key)?.key ? [] : [addonPackage];
			} else {
				if (prev.find((addOn) => addOn.is_compound === true)) {
					prev = [];
				}

				return prev.find((addOn) => addOn.key === addonPackage.key)?.key
					? prev.filter((addOn) => addOn.key !== addonPackage.key)
					: [...prev, addonPackage];
			}
		});
	};
	const handleSubmit = () => {};

	return (
		<Container>
			<div className="header">
				<h4>Add-Ons</h4>
				<p>We create internship-based CVs, Cover letters and Optimise Linkedin Profiles</p>
			</div>

			<section>
				<div>
					<h4>Select Add-Ons (Optional)</h4>
					<p>We create internship-based CVs, Cover letters and Optimise Linkedin Profiles</p>

					<div className="grid">
						{INTERN_PAYMENT_PACKAGE.add_ons.map((addonPackage) => (
							<Package
								key={addonPackage.key}
								title={addonPackage.title}
								body={addonPackage.body}
								onClick={() => handleClick(addonPackage)}
								className={addOns.find((addOn) => addOn.key === addonPackage.key) ? 'active' : ''}
							/>
						))}
					</div>

					<h4>Summary</h4>
					<div className="summary">
						<span>Add-Ons</span>
						<b>
							{addOns.length
								? addOns.reduce((acc, current, index) => `${acc}${current.title}${addOns.length > 1 && index + 1 < addOns.length ? ', ' : ''}`, '')
								: 'No package selected'}
						</b>
					</div>
				</div>

				<Button disabled={addOns.length === 0} onClick={() => handleSubmit(addOns)}>
					Pay £{addOns.reduce((acc, current) => acc + current.price, 0)}
				</Button>
			</section>
		</Container>
	);
};

export default AddOns;
