export const INTERN_PAYMENT_PACKAGE = {
	commitment_package: [
		{
			key: '3 months commitment fee',
			title: '3 Months',
			body: 'Commit to your 3 months internship experience',
			price: { current: 50 },
			duration: '3 Months',
			perks: ['3 Months Internship', 'Add Supervisors', 'Log Reports'],
		},
		// {
		// 	key: 'summer internship',
		// 	title: 'SUMMER INTERNSHIP',
		// 	body: 'Commit to your  10 -12 Weeks internship experience',
		// 	price: { current: 75, previous: 124.99 },
		// 	duration: '12 Weeks',
		// 	link: 'https://app.descinder.com/signup/as-intern',
		// 	perks: ['12 Weeks Internship', 'Add Supervisors', 'Log Reports'],
		// },
		{
			key: '6 months commitment fee',
			title: '6 Months',
			body: 'Commit to your 6 months internship experience',
			price: { current: 100 },
			duration: '6 Months',
			perks: ['6 Months Internship', 'Add Supervisors', 'Log Reports'],
		},
	],
	add_ons: [
		{
			key: 'combo package',
			title: 'Combo Package',
			body: 'Cv, Cover Letter & Linkedin Optimaization',
			price: { current: 50 },
			is_compound: true,
		},
		{ key: 'cv/resume', title: 'Cv/Resume', body: 'We create internship-based Resume/Cv', price: { current: 25 }, is_compound: false },
		{
			key: 'cover letter',
			title: 'Cover Letter',
			body: 'We create internship-based cover letters.',
			price: { current: 15 },
			is_compound: false,
		},
		{
			key: 'linkedin optimization',
			title: 'LinkedIn OptZ',
			body: 'Optimize your Linkedin Profile',
			price: { current: 20 },
			is_compound: false,
		},
	],
};

export const STARTUP_PAYMENT_PACKAGE = [
	{
		has_promo: false,
		key: 'startup_quarter_pack',
		title: 'Quarter Pack',
		// price: { current: 149.99, old: 249.99 },
		price: { current: 249.99, old: 0 },
		refund_rate: '50% Refunded',
		duration: '3 Months',
		video_link: 'https://youtu.be/3WsXuIYzhZA',
		perks: ['Create Project and Task', 'Add Up to 5 Resources', '3 Months Lifecycle'],
	},
	{
		has_promo: false,
		key: 'startup_half_pack',
		title: 'Half Pack',
		// price: { current: 249.99, old: 349.99 },
		price: { current: 349.99, old: 0 },
		refund_rate: '50% Refunded',
		duration: '6 Months',
		video_link: 'https://youtu.be/-fGO7xVbCRQ',
		perks: ['Create Projects and Task', 'Add up to  8 Resources', '6 Months Lifecycle'],
	},
	{
		has_promo: false,
		key: 'startup_year_pack',
		title: 'Year Pack',
		// price: { current: 349.99, old: 499.99 },
		price: { current: 499.99, old: 0 },
		refund_rate: '50% Refunded',
		duration: '1 Year',
		video_link: ' https://youtu.be/POXx61cXBUg',
		perks: ['Create Projects and Task', 'Add up to 10 Resources', '12 Months Lifecycle'],
	},
];
