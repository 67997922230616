import styled, { css } from 'styled-components';

const Switch = styled.button.attrs({
	type: 'button',
})`
	width: 2.5rem;
	height: 1.5rem;
	background: #e7eaee;
	border-radius: 15px;
	position: relative;
	flex-shrink: 0;

	::before {
		content: '';
		position: absolute;
		width: 1rem;
		height: 1rem;
		left: 0.25rem;
		top: 50%;
		transform: translate(0, -50%);
		background: #a0abbb;
		border-radius: 50%;
		transition: all 0.1s ease-out;
	}

	${({ active }) =>
		active &&
		css`
			background: #4060ea;

			::before {
				background: #fff;
				left: 100%;
				transform: translate(calc(-100% - 0.25rem), -50%);
			}
		`}
`;

export default Switch;
