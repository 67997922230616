import { useEffect, useState } from 'react';
import { CloseModal, ProjectEmpty } from '../../../../assets/svgs';
import { queryStrings } from '../../../../constants';
import { Button, InputGroup, Loader } from '../../../../ui';
import { Container } from './style';
import { useSearchParams } from 'react-router-dom';
import { getDateTime, getPreviousSearchParams, handleApiError } from '../../../../utilities';
import { useGetConcerns, useNotify, useRespondToConcern } from '../../../../hooks';

const ConcernDetails = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [isResponseOpen, setIsResponseOpen] = useState(false);
	const [formData, setFormData] = useState({
		information: '',
	});

	const applicantId = searchParams.get(queryStrings.applicantId) || '';

	const concernId = searchParams.get(queryStrings.concernsId.startup) || searchParams.get(queryStrings.concernsId.intern);
	const isABusiness = searchParams.get(queryStrings.concernsId.startup) ? true : false;

	const notify = useNotify();
	const { mutate: respondToConcern, isLoading: isResponding } = useRespondToConcern();
	const {
		data: concerns,
		isLoading,
		isError,
		refetch,
	} = useGetConcerns({ page: 1, page_size: 200, search: '', user: isABusiness ? 'businesses' : '' });

	const concern = concerns?.results?.find((concern) => concernId === concern?.id);

	const handleChange = (event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleClose = () => {
		let previousParams = getPreviousSearchParams(searchParams);

		delete previousParams[queryStrings.concernsId.startup];
		delete previousParams[queryStrings.concernsId.intern];

		setSearchParams(previousParams, { replace: true });
	};
	const handleSubmit = (event) => {
		event.preventDefault();

		respondToConcern(
			{ id: concern?.id, data: { more_info: formData.information } },
			{
				onSuccess: () => {
					notify({
						message: `Email has been sent to the ${!isABusiness ? 'Business' : 'Intern'}`,
						status: 'success',
						toastOptions: { toastId: 'respond_to_concerns_success' },
					});
					setIsResponseOpen(false);
					handleChange(null, 'information', '');
					refetch();
				},
				onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'respond_to_concerns_error' } }),
			}
		);
	};

	useEffect(() => {
		if (applicantId) return;

		let previousParams = getPreviousSearchParams(searchParams);
		delete previousParams[queryStrings.applicantId];

		setSearchParams(previousParams, { replace: true });
	}, [applicantId, searchParams, setSearchParams]);

	return (
		<>
			<Container>
				{isLoading ? (
					<center>
						<CloseModal tabIndex={0} onClick={handleClose} />

						<div>
							<Loader />
						</div>
					</center>
				) : isError ? (
					<center>
						<CloseModal tabIndex={0} onClick={handleClose} />

						<div>
							<div>
								<ProjectEmpty />
								<p>Unable to fetch details at the moment</p>
							</div>
						</div>
					</center>
				) : (
					<div>
						<div className="header">
							<CloseModal tabIndex={0} onClick={handleClose} />
							<h2>{isABusiness ? 'Business' : 'Intern'} Complaints Report</h2>
							<hr />
						</div>

						<article>
							<b>Raised By ({isABusiness ? 'Startup' : 'Intern'})</b>
							<p>{concern?.raised_by_info?.name}</p>
						</article>

						<article className="flexed">
							<div>
								<b>Email</b>
								<p>{concern?.raised_by_info?.email}</p>
							</div>

							<div>
								<b>Phone number</b>
								<p>{concern?.raised_by_info?.phone}</p>
							</div>
						</article>

						{!isABusiness ? (
							<article className="internship">
								<div>
									<b>Applicant Type</b>
									<p>{concern?.raised_by_info?.applicant_type}</p>
								</div>
								<div>
									<b>Internship Duration</b>
									<p>{getDateTime(concern?.raised_by_info?.start_date, 'mm dd yyyy')}</p>
								</div>
								<div>
									<b>Internship Duration</b>
									<p>{concern?.raised_by_info?.program_duration}</p>
								</div>
							</article>
						) : null}

						<article className="flexed">
							<div>
								<b>Concern Type</b>
								<p>{concern?.concern_type}</p>
							</div>

							<div>
								<b>Date & Time</b>
								<p>{`${getDateTime(concern?.created_at, 'dd/mm/yyyy')}, ${getDateTime(concern?.created_at, null, false)}` || 'N/A'}</p>
							</div>
						</article>

						<article>
							<b>More Information</b>
							<p>{concern?.description}</p>
						</article>

						{concern?.raised_against ? (
							<>
								<article>
									<b>Raised Against ({!isABusiness ? 'Startup' : 'Intern'})</b>
									<p>{concern?.raised_against_info?.name}</p>
								</article>

								<article className="flexed">
									<div>
										<b>Email</b>
										<p>{concern?.raised_against_info?.email}</p>
									</div>

									<div>
										<b>Phone number</b>
										<p>{concern?.raised_against_info?.phone}</p>
									</div>
								</article>

								{isABusiness ? (
									<article className="internship">
										<div>
											<b>Applicant Type</b>
											<p>{concern?.raised_against_info?.applicant_type}</p>
										</div>
										<div>
											<b>Internship Duration</b>
											<p>{getDateTime(concern?.raised_against_info?.start_date, 'mm dd yyyy')}</p>
										</div>
										<div>
											<b>Internship Duration</b>
											<p>{concern?.raised_against_info?.program_duration}</p>
										</div>
									</article>
								) : null}
							</>
						) : null}

						<Button disabled={concern?.is_message_sent} loading={isResponding} onClick={() => setIsResponseOpen(true)}>
							Send email to {!isABusiness ? 'Business' : 'Intern'}
						</Button>
					</div>
				)}
			</Container>

			{isResponseOpen ? (
				<Container>
					<form onSubmit={handleSubmit}>
						<div className="header">
							<CloseModal tabIndex={0} onClick={() => setIsResponseOpen(false)} />
							<h2>Email Response</h2>
							<hr />
							<br />

							<InputGroup
								label={`Additional message to the ${isABusiness ? 'Business' : 'Intern'}`}
								name="information"
								onChange={handleChange}
								placeholder="Do you have any additional message to be sent?"
								value={formData.information}
								variant="textarea"
								required
							/>
						</div>

						<Button disabled={concern?.is_message_sent} loading={isResponding}>
							Send email to {!isABusiness ? 'Business' : 'Intern'}
						</Button>
					</form>
				</Container>
			) : null}
		</>
	);
};
export default ConcernDetails;
