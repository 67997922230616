import { NavLink } from 'react-router-dom';
import { Approutes } from '../../../../constants';
import { useMessage } from '../../../../hooks';

const StartupLinks = ({ closeMobileNav }) => {
	const { numberOfUnreadMessages } = useMessage();

	return (
		<>
			<li>
				<NavLink to={Approutes.startup.projects.index} onClick={closeMobileNav}>
					Projects
				</NavLink>
			</li>
			<li>
				<NavLink to={Approutes.startup.early_starters} onClick={closeMobileNav}>
					Early Starters
				</NavLink>
			</li>
			<li>
				<NavLink to={Approutes.startup.extra_picks} onClick={closeMobileNav}>
					Extra Picks
				</NavLink>
			</li>
			{/* <li>
								<NavLink to={Approutes.messages} onClick={closeMobileNav}>
									Messages
								</NavLink>
							</li> */}
			<li>
				<NavLink to={Approutes.messages.index} onClick={closeMobileNav} className={numberOfUnreadMessages > 0 ? 'unread' : ''}>
					Messages
				</NavLink>
			</li>
			<li>
				<NavLink to={Approutes.profile} onClick={closeMobileNav}>
					Profile
				</NavLink>
			</li>
			<li>
				<NavLink to={Approutes.settings.index} onClick={closeMobileNav}>
					Settings
				</NavLink>
			</li>
		</>
	);
};
export default StartupLinks;
