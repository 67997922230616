import { useState, useEffect, useCallback } from 'react';
import { ChevronDown, Company, CompanyActive, ProfileActive } from '../../../assets/svgs';
import InstitutionProfile from './InstitutionProfile';
import SelectPlan from './SelectPlan';
import { useDecodedUser, useGetUserById, useUser } from '../../../hooks';
import { GeneralChangeEventType } from '../../../types';

export type FormDataType = {
	institution_name: string;
	phone_number: string;
	institution_email: string;
	address: string;
	country: string;
	plan: string;
	promo_code: string;
	avatar: {
		name: string;
		file: null;
	};
};

const AsInstitution = () => {
	const user = useDecodedUser();
	const { data: userDetails } = useUser();
	const { data: configDetails } = useGetUserById({ id: user?.user_id });

	const [step, setStep] = useState(1);
	const [formData, setFormData] = useState<FormDataType>({
		institution_name: '',
		phone_number: '',
		institution_email: '',
		address: '',
		country: '',
		plan: '',
		promo_code: '',
		avatar: {
			name: '',
			file: null,
		},
	});

	const handleChange: GeneralChangeEventType = useCallback((event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({
			...prev,
			[name as 'institution_name' | 'phone_number' | 'institution_email' | 'address' | 'country' | 'plan' | 'avatar']: value,
		}));
	}, []);

	useEffect(() => {
		const currentStep: HTMLDivElement | null = document.querySelector(`#step${step}`);

		currentStep?.parentElement?.scroll(currentStep.offsetLeft, 0);
	}, [step]);

	useEffect(() => {
		if (!userDetails) return;

		setFormData((prev) => ({
			...prev,
			institution_name: userDetails?.name || '',
			phone_number: userDetails?.mobile_number || '',
			institution_email: userDetails?.institution_email || '',
			avatar: { name: userDetails?.avatar ? userDetails?.avatar?.split('/')?.pop() || 'Avatar' : '', file: null },
			address: userDetails?.address || '',
			country: userDetails?.country || '',
		}));
	}, [userDetails]);

	useEffect(() => {
		if (configDetails?.institution_profile_step_1_complete === false) {
			setStep(1);
		} else if (configDetails?.institution_profile_step_2_complete === false) {
			setStep(2);
		}
	}, [configDetails]);

	return (
		<>
			{step !== 1 ? (
				<button className="back-btn" onClick={() => setStep((prev) => prev - 1)}>
					<ChevronDown /> <span>Go Back</span>
				</button>
			) : null}

			<div className="steps-con">
				<div id="step1" className="step active">
					<ProfileActive />
					<ProfileActive />

					<div>
						<b>Institution Profile</b>
						<span>Step 01</span>
					</div>
				</div>
				<div id="step2" className={step > 1 ? 'step active' : 'step'}>
					<Company />
					<CompanyActive />

					<div>
						<b>Choose Plan</b>
						<span>Step 02</span>
					</div>
				</div>
			</div>

			{step === 1 ? (
				<InstitutionProfile formData={formData} handleChange={handleChange} setStep={setStep} />
			) : (
				<SelectPlan formData={formData} handleChange={handleChange} />
			)}
		</>
	);
};

export default AsInstitution;
