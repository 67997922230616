import { useEffect, useRef, useState } from 'react';
import { BillingCheckMark, BillingInvoice, ProjectEmpty } from '../../../../assets/svgs';
import { useDecodedUser, useGetCurrentSubscription, useGetPayment, useNotify } from '../../../../hooks';
import { Loader, Pagination } from '../../../../ui';
import { Container } from './styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getDateDifference, getDateTime, getPreviousSearchParams, isIntern, isStartUp } from '../../../../utilities';
import { Approutes, INTERN_PAYMENT_PACKAGE, STARTUP_PAYMENT_PACKAGE, queryStrings } from '../../../../constants';

const Billing = () => {
	const notify = useNotify();
	const navigate = useNavigate();
	const user = useDecodedUser();
	const pageSize = useRef(5);
	const [page, setPage] = useState(1);
	const [searchParams, setSearchParams] = useSearchParams();

	const { data: subscription, isLoading: isSubscriptionLoading, isError: isSubscriptionError } = useGetCurrentSubscription();
	const {
		data: payments,
		isLoading: isPaymentLoading,
		isError: isPaymentError,
	} = useGetPayment({ page, page_size: pageSize.current, search: '' });

	const DAYS_REMAINING = getDateDifference(new Date(), subscription?.end_date) || 0;

	const SELECTED_PACKAGE = isStartUp(user)
		? STARTUP_PAYMENT_PACKAGE.find((startupPackage) => startupPackage.key === subscription?.packages?.[0])
		: INTERN_PAYMENT_PACKAGE.commitment_package.find((internPackage) => internPackage.key === subscription?.packages?.[0]);

	const handleOpenSubscription = () => {
		if (isStartUp(user) && user?.startup_profile_status !== 'APPROVED') {
			notify({
				message: 'Please wait until your profile has been verified before making payment',
				toastOptions: { toastId: 'profile_verification_needed' },
			});

			return;
		} else if (isIntern(user) && user?.intern_profile_status !== 'APPROVED') {
			notify({
				message: 'Please wait until your profile has been verified before making payment',
				toastOptions: { toastId: 'profile_verification_needed' },
			});

			return;
		}

		let previousParams = getPreviousSearchParams(searchParams);

		previousParams = { ...previousParams, [queryStrings.subscription_plan]: 'open' };
		setSearchParams(previousParams, { replace: true });
	};

	useEffect(() => {
		if (!isStartUp(user)) {
			navigate(Approutes.settings.index, { replace: true });
		}
	}, [user, navigate]);

	return (
		<>
			<Container>
				{isPaymentLoading || isSubscriptionLoading ? (
					<center>
						<Loader />
					</center>
				) : (
					<>
						{isSubscriptionError ? (
							<div className="empty-state smaller sub">
								<ProjectEmpty />
								<p>Unable to fetch your subscription at the moment</p>
							</div>
						) : !subscription?.id ? (
							<div className="empty-state sub">
								<ProjectEmpty />
								<p>
									You aren't subscribed to any plan. <button onClick={handleOpenSubscription}>Click here</button> to view available plans
								</p>
							</div>
						) : (
							<section className="package-payment-con">
								<div className="package">
									<div className="header">
										<h4>{SELECTED_PACKAGE?.title?.toUpperCase()} </h4>
										{DAYS_REMAINING <= 30 ? (
											// <span>{DAYS_REMAINING === 0 ? 'Expired' : `${DAYS_REMAINING} day${DAYS_REMAINING !== 1 ? 's' : ''} remaining`}</span>
											<span>{`${DAYS_REMAINING} day${DAYS_REMAINING !== 1 ? 's' : ''} remaining`}</span>
										) : null}
									</div>
									<hr />

									<ul>
										{/* {STARTUP_PAYMENT_PACKAGE.find(startupPackage => startupPackage.key === payments?.results?.[0]?.packages?.[0])?.perks.map((perk) => ( */}
										{/* {INTERN_PAYMENT_PACKAGE.commitment_package
									.find((internPackage) => internPackage.key === payments?.results?.[0]?.packages?.[0])
									?.perks.map((perk) => (
										<li key={perk}>{perk}</li>
									))} */}
										{SELECTED_PACKAGE?.perks?.map((perk) => (
											<li key={perk}>{perk}</li>
										))}
									</ul>

									{/* <Button>Upgrade</Button> */}
								</div>

								<div className="payment">
									<h4>Current Subscription</h4>
									<hr />

									<div>
										<span>Payment date</span>
										<b>{getDateTime(subscription?.subscribed_at, 'mm dd yyyy')}</b>
									</div>
									<div>
										<span>Type of plan</span>
										<b>{SELECTED_PACKAGE?.title}</b>
									</div>
									<div>
										<span>Plan Start</span>
										<b>{getDateTime(subscription?.start_date, 'mm dd yyyy')}</b>
									</div>
									<div>
										<span>Plan Ends </span>
										<b>{getDateTime(subscription?.end_date, 'mm dd yyyy')}</b>
									</div>
									<div>
										<span>Total payment</span>
										<b>£{subscription?.amount}</b>
									</div>
								</div>
							</section>
						)}
						{isPaymentError ? (
							<div className="empty-state smaller">
								<ProjectEmpty />
								<p>Unable to fetch your payment history at the moment</p>
							</div>
						) : !payments?.results || payments?.results?.length === 0 ? null : (
							<>
								<h2>Billing History</h2>
								<div className="table-container">
									<table>
										<thead>
											<tr>
												<th>Invoice</th>
												<th>Billing Name</th>
												<th>Payment Date</th>
												<th>Amount</th>
												<th>Status</th>
											</tr>
										</thead>
										<tbody>
											{payments?.results?.map((payment, index) => (
												<tr key={payment?.id}>
													<td>
														<div className="invoice">
															<BillingInvoice />
															Invoice #00{index + page} - {getDateTime(payment?.paid_at, 'mm yyyy')}
														</div>
													</td>
													<td>
														{isStartUp(user)
															? STARTUP_PAYMENT_PACKAGE.find((startupPackage) => startupPackage.key === payment?.packages?.[0])?.title
															: payment?.packages?.join(', ')}
													</td>
													<td>{getDateTime(payment?.paid_at, 'mm dd yyyy')}</td>
													<td>£{payment?.amount}</td>
													<td>
														<div className={`status ${payment?.status}`}>
															<BillingCheckMark />
															{payment?.status}
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>

								<Pagination page={page} setPage={setPage} maxPages={Math.ceil(payments?.total / pageSize.current)} disabled={isPaymentLoading} />
							</>
						)}
					</>
				)}
			</Container>
		</>
	);
};
export default Billing;
