import { Container } from './styles';
import { useState, useEffect } from 'react';
import { Company, CompanyActive, Resource, ResourceActive, ProfileActive, ChevronDown } from '../../assets/svgs';
import ProjectDetails from './ProjectDetails';
import AddTasks from './AddTasks';
import AddResource from './AddResource';
import { Link } from 'react-router-dom';
import { Approutes, filterApplicants } from '../../constants';
import { useCallback } from 'react';

const ProjectCreate = () => {
	const [step, setStep] = useState(1);
	const [formData, setFormData] = useState({
		project_id: '',
		project_name: '',
		project_start_date: '',
		sector: '',
		timeline: '',
		resource_experience_level: '',
		required_document: {
			name: '',
			file: null,
		},
		description: '',
		project_duration: '',
		skill_tag: [],
		task_name: '',
		task_description: '',
		tasks: [],
		search: '',
		filter: (() => {
			let state = {};
			filterApplicants.forEach((option) => (state[option?.name] = { value: '', queryKey: option?.queryKey }));

			return state;
		})(),
		selected_applicants: [],
	});

	const handleChange = useCallback((event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	}, []);

	useEffect(() => {
		const currentStep = document.querySelector(`#step${step}`);

		currentStep.parentElement.scroll(currentStep.offsetLeft, 0);
	}, [step]);

	return (
		<Container>
			{step === 3 ? (
				<Link to={Approutes.startup.projects.index}>
					<ChevronDown /> Back to Projects
				</Link>
			) : null}

			<section>
				<div className="steps-con">
					<div id="step1" className="step active">
						<ProfileActive />
						<ProfileActive />

						<div>
							<b>Project Details</b>
							<span>Step 01</span>
						</div>
					</div>
					<div id="step2" className={step > 1 ? 'step active' : 'step'}>
						<Company />
						<CompanyActive />

						<div>
							<b>Add Task</b>
							<span>Step 02</span>
						</div>
					</div>
					<div id="step3" className={step > 2 ? 'step active' : 'step'}>
						<Resource />
						<ResourceActive />

						<div>
							<b>Add Resource(s)</b>
							<span>Step 03</span>
						</div>
					</div>
				</div>

				{step === 1 ? (
					<ProjectDetails formData={formData} handleChange={handleChange} setStep={setStep} />
				) : step === 2 ? (
					<AddTasks formData={formData} setFormData={setFormData} handleChange={handleChange} setStep={setStep} />
				) : (
					<AddResource formData={formData} handleChange={handleChange} />
				)}
			</section>
		</Container>
	);
};

export default ProjectCreate;
