import { Link } from 'react-router-dom';
import { EyeOpen } from '../../../../../assets/svgs';
import { ActiveInternsType } from '../../../../../types';
import { getDateTime } from '../../../../../utilities';
import { Container } from './styles';
import { Approutes } from '../../../../../constants';

const ActiveInterns = ({ page, interns }: { page: number; interns: ActiveInternsType[] | undefined }) => {
	return (
		<Container>
			<div className="table-container">
				<table>
					<thead>
						<tr>
							<th>S/N</th>
							<th>Name</th>
							<th>Email</th>
							<th>Department</th>
							<th>Approval Status</th>
							<th>Start Date</th>
							<th>Internship Status</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{interns?.map((intern, index) => (
							<tr key={intern?.id}>
								<td>{(page - 1) * 10 + index + 1}</td>
								<td>{intern?.name || 'N/A'}</td>
								<td>{intern?.email}</td>
								<td>{intern?.department_name || 'N/A'}</td>
								<td>{intern?.intern_profile_status?.toLowerCase()}</td>
								<td>{getDateTime(intern?.start_date, 'mm dd yyyy') || 'N/A'}</td>
								<td>{intern?.is_in_project ? 'In a project' : 'Not in a project'}</td>
								<td>
									<Link to={Approutes.institution_admin.use_intern(intern?.id)}>
										<EyeOpen />
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{interns?.map((intern) => (
				<div key={intern?.id} className="mobile-card">
					<div className="flexed">
						<div>
							<b>Name</b>
							<p>{intern?.name || 'N/A'}</p>
						</div>
						<div>
							<Link to={Approutes.institution_admin.use_intern(intern?.id)}>
								<EyeOpen />
							</Link>
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>Email</b>
							<p>{intern?.email}</p>
						</div>
						<div>
							<b>Department</b>
							<p>{intern?.department_name || 'N/A'}</p>
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>Approval Status</b>
							<p>{intern?.intern_profile_status?.toLowerCase()}</p>
						</div>
						<div>
							<b>Start Date</b>
							<p>{getDateTime(intern?.start_date, 'mm dd yyyy') || 'N/A'}</p>
						</div>
					</div>
					<div>
						<b>Internship Status</b>
						<p>{intern?.is_in_project ? 'In a project' : 'Not in a project'}</p>
					</div>
				</div>
			))}
		</Container>
	);
};
export default ActiveInterns;
