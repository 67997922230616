import styled from 'styled-components';

export const StyledApplicantCard = styled.div`
	border-radius: 0.25rem;
	background: #fff;
	border: 1px solid rgba(18, 18, 18, 0.03);
	overflow: hidden;
	flex: 1;
	display: flex;
	flex-direction: column;

	&.active {
		box-shadow: 0 20px 23px rgba(18, 18, 18, 0.15);

		.bottom {
			border-color: #fee9e9;

			button {
				:first-child {
					display: none;
				}
				:last-child {
					display: block;
				}
			}
		}
	}

	.extra-badge {
		font-size: 0.625rem;
		font-weight: 700;
		line-height: 1;
		margin-left: auto;
		color: #21b787;
		background: #dcf9f0;
		border-radius: 0px 0.25rem 0px 0px;
		display: flex;
		align-items: center;
		padding: 0.375rem 0.75rem;
		gap: 0.125rem;
	}
	.heading {
		margin: 1.5rem 1rem 0.75rem;
		border-bottom: 1px solid #f4f4f4;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 1rem;
		gap: 0.5rem;

		&.rm-mt {
			margin-top: 0;
		}

		img {
			width: 2.5rem;
			height: 2.5rem;
			margin-right: 0.63rem;
		}
		b {
			color: #2f2f2f;
			font-size: 1rem;
			font-weight: 700;
			line-height: 132%;
		}
		svg {
			width: 1.25rem;
			height: 1.25rem;
			cursor: pointer;
		}
	}
	.duration-con {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;
		border-bottom: 1px solid #f4f4f4;
		padding-bottom: 0.75rem;
		margin: 0 1rem 1rem;
		font-size: 0.75rem;
		line-height: 1.2;

		span:first-child {
			display: flex;
			align-items: center;
			gap: 0.25rem;
		}
	}
	h6 {
		color: #b7bbc9;
		font-family: 'Urbanist', sans-serif;
		font-size: 0.625rem;
		font-weight: 700;
		line-height: 132%;
		letter-spacing: 0.00938rem;
		margin-left: 1rem;
	}
	.role-list {
		display: flex;
		flex-wrap: wrap;
		gap: 0.35rem;
		margin-top: 1rem;
		padding: 0 1rem;

		span {
			border-radius: 0.125rem;
			background: #f4f5f9;
			color: #2f2f2f;
			font-size: 0.625rem;
			font-weight: 500;
			line-height: 132%;
			padding: 0.5rem 0.63rem;
			text-transform: capitalize;
		}
	}
	.bottom {
		display: flex;
		justify-content: flex-end;
		border-bottom: 6px solid #e5faf4;
		margin-top: auto;

		button {
			border-radius: 0.25rem;
			background: #e5faf4;
			padding: 0.68rem 0.87rem 0.305rem;
			margin-top: 0.5rem;

			:last-child {
				display: none;
				background-color: #fee9e9;
			}

			svg {
				width: 0.825rem;
				height: 0.825rem;
			}
		}
	}
`;
