import { Container, Pallet } from './styles';

const PalletList = ({ loading, data }) => {
	return (
		<Container>
			{loading
				? [1, 2, 3, 4].map((num) => (
						<Pallet key={num} className="loading">
							<div className="img"></div>
							<div>
								<h6>{}</h6>
								<p></p>
							</div>
						</Pallet>
				  ))
				: data?.map((item) => (
						<Pallet key={item.title}>
							<div className="img">{item?.icon}</div>
							<div>
								<h6>{item.body}</h6>
								<p>{item.title}</p>
							</div>
						</Pallet>
				  ))}
		</Container>
	);
};
export default PalletList;
