import { ChevronDown, CloseModal } from '../../../assets/svgs';
import { Button } from '../../../ui';
import { Container } from './styles';

const StartupPaymentExplained = ({ handleClose, handleBack, handleSubmit }) => {
	return (
		<Container>
			<div>
				<div className="header">
					<CloseModal tabIndex={0} className="close-icon" onClick={handleClose} />

					<h2>Start-Up/SMEs Billing and Assignment Cycle</h2>
					<p>Understanding the payment to project completion, Navigating the Start-up/SMEs experience</p>
					<hr />
				</div>

				<button className="back-btn" onClick={handleBack}>
					<ChevronDown /> Go Back
				</button>

				<div className="body">
					<p>
						Upon payment, startups immediately activate all pending projects and gain flexibility in managing them, including adding interns to
						upcoming projects within 14 days and marking projects as complete. Projects scheduled to start before the payment date are rescheduled
						to start on the payment date, ensuring alignment between project timelines and billing, thus optimizing project management efficiency.
					</p>
					<br />

					<b>When a startup pays:</b>
					<ol>
						<li>All pending projects are made active regardless of timeline and subscription package</li>
						<li>They can now add interns to projects starting within 14 days of the current date</li>
						<li>Projects can be marked as complete</li>
						<li>All pending projects start date whose date is before the date of payment are updated to the date of payment</li>
						<li>Billing starts in 30 days if no projects starts within 30 days else the nearest project start date is chosen</li>
					</ol>
					<br />

					<b>When a startup creates a project:</b>
					<ol>
						<li>If the projects start date is closer than the billing date, the billing date is changed to the project start date</li>
					</ol>
					<br />

					<b>When payment expires:</b>
					<ol>
						<li>All active projects are archived</li>
						<li>All interns leave the project</li>
					</ol>
				</div>

				<Button onClick={handleSubmit}>I Understand, Continue</Button>
			</div>
		</Container>
	);
};
export default StartupPaymentExplained;
