import { useMutation, useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';

export const useInternRaiseConcern = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({
		mutationFn: ({ concern_type, description, raised_against }) =>
			axiosPrivate.post(Appurls.user.raise_concern_as_intern, {
				concern_type,
				description,
				raised_against,
			}),
	});
};

export const useStartupRaiseConcern = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({
		mutationFn: ({ concern_type, description, raised_against }) =>
			axiosPrivate.post(Appurls.user.flag_intern_as_startup, {
				concern_type,
				description,
				raised_against,
			}),
	});
};

export const useGetConcerns = ({ page = 1, page_size = 5, search, user = 'businesses' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(
					`${
						user === 'businesses' ? Appurls.dashboard.concerns.startup : Appurls.dashboard.concerns.intern
					}?page=${page}&page_size=${page_size}&search=${search}`
				)
				.then((response) => response.data),
		queryKey: ['dashboard-concerns', { page, page_size, search, user }],
	});
};

export const useRespondToConcern = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation(({ id, data }) => axiosPrivate.post(Appurls.dashboard.concerns.respond_to_concern(id), data));
};
