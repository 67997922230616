import { useNavigate } from 'react-router-dom';
import { ChevronDown } from '../../../assets/svgs';
import { Container } from './styles';

const PageNotFound = () => {
	const navigate = useNavigate();

	return (
		<Container>
			<button onClick={() => navigate(-1)}>
				<ChevronDown />
				Go Back
			</button>
		</Container>
	);
};

export default PageNotFound;
