import { Loader, Pagination } from '../../ui';
import { EyeOpen, ProjectEmpty, TaskLog } from '../../assets/svgs';
import { useState } from 'react';
import { Container } from './styles';
import { useGetApplicantTask, useGetProject } from '../../hooks';
import { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getDateTime } from '../../utilities';
import { Approutes } from '../../constants';
import { LogTimesheet } from '../../components';

const MyTasks = () => {
	const pageSize = useRef(5);
	const { projectId } = useParams();
	const [page, setPage] = useState(1);
	const [isCompleted, setIsCompleted] = useState(null);
	const [logState, setLogState] = useState({
		submitLog: false,
		taskId: '',
		projectId: '',
	});

	const { data: project } = useGetProject({ id: projectId });
	const { data: tasks, isLoading, isError } = useGetApplicantTask({ projectId, page, page_size: 5, is_completed: isCompleted });

	const handleNewLog = (taskId, projectId) => {
		setLogState({
			submitLog: true,
			taskId,
			projectId,
		});
	};

	return (
		<Container>
			<div className="header">
				<h4>My Tasks {project?.name ? `- ${project?.name}` : ''}</h4>
				<p>View and get more information about your task </p>
			</div>
			<div className="controls">
				<button
					className={isCompleted === null ? 'active' : ''}
					onClick={() => {
						setPage(1);
						setIsCompleted(null);
					}}
				>
					All
				</button>
				<button
					className={isCompleted === false ? 'active' : ''}
					onClick={() => {
						setPage(1);
						setIsCompleted(false);
					}}
				>
					Active
				</button>
				<button
					className={isCompleted === true ? 'active' : ''}
					onClick={() => {
						setPage(1);
						setIsCompleted(true);
					}}
				>
					Completed
				</button>
			</div>

			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>Unable to fetch available tasks at the moment</p>
				</div>
			) : !tasks?.results || tasks?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p>
						{isCompleted === true
							? `You don't have any completed task ${projectId ? 'on this project' : ''}`
							: isCompleted === false
							? `You don't have any active task ${projectId ? 'on this project' : ''}`
							: `You haven't been assigned any task ${projectId ? 'on this project' : ''}`}
					</p>
				</div>
			) : (
				<>
					<div className="table-container">
						<table>
							<thead>
								<tr>
									<th>S/N</th>
									<th>Task</th>
									{!project?.name ? <th>Project</th> : null}
									<th>Start Date</th>
									<th>End Date</th>
									<th>Task Status</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{tasks?.results?.map((task, index) => (
									<tr key={task?.id}>
										<td>{index + 1}</td>
										<td>{task?.name}</td>
										{!project?.name ? <td>{task?.project_name}</td> : null}
										<td>{task?.start_date ? getDateTime(task?.start_date, 'mm dd yyyy') : 'Not set'}</td>
										<td>{task?.end_date ? getDateTime(task?.end_date, 'mm dd yyyy') : 'Not set'}</td>
										<td className={task?.is_completed ? 'completed' : 'active'}>{task?.is_completed ? 'Completed' : 'Active'}</td>
										<td>
											<Link to={Approutes.intern.tasks.use_task_details(projectId ? projectId : task?.project_id, task?.id)}>
												<EyeOpen />
											</Link>
											<TaskLog onClick={() => handleNewLog(task?.id, task?.project_id)} />
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					{tasks?.results?.map((task, index) => (
						<div className="mobile-card" key={task?.id}>
							<div key={task?.id}>
								<div>
									<b>Task Name</b>
									<p>{task?.name}</p>
								</div>
								<div className="action-con">
									<Link to={Approutes.intern.tasks.use_task_details(projectId ? projectId : task?.project_id, task?.id)}>
										<EyeOpen />
									</Link>
									<TaskLog onClick={() => handleNewLog(task?.id, task?.project_id)} />
								</div>
							</div>
							<div>
								<div>
									<b>{task?.start_date ? getDateTime(task?.start_date, 'mm dd yyyy') : 'Not set'}</b>
									<p>Start Date</p>
								</div>
								<div>
									<b>{task?.end_date ? getDateTime(task?.end_date, 'mm dd yyyy') : 'Not set'}</b>
									<p>End Date</p>
								</div>
							</div>
							<div>
								<div>
									<b className={task?.is_completed ? 'completed' : 'active'}>{task?.is_completed ? 'Completed' : 'Active'}</b>
									<p>Task Status</p>
								</div>
								{!project?.name ? (
									<div>
										<b>{task?.project_name}</b>
										<p>Project Name</p>
									</div>
								) : null}
							</div>
						</div>
					))}

					<Pagination page={page} setPage={setPage} maxPages={Math.ceil(tasks?.total / pageSize.current)} disabled={isLoading} />
				</>
			)}

			{logState.submitLog ? (
				<LogTimesheet taskId={logState.taskId} projectId={logState.projectId} handleClose={() => setLogState({ submitLog: false })} />
			) : null}
		</Container>
	);
};

export default MyTasks;
