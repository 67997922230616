import { FormEvent, useEffect, useRef, useState } from 'react';
import { Button, InputGroup, SelectGroup } from '../../../ui';
import { GeneralChangeEventType } from '../../../types';
import { FormDataType } from '.';
import { CardInformation } from '../../../components';
import { useNavigate } from 'react-router-dom';
import { Approutes } from '../../../constants';
import { useValidateCouponCode } from '../../../hooks';
import { PlanContainer } from './styles';
import { useQueryClient } from '@tanstack/react-query';

const SelectPlan = ({ formData, handleChange }: { formData: FormDataType; handleChange: GeneralChangeEventType }) => {
	const queryClient = useQueryClient();

	const [showModal, setShowModal] = useState(false);
	const [showCode, setShowCode] = useState(false);
	const [tempCode, setTempCode] = useState('');

	const {
		data: codeResponse,
		isLoading: isCodeValidating,
		isError: isCodeInvalid,
	} = useValidateCouponCode({ code: formData.promo_code, plan_package: formData.plan || '' });

	const codeMessage =
		codeResponse?.type === 'percent_reduction'
			? `${codeResponse?.reduced_percent}% Price Reduction`
			: codeResponse?.type === 'price_reduction'
			? `£${codeResponse?.reduced_price} Price Reduction`
			: '';

	const navigate = useNavigate();
	const promoConRef = useRef<HTMLDivElement>(null);

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();

		setShowModal(true);
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			handleChange(null, 'promo_code', tempCode);
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [tempCode, handleChange]);

	return (
		<PlanContainer>
			<form onSubmit={handleSubmit} className="institution-plan">
				<h2>Select Plan</h2>
				<p>Select your subscription plan</p>

				<div className="select-con">
					<SelectGroup
						label="Choose Plan"
						name="plan"
						onChange={handleChange}
						placeholder="Select Plan"
						value={formData.plan}
						options={[
							'0 - 100 Interns (£5,000)',
							'101 - 200 Interns (£8,000)',
							'201- 300 Interns (£15,000)',
							'301- 400 Interns (£25,000)',
							'401- 900 Interns (£39,000)',
						]}
						required
						dropdownPosBottom={false}
					/>
				</div>

				<div className="promo-con" ref={promoConRef}>
					<div>
						<button type="button" onClick={() => setShowCode(true)}>
							Click here to use promo code
						</button>

						{showCode || formData.promo_code ? (
							<>
								<InputGroup
									name="promo_code"
									value={tempCode}
									onChange={(event) => setTempCode(event?.target?.value || '')}
									placeholder="Enter your promo code"
									disabled={formData.plan ? false : true}
								/>
								{!formData.plan ? (
									<p className="error">Select a Plan to use Promo Code</p>
								) : isCodeInvalid ? (
									<p className="error">Invalid/Expired Promo Code</p>
								) : codeMessage ? (
									<p>{codeMessage}</p>
								) : null}
							</>
						) : null}
					</div>
				</div>

				<div className="btn-group">
					<Button disabled={isCodeValidating}>Proceed & Pay </Button>
				</div>
			</form>

			{showModal ? (
				<CardInformation
					formData={formData}
					handleClose={() => setShowModal(false)}
					handleSubmit={() => {
						queryClient.invalidateQueries({ queryKey: ['user'] });
						queryClient.invalidateQueries({ queryKey: ['user-id'] });

						setShowModal(false);
						navigate(Approutes.home);
					}}
				/>
			) : null}
		</PlanContainer>
	);
};

export default SelectPlan;
