import { useCallback, useRef, useState } from 'react';
import { Container } from './styles';
import { Loader, Pagination } from '../../../../ui';
import { useGetConcerns } from '../../../../hooks';
import { useSearchParams } from 'react-router-dom';
import { getPreviousSearchParams, getDateTime } from '../../../../utilities';
import { queryStrings } from '../../../../constants';
import { EyeOpen, ProjectEmpty } from '../../../../assets/svgs';

const Concerns = () => {
	const pageSize = useRef(10);
	const [page, setPage] = useState(1);
	const [searchParams, setSearchParams] = useSearchParams();

	const [control, setControl] = useState('businesses');
	const [, setFormData] = useState({
		selected_applicants: [],
		search: '',
		// filter_stats: (() => {
		// 	let state = {};
		// 	concernFilters.forEach((option) => (state[option?.name] = { value: '', queryKey: option?.queryKey }));

		// 	return state;
		// })(),
		// filter: (() => {
		// 	let state = {};
		// 	(control === 'businesses' ? businessesFilters : internsFilters).forEach(
		// 		(option) => (state[option?.name] = { value: '', queryKey: option?.queryKey })
		// 	);

		// 	return state;
		// })(),
	});

	const { data: concerns, isLoading, isError } = useGetConcerns({ page, page_size: pageSize.current, search: '', user: control });

	const handleItemClick = (id) => {
		let previousParams = getPreviousSearchParams(searchParams);

		delete previousParams[queryStrings.concernsId.startup];
		delete previousParams[queryStrings.concernsId.intern];
		previousParams = { ...previousParams, [control === 'businesses' ? queryStrings.concernsId.startup : queryStrings.concernsId.intern]: id };
		setSearchParams(previousParams, { replace: true });
	};

	const handleChange = useCallback((event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	}, []);

	const handleControl = (value) => {
		setPage(1);
		setControl(value);

		handleChange(null, 'search', '');
		// handleChange(
		// 	null,
		// 	'filter',
		// 	(() => {
		// 		let state = {};
		// 		(value === 'businesses' ? businessesFilters : internsFilters).forEach(
		// 			(option) => (state[option?.name] = { value: '', queryKey: option?.queryKey })
		// 		);

		// 		return state;
		// 	})()
		// );
	};

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Concerns</h4>
					{/* <p>Update your photo and personal details</p> */}
				</div>
			</div>

			<div className="table-header">
				<div className="controls">
					<button className={control === 'businesses' ? 'active' : ''} onClick={() => handleControl('businesses')}>
						Businesses
					</button>
					<button className={control === 'interns' ? 'active' : ''} onClick={() => handleControl('interns')}>
						Interns
					</button>
				</div>
			</div>

			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<center>
					<div>
						<ProjectEmpty />
						<p className="error-message">Unable to fetch concerns at the moment</p>
					</div>
				</center>
			) : !concerns?.results || concerns?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p className="error-message">No concerns to display</p>
				</div>
			) : (
				<>
					<div className="table-container">
						<table>
							<thead>
								<tr>
									<th>S/N</th>
									<th>{control === 'businesses' ? "Business's" : "Intern's"} Name</th>
									<th>Concern Type</th>
									<th>More Information</th>
									<th>Date & Time</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{concerns?.results?.map((concern, index) => (
									<tr key={concern?.id}>
										<td>{index + 1}</td>
										<td>{concern?.raised_by_info?.name}</td>
										<td>{concern?.concern_type}</td>
										<td>{concern?.description || 'N/A'}</td>
										<td>{`${getDateTime(concern?.created_at, 'dd/mm/yyyy')}, ${getDateTime(concern?.created_at, null, false)}` || 'N/A'}</td>
										<td>
											<EyeOpen onClick={() => handleItemClick(concern?.id)} />
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{concerns?.results?.map((concern) => (
						<div key={concern?.id} className="mobile-card">
							<div className="flexed">
								<div>
									<b>{control === 'businesses' ? "Business's" : "Intern's"} Name</b>
									<p>{concern?.raised_by_info?.name}</p>
								</div>
								<div className="action-con" onClick={() => handleItemClick(concern?.id)}>
									<EyeOpen />
								</div>
							</div>
							<div className="flexed">
								<div>
									<b>Concern Type</b>
									<p>{concern?.concern_type}</p>
								</div>
								<div>
									<b>Date & Time</b>
									<p>{`${getDateTime(concern?.created_at, 'dd/mm/yyyy')}, ${getDateTime(concern?.created_at, null, false)}` || 'N/A'}</p>
								</div>
							</div>
							<div>
								<div>
									<b>More Information</b>
									<p>{concern?.description || 'N/A'}</p>
								</div>
								<div></div>
							</div>
						</div>
					))}

					<Pagination page={page} setPage={setPage} maxPages={Math.ceil(concerns?.total / pageSize.current)} disabled={isLoading} />
				</>
			)}
		</Container>
	);
};
export default Concerns;
